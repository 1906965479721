import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { routesMapper } from "../../utils/constant";
import { Typography } from "@mui/material";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { routesForBreadcrumbs } from "../../utils/constant";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { spacing } from "@mui/system";

function Breadcrumb({ props }) {
  const crumbs = useBreadcrumbs(routesForBreadcrumbs);

  const selectedCrumbs = crumbs.filter((crumb) => {
    return routesMapper.includes(crumb.match.pattern.path);
  });

  if (selectedCrumbs.length <= 1) return null;
  const last = selectedCrumbs.pop();

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {selectedCrumbs.map(({ match, breadcrumb }) => (
        <Link
          key={match.pathname}
          to={match.pathname}
          style={{ textDecoration: "none", color: "#00aa55" }}
        >
          {breadcrumb}
        </Link>
      ))}
      <Typography color="text.primary">
        {last.breadcrumb}
        {props ? (
          <label
            className="align-middle"
            style={{ color: "#00aa55", marginTop: "0px" }}
          >
            <span
              className="align-middle"
              style={{
                color: "#00aa55",
                paddingLeft: "2px",
                paddingRight: "2px",
              }}
            >
              {" "}
              >{" "}
            </span>
            {props}
          </label>
        ) : (
          ""
        )}
      </Typography>
    </Breadcrumbs>
  );
}

export default Breadcrumb;
