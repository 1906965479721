import {
  getVenderExist,
  deleteServices,
  addNotesByID,
  addServices,
  deleteNoteApi,
  updateNotesByID,
} from "../../../utils/apis.utils";
import { startNotesLoader, stopNotesLoader } from "../notes/notes.slice";
import { createQueryString } from "../../../utils/global/global";
import { fetchNotesByIdThunk } from "./notes.slice";

import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import { useDebugValue } from "react";

export const fetchVenderExistHandler = async (data, thunkApi) => {
  thunkApi.dispatch(startNotesLoader());
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.post(getVenderExist, data);

  thunkApi.dispatch(stopNotesLoader());
  if (response?.message === "Vendor found") {
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
  } else {
    thunkApi.dispatch(
      showFailureToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
  }
  return {
    data: response.data,
  };
};

// export const deleteNotesHandler = async (data, thunkApi) => {
//   try {
//     thunkApi.dispatch(startNotesLoader());
//     const { vendor } = thunkApi.extra.apiService;
//     const response = await vendor.delete(deleteNoteApi + "/" + data.id);
//     thunkApi.dispatch(stopNotesLoader());
//     thunkApi.dispatch(
//       showSuccessToast({
//         message: response.message,
//         vertical: "top",
//         horizontal: "right",
//       })
//     );

//     thunkApi.dispatch(fetchNotesByIdThunk(data.page));

//     // return id;
//   } catch (err) {
//     thunkApi.dispatch(stopNotesLoader());
//     thunkApi.dispatch(showFailureToast({ message: err }));
//     return Promise.reject(err);
//   } finally {
//     thunkApi.dispatch(stopNotesLoader());
//   }
// };

// export const addNotesByIdHandler = async (data, thunkApi) => {
//   try {
//     thunkApi.dispatch(startNotesLoader());
//     const { vendor } = thunkApi.extra.apiService;
//     const response = await vendor.post(addNotesByID, {
//       notes: data.name,
//     });
//     thunkApi.dispatch(stopNotesLoader());
//     thunkApi.dispatch(
//       showSuccessToast({
//         message: response.message,
//         vertical: "top",
//         horizontal: "right",
//       })
//     );
//     thunkApi.dispatch(fetchNotesByIdThunk(data.page));

//     // return response.data;
//   } catch (err) {
//     thunkApi.dispatch(stopNotesLoader());
//     thunkApi.dispatch(showFailureToast({ message: err }));
//     return Promise.reject(err);
//   } finally {
//     thunkApi.dispatch(stopNotesLoader());
//   }
// };

// export const updateNotesHandler = async (data, thunkApi) => {
//   var id = data.id;
//   var apiData = {
//     notes: data.notes,
//   };

//   try {
//     thunkApi.dispatch(startNotesLoader());
//     const { vendor } = thunkApi.extra.apiService;
//     const response = await vendor.put(updateNotesByID + "/" + id, apiData);
//     thunkApi.dispatch(stopNotesLoader());
//     thunkApi.dispatch(
//       showSuccessToast({
//         message: response.message,
//         vertical: "top",
//         horizontal: "right",
//       })
//     );
//     return response.data;
//   } catch (err) {
//     thunkApi.dispatch(stopNotesLoader());
//     thunkApi.dispatch(showFailureToast({ message: err }));
//     return Promise.reject(err);
//   } finally {
//     thunkApi.dispatch(stopNotesLoader());
//   }
// };
