import { Checkbox, FormControl } from "@mui/material";
import { useEffect } from "react";
import { advanceFilterFieldType } from "../../utils/enum";
import StyledTreeItem from "./StylesTreeItem";

function FilterBoolean({ label, value, handleFilterValueChange, state }) {
  function handleChange(e) {
    const eleValue = e.target.value;
    if (eleValue == "false") {
      return handleFilterValueChange(
        advanceFilterFieldType.boolean,
        value,
        true
      );
    }
    handleFilterValueChange(advanceFilterFieldType.boolean, value, false);
  }

  return (
    <StyledTreeItem nodeId={value} labelText={label}>
      <FormControl sx={{ ml: 5, mb: 2, width: 10 }}>
        <Checkbox
          checked={state.values && state.values[0] ? true : false}
          onChange={handleChange}
          value={state.values && state.values[0] ? true : false}
        />
      </FormControl>
    </StyledTreeItem>
  );
}

export default FilterBoolean;
