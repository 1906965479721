import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
// material
import { Menu, MenuItem, IconButton, ListItemText } from "@mui/material";

export default function ServiceMoreMenu({ handleAddRow }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton
        component="span"
        onClick={() => {
          setIsOpen(true);
        }}
        sx={{ padding: "0" }}
        ref={ref}
      >
        <AddIcon
          sx={{
            border: "none",
            backgroundColor: "#fff",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            padding: "7px",
            color: "#9c9ea4",
            "&:hover": {
              color: "#00aa55",
            },
          }}
        />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 150, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: "text.secondary" }}
          onClick={() => {
            setIsOpen(false);
            handleAddRow();
          }}
        >
          <ListItemText
            primary="Add Service"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
