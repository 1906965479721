import {
  Autocomplete,
  Chip,
  FormControl,
  ListItem,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { shortlistingFilterFieldType } from "../../utils/enum";

function Dropdown({
  width = "300px",
  label,
  value,
  state,
  handleFilterValueChange,
  deleteFilterValue,
  options,
}) {
  const [inputValue, setInputValue] = useState("");

  function handleSelect(_, newValue) {
    setInputValue("");
    handleFilterValueChange(
      shortlistingFilterFieldType.dropdown,
      value,
      newValue
    );
  }

  return (
    <>
      <FormControl sx={{ m: 2, width: width }}>
        <Autocomplete
          inputValue={inputValue}
          options={options}
          sx={{ width: width }}
          renderInput={(params) => <TextField {...params} label={label} />}
          onInputChange={(e, newVal) => setInputValue(newVal)}
          onChange={handleSelect}
        />
      </FormControl>
      <ListItem sx={{ display: "flex", flexWrap: "wrap" }}>
        {state.values?.map((ele) => (
          <Chip
            label={ele}
            onDelete={() =>
              deleteFilterValue(
                shortlistingFilterFieldType.dropdown,
                value,
                ele
              )
            }
            sx={{ margin: "0.5rem" }}
          />
        ))}
      </ListItem>
    </>
  );
}

export default Dropdown;
