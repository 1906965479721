import React from "react";
import {
  Modal,
  Box,
  Stack,
  Typography,
  IconButton,
  Card,
  Container,
  TextField,
  InputAdornment,
  FormControlLabel,
  Button,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik, Form, FormikProvider } from "formik";
import FormControl from "@mui/material/FormControl";
import Element from "../Form/Element";
import { inputType } from "../../utils/enum";
import Dropdown from "../Form/Dropdown";
import MobileInput from "../Form/MobileInput";
import { createNewAccountValidation } from "../../utils/validations/vendor.validation";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { green } from "@mui/material/colors";
import { assignVendorToProfileHandler } from "../../utils/global/vendor.global";
import { useDispatch } from "react-redux";
import { showFailureToast } from "../../store/slices/toast/toast.slice";

function AddAccountModal({ handleClose, setAssignedVendor }) {
  const { values, handleBlur, handleChange, handleSubmit, touched, errors } =
    useFormik({
      initialValues: {
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
      },
      validationSchema: createNewAccountValidation,
      onSubmit: onSubmit,
    });
  const dispatch = useDispatch();

  function onSubmit(data) {
    assignVendorToProfileHandler({ ...data, ...data.mobile })
      .then((response) => {
        setAssignedVendor(response.data[0]);
        handleClose();
      })
      .catch((err) => {
        dispatch(
          showFailureToast({
            message: err,
            vertical: "top",
            horizontal: "right",
          })
        );
      });
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack direction="row" spacing={3} mb={2}>
        <Element
          eletype={inputType.input}
          label="First Name"
          inputProps={{
            onChange: handleChange,
            onBlur: handleBlur,
            name: "first_name",
          }}
          value={values.first_name}
          errorText={touched.first_name && errors.first_name}
        />

        <Element
          eletype={inputType.input}
          label="Last Name"
          inputProps={{
            onChange: handleChange,
            onBlur: handleBlur,
            name: "last_name",
          }}
          value={values.last_name}
          errorText={touched.last_name && errors.last_name}
        />
      </Stack>
      <Stack direction="row" spacing={3} mb={2}>
        <Element
          eletype={inputType.input}
          label="Email"
          inputProps={{
            onChange: handleChange,
            onBlur: handleBlur,
            name: "email",
          }}
          value={values.email}
          errorText={touched.email && errors.email}
        />
      </Stack>
      <Stack direction="row" spacing={3} mb={2}>
        <MobileInput
          label="Mobile"
          name="mobile"
          value={values.mobile}
          onChange={handleChange}
          onBlur={handleBlur}
          errorText={touched.mobile && errors.mobile?.mobile_number}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          color="secondary"
          startIcon={<SaveIcon />}
          variant="contained"
          sx={{
            background: green[500],
            ":hover": { background: green[300] },
          }}
          type="submit"
        >
          Save
        </Button>
      </Stack>
    </form>
  );
}

export default AddAccountModal;
