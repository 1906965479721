import TreeView from "@mui/lab/TreeView";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Icon,
  Stack,
  Typography,
} from "@mui/material";
import SimpleBar from "simplebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { inputType } from "../../utils/enum";
import Element from "../Form/Element";
import { useDispatch, useSelector } from "react-redux";
import { getServicesListThunk } from "../../store/slices/onboard/onboard.slice";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { benchStrengthLocationHandler, countryFormatter } from "../../utils/global/vendor.global";
import SearchCountry from "../Modal/SearchCountry";
import { indianRegions } from "../../utils/constant";

const FilterProfiles = ({ handleFilterClose }) => {
  const dispatch = useDispatch();
  const { servicesList } = useSelector(({ onBoard }) => onBoard.data);

  const [l0Services, setL0Services] = useState([]);
  const [l1Services, setL1Services] = useState([]);

  useEffect(() => {
    dispatch(getServicesListThunk());
  }, []);

  useEffect(() => {
    const l0 = servicesList.map((item) => ({
      title: item?.title,
      _id: item?._id,
    }));
    setL0Services(l0);

    const l1 = servicesList
      .map((item) =>
        item?.sub_services.map((ele) => ({
          title: ele?.title,
          _id: ele?._id,
        }))
      )
      .flat();
    // console.log("l1", l1);
    setL1Services(l1);
  }, [servicesList]);

  const applyFilter = (data) => {
    console.log(data, "qwedata");
    const filter_data = {
        services: [data.services],
        sub_services: [data.sub_services],
        location: [data.location],
      };
    }


  const filterFormik = useFormik({
    initialValues: {
      services: [],
      sub_services: [],
      location: [],
    },
    onSubmit: applyFilter,
  });

  useEffect(() => {
    const filteredL1 = servicesList.filter(
      (item) => item?._id === filterFormik.values.services
    );

    const l1 = filteredL1[0]?.sub_services.map((elem) => ({
      _id: elem._id,
      title: elem.title,
    }));

    setL1Services(l1);
  }, [filterFormik.values.services]);

  const handleResetFilter = () => {
    filterFormik.setValues({
      services: [],
      sub_services: [],
      location: [],
    });
  };

  return (
    <>
      <Container>
        <Box>
          <Grid container>
            <Grid item md={12}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div" pt={2} mb={1}>
                  Filter By
                </Typography>
                <Icon
                  icon="eva:close-outline"
                  style={{ fontSize: "27px", cursor: "pointer" }}
                  onClick={handleFilterClose}
                />
              </Stack>
              <Divider />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ flexBasis: "430px" }}>
                  <Grid container>
                    <Grid item md={12} p={1}>
                      <Element
                        label="Services"
                        inputProps={{
                          name: "services",
                          onChange: filterFormik.handleChange,
                          onBlur: filterFormik.handleBlur,
                        }}
                        value={filterFormik.values.services}
                        options={l0Services}
                        errorText={
                          filterFormik.touched.services &&
                          filterFormik.errors.services
                        }
                        eletype={inputType.select}
                        icons={
                          <ArrowDropDownIcon
                            sx={{ position: "absolute", right: 20, bottom: 24 }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item md={12} p={1}>
                      <Element
                        label="Sub Services"
                        inputProps={{
                          name: "sub_services",
                          onChange: filterFormik.handleChange,
                          onBlur: filterFormik.handleBlur,
                        }}
                        value={filterFormik.values.sub_services}
                        options={l1Services}
                        errorText={
                          filterFormik.touched.sub_services &&
                          filterFormik.errors.sub_services
                        }
                        eletype={inputType.select}
                        icons={
                          <ArrowDropDownIcon
                            sx={{ position: "absolute", right: 20, bottom: 24 }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item md={12} p={1}>
                      <Element
                        label="Location (State)"
                        inputProps={{
                          name: "location",
                          onChange: filterFormik.handleChange,
                          onBlur: filterFormik.handleBlur,
                        }}
                        value={filterFormik.values.location}
                        options={indianRegions}
                        errorText={
                          filterFormik.touched.location &&
                          filterFormik.errors.location
                        }
                        eletype={inputType.select}
                        icons={
                          <ArrowDropDownIcon
                            sx={{ position: "absolute", right: 20, bottom: 24 }}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <Box
                    sx={{ textAlign: "center", width: "100%" }}
                    mt={3}
                    mb={3}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ padding: "10px 30px", marginRight: "10px" }}
                      onClick={() => {
                        handleFilterClose();
                        // applyFilter();
                        filterFormik.handleSubmit();
                      }}
                    >
                      Apply Filter
                    </Button>

                    <Button
                      color="inherit"
                      size="large"
                      sx={{ padding: "10px 20px", border: "2px solid black" }}
                      onClick={handleResetFilter}
                    >
                      Reset Filter
                    </Button>
                  </Box>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
export default FilterProfiles;
