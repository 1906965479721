import React from "react";
import ReactDOM from "react-dom";

import "./Loader.css";

function Loader({ bg = "rgba(0, 0, 0, 0.4)", height = "100vh" }) {
  return ReactDOM.createPortal(
    <div className="loader" style={{ backgroundColor: bg, height: height }}>
      <div
        id="loading-indicator"
        style={{ width: "60px", height: "60px" }}
        role="progressbar"
        className={
          "MuiCircularProgress-root MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate"
        }
      >
        <svg viewBox="22 22 44 44" className="MuiCircularProgress-svg">
          <circle
            cx="44"
            cy="44"
            r="20.2"
            fill="none"
            strokeWidth="3.6"
            className={
              "MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate"
            }
          ></circle>
        </svg>
      </div>
    </div>,
    document.getElementById("loader")
  );
}

export default Loader;
