import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllSkillsHandler,
  deleteSkillsHandler,
  addSkillsHandler,
  updateSkillsHandler,
} from "./skills.thunk";

const INIT_STATE = {
  skillsdata: [],
  isLoading: false,
};

export const fetchAllSkillsThunk = createAsyncThunk(
  "fetchAllSkills",
  fetchAllSkillsHandler
);

export const deleteSkillsThunk = createAsyncThunk(
  "deleteSkills",
  deleteSkillsHandler
);

export const AddSkillsThunk = createAsyncThunk("addSkills", addSkillsHandler);

export const updateSkillThunk = createAsyncThunk(
  "updateSkills",
  updateSkillsHandler
);

const skillsSlice = createSlice({
  name: "skills",
  initialState: INIT_STATE,
  reducers: {
    startSkillsLoader: (state) => {
      state.isLoading = true;
    },
    stopSkillsLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSkillsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllSkillsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.skillsdata = action.payload;
      })
      .addCase(fetchAllSkillsThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteSkillsThunk.fulfilled, (state, action) => {
        var id = action.payload;
        var newData = state.skillsdata.filter((data) => data._id !== id);
        state.skillsdata = newData;
        return state;
      })
      .addCase(AddSkillsThunk.fulfilled, (state, action) => {
        state.skillsdata.push(action.payload);
      })
      .addCase(updateSkillThunk.fulfilled, (state, action) => {
        const idx = state.skillsdata.findIndex(
          (ele) => ele._id === action.payload._id
        );
        state.skillsdata[idx] = action.payload;
        return state;
      });
    // .addCase(deleteSkillsThunk.fulfilled, (state, action) => {
    //
    //   state.isLoading = false;
    //   state.servicedata = action.payload;
    // })
    // .addCase(deleteSkillsThunk.rejected, (state) => {
    //
    //   state.isLoading = false;
    // });
  },
});

export const { startSkillsLoader, stopSkillsLoader } = skillsSlice.actions;

export default skillsSlice.reducer;
