import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllServicesHandler,
  deleteServicesHandler,
  addServicesHandler,
  updateServicesHandler,
} from "./service.thunk";

const INIT_STATE = {
  servicedata: [],
  isLoading: false,
};

export const fetchAllSericesThunk = createAsyncThunk(
  "fetchAllServices",
  fetchAllServicesHandler
);

export const deleteSericesThunk = createAsyncThunk(
  "deleteService",
  deleteServicesHandler
);

export const AddSericesThunk = createAsyncThunk(
  "addService",
  addServicesHandler
);

export const updateSericesThunk = createAsyncThunk(
  "updateService",
  updateServicesHandler
);

const serviceSlice = createSlice({
  name: "services",
  initialState: INIT_STATE,
  reducers: {
    startServiceLoader: (state) => {
      state.isLoading = true;
    },
    stopServiceLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSericesThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllSericesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.servicedata = action.payload;
      })
      .addCase(fetchAllSericesThunk.rejected, (state) => {
        state.isLoading = false;
      });
    //   .addCase(deleteSericesThunk.pending, (state) => {
    //
    //   state.isLoading = true;
    // })
    // .addCase(deleteSericesThunk.fulfilled, (state, action) => {
    //
    //   state.isLoading = false;
    //   state.servicedata = action.payload;
    // })
    // .addCase(deleteSericesThunk.rejected, (state) => {
    //
    //   state.isLoading = false;
    // });
  },
});

export const { startServiceLoader, stopServiceLoader } = serviceSlice.actions;

export default serviceSlice.reducer;
