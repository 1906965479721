export const typeOfCompanyOptions = [
  {
    label: "Private Limited Company",
    value: "Private Limited Company",
  },
  {
    label: "Public Limited Company",
    value: "public-limited-company",
  },
  {
    label: "Sole Proprietorship",
    value: "sole-proprietorship",
  },
  {
    label: "Partnership",
    value: "partnership",
  },
  {
    label: "Limited Liability Partnership (LLP)",
    value: "limited-liability-partnership",
  },
  {
    label: "Liaison Office",
    value: "liaison office",
  },
  {
    label: "Subsidiary Company",
    value: "subsidiary-company",
  },
  {
    label: "Unlimited Company",
    value: "unlimited-company",
  },
  {
    label: "Joint Hindu Family",
    value: "joint-hindu-family",
  },
  {
    label: "Team Of Freelancers",
    value: "team-of-freelancers",
  },
];

export const companyTurnoverOptions = [
  {
    label: "0-2 Cr",
    value: "0-2 Cr",
  },
  {
    label: "2-5 Cr",
    value: "2-5 Cr",
  },
  {
    label: "5-10 Cr",
    value: "5-10 Cr",
  },
  {
    label: "10-20 Cr",
    value: "10-20 Cr",
  },
  {
    label: "20-50 Cr",
    value: "20-50 Cr",
  },
  {
    label: "Above 50 cr",
    value: "Above 50 cr",
  },
];

export const numberOfEmployeesOptions = [
  {
    label: "1-50",
    value: "1-50",
  },
  {
    label: "51-200",
    value: "51-200",
  },
  {
    label: "201-500",
    value: "201-500",
  },
  {
    label: "500-1000",
    value: "500-1000",
  },
  {
    label: "1000-2000",
    value: "1000-2000",
  },
  {
    label: "2000-5000",
    value: "2000-5000",
  },
  {
    label: "5000+",
    value: "5000+",
  },
];

export const noOfClientsOptions = [
  {
    label: "0-10",
    value: "0-10",
  },
  {
    label: "10-30",
    value: "10-30",
  },
  {
    label: "30-50",
    value: "30-50",
  },
  {
    label: "50+",
    value: "50+",
  },
];

export const ageRangeOfPOC = [
  {
    label: "20-25",
    value: "20-25",
  },
  {
    label: "26-30",
    value: "26-30",
  },
  {
    label: "30-35",
    value: "30-35",
  },
  {
    label: "35-40",
    value: "35-40",
  },
  {
    label: "45-50",
    value: "45-50",
  },
  {
    label: "50+",
    value: "50+",
  },
];

export const personalityPOC = [
  {
    label: "Manipulative",
    value: "Manipulative",
  },
  {
    label: "Consultative",
    value: "Consultative",
  },
  {
    label: "Yes-Man",
    value: "Yes-Man",
  },
];
export const escalationLevel = [
  {
    label: "NA",
    value: "na",
  },
  {
    label: "L1",
    value: "l1",
  },
  {
    label: "L2",
    value: "l2",
  },
  {
    label: "L3",
    value: "l3",
  },
];

export const budgetOptions = [
  {
    label: "0-10k",
    value: "0-10k",
  },
  {
    label: "10-20k",
    value: "10-20k",
  },
  {
    label: "20-50k",
    value: "20-50k",
  },
  {
    label: "50-100k",
    value: "50-100k",
  },
  {
    label: "100-300k",
    value: "100-300k",
  },
  {
    label: "300-500k",
    value: "300-500k",
  },
  {
    label: "500-1000k",
    value: "500-1000k",
  },
  {
    label: "1000k+",
    value: "1000k+",
  },
];

export const checkboxGroupOptions = [
  {
    name: "Budget",
    value: "Budget",
  },
  {
    name: "Industry",
    value: "Industry",
  },
  {
    name: "Region",
    value: "Region",
  },
  {
    name: "Tech Stack",
    value: "Tech Stack",
  },
  {
    name: "Client Personal",
    value: "Client Personal",
  },
];

//sass Partner options start here
export const partnersOptions = [
  {
    label: "SaaS Partner",
    value: "saas_partner",
  },
  {
    label: "Channel Partner",
    value: "channel_partner",
  },
  {
    label: "Implementation Partner",
    value: "implementation_partner",
  },
  {
    label: "Platinum Member",
    value: "platinum_member",
  },
  {
    label: "Gold Member",
    value: "gold_member",
  },
  {
    label: "Silver Member",
    value: "silver_member",
  },
];

//portfolio options
export const clientPersonaOptions = [
  {
    label: "Small Business",
    value: "small_business",
  },
  {
    label: "Mid Market Business",
    value: "mid_market_business",
  },
  {
    label: "Enterprise Business",
    value: "enterprise_business",
  },
];

export const platformOptions = [
  {
    label: "Web",
    value: "web",
  },
  {
    label: "App",
    value: "app",
  },
];

export const backendOptions = [
  {
    label: "Backend 1",
    value: "backend_one",
  },
  {
    label: "Backend 2",
    value: "backend_two",
  },
  {
    label: "Backend 3",
    value: "backend_three",
  },
];

export const frontendOptions = [
  {
    label: "frontend 1",
    value: "frontend_one",
  },
  {
    label: "frontend 2",
    value: "frontend_two",
  },
  {
    label: "frontend 3",
    value: "frontend_three",
  },
];

export const databaseOptions = [
  {
    label: "database 1",
    value: "database_one",
  },
  {
    label: "database 2",
    value: "database_two",
  },
  {
    label: "database 3",
    value: "database_three",
  },
];

export const cloudOptions = [
  {
    label: "cloud 1",
    value: "cloud_one",
  },
  {
    label: "cloud 2",
    value: "cloud_two",
  },
  {
    label: "cloud 3",
    value: "cloud_three",
  },
];

//founder options start here
export const founderOptions = [
  {
    label: "Founder",
    value: "founder",
  },
  {
    label: "Co-Founder",
    value: "co_founder",
  },
  {
    label: "C-Suite",
    value: "c_suite",
  },
];

//new option added in basic info 
export const augmentationOptions = [
  {
    name: "On Site",
    value: "on_site",
  },
  {
    name: "Remote",
    value: "remote",
  },
];

export const cmmiLevelOptions = [
  {
    label: "None",
    value: "none",
  },
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "4",
    value: "4",
  },
  {
    label: "5",
    value: "5",
  },
];

export const turnOverYears = [
  {
    label: "2021-2022",
    value: "2021-2022",
  },
  {
    label: "2022-2023",
    value: "2022-2023",
  },
  {
    label: "2023-2024",
    value: "2023-2024",
  },
];
export const certificationType = [
  {
    label: "Sass Partner",
    value: "sass_partner",
  },
  {
    label: "Channel Partner",
    value: "channel_partner",
  },
  {
    label: "Implementation Partner",
    value: "implementation_partner",
  },
  {
    label: "Platinum Member",
    value: "platinum_member",
  },
  {
    label: "Gold Member",
    value: "gold_member",
  },
  {
    label: "Silver Member",
    value: "silver_member",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const certificationNameType = [
  {
    label: "CMMI",
    value: "cmmi",
  },
  {
    label: "ISO",
    value: "iso",
  },
];

//address type line option
export const addressTypes = [
  {
    label: "Registered",
    value: "registered",
  },
  {
    label: "Branch Office",
    value: "branch_office",
  },
];

export const resourceTypesOption = [
  {
    label: "Developer",
    value: "developer",
  },
  {
    label: "BA",
    value: "ba",
  },
  {
    label: "PM",
    value: "pm",
  },
  {
    label: "QA",
    value: "qa",
  },
  {
    label: "Designer",
    value: "designer",
  },
  {
    label: "Technical Architect",
    value: "technical_architect",
  },
  {
    label: "Others",
    value: "other",
  },
];

//tech stack options
export const techStackOptions = [
  {
    label: "Test Stack 1",
    value: "test_stack_1",
  },
  {
    label: "Test Stack 2",
    value: "test_stack_2",
  },
  {
    label: "Test Stack 3",
    value: "test_stack_3",
  },
];

//platform review options

export const platformReviewOptions = [
  {
    label: "Google Review Rating",
    value: "google_review_rating",
  },
  {
    label: "Clutch Rating",
    value: "clutch_rating",
  },
  {
    label: "GoodFirms Rating",
    value: "goodfirms_rating",
  },
  {
    label: "G2 Rating",
    value: "g2_rating",
  },
  {
    label: "Ambition Box Rating",
    value: "ambition_box_rating",
  },
  {
    label: "Trustpilot Rating",
    value: "trustpilot_rating",
  },
  {
    label: "Glassdoor Rating",
    value: "glassdoor_rating",
  },
];

//bench strength Resource Bifurcation

export const resourceBifurcationOption = [
  {
    label: "Frontend",
    value: "frontend",
  },
  {
    label: "Backend",
    value: "backend",
  },
  {
    label: "Devops",
    value: "devops",
  },
];
