import { useMemo, useRef, useState, useEffect } from "react";
import { useStyles } from "./Form.theme";
import { Chip, ListItem } from "@mui/material";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { getVirtualElement } from "../../utils/global/global";

const MultiSelectDropdown = ({
  label,
  onChange,
  name,
  placeholder,
  options = [],
  inputProps,
  style,
  value,
  errorText,
  icons,
  disabled,
  resetTheField,
  refOptions = null,
  dropdownIcon = true,
}) => {
  const classes = useStyles();
  const [input, setInput] = useState("");
  const [chipsData, setChipsData] = useState([]);
  const [showMenuItem, setShowMenuItem] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const refElement = useMemo(() => {
    return getVirtualElement({ ...inputProps, type: "input" });
  }, []);

  var dropdownRef = useRef();

  useOnClickOutside(dropdownRef, () => setShowMenuItem(false));

  useEffect(() => {
    setFilteredOptions(
      options.filter(option => 
        option.label?.toLowerCase().includes(input.toLowerCase()) || 
        option.title?.toLowerCase().includes(input.toLowerCase()) ||
        option.name?.toLowerCase().includes(input.toLowerCase())
      )
    );
  }, [input, options]);

  function handleDelete(idx) {
    const copiedData = chipsData.filter((ele) => ele !== idx);
    setChipsData(copiedData);
    onChange({
      target: {
        name: name,
        value: copiedData,
      },
    });
  }

  function handleItemClick(value) {
    if (!chipsData.includes(value)) {
      const newChipsData = [...chipsData, value];
      setChipsData(newChipsData);
      onChange({
        target: {
          name: name,
          value: newChipsData,
        },
      });
    }
    setShowMenuItem(false);
    setInput("");
  }

  function renderMenu() {
    return (
      <>
        {!disabled ? (
          <div className={classes.menuWrapper} ref={dropdownRef}>
            <ul className={classes.dropDown}>
              {filteredOptions.map((option) => (
                <li
                  key={option.value || option._id || option.id}
                  className={classes.dropdownItem}
                  onClick={() => handleItemClick(option.label || option.title || option.name)}
                >
                  {option.label || option.title || option.name}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }

  return (
    <>
      <div className={classes.formGroup}>
        {label ? (
          <label className={classes.formLabel} style={style}>
            {label}
          </label>
        ) : null}
        <input
          className={classes.formControl}
          placeholder={placeholder}
          onChange={(e) => {
            setInput(e.target.value);
            setShowMenuItem(true);
          }}
          value={input}
          errorText={errorText}
        />
      </div>

      {chipsData.map((val, index) => (
        <ListItem key={index} sx={{ display: "contents" }}>
          <Chip
            label={val}
            onDelete={() => handleDelete(val)}
            sx={{ marginRight: "1rem", marginBottom: "1rem" }}
          />
        </ListItem>
      ))}
      {showMenuItem && renderMenu()}
    </>
  );
};

export default MultiSelectDropdown;
