import React, { useEffect, useState } from "react";

import {
  Box,
  Grid,
  Button,
  Typography,
  Rating,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import Element from "../../components/Form/Element";

import { inputType } from "../../utils/enum";

function ViewRating({ handleChange, handleBlur, style }) {
  const [ratingVal, setRating] = useState(5);
  const [ratingVal2, setRating2] = useState(4);
  const [ratingVal3, setRating3] = useState(3);
  const [ratingVal4, setRating4] = useState(2);
  const [ratingVal5, setRating5] = useState(4);
  const [ratingVal6, setRating6] = useState(1);
  const [ratingVal7, setRating7] = useState(3);
  return (
    <>
      <Box>
        <form>
          <Grid container spacing={2} p={3}>
            <Grid item md={12} pb={2} justify="center">
              <Typography variant="h5" align="center">
                Add Review By KAM
              </Typography>
              <Box mt={2}>
                <Element
                  eletype={inputType.input}
                  label="Title"
                  inputProps={{
                    name: "title",
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                  value="sourabh"
                />

                <Grid container spacing={4} mb={1}>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "18px",

                        color: "#a3a2a2f7",
                        marginBottom: "8px",
                      }}
                    >
                      Communication Skills
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "18px",

                        color: "#a3a2a2f7",
                        marginBottom: "8px",
                      }}
                    >
                      Problem Solving
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "18px",

                        color: "#a3a2a2f7",
                        marginBottom: "8px",
                      }}
                    >
                      Turnaround Time
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "18px",

                        color: "#a3a2a2f7",
                        marginBottom: "8px",
                      }}
                    >
                      Client Handling
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "18px",

                        color: "#a3a2a2f7",
                        marginBottom: "8px",
                      }}
                    >
                      Delivery Timeline
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "18px",

                        color: "#a3a2a2f7",
                        marginBottom: "8px",
                      }}
                    >
                      Understanding of Scope
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "18px",

                        color: "#a3a2a2f7",
                        marginBottom: "8px",
                      }}
                    >
                      helpful
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "18px",

                        color: "#a3a2a2f7",
                        marginBottom: "8px",
                      }}
                    >
                      Way of Talking
                    </Typography>
                  </Grid>

                  <Grid item xs={5} textAlign="end">
                    <Rating
                      name="simple-controlled"
                      value={ratingVal}
                      sx={{ fontSize: "30px" }}
                      onChange={(event, newValue) => {
                        setRating(newValue);
                      }}
                    />

                    <Rating
                      name="simple-controlled"
                      value={ratingVal2}
                      sx={{ fontSize: "30px" }}
                      onChange={(event, newValue) => {
                        setRating2(newValue);
                      }}
                    />
                    <Rating
                      name="simple-controlled"
                      value={ratingVal3}
                      sx={{ fontSize: "30px" }}
                      onChange={(event, newValue) => {
                        setRating3(newValue);
                      }}
                    />
                    <Rating
                      name="simple-controlled"
                      value={ratingVal4}
                      sx={{ fontSize: "30px" }}
                      onChange={(event, newValue) => {
                        setRating4(newValue);
                      }}
                    />
                    <Rating
                      name="simple-controlled"
                      value={ratingVal5}
                      sx={{ fontSize: "30px" }}
                      onChange={(event, newValue) => {
                        setRating5(newValue);
                      }}
                    />
                    <Rating
                      name="simple-controlled"
                      value={ratingVal6}
                      sx={{ fontSize: "30px" }}
                      onChange={(event, newValue) => {
                        setRating6(newValue);
                      }}
                    />
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>

                    <FormControl sx={{ marginLeft: "51px" }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="polite"
                          control={<Radio />}
                          label="polite"
                        />
                        <FormControlLabel
                          value="aggresive"
                          control={<Radio />}
                          label="aggresive"
                        />
                        <FormControlLabel
                          value="abusive"
                          control={<Radio />}
                          label="abusive"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <Element
                  eletype={inputType.textarea}
                  label="Description"
                  inputProps={{
                    name: "title",
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                  value="sourabh"
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default ViewRating;
