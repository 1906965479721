import * as Yup from "yup";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch } from "react-redux";

// material
import { Link, Stack, Checkbox, FormControlLabel, Button } from "@mui/material";

// ----------------------------------------------------------------------
import Element from "../../Form/Element";
import { inputType } from "../../../utils/enum";
import { loginByEmailAsyncThunk } from "../../../store/slices/auth/auth.slice";

export default function LoginForm() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },

    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(
        loginByEmailAsyncThunk({
          ...values,
        })
      )
        .unwrap()
        .then(() => {
          navigate({
            pathname: "/",
          });
        });
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Element
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            inputProps={{ ...getFieldProps("email") }}
            error={Boolean(touched.email && errors.email)}
            errorText={touched.email && errors.email}
            eletype={inputType.input}
          />

          <Element
            fullWidth
            autoComplete="current-password"
            inputProps={{
              type: "password",
              ...getFieldProps("password"),
            }}
            label="Password"
            error={Boolean(touched.password && errors.password)}
            errorText={touched.password && errors.password}
            eletype={inputType.input}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps("remember")}
                checked={values.remember}
              />
            }
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="/forgotPassword">
            Forgot password?
          </Link>
        </Stack>

        <Button fullWidth size="large" type="submit" variant="contained">
          Login
        </Button>
      </Form>
    </FormikProvider>
  );
}
