import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllIndustryHandler,
  deleteIndustryHandler,
  addIndustryHandler,
  updateIndustryHandler,
} from "./industry.thunk";

const INIT_STATE = {
  industrydata: [],
  isLoading: false,
};

export const fetchAllIndustryThunk = createAsyncThunk(
  "fetchAllIndustry",
  fetchAllIndustryHandler
);

export const deleteIndustryThunk = createAsyncThunk(
  "deleteIndustry",
  deleteIndustryHandler
);

export const AddIndustryThunk = createAsyncThunk(
  "addIndustry",
  addIndustryHandler
);

export const updateIndustryThunk = createAsyncThunk(
  "updateIndustry",
  updateIndustryHandler
);

const industrySlice = createSlice({
  name: "industries",
  initialState: INIT_STATE,
  reducers: {
    startIndustryLoader: (state) => {
      state.isLoading = true;
    },
    stopIndustryLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllIndustryThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllIndustryThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.industrydata = action.payload;
      })
      .addCase(fetchAllIndustryThunk.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { startIndustryLoader, stopIndustryLoader } =
  industrySlice.actions;

export default industrySlice.reducer;
