import {
  getAllPortfolio,
  deletePortfolio,
  addPortfolio,
  pastworkApi,
  getAllPastWorkApi,
  getTechDataApi,
} from "../../../utils/apis.utils";
import {
  startPortfolioLoader,
  stopPortfolioLoader,
  fetchAllPortfolioThunk,
} from "../portfolio/portfolio.slice";
import { createQueryString } from "../../../utils/global/global";

import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import { fetchPastworkThunk } from "../vendor/vendor.slice";

export const fetchAllPortfolioHandler = async (page = 1, thunkApi) => {
  const query = createQueryString({
    page,
  });
  // thunkApi.dispatch(startPortfolioLoader());
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(getAllPortfolio + query);
  // thunkApi.dispatch(stopPortfolioLoader());
  return {
    data: response.data,
    total: response.total,
  };
  // return response.data;
};

export const deletePortfolioHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startPortfolioLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.delete(deletePortfolio + "/" + data.id);
    thunkApi.dispatch(stopPortfolioLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllPortfolioThunk(data.page));
    // return id;
  } catch (err) {
    thunkApi.dispatch(stopPortfolioLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopPortfolioLoader());
  }
};

export const addPortfolioHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startPortfolioLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.post(addPortfolio, data.data);
    thunkApi.dispatch(stopPortfolioLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllPortfolioThunk(data.page));
    // return response.data;
  } catch (err) {
    thunkApi.dispatch(stopPortfolioLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopPortfolioLoader());
  }
};

export const updatePortfolioHandler = async ({ id, data }, thunkApi) => {
  try {
    thunkApi.dispatch(startPortfolioLoader()); 
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.put(addPortfolio + "/" + id, { data: data });
    thunkApi.dispatch(stopPortfolioLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopPortfolioLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopPortfolioLoader());
  }
};

//past work handler
export const fetchPastworkHandler = async (page = 1, thunkApi) => {
  const query = createQueryString({
    page,
  });
  const { profile } = thunkApi.extra.apiService;
  const response = await profile.get(getAllPastWorkApi + query);
  return {
    data: response.data,
    total: response.total,
  };
};

export const fetchTechDataHandler = async (rb_type, thunkApi) => {
  const query = createQueryString({
    rb_type,
  });
  const { profile } = thunkApi.extra.apiService;
  const response = await Promise.all([
    profile.get(getTechDataApi + query),
    profile.get(getTechDataApi + query), // Adjust this to the actual endpoint for backend data
  ]);
  return {
    frontend: response?.[0]?.data,
    backend: response?.[1]?.data,
  };
};

export const createPastworkHandler = async ({ data, id }, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    var response = null;
    if (id) {
      response = await profile.put(`${pastworkApi}/${id}`, data);
    } else {
      response = await profile.post(pastworkApi, data);
    }
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchPastworkThunk(data.page));
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const deletePastworkHandler = async (id, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.delete(pastworkApi + "/" + id);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchPastworkThunk());
    return id;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const dupdatePastworkHandler = async ({ id, data }, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.put(pastworkApi + "/" + id, { data: data });
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchPastworkThunk());
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};