import { allVendorServices, getAllPortfolio } from "../apis.utils";
import { vendorAxios } from "../axios/vendor.axios";

export const fetchAllServicesOfVendor = () => {
  return vendorAxios.get(allVendorServices).then((response) => response.data);
};

export const fetchAllPortfoliosOfVendor = () => {
  return vendorAxios.get(getAllPortfolio).then((response) => response.data);
};
