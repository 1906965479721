import React, { useEffect } from "react";
import { Box, Card, Typography, Stack, Avatar, Grid } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DropZone from "../Portfolio/DropZone";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllUploadCOQThunk,
  addUploadCOQThunk,
} from "../store/slices/uploadcoq/uploadcoq.slice";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { DateTime } from "luxon";
import useBusinessId from "../hooks/useBusinessId";
import withBusinessId from "../hoc/withBusinessId";
import UploadLoader from "../components/Skeletons/UploadLoader";

function Upload_COQ() {
  const dispatch = useDispatch();
  const businessId = useBusinessId();

  const { uploadcoqdata } = useSelector((uploadCOQ) => uploadCOQ.uploadCOQ);

  useEffect(() => {
    dispatch(fetchAllUploadCOQThunk(businessId));
  }, [dispatch, businessId]);

  async function addAttachment(file) {
    if (!file.file || file.file?.length === 0) return;
    const formData = new FormData();
    file.file.forEach((ele) => {
      formData.append("files", ele);
    });
    dispatch(addUploadCOQThunk(formData));
  }

  return (
    <div>
      <Card>
        <Box p={5}>
          <Typography variant="h5"> Upload COQ</Typography>
          <Box py={3}>
            <DropZone addAttachment={addAttachment} accept=".pdf" />
          </Box>
          <Stack direction="row" spacing={5}>
            <Grid container spacing={2}>
              {uploadcoqdata &&
                uploadcoqdata?.map((data) => (
                  <Grid item xs={6} md={4}>
                    <a href={data.attachment_url} target="_blank">
                      <Card sx={{ width: "300px", borderRadius: "10px" }}>
                        <Box p={3}>
                          <Stack direction="row" spacing={2}>
                            <Avatar
                              sx={{ bgcolor: "#fff3f0" }}
                              variant="rounded"
                            >
                              {data.media_type === "pdf" ? (
                                <PictureAsPdfIcon sx={{ color: "#de4435" }} />
                              ) : (
                                <PhotoLibraryIcon sx={{ color: "#00c9a7" }} />
                              )}
                            </Avatar>
                            <Box>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: "500" }}
                              >
                                {data.filename?.length > 15
                                  ? data.filename.substring(0, 15) + "..."
                                  : data.filename}
                              </Typography>
                              <Typography sx={{ fontSize: "12px" }}>
                                {DateTime.fromISO(data.created_by).toFormat(
                                  "DD"
                                )}
                              </Typography>
                            </Box>
                            <Avatar
                              sx={{ bgcolor: "#f3f6f8", color: "#637281" }}
                            >
                              S
                            </Avatar>
                          </Stack>
                        </Box>
                      </Card>
                    </a>
                  </Grid>
                ))}
            </Grid>
          </Stack>
        </Box>
      </Card>
      {/* <UploadLoader /> */}
    </div>
  );
}

export default withBusinessId(Upload_COQ);
