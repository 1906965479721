import React, { useMemo } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  Button,
  Divider,
} from "@mui/material";
import ListSearchbar from "../Common/ListSearchbar";
import { useTheme } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { useState } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import TreeView from "@mui/lab/TreeView";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import FilterElement from "./FilterElement";
import { advanceFilterFieldType } from "../../utils/enum";
import { useSelector } from "react-redux";
import { typeOfCompanyOptions } from "../../utils/options.util";

function Filter({ handleFilterClose, filterState }) {
  const [search, setSearch] = useState("");
  const [filteredResults, setFilteredResults] = useState(filterData);

  const { servicedata } = useSelector(({ services }) => services);
  const { industrydata } = useSelector(({ industry }) => industry);
  const { techstack } = useSelector(({ techstack }) => techstack);
  const { tagData } = useSelector(({ tag }) => tag);

  const options_mapper = useMemo(() => {
    return {
      service_name: servicedata.map((ele) => ({
        label: ele.title,
        value: ele.title,
      })),
      industry: industrydata.map((ele) => ({
        label: ele.title,
        value: ele.title,
      })),
      tech_stack: techstack.map((ele) => ({
        label: ele.name,
        value: ele.name,
      })),
      tag: tagData.map((ele) => ({
        label: ele.tag,
        value: ele.tag,
      })),
      company_type: typeOfCompanyOptions,
    };
  }, []);

  function handleSearchChange(e) {
    const value = e.target.value;
    if (!value) return setFilteredResults(filterData);
    setSearch(value);
    const filteredData = filterData.filter((item) => {
      return item.label.toLowerCase().includes(value?.toLowerCase());
    });
    setFilteredResults(filteredData);
  }

  const { state, applyFilter, resetFilter, ...rest } = filterState;

  return (
    <Container>
      <Box>
        <Grid container>
          <Grid item md={12}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              justifyContent="space-between"
            >
              <Typography variant="h5" component="div" pt={2} mb={1}>
                Filter By
              </Typography>
              <Icon
                icon="eva:close-outline"
                style={{ fontSize: "27px", cursor: "pointer" }}
                onClick={handleFilterClose}
              />
            </Stack>
            <Divider />
            <ListSearchbar
              placeholder="Search Categories"
              handleSearchChange={handleSearchChange}
              styles={{
                width: "300px",
                marginLeft: "-1rem",
                borderRadius: "20px",
              }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ flexBasis: "430px" }}>
                <SimpleBar style={{ maxHeight: 400 }}>
                  <TreeView
                    aria-label="gmail"
                    defaultCollapseIcon={<ArrowDropDownIcon />}
                    defaultExpandIcon={<ArrowRightIcon />}
                    defaultEndIcon={<div style={{ width: 24 }} />}
                    sx={{
                      height: "auto",
                      flexGrow: 1,
                      maxWidth: 400,
                      overflowY: "auto",
                      mb: 0.5,
                    }}
                    multiSelect
                  >
                    {filteredResults.map((data, key) => (
                      <FilterElement
                        key={key}
                        {...data}
                        {...rest}
                        state={state[data.value]}
                        options={options_mapper[data.value]}
                      />
                    ))}
                  </TreeView>
                </SimpleBar>
              </div>
              <div>
                <Box sx={{ textAlign: "center", width: "100%" }} mt={3} mb={3}>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ padding: "10px 30px", marginRight: "10px" }}
                    onClick={() => {
                      handleFilterClose();
                      applyFilter();
                    }}
                  >
                    Apply Filter
                  </Button>

                  <Button
                    color="inherit"
                    size="large"
                    sx={{ padding: "10px 20px", border: "2px solid black" }}
                    onClick={resetFilter}
                  >
                    Reset Filter
                  </Button>
                </Box>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Filter;

var filterData = [
  {
    label: "Brand Name",
    value: "brand_name",
    type: advanceFilterFieldType.text,
  },
  {
    label: "Registered Business Name",
    value: "registered_business_name",
    type: advanceFilterFieldType.text,
  },
  {
    label: "City",
    value: "city",
    type: advanceFilterFieldType.text,
  },
  {
    label: "State",
    value: "state",
    type: advanceFilterFieldType.text,
  },
  {
    label: "Country",
    value: "country",
    type: advanceFilterFieldType.text,
  },
  {
    label: "Verified",
    value: "verified",
    type: advanceFilterFieldType.boolean,
  },
  {
    label: "Service Name",
    value: "service_name",
    type: advanceFilterFieldType.dropdown,
  },
  {
    label: "Tech Stack Name",
    value: "tech_stack",
    type: advanceFilterFieldType.dropdown,
  },
  {
    label: "Tag",
    value: "tag",
    type: advanceFilterFieldType.dropdown,
  },
  {
    label: "Industry",
    value: "industry",
    type: advanceFilterFieldType.dropdown,
  },

  {
    label: "Company Type",
    value: "company_type",
    type: advanceFilterFieldType.dropdown,
  },
  {
    label: "Year Of Establishment",
    value: "year_of_establishment",
    type: advanceFilterFieldType.date,
  },
];
