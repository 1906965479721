import React, { useState } from "react";
import {
  Container,
  Stack,
  Typography,
  Card,
  CardActions,
  Button,
  Grid,
} from "@mui/material";
//Component
import Page from "../../../components/Page";
import Review from "../../../components/Review/Review";

export default function ProjectFeedback() {
  const [questions, setQuestions] = useState(questionsData);
  const [feedback, setFeedback] = useState([]);
  const [current, setCurrent] = useState(0);

  function handleNext() {
    if (current === questions.length - 1) return;
    setCurrent((current) => current + 1);
  }

  function handlePrev() {
    if (current === 0) return;
    setCurrent((current) => current - 1);
  }

  function handleSubmit() {}

  const total = questions.length;

  const isFirst = current === 0;
  const isLast = current === total - 1;

  return (
    <Page title="Proposal | EMB">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Project Feedback
          </Typography>
        </Stack>

        <Card>
          <Review current={current} total={total} questions={questions} />

          <CardActions>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={4}>
                <Button
                  size="small"
                  sx={{ float: "left", opacity: isFirst ? 0.3 : 1 }}
                  onClick={handlePrev}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={2}
                  mr={2}
                >
                  <Button
                    size="small"
                    onClick={handleNext}
                    sx={{ opacity: isLast ? 0.3 : 1 }}
                  >
                    Next
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ opacity: isLast ? 1 : 0.3 }}
                    onClick={handleSubmit}
                  >
                    Done
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Container>
    </Page>
  );
}

var questionsData = [
  {
    question: "What do you want to do with XD?",
    subTitle:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo repellendus velit culpa, dolorum quod sapiente debitis voluptatem unde sunt fugit.",
    type: "radio",
    options: [
      {
        label: "What do you want to do",
        value: 1,
      },
      {
        label: "What do you want to do",
        value: 1,
      },
      {
        label: "What do you want to do",
        value: 1,
      },
      {
        label: "What do you want to do",
        value: 1,
      },
    ],
  },
  {
    question: "What do you want to do with XD?",
    subTitle:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo repellendus velit culpa, dolorum quod sapiente debitis voluptatem unde sunt fugit.",
    type: "radio",
    options: [
      {
        label: "What do you want to do 2",
        value: 1,
      },
      {
        label: "What do you want to do 2",
        value: 1,
      },
      {
        label: "What do you want to do 2",
        value: 1,
      },
      {
        label: "What do you want to do 2",
        value: 1,
      },
    ],
  },
  {
    question: "What do you want to do with XD?",
    subTitle:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo repellendus velit culpa, dolorum quod sapiente debitis voluptatem unde sunt fugit.",
    type: "rating",
  },
];
