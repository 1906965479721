/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Element from "../components/Form/Element";
import { inputType } from "../utils/enum";
import { socialMediaValidation } from "../utils/validations/vendor.validation";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { platformReviewOptions } from "../utils/options.util";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  createPlatformReviewThunk,
  deletePlatformReviewThunk,
  fetchPlatformReviewThunk,
  fetchVendorProfileDetailsThunk,
} from "../store/slices/vendor/vendor.slice";
import { addNullId, changeStringToNum } from "../hooks/changeStringToNum";
import usePermissions from "../hooks/usePermission";
import systemPermissions, { actions } from "../utils/global/permissions.global";

const SocialMediaRating = () => {
  const [selectedPlatform, setSelectedPlatform] = useState([]);
  const dispatch = useDispatch();
  const { platformReviewList } = useSelector(({ vendor }) => vendor);
  const userPerm = usePermissions();

  useEffect(() => {
    dispatch(fetchPlatformReviewThunk());
  }, [dispatch]);

  useEffect(() => {
    setFieldValue("socialMedia", platformReviewList);
  }, [platformReviewList]);

  const onSubmit = (data) => {
    delete data?.founder_selectOption;
    const respWithNumberCount = changeStringToNum(data?.socialMedia, "rating");
    const prefixIds = addNullId(respWithNumberCount);
    dispatch(
      createPlatformReviewThunk({
        data: {
          data: prefixIds,
        },
      })
    )
      .unwrap()
      .then(dispatch(fetchVendorProfileDetailsThunk()));
  };

  const handleClick = (index) => {
    dispatch(
      createPlatformReviewThunk({ socialMedia: values?.socialMedia?.[index] })
    );
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: { socialMedia: platformReviewList ?? [] },
    validationSchema: socialMediaValidation,
    onSubmit: onSubmit,
  });

  const handleAddRating = () => {
    setSelectedPlatform([...selectedPlatform, values.founder_selectOption]);
    setFieldValue("socialMedia", [
      ...values.socialMedia,
      { rating: "", link: "", platform: values.founder_selectOption },
    ]);
    setFieldValue("founder_selectOption", "");
  };

  const handleDeleteRating = (item) => {
    const { id } = item ?? {};
    if (!id) {
      const remainValue = values?.socialMedia?.filter(
        (_, i) => _?.platform !== item?.platform
      );
      setFieldValue("socialMedia", remainValue);
    } else {
      dispatch(deletePlatformReviewThunk(id))
        .unwrap()
        .then(dispatch(fetchVendorProfileDetailsThunk()));
      setFieldValue("socialMedia", platformReviewList?.[0]?.socialMedia);
    }
  };

  // console.log("valuess", values);

  return (
    <Container>
      <Box>
        <Grid container spacing={2} mt={5}>
          <Grid item md={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h4" gutterBottom>
                Social Media Ratings
              </Typography>
            </Stack>
            {/* <Grid item md={6} pb={0}>
              <Element
                label="Platform"
                inputProps={{
                  name: "founder_selectOption",
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                value={values.founder_selectOption}
                options={platformReviewOptions?.filter(
                  (item) => !selectedPlatform.includes(item.value)
                )}
                errorText={
                  touched.founder_selectOption && errors.founder_selectOption
                }
                eletype={inputType.select}
                icons={
                  <ArrowDropDownIcon
                    sx={{
                      position: "absolute",
                      right: 20,
                      bottom: 24,
                    }}
                  />
                }
              />
            </Grid> */}
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item md={4}>
            <Typography variant="h6" gutterBottom>
              Get Started with adding your first rating
            </Typography>
          </Grid>
          <Grid item md={6} style={{ padding: "10px" }}>
            {userPerm.hasAccess(
              systemPermissions["vendorProfileDetailPage.platformReview"],
              actions.create
            ) && (
              <Button
                onClick={handleAddRating}
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
              >
                Add Rating
              </Button>
            )}
          </Grid>
        </Grid>
        <Platformrating
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleSubmit={handleSubmit}
          handleDeleteRating={handleDeleteRating}
          userPerm={userPerm}
        />
      </Box>
    </Container>
  );
};

const Platformrating = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  handleDeleteRating,
  userPerm,
}) => {
  return (
    <>
      <form onSubmit={handleSubmit}>
        {values.socialMedia?.map((item, index) => (
          <>
            <Grid>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-start"
                spacing={2}
              >
                <Grid item md={4} pb={0}>
                  <Element
                    label="Platform"
                    inputProps={{
                      placeholder: "Enter Brand Name",
                      type: "text",
                      name: `socialMedia.${index}.platform`,
                      onChange: handleChange,
                    }}
                    eletype={inputType.select}
                    options={platformReviewOptions}
                    value={item.platform}
                  />
                </Grid>
                <Grid item md={4} pb={0}>
                  <Element
                    label="Ratings*"
                    inputProps={{
                      placeholder: "Enter Brand Name",
                      type: "number",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: `socialMedia.${index}.rating`,
                    }}
                    eletype={inputType.number}
                    value={item.rating}
                    errorText={
                      touched.socialMedia &&
                      touched.socialMedia[index] &&
                      errors.socialMedia &&
                      errors.socialMedia[index]?.rating
                    }
                  />
                </Grid>
                <Grid item md={4} pb={0}>
                  <Element
                    label="Link*"
                    inputProps={{
                      placeholder: "Enter Link ",
                      type: "text",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: `socialMedia.${index}.link`,
                    }}
                    eletype={inputType.url}
                    value={item.link}
                    errorText={
                      touched.socialMedia &&
                      touched.socialMedia[index] &&
                      errors.socialMedia &&
                      errors.socialMedia[index]?.link
                    }
                  />
                </Grid>
                <Grid md={3} pt={5} pr={4}>
                  <Box
                    sx={{
                      display: "flex",
                      marginBottom: "30px",
                      justifyContent: "end",
                    }}
                  >
                    {userPerm.hasAccess(
                      systemPermissions[
                        "vendorProfileDetailPage.platformReview"
                      ],
                      actions.delete
                    ) && (
                      <Button
                        variant="contained"
                        onClick={() => handleDeleteRating(item)}
                      >
                        Delete
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Stack>
            </Grid>
          </>
        ))}
        {values.socialMedia?.length > 0 && (
          <Grid item md={12} p={3}>
            {userPerm.hasAccess(
              systemPermissions["vendorProfileDetailPage.platformReview"],
              actions.edit
            ) && (
              <Button
                type="submit"
                variant="contained"
                sx={{ float: "right" }}
                disabled={values?.socialMedia?.length === 0}
              >
                Save
              </Button>
            )}
          </Grid>
        )}
      </form>
    </>
  );
};

export default SocialMediaRating;
