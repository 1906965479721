import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import DashboardApp from "./pages/DashboardApp";
import NotFound from "./pages/Page404";
import AllProjects from "./components/_dashboard/projects/AllProjects";
import { Outlet } from "react-router-dom";
import ProjectDetails from "./pages/ProjectDetail";
import MyProfile from "./pages/Profile/MyProfile";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import NewPassword from "./pages/Auth/NewPassword";
import AuthProtector from "./components/Protector/AuthProtector";
import withAuth from "./hoc/withAuth";
import BasicInfo from "./Profile/BasicInfo";
import ClientsDetails from "./pages/Clients";
import Portfolio from "./Portfolio/Portfolio";
import UploadPortfolio from "./Portfolio/UploadPortfolio";
import BankDetails from "./Profile/BankDetails";
import VendorProfile from "./Profile/VendorProfile";
import Language from "./pages/ContentManagement/Language";
import Notification from "./pages/Notification/Notification";
import ViewProfile from "./pages/ViewProfile";
import Industry from "./pages/Industry/Industry";
import Contact from "./pages/Contact/Contact";
import TeckStack from "./pages/ContentManagement/TeckStack";
import ChatTicket from "./pages/Contact/ChatTicket";

import {
  addressesPath,
  bankDetailsPath,
  basicInfoPath,
  cvPath,
  expertiseBreakup,
  poc,
  portfolioPath,
  ra,
  servicePath,
  uploadCOQPath,
  uploadPortfolioPath,
  verificationPath,
  saasChannelPartner,
  socialMediaRating,
  founderInfo,
  techStackForte,
  certification,
  benchStrength,
  testimonials,
  platformReview,
  shortlist,
  services,
  dealTimelimePath,
} from "./utils/constant";
import BusinessVerification from "./Profile/Business_Verification";
import ServiceOffered from "./Profile/ServiceOffered";
import POC from "./Profile/POC";
import ExpertiseBreakup from "./Profile/Expertise_Breakup";
import UploadCOQ from "./Profile/Upload_COQ";
import DataGridDemo from "./Profile/ProjectTable";
import AddServices from "./pages/Services/AddServices";
import Tag from "./pages/ContentManagement/Tag";
import VendorShortlist from "./pages/VendorShortlist/VendorShortlist";
import ShorlistedVendor from "./pages/VendorShortlist/ShorlistedVendor";
import Category from "./pages/ContentManagement/Category";
import RAList from "./pages/RA/RA_List";
import RaDetails from "./pages/RA/RaDetails";
import RaDetailsFull from "./pages/RA";
import RaCvs from "./pages/RA/RaCvs";
import FinanceApproval from "./pages/FinanceApproval/FinanceApproval";
import SocialMediaRating from "./Profile/SocialMediaRating";
import TechStackForte from "./Profile/TechStackForte";
import SassProfileTable from "./Profile/SassProfileTable";
import Certification from "./Profile/Certification";
import BenchStrength from "./Profile/BenchStrenght";
import Testimonials from "./Profile/Testimonials";
import FounderprofileTable from "./Profile/FounderprofileTable";
import BenchStrengthTableForm from "./Profile/BenchStrengthTableForm";
import PlatformReview from "./Profile/PlatformReview";
import ProfileApproval from "./pages/ProfileApproval/ProfileApproval";
import RaShortlist from "./pages/RA/RaShortlist";
import VendorServices from "./Profile/VendorServices";
import DealTimeline from "./pages/RA/Timeline/DealTimeline";
import ProfileTableTypeProvider from "./context/vendorProfile.context";
// import DealTimeline from "./pages/RA/Timeline/DealTimeline";

function AppRoutes() {
  return useRoutes([
    {
      path: "/dashboard",
      element: (
        <AuthProtector>
          <DashboardLayout />
        </AuthProtector>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        {
          path: "app",
          element: <Outlet />,
          children: [
            { path: "", element: <DashboardApp /> },
            {
              path: "",
              element: <Outlet />,
              children: [
                { path: "projects", element: <AllProjects /> },
                { path: "projects/:id", element: <ProjectDetails /> },
              ],
            },
          ],
        },
        {
          path: "myProfile",
          element: <MyProfile />,
        },
        { path: "notification", element: <Notification /> },
        { path: "industry", element: <Industry /> },
        { path: "language", element: <Language /> },
        { path: "services", element: <AddServices /> },
        { path: "tech-stack", element: <TeckStack /> },
        { path: "tag", element: <Tag /> },
        { path: "category", element: <Category /> },
        {
          path: "profiles",
          element: (
            <ProfileTableTypeProvider>
              <VendorProfile />
            </ProfileTableTypeProvider>
          ),
        },
        {
          path: "profiles/:id",
          element: <ClientsDetails />,
          children: [
            { path: basicInfoPath, element: <BasicInfo /> },
            { path: addressesPath, element: <DataGridDemo /> },
            { path: bankDetailsPath, element: <BankDetails /> },
            { path: verificationPath, element: <BusinessVerification /> },
            { path: servicePath, element: <ServiceOffered /> },
            { path: portfolioPath, element: <Portfolio /> },
            { path: uploadPortfolioPath, element: <UploadPortfolio /> },
            { path: poc, element: <POC /> },
            { path: expertiseBreakup, element: <ExpertiseBreakup /> },
            // { path: uploadCOQPath, element: <UploadCOQ /> },
            { path: saasChannelPartner, element: <SassProfileTable /> },
            { path: certification, element: <Certification /> },
            { path: testimonials, element: <Testimonials /> },
            { path: services, element: <VendorServices /> },
            { path: socialMediaRating, element: <SocialMediaRating /> },
            { path: founderInfo, element: <FounderprofileTable /> },
            { path: techStackForte, element: <TechStackForte /> },
            { path: benchStrength, element: <BenchStrengthTableForm /> },
            { path: platformReview, element: <PlatformReview /> },
          ],
        },

        {
          path: "contact",
          element: <Outlet />,
          children: [
            { path: "", element: <Contact /> },
            { path: "tickets/:ticketId", element: <ChatTicket /> },
          ],
        },
        {
          path: "ra",
          element: <Outlet />,
          children: [
            { path: "", element: <RAList /> },
            {
              path: ":leadId",
              element: <RaDetailsFull />,
              children: [
                { path: basicInfoPath, element: <RaDetails /> },
                {
                  path: cvPath,
                  element: <RaCvs />,
                },
                {
                  path: shortlist,
                  element: <RaShortlist />,
                },
                {
                  path: dealTimelimePath,
                  element: <DealTimeline />,
                },
              ],
            },
          ],
        },

        {
          path: "shortlist",
          element: <Outlet />,
          children: [
            { path: "", element: <VendorShortlist /> },
            { path: ":id", element: <ShorlistedVendor /> },
          ],
        },
        {
          path: "financeapproval",
          element: <Outlet />,
          children: [{ path: "", element: <FinanceApproval /> }],
        },
        {
          path: "profile-approval",
          element: <Outlet />,
          children: [{ path: "", element: <ProfileApproval /> }],
        },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "forgotpassword", element: <ForgotPassword /> },
        { path: "reset-password", element: <NewPassword /> },
        { path: "404", element: <NotFound /> },
        { path: "view-profile/:id", element: <ViewProfile /> },
        { path: "/", element: <Navigate to="/dashboard/app" /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

export default withAuth(AppRoutes);
