import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  Button,
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Page from "../../components/Page";
import { useParams, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearVendorProfile,
  fetchVendorProfileDetailsThunk,
} from "../../store/slices/vendor/vendor.slice";
import Loader from "../../components/Loader/Loader";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import useBusinessId from "../../hooks/useBusinessId";
import {
  deleteAssignVendorHandler,
  fetchAssignedVendorHandler,
  storeOrgId,
} from "../../utils/global/vendor.global";
import { getOrgIdByProfileId } from "../../utils/global/vendor.global";
import {
  addressesPath,
  bankDetailsPath,
  basicInfoPath,
  expertiseBreakup,
  poc,
  portfolioPath,
  servicePath,
  uploadPortfolioPath,
  verificationPath,
  saasChannelPartner,
  socialMediaRating,
  founderInfo,
  techStackForte,
  certification,
  benchStrength,
  testimonials,
  services,
} from "../../utils/constant";
import CustomTabs from "../../components/Common/Tabs";
import ListHead from "../../components/Common/ListHead";
import Scrollbar from "../../components/Scrollbar";
import Modal from "../../components/Modal/Modal";
import AddAccountModal from "../../components/Modal/AddAccountModal";
import DeleteAction from "../../components/Common/DeleteAction";
import usePermissions from "../../hooks/usePermission";
import { ConfigProvider, Progress } from "antd";

const TABLE_HEAD = [
  { id: "first_name", label: "First Name", alignRight: false },
  { id: "last_name", label: "Last Name", alignRight: false },
  { id: "action", label: "Action ", alignRight: false },
];

function ClientsDetails() {
  const params = useParams();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [bus_name, setBus_name] = useState("");
  const businessId = useBusinessId();
  const navigate = useNavigate();
  const [tabs, setTabs] = useState([]);

  const { resource_augmentation_service, profileData } = useSelector(
    ({ vendor }) => vendor.profileData
  );

  const {profile_progress_count} = useSelector(
    ({ vendor }) => vendor
  );

  const userPerm = usePermissions();

  const [showModal, setShowModal] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [assignedVendor, setAssignedVendor] = useState(null);

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    const tabs = userPerm.getProfileTabs();
    setTabs(tabs);
  }, [tabs.length]);

  useEffect(() => {
    if (userPerm.firstAvailableLink) {
      const id = params.id;
      navigate(`/dashboard/profiles/${id}/${userPerm.firstAvailableLink}`);
    }
  }, [userPerm.firstAvailableLink]);

  useEffect(() => {
    if (showTable) {
      fetchAssignedVendorHandler().then((response) => {
        if (!response.data) setAssignedVendor(null);
        else setAssignedVendor(response.data[0]);
      });
    }
  }, [showTable]);

  useEffect(() => {
    if (params.id === "new") {
      dispatch(clearVendorProfile());
      return setLoading(false);
    }
    dispatch(clearVendorProfile());
    getOrgIdByProfileId(params.id)
      .then((response) => {
        const data = response.data;
        storeOrgId(data.profile_org_id);
        return dispatch(fetchVendorProfileDetailsThunk())
          .unwrap()
          .then((d) => {
            setBus_name(d.brand_name);
          });
      })
      .finally(() => setLoading(false));
  }, [dispatch, params.id]);

  const modifiedLabels = useMemo(() => {
    if (tabs.length > 0) {
      console.log(tabs);

      for (var i = 1; i < tabs.length; i++) {
        if (businessId) {
          tabs[i].isDisabled = false;
        } else {
          tabs[i].isDisabled = true;
        }
      }
      if (!resource_augmentation_service) {
        return tabs.filter((tab) => tab.label !== "Resources");
      }
      return tabs;
    }
    return tabs;
  }, [businessId, resource_augmentation_service, tabs.length]);

  if (isLoading) return <Loader bg="rgba(255,255,255)" />;

  function handleAccountDelete() {
    deleteAssignVendorHandler(assignedVendor.user_id).then(() => {
      setAssignedVendor(null);
    });
  }

  return (
    <Page title="OPS Dashboard | EMB">
      <Container sx={{ margin: 0 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={5}
          mb={5}
        >
          <Stack direction={"row"} alignItems={"center"} gap={5}>
            <div>
              <span
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/dashboard/profiles")}
              >
                &#8592;
              </span>
            </div>
            <div>
              <Typography variant="h4" gutterBottom>
                Personal Information
              </Typography>
              <Breadcrumb props={bus_name} />
            </div>
          </Stack>
          <Card
            style={{
              textAlign: "center",
              padding: "0.5rem 1rem",
              borderRadius: "6px",
              width: "22rem",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h4 style={{ color: "brown" }}>Profile Completion</h4>
            <hr
              style={{
                margin: "0.5rem",
                border: "1px solid black",
                width: "1px",
                height: "40px",
              }}
            />
            <ConfigProvider
              theme={{
                components: {
                  Progress: {
                    /* here is your component tokens */
                    circleTextColor: "black",
                    defaultColor: "rgb(0, 171, 85)",
                  },
                },
              }}
            >
              <Progress
                type="dashboard"
                percent={Math.ceil(Number(profile_progress_count))}
                gapDegree={30}
                size={"small"}
                circleTextColor={"white"}
              />
              {/* <Progress
                        percent={Number(profileData?.profile_progress_count)}
                        size="default"
                      /> */}
            </ConfigProvider>
          </Card>
        </Stack>

        {showTable ? (
          <Container>
            <Box mt={5}>
              <Card>
                <Container>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={5}
                    mb={5}
                  >
                    <div>
                      <Typography variant="h4" gutterBottom>
                        Added Account
                      </Typography>
                    </div>
                    {!assignedVendor && (
                      <Button
                        size="large"
                        variant="contained"
                        onClick={handleOpen}
                      >
                        Create Account
                      </Button>
                    )}
                  </Stack>
                  <Card sx={{ boxShadow: "none", mt: "2rem" }}>
                    <Scrollbar>
                      <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                          <ListHead
                            headLabel={TABLE_HEAD}
                            styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                          />
                          {assignedVendor ? (
                            <TableBody>
                              <TableRow
                                tabIndex={-1}
                                role="checkbox"
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell align="left">
                                  {assignedVendor.first_name}
                                </TableCell>

                                <TableCell align="left">
                                  {assignedVendor.last_name}
                                </TableCell>

                                <TableCell align="left">
                                  <DeleteAction
                                    handleDelete={handleAccountDelete}
                                  />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ) : (
                            <div style={{ margin: "10px", fontWeight: "bold" }}>
                              No Attached User
                            </div>
                          )}
                        </Table>
                      </TableContainer>
                    </Scrollbar>
                  </Card>
                </Container>
              </Card>
              <div>
                <Button
                  onClick={() => setShowTable(false)}
                  sx={{ mt: 3 }}
                  variant="contained"
                >
                  Back
                </Button>
              </div>
            </Box>
          </Container>
        ) : (
          <Grid>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <CustomTabs labels={modifiedLabels} />
            </Box>
            <div style={{ marginBottom: "20px" }}></div>
            <Outlet />
          </Grid>
        )}
      </Container>

      <Modal
        isOpen={showModal}
        closeModal={handleClose}
        title="Create Account"
        content={
          <AddAccountModal
            handleClose={handleClose}
            setAssignedVendor={setAssignedVendor}
          />
        }
        titleStyle={{ textAlign: "center", marginTop: 2 }}
      />
    </Page>
  );
}

export const getBasicInfoTabs = (ids) => {
  if (!Array.isArray(ids) || ids.length === 0) return [];
  let final = [];

  if (ids.includes("0")) {
    final.push({
      label: "Info",
      value: basicInfoPath,
      icon: "/static/icons/address.svg",
      activeIcon: "/static/icons/address_active.svg",
    });
  }

  if (ids.includes("1")) {
    final.push({
      label: "Address",
      value: addressesPath,
      icon: "/static/icons/address.svg",
      activeIcon: "/static/icons/address_active.svg",
    });
  }

  if (ids.includes("2")) {
    final.push({
      label: "POC",
      value: poc,
      icon: "/static/icons/POC.svg",
      activeIcon: "/static/icons/POC_active.svg",
    });
  }

  if (ids.includes("3")) {
    final.push({
      label: "Founders Information",
      value: founderInfo,
      icon: "/static/icons/expertise.svg",
      activeIcon: "/static/icons/expertise_active.svg",
    });
  }

  return {
    label: "Basic Information",
    value: basicInfoPath,
    icon: "/static/icons/overview.svg",
    activeIcon: "/static/icons/overview_active.svg",
    childTabs: final.filter(Boolean),
  };
};

export const getBankInfoTabs = (ids) => {
  if (!Array.isArray(ids) || ids.length === 0) return [];
  let final = [];
  if (ids.includes("0")) {
    final.push({
      label: "Details",
      value: bankDetailsPath,
      icon: "/static/icons/bank_details.svg",
      activeIcon: "/static/icons/bank_details_active.svg",
    });
  }
  if (ids.includes("1")) {
    final.push({
      label: "Documentation",
      value: verificationPath,
      icon: "/static/icons/verification.svg",
      activeIcon: "/static/icons/verification_active.svg",
    });
  }

  return {
    label: "Bank",
    value: bankDetailsPath,
    icon: "/static/icons/bank_details.svg",
    activeIcon: "/static/icons/bank_details_active.svg",
    childTabs: final.filter(Boolean),
  };
};
export const getPortfolioTabs = (ids) => {
  if (!Array.isArray(ids) || ids.length === 0) return [];
  let final = [];
  if (ids.includes("0")) {
    final.push({
      label: "Services",
      value: services,
      icon: "/static/icons/expertise.svg",
      activeIcon: "/static/icons/expertise_active.svg",
    });
  }
  if (ids.includes("1")) {
    final.push({
      label: "Product Code",
      value: servicePath,
      icon: "/static/icons/service.svg",
      activeIcon: "/static/icons/service_active.svg",
    });
  }

  if (ids.includes("2")) {
    final.push({
      label: "Past Work",
      value: portfolioPath,
      icon: "/static/icons/portfolio.svg",
      activeIcon: "/static/icons/portfolio_active.svg",
    });
  }

  if (ids.includes("3")) {
    final.push({
      label: "Corporate Deck",
      value: uploadPortfolioPath,
      icon: "/static/icons/upload_portfolio.svg",
      activeIcon: "/static/icons/upload_portfolio_active.svg",
    });
  }

  if (ids.includes("4")) {
    final.push({
      label: "Testimonials",
      value: testimonials,
      icon: "/static/icons/expertise.svg",
      activeIcon: "/static/icons/expertise_active.svg",
    });
  }

  return {
    label: "Portfolio",
    value: services,
    icon: "/static/icons/service.svg",
    activeIcon: "/static/icons/service_active.svg",
    childTabs: final.filter(Boolean),
  };
};
export const getAffiliationsTabs = (ids) => {
  if (!Array.isArray(ids) || ids.length === 0) return [];
  let final = [];
  if (ids.includes("0")) {
    final.push({
      label: "Saas Channel Partner",
      value: saasChannelPartner,
      icon: "/static/icons/expertise.svg",
      activeIcon: "/static/icons/expertise_active.svg",
    });
  }
  if (ids.includes("1")) {
    final.push({
      label: "Certifications",
      value: certification,
      icon: "/static/icons/expertise.svg",
      activeIcon: "/static/icons/expertise_active.svg",
    });
  }

  return {
    label: "Affiliations",
    value: saasChannelPartner,
    icon: "/static/icons/expertise.svg",
    activeIcon: "/static/icons/expertise_active.svg",
    childTabs: final.filter(Boolean),
  };
};
export const getExpertiseBreakupTabs = () => {
  return {
    label: "Expertise Breakup",
    value: expertiseBreakup,
    icon: "/static/icons/expertise.svg",
    activeIcon: "/static/icons/expertise_active.svg",
  };
};
export const getPlatformReviewTabs = () => {
  return {
    label: "Platform Review",
    value: socialMediaRating,
    icon: "/static/icons/expertise.svg",
    activeIcon: "/static/icons/expertise_active.svg",
  };
};
export const getResourcesTabs = (ids) => {
  if (!Array.isArray(ids) || ids.length === 0) return [];
  let final = [];
  if (ids.includes("0")) {
    final.push({
      label: "Tech Stack Forte",
      value: techStackForte,
      icon: "/static/icons/expertise.svg",
      activeIcon: "/static/icons/expertise_active.svg",
    });
  }
  if (ids.includes("1")) {
    final.push({
      label: "Bench Strength",
      value: benchStrength,
      icon: "/static/icons/expertise.svg",
      activeIcon: "/static/icons/expertise_active.svg",
    });
  }

  return {
    label: "Resources",
    value: techStackForte,
    icon: "/static/icons/expertise.svg",
    activeIcon: "/static/icons/expertise_active.svg",
    childTabs: final.filter(Boolean),
  };
};

var labels = [
  {
    label: "Basic Information",
    value: basicInfoPath,
    icon: "/static/icons/overview.svg",
    activeIcon: "/static/icons/overview_active.svg",
    childTabs: [
      {
        label: "Info",
        value: basicInfoPath,
        icon: "/static/icons/address.svg",
        activeIcon: "/static/icons/address_active.svg",
      },
      {
        label: "Address",
        value: addressesPath,
        icon: "/static/icons/address.svg",
        activeIcon: "/static/icons/address_active.svg",
      },
      {
        label: "POC",
        value: poc,
        icon: "/static/icons/POC.svg",
        activeIcon: "/static/icons/POC_active.svg",
      },
      {
        label: "Founders Information",
        value: founderInfo,
        icon: "/static/icons/expertise.svg",
        activeIcon: "/static/icons/expertise_active.svg",
      },
    ],
  },
  {
    label: "Bank",
    value: bankDetailsPath,
    icon: "/static/icons/bank_details.svg",
    activeIcon: "/static/icons/bank_details_active.svg",
    childTabs: [
      {
        label: "Details",
        value: bankDetailsPath,
        icon: "/static/icons/bank_details.svg",
        activeIcon: "/static/icons/bank_details_active.svg",
      },
      {
        label: "Documentation",
        value: verificationPath,
        icon: "/static/icons/verification.svg",
        activeIcon: "/static/icons/verification_active.svg",
      },
    ],
  },
  {
    label: "Portfolio",
    value: services,
    icon: "/static/icons/service.svg",
    activeIcon: "/static/icons/service_active.svg",
    childTabs: [
      {
        label: "Services",
        value: services,
        icon: "/static/icons/expertise.svg",
        activeIcon: "/static/icons/expertise_active.svg",
      },
      {
        label: "Product Code",
        value: servicePath,
        icon: "/static/icons/service.svg",
        activeIcon: "/static/icons/service_active.svg",
      },
      {
        label: "Past Work",
        value: portfolioPath,
        icon: "/static/icons/portfolio.svg",
        activeIcon: "/static/icons/portfolio_active.svg",
      },
      {
        label: "Corporate Deck",
        value: uploadPortfolioPath,
        icon: "/static/icons/upload_portfolio.svg",
        activeIcon: "/static/icons/upload_portfolio_active.svg",
      },
      {
        label: "Testimonials",
        value: testimonials,
        icon: "/static/icons/expertise.svg",
        activeIcon: "/static/icons/expertise_active.svg",
      },
    ],
  },

  {
    label: "Affiliations",
    value: saasChannelPartner,
    icon: "/static/icons/expertise.svg",
    activeIcon: "/static/icons/expertise_active.svg",
    childTabs: [
      {
        label: "Saas Channel Partner",
        value: saasChannelPartner,
        icon: "/static/icons/expertise.svg",
        activeIcon: "/static/icons/expertise_active.svg",
      },
      {
        label: "Certifications",
        value: certification,
        icon: "/static/icons/expertise.svg",
        activeIcon: "/static/icons/expertise_active.svg",
      },
    ],
  },
  {
    label: "Expertise Breakup",
    value: expertiseBreakup,
    icon: "/static/icons/expertise.svg",
    activeIcon: "/static/icons/expertise_active.svg",
  },

  {
    label: "Platform Review",
    value: socialMediaRating,
    icon: "/static/icons/expertise.svg",
    activeIcon: "/static/icons/expertise_active.svg",
  },
  {
    label: "Resources",
    value: techStackForte,
    icon: "/static/icons/expertise.svg",
    activeIcon: "/static/icons/expertise_active.svg",
    childTabs: [
      {
        label: "Tech Stack Forte",
        value: techStackForte,
        icon: "/static/icons/expertise.svg",
        activeIcon: "/static/icons/expertise_active.svg",
      },
      {
        label: "Bench Strength",
        value: benchStrength,
        icon: "/static/icons/expertise.svg",
        activeIcon: "/static/icons/expertise_active.svg",
      },
    ],
  },
];

export default ClientsDetails;
