export function setLocalData(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch (error) {}
}

export function removeLocalData(key) {
  try {
    localStorage.removeItem(key);
  } catch (error) {}
}
export function getLocalData(key) {
  try {
    let data = localStorage.getItem(key);
    return data;
  } catch (error) {}
}

export function validatePAN(panNumber) {
  var panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  if (panRegex.test(panNumber)) {
    return true;
  } else {
    return false;
  }
}

export function validateGST(gstNumber) {
  var gstRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
  if (gstRegex.test(gstNumber)) {
    return true;
  } else {
    return false;
  }
}
