import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  changeStatusOfLeadHandler,
  fetchAllCvsByPartnerHandler,
  fetchAllRALeadsHandler,
  fetchAllRALeadsPaginationHandler,
  fetchAllRaEnableVendorHandler,
  fetchAllTechStackListHandler,
  fetchRAAssignUserHandler,
  fetchRaLeadByIdHandler,
  fetchRaLeadLineItemsHandler,
  postRAAssignUserHandler,
  postShorlistedVendorsHandler,
  putEmbJdUploadThunkHandler,
  searchClientRequirmentHandler,
  uploadCVByEMBHandler,
} from "./ra.thunk";

import { showFailureToast, showSuccessToast } from "../toast/toast.slice";

const INIT_STATE = {
  leads: {
    data: [],
  },
  lead: {},
  partner_cvs: {},
  loader: false,
  assign_user_list: [],
  raEnableVendors: [],
  techStacklist: [],
};

export const fetchAllRALeadsThunk = createAsyncThunk(
  "fetchAllRALeads",
  fetchAllRALeadsHandler
);

export const fetchAllRALeadsWithPaginationThunk = createAsyncThunk(
  "fetchAllRALeadsPagination",
  fetchAllRALeadsPaginationHandler
);

export const fetchRAAssignUserThunk = createAsyncThunk(
  "fetchRAAssignUser",
  fetchRAAssignUserHandler
);

export const postRAAssignUserThunk = createAsyncThunk(
  "postRAAssignUser",
  postRAAssignUserHandler
);

export const searchClientRequirmentThunk = createAsyncThunk(
  "searchClientRequirment",
  searchClientRequirmentHandler
);

export const fetchAllRaEnableVendorThunk = createAsyncThunk(
  "fetchAllRaEnableVendor",
  fetchAllRaEnableVendorHandler
);

export const fetchRaLeadByIdThunk = createAsyncThunk(
  "fetchRaLeadById",
  fetchRaLeadByIdHandler
);

export const fetchRaLeadLineItemsThunk = createAsyncThunk(
  "fetchRaLeadLineItems",
  fetchRaLeadLineItemsHandler
);

export const fetchAllCvsByPartnerThunk = createAsyncThunk(
  "fetchAllCvsByPartner",
  fetchAllCvsByPartnerHandler
);

export const changeStatusOfLeadThunk = createAsyncThunk(
  "changeStatusOfLead",
  changeStatusOfLeadHandler
);

export const uploadCVByEMBThunk = createAsyncThunk(
  "uploadCVByEMB",
  uploadCVByEMBHandler
);

export const fetchAllTechStackListThunk = createAsyncThunk(
  "fetchAllTechStackList",
  fetchAllTechStackListHandler
);

export const postShorlistedVendorsThunk = createAsyncThunk(
  "postShorlistedVendors",
  postShorlistedVendorsHandler
);

export const putEmbJdUploadThunk = createAsyncThunk(
  "putEmbJdUpload",
  putEmbJdUploadThunkHandler
);

// export const fetchAllShortlistPartnersThunk = createAsyncThunk(
//   "fetchAllShortlistPartners",
//   fetchAllShortlistPartnersHandler
// )

const raSlice = createSlice({
  name: "ra",
  initialState: INIT_STATE,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRALeadsThunk.fulfilled, (state, action) => {
        state.leads = action.payload;
        return state;
      })
      .addCase(
        fetchAllRALeadsWithPaginationThunk.fulfilled,
        (state, action) => {
          state.leads = action.payload;
          return state;
        }
      )
      .addCase(fetchRAAssignUserThunk.fulfilled, (state, action) => {
        state.assign_user_list = action.payload;
        return state;
      })
      .addCase(searchClientRequirmentThunk.fulfilled, (state, action) => {
        state.leads = action.payload;
        return state;
      })
      .addCase(fetchAllRaEnableVendorThunk.fulfilled, (state, action) => {
        state.raEnableVendors = action.payload;
        return state;
      })
      .addCase(fetchRaLeadByIdThunk.fulfilled, (state, action) => {
        state.lead = action.payload;
        return state;
      })
      .addCase(fetchRaLeadLineItemsThunk.fulfilled, (state, action) => {
        state.lead = action.payload;
        return state;
      })
      .addCase(fetchAllCvsByPartnerThunk.fulfilled, (state, action) => {
        state.partner_cvs = action.payload;
        return state;
      })
      .addCase(fetchAllTechStackListThunk.fulfilled, (state, action) => {
        state.techStacklist = action.payload;
        return state;
      })

      .addCase(changeStatusOfLeadThunk.fulfilled, (state, action) => {})
      .addCase(postShorlistedVendorsThunk.fulfilled, (state, action) => {})
      .addCase(changeStatusOfLeadThunk.rejected, (state, action) => {})
      .addCase(postRAAssignUserThunk.fulfilled, (state, action) => {})
      .addCase(putEmbJdUploadThunk.fulfilled, (state, action) => {});

    // .addCase(fetchAll)
  },
});

export default raSlice.reducer;
