import { createSlice } from "@reduxjs/toolkit";
import { projectDetailsPage } from "../../../utils/fakeData/tabs.data";
import allProjects from "../../../utils/fakeData/allProject.data";
import usersData from "../../../utils/fakeData/userTable.data";

const INIT_STATE = {
  selectedTab: 0,
  tabs: projectDetailsPage,
  projects: allProjects,
  selectedProject: {
    overview: {},
    proposals: {},
    phases: {},
    calender: {},
    ratings: {},
    tickets: usersData,
  },
};

const projectSlice = createSlice({
  name: "user",
  initialState: INIT_STATE,
  reducers: {
    tabChange: (state, action) => {
      state.selectedTab = action.payload;
      return state;
    },
    deleteProject: (state, action) => {
      var id = action.payload;
      var newProjects = state.projects.filter((project) => project.id !== id);
      state.projects = newProjects;
      return state;
    },
  },
});

export const { tabChange, deleteProject } = projectSlice.actions;

export default projectSlice.reducer;
