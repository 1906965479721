// import thunk from "redux-thunk";
import {
  addBusinessAddress,
  businessAddressApi,
  businessProfileApi,
  fetchAllVendorProfilesApi,
  getVendorProfileDetails,
  updateLanguagesApi,
  updateSkillsApi,
  vendorService,
  fetchAllPOCApi,
  addPOCApi,
  updatePOCApi,
  deletePOCApi,
  fetchTurnoverForLast3YearsApi,
  turnoverApi,
  searchBusinessProfileApi,
  advanceFilterApi,
  getVendorSassDetailsApi,
  deleteVendorSassProfile,
  createVendorSassProfile,
  getFounderProfileApi,
  createFounderProfileApi,
  getBenchStrengthInfoApi,
  getTestimonialInfoApi,
  deleteTestimonialInfoApi,
  createTestimonialInfoApi,
  getCertificateInfoApi,
  createCertificateInfoApi,
  deleteCertificateInfoApi,
  getPlatformReviewApi,
  createPlatformReviewApi,
  deleteTechStackForteApi,
  createTechStackForteApi,
  getTechStackForteApi,
  deleteFounderProfileApi,
  createBenchStrengthInfoApi,
  deleteBenchStrengthInfoApi,
  getExpertiseBreakupApi,
  createExpertiseBreakupApi,
  deleteExpertiseBreakupApi,
  deletePlatformReviewApi,
  fetchAllVendorProfilesApiNew,
} from "../../../utils/apis.utils";
import { createQueryString } from "../../../utils/global/global";
import {
  fetchAllAddressThunk,
  fetchAllPOCThunk,
  fetchBenchInfoThunk,
  fetchCertificationThunk,
  fetchExpertisebreakupThunk,
  fetchFounderProfileThunk,
  fetchPlatformReviewThunk,
  fetchSassChannelDetailsThunk,
  fetchTechStackForteThunk,
  fetchTestimonialInfoThunk,
} from "./vendor.slice";
import { showSuccessToast, showFailureToast } from "../toast/toast.slice";
import { profileTableType } from "../../../utils/enum";
// import { vendorSassChannelProfileData } from "../../../utils/fakeData/vendorSassChannelProfile.data";

export const fetchVendorDetailsHandler = async (__, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(getVendorProfileDetails);
  return response.data;
};

export const fetchVendorServiceHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(vendorService + "/" + id);
  return response.data;
};

export const fetchAllVendorProfilesHandler = async (
  {
    action_type = profileTableType.active,
    page = 1,
    limit = 10,
    search = null,
  },
  thunkApi
) => {
  const { user } = thunkApi.extra.apiService;
  let queryObj = {};
  if (search) {
    queryObj = {
      page,
      profile_stage: action_type,
      limit,
      query: search,
    };
  } else {
    queryObj = {
      page,
      profile_stage: action_type,
      limit,
    };
  }

  const query = createQueryString(queryObj);
  // const response = await user.get(fetchAllVendorProfilesApi + query);
  const response = await user.get(fetchAllVendorProfilesApiNew + query);
  return {
    data: response.data,
    total: response.total,
  };
};

export const searchVendorProfilesHandler = async (
  { page = 1, search, filter_data },
  thunkApi
) => {
  const { user } = thunkApi.extra.apiService;
  const query = createQueryString({
    page,
    bus_profile: search,
  });
  const response = await user.post(searchBusinessProfileApi, {
    filter_data,
    page,
    bus_profile: search,
  });
  return {
    data: response.data,
    total: response.total,
  };
};

export const updateBusinessProfileHandler = async ({ id, data }, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.put(businessProfileApi + "/new", { data });
  thunkApi.dispatch(
    showSuccessToast({
      message: response.message,
      vertical: "top",
      horizontal: "right",
    })
  );
  return response.data;
};

export const fetchAllAddressHandler = async (page = 1, thunkApi) => {
  const query = createQueryString({
    page,
  });
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(businessAddressApi + query);
  return {
    data: response.data,
    total: response.total,
  };
};

export const addAddressForBusinessProfileHandler = async (data, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.post(addBusinessAddress, data);
  thunkApi.dispatch(
    showSuccessToast({
      message: response.message,
      vertical: "top",
      horizontal: "right",
    })
  );
  thunkApi.dispatch(fetchAllAddressThunk(data.page));
  // return response.data;
};

export const updateLanguagesHandler = async (data, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.post(updateLanguagesApi, data);
  thunkApi.dispatch(
    showSuccessToast({
      message: response.message,
      vertical: "top",
      horizontal: "right",
    })
  );
  return response.data;
};

export const updateSkillsHandler = async (data, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.post(updateSkillsApi, data);
  thunkApi.dispatch(
    showSuccessToast({
      message: response.message,
      vertical: "top",
      horizontal: "right",
    })
  );
  return response.data;
};

export const createBusinessProfileHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(businessProfileApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {}
};

export const createPOCHandler = async (d, thunkApi) => {
  try {
    var data = d.data;
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.post(addPOCApi, { data });
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllPOCThunk(d.page));

    // return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchAllPOCHandler = async (page = 1, thunkApi) => {
  const query = createQueryString({
    page,
  });
  const { profile } = thunkApi.extra.apiService;
  const response = await profile.get(fetchAllPOCApi + query);
  return {
    data: response.data,
    total: response.total,
  };
  // return response.data;
};

export const updatePOCHandler = async ({ id, data }, thunkApi) => {
  const { profile } = thunkApi.extra.apiService;
  const response = await profile.put(updatePOCApi + "/" + id, { data });
  thunkApi.dispatch(
    showSuccessToast({
      message: response.message,
      vertical: "top",
      horizontal: "right",
    })
  );
  return response.data;
};

export const deletePOCHandler = async (data, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.delete(deletePOCApi + "/" + data.id);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    // return id;(
    thunkApi.dispatch(fetchAllPOCThunk(data.page));
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const updateBusinessAddressHandler = async ({ id, data }, thunkApi) => {
  try {
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.put(addBusinessAddress + "/" + id, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const deleteBusinessAddressHandler = async (data, thunkApi) => {
  try {
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.delete(addBusinessAddress + "/" + data.id);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllAddressThunk(data.page));
    // return id;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const fetchTurnoverForLast3YearsHandler = async (__, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(fetchTurnoverForLast3YearsApi);
  return response.data;
};

export const deleteTurnoverForLast3YearsHandler = async (id, thunkApi) => {
  try {
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.delete(turnoverApi + "/" + id);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return id;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const editTurnoverForLast3YearsHandler = async (data, thunkApi) => {
  try {
    var id = data.id;
    var sendData = data.data;
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.put(turnoverApi + "/" + id, {
      data: sendData,
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const addTurnoverForLast3YearsHandler = async (data, thunkApi) => {
  try {
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.post(turnoverApi, { data: data });
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const deleteVendorProfilesHandler = async (id, thunkApi) => {
  try {
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.delete(getVendorProfileDetails + "/" + id);

    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return id;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const fetchProfilesByAdvanceFilterHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(advanceFilterApi, data);
    return {
      data: response.data,
      total: response.total,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

//sass partner api call
export const fetchSassChannelDetailsHandler = async (page = 1, thunkApi) => {
  try {
    const query = createQueryString({
      page,
    });
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.get(getVendorSassDetailsApi + query);

    return {
      data: response?.data,
      total: response?.total,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteSassChannelProfileHandler = async (
  { id, page },
  thunkApi
) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.delete(deleteVendorSassProfile + "/" + id);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    // return id;(
    thunkApi.dispatch(fetchSassChannelDetailsThunk(page));
    return id;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const createSassChannelProfileHandler = async (
  { data, id },
  thunkApi
) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    var response = null;
    if (id) {
      response = await profile.put(`${createVendorSassProfile}/${id}`, data);
    } else {
      response = await profile.post(createVendorSassProfile, data);
    }
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    // return response.data;
    thunkApi.dispatch(fetchSassChannelDetailsThunk(data.page));
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
  }
};

//founder profile info
export const fetchFounderProfileHandler = async (page = 1, thunkApi) => {
  try {
    const query = createQueryString({
      page,
    });
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.get(getFounderProfileApi + query);

    return {
      data: response?.data,
      total: response?.total,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createFounderProfileHandler = async ({ data, id }, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    var response = null;
    if (id) {
      response = await profile.put(`${createFounderProfileApi}/${id}`, {
        data: data,
      });
    } else {
      response = await profile.post(createFounderProfileApi, { data: data });
    }

    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    // return response.data;
    thunkApi.dispatch(fetchFounderProfileThunk(data.page));
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const deleteFounderProfileHandler = async ({ id, page }, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.delete(deleteFounderProfileApi + "/" + id);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchFounderProfileThunk(page));
    return id;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

//bench strength info
export const fetchBenchStrengthInfoHandler = async (page = 1, thunkApi) => {
  try {
    const query = createQueryString({
      page,
    });
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.get(getBenchStrengthInfoApi + query);

    return {
      data: response?.data,
      total: response?.total,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createBenchStrengthHandler = async ({ data, id }, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    var response = null;
    if (id) {
      response = await profile.put(`${createBenchStrengthInfoApi}/${id}`, data);
    } else {
      response = await profile.post(createBenchStrengthInfoApi, data);
    }
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchBenchInfoThunk(data.page));
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const deleteBenchStrengthHandler = async ({ id, page }, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.delete(
      deleteBenchStrengthInfoApi + "/" + id
    );
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchBenchInfoThunk(page));
    return id;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

//testimonial info data
export const fetchTestimonialInfoHandler = async (page = 1, thunkApi) => {
  try {
    const query = createQueryString({
      page,
    });
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.get(getTestimonialInfoApi + query);

    return {
      data: response?.data,
      total: response?.total,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteTestimonialInfoHandler = async ({ id, page }, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.delete(deleteTestimonialInfoApi + "/" + id);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchTestimonialInfoThunk(page));
    return id;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const createTestimonialInfoHandler = async ({ data, id }, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    var response = null;
    if (id) {
      response = await profile.put(`${createTestimonialInfoApi}/${id}`, {
        data: data,
      });
    } else {
      response = await profile.post(createTestimonialInfoApi, { data: data });
    }
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchTestimonialInfoThunk(data.page));
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

//certificate info
export const fetchCertificationHandler = async (page = 1, thunkApi) => {
  try {
    const query = createQueryString({
      page,
    });
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.get(getCertificateInfoApi + query);
    return {
      data: response?.data,
      total: response?.total,
    };
  } catch (error) {
    thunkApi.dispatch(showFailureToast({ message: error }));
    return Promise.reject(error);
  }
};

export const createCertificationHandler = async ({ data, id }, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    var response = null;
    if (id) {
      response = await profile.put(`${createCertificateInfoApi}/${id}`, data);
    } else {
      response = await profile.post(createCertificateInfoApi, data);
    }
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchCertificationThunk(data.page));
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const deleteCertificationHandler = async ({ id, page }, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.delete(deleteCertificateInfoApi + "/" + id);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchCertificationThunk(page));
    return id;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

//platform review info

export const fetchPlatformReviewHandler = async (page = 1, thunkApi) => {
  try {
    const query = createQueryString({
      page,
    });
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.get(getPlatformReviewApi + query);
    return {
      data: response?.data,
    };
  } catch (error) {
    thunkApi.dispatch(showFailureToast({ message: error }));
    return Promise.reject(error);
  }
};

export const createPlatformReviewHandler = async ({ data, id }, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    var response = null;
    if (id) {
      response = await profile.put(`${createPlatformReviewApi}/${id}`, data);
    } else {
      response = await profile.post(createPlatformReviewApi, data);
    }
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchPlatformReviewThunk(data.page));
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const deletePlatformReviewHandler = async (id, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.delete(deletePlatformReviewApi + "/" + id);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchPlatformReviewThunk());
    return id;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

//tech stack forte

export const fetchTechStackForteHandler = async (page = 1, thunkApi) => {
  try {
    const query = createQueryString({
      page,
    });
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.get(getTechStackForteApi + query);
    return {
      data: response?.data,
    };
  } catch (error) {
    thunkApi.dispatch(showFailureToast({ message: error }));
    return Promise.reject(error);
  }
};

export const createTechStackForteHandler = async (data, thunkApi) => {
  try {
    const { vendor } = thunkApi.extra.apiService;
    var response = null;
    // if(id){
    // response = await vendor.put(`${createTechStackForteApi}/${id}`, data);
    // }else{

    response = await vendor.post(createTechStackForteApi, { data: data });
    // }
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchTechStackForteThunk(data.page));
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const updateTechStackForteHandler = async ({ data, id }, thunkApi) => {
  try {
    const { vendor } = thunkApi.extra.apiService;
    var response = null;

    response = await vendor.put(createTechStackForteApi + "/" + id, {
      data: data,
    });

    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchTechStackForteThunk(data.page));
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const deleteTechStackForteHandler = async (id, thunkApi) => {
  try {
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.delete(deleteTechStackForteApi + "/" + id);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchTechStackForteThunk());
    return id;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

//expertise breakup  info
export const fetchExpertiseBreakupHandler = async (page = 1, thunkApi) => {
  try {
    const query = createQueryString({
      page,
    });
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.get(getExpertiseBreakupApi + query);
    return {
      data: response?.data,
    };
  } catch (error) {
    thunkApi.dispatch(showFailureToast({ message: error }));
    return Promise.reject(error);
  }
};

export const createExpertiseBreakupHandler = async ({ data, id }, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.post(createExpertiseBreakupApi, {
      data: data,
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchExpertisebreakupThunk());
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const deleteExpertiseBreakupHandler = async (id, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.delete(deleteExpertiseBreakupApi + "/" + id);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchExpertisebreakupThunk());
    return id;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};
