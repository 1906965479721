import React from "react";
import { Grid } from "@mui/material";
import ProjectCard from "./ProjectCard";

const ProjectGrid = ({ projects, ...other }) => {
  return (
    <Grid container spacing={3} {...other}>
      {projects.map((project) => (
        <Grid key={project.id} item xs={12} sm={6} md={3}>
          <ProjectCard project={project} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectGrid;
