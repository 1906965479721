import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchFinanceApprovalHandler,approveFinanceHandler,rejectFinanceHandler } from "./financeApproval.thunk";

const INIT_STATE = {
  financeApprovalData: {},
  isLoading: false,
  totalFinanceData: 0,
};

export const fetchFinanceApprovalThunk = createAsyncThunk(
  "fetchFinanceApproval",
  fetchFinanceApprovalHandler
);
export const fetchFinanceApprovalApproveThunk = createAsyncThunk(
    "fetchFinanceApprovalApprove",
    approveFinanceHandler
  );
  export const fetchFinanceApprovalRejectThunk = createAsyncThunk(
    "fetchFinanceApprovalReject",
    rejectFinanceHandler
  );

const financeApprovalSlice = createSlice({
  name: "financeApproval",
  initialState: INIT_STATE,
  reducers: {
    startFinanceLoader: (state) => {
      state.isLoading = true;
    },
    stopFinanceLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFinanceApprovalThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFinanceApprovalThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.financeApprovalData = action.payload.data;
        state.totalFinanceData = action.payload.data.total;
      })
      .addCase(fetchFinanceApprovalThunk.rejected, (state) => {
        state.financeApprovalData = {}
        state.totalFinanceData = 0;
        state.isLoading = false;
      })
  },
});

export const { startFinanceLoader, stopFinanceLoader } = financeApprovalSlice.actions

export default financeApprovalSlice.reducer;
