import {
  getAllTeamInfo,
  updateDeleteAddTeamInfo,
} from "../../../utils/apis.utils";
import {
  startTeamInfoLoader,
  stopTeamInfoLoader,
} from "../teamInfo/teamInfo.slice";

import { showFailureToast, showSuccessToast } from "../toast/toast.slice";

export const fetchAllTeamInfoHandler = async (__, thunkApi) => {
  thunkApi.dispatch(startTeamInfoLoader());
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(getAllTeamInfo);
  thunkApi.dispatch(stopTeamInfoLoader());
  return response.data;
};

export const deleteTeamInfoHandler = async (id, thunkApi) => {
  try {
    thunkApi.dispatch(startTeamInfoLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.delete(updateDeleteAddTeamInfo + "/" + id);
    thunkApi.dispatch(stopTeamInfoLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return id;
  } catch (err) {
    thunkApi.dispatch(stopTeamInfoLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopTeamInfoLoader());
  }
};

export const addTeamInfoHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startTeamInfoLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.post(updateDeleteAddTeamInfo, data);
    thunkApi.dispatch(stopTeamInfoLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopTeamInfoLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopTeamInfoLoader());
  }
};

export const updateTeamInfoHandler = async (data, thunkApi) => {
  var id = data.id;

  var apiData = {
    data: {
      role_wise_count: parseInt(data.role_wise_count),
    },
  };
  try {
    thunkApi.dispatch(startTeamInfoLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.put(
      updateDeleteAddTeamInfo + "/" + id,
      apiData
    );
    thunkApi.dispatch(stopTeamInfoLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopTeamInfoLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopTeamInfoLoader());
  }
};
