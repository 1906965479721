import React from "react";
import CheckboxList from "../Form/CheckBoxGroup";
import { checkboxGroupOptions } from "../../utils/options.util";
import Element from "./../../components/Form/Element";
import { Box, Grid, Button, Stack } from "@mui/material";
import { inputType } from "../../utils/enum";
import useForm from "../../hooks/useForm";
import { reasonValidation } from "../../utils/validations/vendor.validation";

export default function SelectReasonModel({ closeModal, data, dataFromChild }) {
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useForm({
      initialValues: {
        reason: [],
      },
      onSubmit: onSubmit,
      validationSchema: reasonValidation,
    });

  function onSubmit(d) {
    dataFromChild({ ...data, ...d });
    closeModal();
  }
  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} p={3}>
            <Grid item md={12} pb={3}>
              <Element
                eletype={inputType.checkboxgroup}
                label="Please select reason of selection."
                options={checkboxGroupOptions}
                onChange={handleChange}
                name="reason"
                value={values.reason}
                errorText={touched.reason && errors.reason}
              />
            </Grid>

            <Grid item md={12} p={3} sx={{ textAlign: "center" }}>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
