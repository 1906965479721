import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllLanguageHandler,
  deleteLanguageHandler,
  addLanguageHandler,
  updateLanguageHandler,
  fetchAllLanguageWithPaginationHandler,
} from "./language.thunk";

const INIT_STATE = {
  languagedata: [],
  isLoading: false,
  totallanguage: 0,
};

export const fetchAllLanguageThunk = createAsyncThunk(
  "fetchAllLanguage",
  fetchAllLanguageHandler
);

export const fetchAllLanguageWithPaginationThunk = createAsyncThunk(
  "fetchAllLanguageWithPagination",
  fetchAllLanguageWithPaginationHandler
);

export const deleteLanguageThunk = createAsyncThunk(
  "deleteLanguage",
  deleteLanguageHandler
);

export const AddLanguageThunk = createAsyncThunk(
  "addLanguage",
  addLanguageHandler
);

export const updateLanguageThunk = createAsyncThunk(
  "updateLanguage",
  updateLanguageHandler
);

const languageSlice = createSlice({
  name: "language",
  initialState: INIT_STATE,
  reducers: {
    startLanguageLoader: (state) => {
      state.isLoading = true;
    },
    stopLanguageLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLanguageThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllLanguageThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.languagedata = action.payload.data;
        state.totallanguage = action.payload.total;
      })
      .addCase(fetchAllLanguageThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAllLanguageWithPaginationThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchAllLanguageWithPaginationThunk.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.languagedata = action.payload.data;
          state.totallanguage = action.payload.total;
        }
      )
      .addCase(fetchAllLanguageWithPaginationThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateLanguageThunk.fulfilled, (state, action) => {
        const idx = state.languagedata.findIndex(
          (ele) => ele._id === action.payload._id
        );
        state.languagedata[idx] = action.payload;
        return state;
      })
      .addCase(AddLanguageThunk.fulfilled, (state, action) => {
        // state.languagedata.push(action.payload);
        // state.totallanguage = state.totallanguage + 1;
      })
      .addCase(deleteLanguageThunk.fulfilled, (state, action) => {
        // var id = action.payload;
        // var newData = state.languagedata.filter((data) => data._id !== id);
        // state.languagedata = newData;
        // state.totallanguage = state.totallanguage - 1;
        // return state;
      });
  },
});

export const { startLanguageLoader, stopLanguageLoader } =
  languageSlice.actions;

export default languageSlice.reducer;
