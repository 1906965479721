import React from "react";
import { useStyles } from "./Form.theme";

const Input = ({
  label,
  errorText,
  inputProps,
  value,
  readOnly,
  style,
  icon,
  placeholder,
  disabled,
  filterChange
}) => {
  const classes = useStyles();
  return (
    <div className={classes.formGroup}>
      {label ? (
        <label className={classes.formLabel} style={style}>
          {label}
        </label>
      ) : null}
      <input
        className={classes.formControl}
        {...inputProps}
        value={value}
        readOnly={readOnly}
        icon={icon}
        autoComplete="off"
        style={style}
        placeholder={placeholder}
        disabled={disabled}
        onKeyDown={(e)=> filterChange? filterChange(e): null}
      />
      {errorText && <span className={classes.formErrorStyle}>{errorText}</span>}
    </div>
  );
};

export default Input;
