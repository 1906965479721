// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import {
//   fetchAllPortfolioHandler,
//   deletePortfolioHandler,
//   addPortfolioHandler,
//   updatePortfolioHandler,
//   fetchPastworkHandler,
//   createPastworkHandler,
//   deletePastworkHandler,
//   fetchTechDataHandler,
// } from "./portfolio.thunk";

// const INIT_STATE = {
//   portfoliodata: [],
//   totalPortfolio: 0,
//   isLoading: false,
//   pastworkdata:[],
//   totalcountpastwork:0
// };

// export const fetchAllPortfolioThunk = createAsyncThunk(
//   "fetchAllPortfolio",
//   fetchAllPortfolioHandler
// );

// export const deletePortfolioThunk = createAsyncThunk(
//   "deletePortfolio",
//   deletePortfolioHandler
// );

// export const AddPortfolioThunk = createAsyncThunk(
//   "addPortfolio",
//   addPortfolioHandler
// );

// export const updatePortfolioThunk = createAsyncThunk(
//   "updatePortfolio",
//   updatePortfolioHandler
// );

// //past work info
// export const fetchPastworkThunk = createAsyncThunk(
//   "fetchPastwork",
//   fetchPastworkHandler
// );

// export const fetchTechDataThunk = createAsyncThunk(
//   "fetchTechData",
//   fetchTechDataHandler
// );

// export const deletePastworkThunk = createAsyncThunk(
//   "deletePastWork",
//   deletePastworkHandler
// );

// export const createPastworkThunk = createAsyncThunk(
//   "createPastwork",
//   createPastworkHandler
// );

// const portfolioSlice = createSlice({
//   name: "portfolio",
//   initialState: INIT_STATE,
//   reducers: {
//     startPortfolioLoader: (state) => {
//       state.isLoading = true;
//     },
//     stopPortfolioLoader: (state) => {
//       state.isLoading = false;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchAllPortfolioThunk.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(fetchAllPortfolioThunk.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.portfoliodata = action.payload.data;
//         state.totalPortfolio = action.payload.total;
//       })

//       .addCase(fetchAllPortfolioThunk.rejected, (state) => {
//         state.isLoading = false;
//       })
//       .addCase(AddPortfolioThunk.fulfilled, (state, action) => {
//         // state.portfoliodata.push(action.payload);
//         // state.totalPortfolio = state.totalPortfolio + 1;
//         // return state;
//       })
//       .addCase(deletePortfolioThunk.fulfilled, (state, action) => {
//         // state.portfoliodata = state.portfoliodata.filter(
//         //   (ele) => ele.id !== action.payload
//         // );
//         // state.totalPortfolio = state.totalPortfolio - 1;
//         // return state;
//       })
//       .addCase(updatePortfolioThunk.fulfilled, (state, action) => {
//         const indx = state.portfoliodata.findIndex(
//           (ele) => ele.id === action.payload.id
//         )
//         .addCase(fetchPastworkThunk.pending, (state) => {
//           state.isLoading = true;
//         })
//         .addCase(fetchPastworkThunk.fulfilled, (state, action) => {
//           state.isLoading = false;
//           state.pastworkdata = action.payload.data;
//           state.totalcountpastwork = action.payload.total;
//         })
  
//         .addCase(fetchPastworkThunk.rejected, (state) => {
//           state.isLoading = false;
//         })
//         .addCase(fetchTechDataThunk.pending, (state) => {
//           state.isLoading = true;
//         })
//         .addCase(fetchTechDataThunk.fulfilled, (state, action) => {
//           state.isLoading = false;
//           state.pastworkdata = action.payload.data;
//           state.totalcountpastwork = action.payload.total;
//         })
  
//         .addCase(fetchTechDataThunk.rejected, (state) => {
//           state.isLoading = false;
//         })
//         .addCase(createPastworkThunk.fulfilled, (state, action) => {
//         })
//         .addCase(deletePastworkThunk.fulfilled, (state, action) => {

//         })

//         state.portfoliodata[indx] = action.payload;
//         return state;
//       });
//   },
// });

// export const { startPortfolioLoader, stopPortfolioLoader } =
//   portfolioSlice.actions;

// export default portfolioSlice.reducer;
