import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";
import Paper from "@mui/material/Paper";

import { useDispatch } from "react-redux";
import { getVenodorPortifoliosByIDHandler } from "../../utils/global/vendor.global";

const columns = [
  {
    id: "client_name",
    label: "Client Name",
    align: "left",
    minWidth: 200,
  },
  {
    id: "description",
    label: "Description",
    align: "left",
    minWidth: 200,
  },
  {
    id: "website",
    label: "Website",
    align: "left",
    minWidth: 200,
  },
  {
    id: "application",
    label: "Application",
    align: "left",
    minWidth: 200,
  },
  {
    id: "service",
    label: "Service",
    align: "left",
    minWidth: 200,
  },
  {
    id: "industry",
    label: "Industry",
    align: "left",
    minWidth: 200,
  },
  {
    id: "tech_stacks",
    label: "Tech Stack",
    align: "left",
    minWidth: 200,
  },
];

export default function ShortListPortfolioModel({ closeModal, data }) {
  const [portfolioData, setPortfoliosData] = useState([]);

  useEffect(() => {
    if (data?.portfolio_ids.length != 0) {
      getVenodorPortifoliosByIDHandler(data.portfolio_ids).then((response) => {
        setPortfoliosData(response.data);
      });
    }
  }, []);
  return (
    <div style={{ padding: "0" }}>
      {/* <Scrollbar> */}
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="customized sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      // top: 57,
                      // minWidth: column.minWidth,
                      fontWeight: 900,
                      fontSize: "18px",
                      backgroundColor: "#f3f6f8",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {portfolioData?.map((row) => (
                <TableRow>
                  <TableCell align="left">
                    {row.client_name == null || row.client_name == ""
                      ? "-"
                      : row.client_name}
                  </TableCell>
                  <TableCell align="left">
                    {row.description == null || row.description == ""
                      ? "-"
                      : row.description}
                  </TableCell>
                  <TableCell align="left">
                    {row.website_link == null || row.website_link == "" ? (
                      "-"
                    ) : (
                      <a
                        href={row.website_link}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        {row.website_link}
                      </a>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {row.app_link == null || row.app_link == "" ? (
                      "-"
                    ) : (
                      <a
                        href={row.app_link}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        {row.app_link}
                      </a>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {row.service?.service_name}
                  </TableCell>
                  <TableCell align="left">
                    {row.industries.length > 0 &&
                      row.industries
                        .map((data) => data.industry_name)
                        .join(" , ")}
                  </TableCell>
                  <TableCell align="left">
                    {row.tech_stacks.length > 0 &&
                      row.tech_stacks.map((data) => data.name).join(" , ")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* </Scrollbar> */}
    </div>
  );
}
