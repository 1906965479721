import { timelineActionsEnum } from "./enum";
// First: Deal Id
// Second: Timestamp
export const dealCreationTemplate = ([...args]) => {
  const title = timelineActionsEnum.EMB_DEAL_CREATED;
  const description = `Deal ${args[0]} is Created on ${args[1]}`;
  return {
    title,
    id: args[0],
    meta: [{ description }],
  };
};

// First: Deal Id
// Second: Timestamp
// Third: UserID
// Fourth: User Name
// Fifth: User Email
export const assignUserTemplate = (...args) => {
  const [deal_id, timestamp, user_id, user_name, user_email] = args;
  console.log(deal_id, timestamp, user_id, user_name, user_email);
  const title = timelineActionsEnum.EMB_ASSIGN_USER;
  const description = `Deal ${deal_id} has been assigned to ${user_name}-${user_email} on ${timestamp}`;
  return {
    title,
    id: args[0],
    meta: [
      {
        description,
        anchor: [
          {
            name: user_name,
            link: `/users/${user_id}`,
          },
        ],
      },
    ],
  };
};

// First: Deal Id
// Second: Timestamp
// Third: VendorInfo1
//..so on depending how may vendors have been shorlisted
export const shortlistVendors = ([...args]) => {
  const [deal_id, timestamp, ...vendorInfos] = args;
  const title = timelineActionsEnum.EMB_VENDOR_SHORTLIST;

  function getVendorInfoText(vendorInfos) {
    return vendorInfos.map((vendorInfo) => vendorInfo.name).join(", ");
  }

  const description1 = `Deal ${deal_id} has been shorlisted with the following vendors ${getVendorInfoText(
    vendorInfos
  )} on ${timestamp}`;
  return {
    title,
    id: args[0],
    meta: [
      {
        description: description1,
        anchor: vendorInfos.map((ele) => ({
          name: ele.name,
          link: `/dashboard/profiles/${ele.id}/basic`,
        })),
      },
      {
        description: "Upload JD, Budget, Desciprtion for skill Python",
        anchor: [
          {
            name: "JD Name",
            link: `/dashboard/profiles/${vendorInfos[0].id}/basic`,
          },
        ],
      },
    ],
  };
};

export const EmbCvUploadTemplate = ([...args]) => {
  const [deal_id, timestamp, emb_cv_filename, emb_cv_url, applicant_id] = args;
  const title = "EMB Cv Upload";
  const description = `EMB has uploaded the CV for Applicant ID: ${applicant_id} in Deal ID: ${deal_id} on ${timestamp}`;

  return {
    title,
    id: deal_id,
    meta: [
      {
        description,
        anchor: [
          {
            name: emb_cv_filename,
            link: emb_cv_url,
          },
        ],
      },
    ],
  };
};

export const EmbRejectCvTemplate = ([...args]) => {
  const [deal_id, timestamp, applicant_id, reject_reason] = args;
  const title = "EMB Rejected a Cv";
  const description = `EMB has rejected the CV for Applicant ID: ${applicant_id} in Deal ID: ${deal_id} on ${timestamp} with reason: ${reject_reason}`;

  return {
    title,
    id: deal_id,
    meta: [{ description }],
  };
};

export const moveToClientTemplate = ([...args]) => {
  const [deal_id, timestamp, applicant_id] = args;
  const title = "Move to Client";
  const description = `Applicant ID: ${applicant_id} in Deal ID: ${deal_id} has been moved to client on ${timestamp}`;

  return {
    title,
    id: deal_id,
    meta: [{ description }],
  };
};

export const moveToHrTemplate = ([...args]) => {
  const [deal_id, timestamp, applicant_id] = args;
  const title = "Move to HR";
  const description = `Applicant ID: ${applicant_id} in Deal ID: ${deal_id} has been moved to HR on ${timestamp}`;

  return {
    title,
    id: deal_id,
    meta: [{ description }],
  };
};

export const moveToInterviewTemplate = ([...args]) => {
  const [deal_id, timestamp, applicant_id] = args;
  const title = "Move to Interview";
  const description = `Applicant ID: ${applicant_id} in Deal ID: ${deal_id} has been moved to Intervue.io on ${timestamp}`;

  return {
    title,
    id: deal_id,
    meta: [{ description }],
  };
};
