import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ClockLoader() {
  return (
    <SkeletonTheme baseColor="#edf2f9" highlightColor="#dde6f0" duration={0.7}>
      <div
        style={{
          justifyContent: "space-between",
          borderRadius: "10px",
          padding: "30px 30px",
          background: "#f3f6f8",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Skeleton
          style={{
            backgroundColor: "#edf2f9",
            display: "flex",
            width: "20%",
            height: "3rem",
            borderRadius: "5px",
          }}
        />
        <Skeleton
          style={{
            backgroundColor: "#edf2f9",
            display: "flex",
            width: "100%",
            height: "15rem",
            borderRadius: "5px",
          }}
        />
        <Skeleton
          style={{
            backgroundColor: "#edf2f9",
            display: "flex",
            width: "10%",
            height: "3rem",
            borderRadius: "5px",
            position: "absolute",
            right: 30,
            bottom: 20,
          }}
        />
      </div>
    </SkeletonTheme>
  );
}

export default ClockLoader;
