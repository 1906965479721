import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchNotFound from "../../src/components/NotFound/SearchNotFound";
import { DateTime } from "luxon";
import {
  Typography,
  Container,
  Card,
  Box,
  Grid,
  Stack,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TableFooter,
  TablePagination,
} from "@mui/material";
import Scrollbar from "../components/Common/Scrollbar";
import "../Profile/upload.css";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
// components
import {
  fetchAllExpertiseThunk,
  deleteExpertiseThunk,
} from "../store/slices/expertisebreakup/expertisebreakup.slice";
import Modal from "../components/Modal/Modal";
import ExpertiseModal from "../components/Modal/ExpertiseModal";
import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
import DeleteAction from "../components/Common/DeleteAction";
import withBusinessId from "../hoc/withBusinessId";

const columns = [
  {
    id: "tech_stack",
    label: "Tech Stack",
    align: "left",
    minWidth: 250,
  },
  {
    id: "employees_1_3",
    label: "No. of Emp. (1-3yrs)",
    align: "left",
    minWidth: 250,
  },
  {
    id: "employees_4_8",
    label: "No. of Emp. (4-8yrs)",
    align: "left",
    minWidth: 180,
  },

  {
    id: "employees_8_more",
    label: "No. of Emp. (8+yrs)",
    align: "left",
    minWidth: 180,
  },
  { id: "created_at", label: "Added On", align: "left", minWidth: 120 },
  {
    id: "action",
    label: "Actions",
    align: "left",
    minWidth: 120,
  },
];

function Expertise_Breakup() {
  const { expertisedata, totalexpertise } = useSelector(
    ({ expertise }) => expertise
  );
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [currentdata, setCurrentData] = useState("");
  const [page, setPage] = React.useState(0);

  useEffect(() => {
    if (page !== 0) {
      dispatch(fetchAllExpertiseThunk(page));
      setPage(parseInt(page - 1));
    }
  }, [expertisedata, dispatch, page]);

  const [rowsPerPage] = React.useState(10);

  const handleChangePage = (_, newPage) => {
    dispatch(fetchAllExpertiseThunk(newPage + 1));
    setPage(newPage);
  };

  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setCurrentData("");
    setShowModal(false);
  };

  function handleEdit(id) {
    var data = expertisedata.find((d) => d.id === id);
    setCurrentData(data);
    setTimeout(() => {
      setShowModal(true);
    }, 0);
  }
  function handleDelete(id) {
    dispatch(deleteExpertiseThunk({ id: id, page: page == 0 ? 1 : page + 1 }))
      .unwrap()
      .then((d) => {});
  }

  // useEffect(() => {
  //   dispatch(fetchAllExpertiseThunk());
  // }, [dispatch]);

  return (
    <Container>
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
        >
          <Typography variant="h5" gutterBottom>
            Expertise BreakUp Info
          </Typography>
        </Stack>
        <Grid container spacing={2} mt={1}>
          <Grid item md={12}>
            {/* <Scrollbar>
                {expertisedata.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) =>
                            column.id != "action" ? (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  top: 57,
                                  minWidth: column.minWidth,
                                  backgroundColor: "#f3f6f8",
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ) : (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  backgroundColor: "#f3f6f8",
                                  position: "sticky",
                                  right: 0,
                                }}
                              >
                                {column.label}
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {expertisedata.map((data) => (
                          <TableRow hover sx={{ cursor: "pointer" }}>
                            <TableCell
                              onClick={() => handleEdit(data.id)}
                              component="th"
                              scope="row"
                            >
                              {data.tech_stack?.name}
                            </TableCell>
                            <TableCell
                              onClick={() => handleEdit(data.id)}
                              component="th"
                              scope="row"
                            >
                              {data.employees_1_3}
                            </TableCell>
                            <TableCell
                              onClick={() => handleEdit(data.id)}
                              component="th"
                              scope="row"
                            >
                              {data.employees_4_8}
                            </TableCell>
                            <TableCell
                              onClick={() => handleEdit(data.id)}
                              component="th"
                              scope="row"
                            >
                              {data.employees_8_more}
                            </TableCell>
                            <TableCell
                              onClick={() => handleEdit(data.id)}
                              component="th"
                              scope="row"
                            >
                              {DateTime.fromISO(data.created_at).toLocaleString(
                                DateTime.DATE_MED
                              )}
                            </TableCell>
                            <TableCell
                              style={{
                                position: "sticky",
                                right: 0,
                                background: "white",
                                boxShadow: "5px 2px 5px grey",
                                borderRight: "2px solid black",
                              }}
                            >
                              <DeleteAction
                                handleDelete={() => handleDelete(data.id)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter
                        style={{
                          display: "inline-block",
                          marginTop: "4rem",
                        }}
                      >
                        <TableRow>
                          <TablePagination
                            style={{
                              right: 0,
                              background: "white",
                              width: "100%",
                              position: "absolute",
                              bottom: 0,
                            }}
                            count={totalexpertise}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={false}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                ) : (
                  <SearchNotFound
                    src="/static/support.svg"
                    subtitle="There is currently no data to show."
                  />
                )}
              </Scrollbar> */}
            <ExpertiseModal
              closeModal={handleClose}
              props={currentdata}
              page={page === 0 ? 1 : page + 1}
            />
            {/* <Modal
              maxWidth="md"
              isOpen={showModal}
              closeModal={handleClose}
              title="Add Expertise Breakup Form"
              content={
                <ExpertiseModal
                  closeModal={handleClose}
                  props={currentdata}
                  page={page == 0 ? 1 : page + 1}
                />
              }
              dialogProps={{ fullWidth: true }}
              titleStyle={{ textAlign: "center", marginTop: 2 }}
              actionStyle={{ justifyContent: "center", marginBottom: 2 }}
            /> */}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default withBusinessId(Expertise_Breakup);
