import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchNotesByIdHandler,
  deleteNotesHandler,
  addNotesByIdHandler,
  updateNotesHandler,
} from "./notes.thunk";

const INIT_STATE = {
  notesData: [],
  isLoading: false,
  totalNotes: 0,
};

export const fetchNotesByIdThunk = createAsyncThunk(
  "fetchNotesById",
  fetchNotesByIdHandler
);

export const deleteNotesThunk = createAsyncThunk(
  "deleteNotes",
  deleteNotesHandler
);

export const addNotesByIdThunk = createAsyncThunk(
  "addNotesById",
  addNotesByIdHandler
);

export const updateNotesThunk = createAsyncThunk(
  "updateNotes",
  updateNotesHandler
);

const notesSlice = createSlice({
  name: "notes",
  initialState: INIT_STATE,
  reducers: {
    startNotesLoader: (state) => {
      state.isLoading = true;
    },
    stopNotesLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotesByIdThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNotesByIdThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.notesData = action.payload.data;
        state.totalNotes = action.payload.total;
      })
      .addCase(fetchNotesByIdThunk.rejected, (state) => {
        state.notesData = [];
        state.isLoading = false;
      })
      .addCase(addNotesByIdThunk.fulfilled, (state, action) => {
        // state.notesData.push(action.payload);
        // state.totalNotes = state.totalNotes + 1;
      })
      .addCase(deleteNotesThunk.fulfilled, (state, action) => {
        // var data = state.notesData.filter((ele) => ele.id !== action.payload);
        // state.notesData = data;
        // state.totalNotes = state.totalNotes - 1;
        // return state;
      })
      .addCase(updateNotesThunk.fulfilled, (state, action) => {
        const indx = state.notesData.findIndex(
          (ele) => ele.id === action.payload.id
        );
        state.notesData[indx] = action.payload;
        return state;
      });
  },
});

export const { startNotesLoader, stopNotesLoader } = notesSlice.actions;

export default notesSlice.reducer;
