import React from "react";
import { Box, Grid, Button } from "@mui/material";
import { inputType } from "../utils/enum";
import { turnOverYears } from "../utils/options.util";
import Element from "../components/Form/Element";
import { turnoverValidations } from "../utils/validations/vendor.validation";
import useForm from "../hooks/useForm";

import {
  addTurnoverForLast3YearsThunk,
  editTurnoverForLast3YearsThunk,
} from "../store/slices/vendor/vendor.slice";
import { useDispatch } from "react-redux";

export default function TurnOverModal({ props, closeModal }) {
  const dispatch = useDispatch();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useForm({
      initialValues: {
        year: props.turnover_year === "" ? "" : props.turnover_year,
        projectDeliveredAmount:
          props.project_delivered_amount === ""
            ? ""
            : props.project_delivered_amount,
        projectBookedAmount:
          props.project_booked_amount === "" ? "" : props.project_booked_amount,
        projectDelivered:
          props.project_delivered === "" ? "" : props.project_delivered,
        projectBooked: props.project_booked === "" ? "" : props.project_booked,
      },
      onSubmit: onSubmit,
      validationSchema: turnoverValidations,
    });

  function onSubmit(fieldsData) {
    if (!props.turnover_year) {
      let data = {
        turnover_year: fieldsData.year,
        // turnover_amount: fieldsData.turnover,
        project_delivered: fieldsData.projectDelivered,
        project_delivered_amount: fieldsData.projectDeliveredAmount,
        project_booked: fieldsData.projectBooked,
        project_booked_amount: fieldsData.projectBookedAmount,
      };

      dispatch(addTurnoverForLast3YearsThunk(data))
        .unwrap()
        .then((d) => {
          closeModal();
        });
    } else {
      let turnoverData = {
        id: props.id,
        data: {
          turnover_year: fieldsData.year,
          // turnover_amount: fieldsData.turnover,
          project_delivered: fieldsData.projectDelivered,
          project_delivered_amount: fieldsData.projectDeliveredAmount,
          project_booked: fieldsData.projectBooked,
          project_booked_amount: fieldsData.projectBookedAmount,
        },
      };

      dispatch(editTurnoverForLast3YearsThunk(turnoverData))
        .unwrap()
        .then((d) => {
          closeModal();
        });
    }
  }

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} p={3}>
            <Grid item md={12} pb={3}>
              <Element
                label="Year"
                inputProps={{
                  name: "year",
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                value={values.year}
                options={turnOverYears}
                errorText={touched.year && errors.year}
                eletype={inputType.select}
              />
            </Grid>

            {/* <Grid item md={12} pb={3}>
              <Element
                label="Turnover"
                inputProps={{
                  placeholder: "Turnover",
                  type: "text",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "turnover",
                }}
                eletype={inputType.number}
                value={values.turnover}
                errorText={touched.turnover && errors.turnover}
              />
            </Grid> */}

            <Grid item md={12} pb={3}>
              <Element
                label="Project Booked"
                inputProps={{
                  placeholder: "Project Booked",
                  type: "text",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "projectBooked",
                }}
                eletype={inputType.number}
                value={values.projectBooked}
                errorText={touched.projectBooked && errors.projectBooked}
              />
            </Grid>

            <Grid item md={12} pb={3}>
              <Element
                label="Project Booked Amount"
                inputProps={{
                  placeholder: "Project Booked Amount",
                  type: "text",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "projectBookedAmount",
                }}
                eletype={inputType.number}
                value={values.projectBookedAmount}
                errorText={
                  touched.projectBookedAmount && errors.projectBookedAmount
                }
              />
            </Grid>

            <Grid item md={12} pb={3}>
              <Element
                label="Project Delivered"
                inputProps={{
                  placeholder: "Project Delivered",
                  type: "text",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "projectDelivered",
                }}
                eletype={inputType.number}
                value={values.projectDelivered}
                errorText={touched.projectDelivered && errors.projectDelivered}
              />
            </Grid>

            <Grid item md={12} pb={3}>
              <Element
                label="Project Delivered Amount"
                inputProps={{
                  placeholder: "Project Delivered Amount",
                  type: "text",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "projectDeliveredAmount",
                }}
                eletype={inputType.number}
                value={values.projectDeliveredAmount}
                errorText={
                  touched.projectDeliveredAmount && errors.projectDeliveredAmount
                }
              />
            </Grid>

            <Grid item md={12} p={3} sx={{ textAlign: "center" }}>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
