import {
  Autocomplete,
  Chip,
  FormControl,
  FormGroup,
  Input,
  ListItem,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { advanceFilterFieldType } from "../../utils/enum";
import StyledTreeItem from "./StylesTreeItem";

function FilterDropdown({
  label,
  value,
  state,
  handleFilterValueChange,
  deleteFilterValue,
  options,
}) {
  const [inputValue, setInputValue] = useState("");

  function handleSelect(_, newValue) {
    setInputValue("");
    handleFilterValueChange(
      advanceFilterFieldType.dropdown,
      value,
      null,
      newValue
    );
  }

  function handleSearchValueChange(e) {
    const eleValue = e.target.value;
    handleFilterValueChange(advanceFilterFieldType.dropdown, value, eleValue);
  }

  return (
    <StyledTreeItem nodeId={value} labelText={label}>
      <FormGroup>
        <FormControl sx={{ mt: 2, ml: 2, maxWidth: 150 }}>
          <Select
            sx={{ height: "40px" }}
            placeholder="Select Choice"
            value={state.search_type}
            onChange={handleSearchValueChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="is">is</MenuItem>
            <MenuItem value="is_not">is not</MenuItem>
          </Select>
        </FormControl>
      </FormGroup>
      <FormControl sx={{ m: 2, width: 300 }}>
        <Autocomplete
          inputValue={inputValue}
          options={options}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label={label} />}
          onInputChange={(e, newVal) => setInputValue(newVal)}
          onChange={handleSelect}
        />
      </FormControl>
      <ListItem sx={{ display: "contents" }}>
        {state.values?.map((ele) => (
          <Chip
            label={ele}
            onDelete={() =>
              deleteFilterValue(
                advanceFilterFieldType.dropdown,
                value,
                null,
                ele
              )
            }
            sx={{ margin: "0.5rem 2rem" }}
          />
        ))}
      </ListItem>
    </StyledTreeItem>
  );
}

export default FilterDropdown;
