import React from "react";
import { Card, Box, Grid, Button, Stack } from "@mui/material";
import Element from "./../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { passwordvalidation } from "../../utils/validations/vendor.validation";
import useForm from "../../hooks/useForm";
import { useDispatch } from "react-redux";
import { updatePasswordThunk } from "../../store/slices/user/user.slice";
import { Formik } from "formik";

export default function EditPassword() {
  const dispatch = useDispatch();
  function onSubmit(data) {
    dispatch(updatePasswordThunk(data));
  }

  return (
    <div>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box p={3}>
              <Formik
                initialValues={{
                  old_password: "",
                  new_password: "",
                  confirmpass: "",
                }}
                onSubmit={onSubmit}
                validationSchema={passwordvalidation}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box sx={{ margin: "20px 0 10px 0" }}>
                      <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        spacing={2}
                      >
                        <Element
                          label="Old Password"
                          inputProps={{
                            type: "password",
                            onChange: handleChange,
                            onBlur: handleBlur,
                            name: "old_password",
                          }}
                          eletype={inputType.input}
                          value={values.old_password}
                          errorText={
                            touched.old_password && errors.old_password
                          }
                        />
                      </Stack>
                    </Box>

                    <Box sx={{ margin: "20px 0 10px 0" }}>
                      <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        spacing={2}
                      >
                        <Element
                          label="New Password"
                          inputProps={{
                            type: "password",
                            onChange: handleChange,
                            onBlur: handleBlur,
                            name: "new_password",
                          }}
                          eletype={inputType.input}
                          value={values.new_password}
                          errorText={
                            touched.new_password && errors.new_password
                          }
                        />
                      </Stack>
                    </Box>

                    <Box sx={{ margin: "20px 0 10px 0" }}>
                      <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        spacing={2}
                      >
                        <Element
                          label="Confirm Password"
                          inputProps={{
                            type: "password",
                            onChange: handleChange,
                            onBlur: handleBlur,
                            name: "confirmpass",
                          }}
                          eletype={inputType.input}
                          value={values.confirmpass}
                          errorText={touched.confirmpass && errors.confirmpass}
                        />
                      </Stack>
                    </Box>

                    <Grid item md={12} p={3}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ float: "right" }}
                      >
                        Save
                      </Button>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}
