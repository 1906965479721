import React, { useState, useEffect, useMemo } from "react";
import FilterShortlist from "../../components/FilterShortList/FilterShortlist";
import Drawer from "@mui/material/Drawer";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import { Icon } from "@iconify/react";
import ListSearchbar from "../../components/Common/ListSearchbar";
import {
  Button,
  Box,
  Stack,
  Container,
  Grid,
  Typography,
  Card,
  TablePagination,
  TableFooter,
  TableRow,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "./ShortlistedVendor.css";
import { useParams } from "react-router";
import Modal from "../../components/Modal/Modal";
import _ from "lodash";
import {
  fetchDealInfoById,
  getRecommendedVendorsThunk,
  getShortlistedVendorsThunk,
  addVendorAsShortlistedHThunk,
  deleteVendorAsShortlistedHThunk,
  fetchAllTagsByServiceThunk,
  fetchAllTechStackByServiceThunk,
  fetchAllTechStackByIDsThunk,
  fetchVendorShortlistingProfileByFilterThunk,
} from "../../store/slices/shortlisting/shortlisting.slice";

import VendorProfileCard from "./VendorProfileCard";
import { showFailureToast } from "../../store/slices/toast/toast.slice";
import { fetchAllIndustryThunk } from "../../store/slices/Industry/industry.slice";
import useShortListingFilter from "../../hooks/useShortlistingFilter";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ShortlistNotesModal from "../../components/Modal/ShortlistNotesModal";
import {
  getModifiedFilterState,
  checkForFilterValidity,
} from "../../utils/global/global";
import usePermissions from "../../hooks/usePermission";
import systemPermissions, {
  actions,
} from "../../utils/global/permissions.global";

function ShorlistedVendor() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const {
    selectedDeal,
    shortlistedVendors: {
      recommendedVendors,
      shortListedVenodrsData,
      totalRecommendedVendors,
    },
  } = useSelector(({ shortlist }) => shortlist);

  const params = useParams();
  const userPerm = usePermissions();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");

  function handleFilter() {
    setShowDrawer(true);
  }
  function handleFilterClose() {
    setShowDrawer(false);
  }

  const { isFilterApplied, ...filterState } = useShortListingFilter({
    initValues: {
      service: {
        values: selectedDeal?.sku_name
          ? selectedDeal?.sku_name?.map((ele) => ele.name)
          : [],
      },
      selectedService: selectedDeal?.sku_name && selectedDeal?.sku_name,
    },
  });

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleChangePage = (_, newPage) => {
    if (isFilterApplied) {
      const [_, newState] = getModifiedFilterState(filterState.state);
      dispatch(
        fetchVendorShortlistingProfileByFilterThunk({
          filter_data: newState,
          page: newPage + 1,
          limit: 10,
          deal_id: params.id,
        })
      );
      return setPage(newPage);
    }

    dispatch(
      getRecommendedVendorsThunk({
        id: params.id,
        page: newPage + 1,
        rowperpage: rowsPerPage,
      })
    );
    setPage(newPage);
  };

  const _searchChange = useMemo(() => {
    return _.debounce((search, filterState) => {
      const [_, newState] = getModifiedFilterState(filterState);

      // if (!search && !filterData.isAllowed) {
      //   return dispatch(fetchAllVendorProfilesThunk());
      // }
      const filter_data = [
        {
          field_name: "name",
          search_type: "start_with",
          values: [search],
        },
      ];
      setPage(0);
      dispatch(
        fetchVendorShortlistingProfileByFilterThunk({
          filter_data: filter_data,
          page: 1,
          limit: 10,
          deal_id: params.id,
        })
      );
    }, 500);
  }, []);

  useEffect(() => {
    dispatch(fetchDealInfoById(params.id));
    dispatch(
      getRecommendedVendorsThunk({
        id: params.id,
        page: 1,
        rowperpage: rowsPerPage,
      })
    );
    dispatch(getShortlistedVendorsThunk(params.id));
  }, [params.id, dispatch, rowsPerPage]);

  useEffect(() => {
    let id = selectedDeal?.sku_name && selectedDeal?.sku_name;
    if (id) {
      dispatch(fetchAllIndustryThunk());
      dispatch(
        fetchAllTechStackByIDsThunk(
          selectedDeal.sku_name.map((ele) => `"${ele._id}"`)
        )
      );
    }
  }, [selectedDeal?.sku_name, dispatch]);

  function fncSelect(data) {
    if (shortListedVenodrsData.length < 4) {
      var sendData = {
        deal_id: params.id,
        vendor_id: data.id,
        reason: data.reason,
        source_type: data.source_type,
        portfolio_ids: data.portfolio_ids,
      };
      dispatch(addVendorAsShortlistedHThunk(sendData));
    } else {
      dispatch(
        showFailureToast({
          message: "Maximum 4 vendors can be shortlisted",
          vertical: "top",
          horizontal: "right",
        })
      );
    }
  }

  function fncUnSelect(data) {
    var sendData = {
      deal_id: params.id,
      vendors: [data.id],
    };
    dispatch(deleteVendorAsShortlistedHThunk(sendData));
  }

  //search
  function handleSearchChange(e) {
    const value = e.target.value;
    _searchChange(value, filterState.state);
    setSearch(value);
  }
  return (
    <>
      <Container>
        <Drawer
          anchor="right"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          PaperProps={{
            style: { width: "600px" },
          }}
        >
          <FilterShortlist
            handleFilterClose={handleFilterClose}
            filterState={filterState}
          />
        </Drawer>
        <Card p={2}>
          <Grid container spacing={2} p={5}>
            <Grid item md={12}>
              <Box>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  mb={2}
                >
                  <Box
                    sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                  >
                    <img
                      src="/static/filter.svg"
                      style={{ borderRadius: 0 }}
                      alt="filter_svg"
                    />
                    <Typography variant="h5">Project Details</Typography>
                  </Box>
                  <Box>
                    {selectedDeal.notes?.length > 0 && (
                      <Button
                        startIcon={<RemoveRedEyeIcon />}
                        onClick={handleOpen}
                      >
                        Notes
                      </Button>
                    )}
                  </Box>
                </Stack>
              </Box>
            </Grid>
            {selectedDeal?.vendor_region_preference && (
              <Grid item md={12}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Vendor Region Preference :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      {selectedDeal?.vendor_region_preference}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.deal_name && (
              <Grid item md={6}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Deal Name :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      {selectedDeal?.deal_name}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.client_budget && (
              <Grid item md={6}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Client Budget :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      ₹ {selectedDeal?.client_budget}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.client_service_knowledge && (
              <Grid item md={6}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Client Service Knowledge :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      {selectedDeal?.client_service_knowledge}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.industry_level_1 && (
              <Grid item md={6}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Industry Level 1 :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      {selectedDeal?.industry_level_1}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.industry_level_2 && (
              <Grid item md={6}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Industry Level 2 :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      {selectedDeal?.industry_level_2}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.age_mapping_client && (
              <Grid item md={6}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Age Mapping For Client :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      {selectedDeal?.age_mapping_client}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.client_english_proficiency && (
              <Grid item md={6}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Client English Proficiency :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      {selectedDeal?.client_english_proficiency}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.sku_name && (
              <Grid item md={6}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    SKU Name:{" "}
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      {selectedDeal?.sku_name
                        ?.map((ele) => ele.name)
                        ?.join(",")}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.tech_stack && (
              <Grid item md={6}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Tech Stack :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      {selectedDeal?.tech_stack}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.need_relevant_past_work && (
              <Grid item md={6}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Need Relevant Past Work :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      {selectedDeal?.need_relevant_past_work ? "Yes" : "No"}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.is_client_polished && (
              <Grid item md={6}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Is Client Polished :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      {selectedDeal?.is_client_polished ? "Yes" : "No"}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.client_project_completion_deadline && (
              <Grid item md={6}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Project Completion Deadline From Client :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {selectedDeal?.client_project_completion_deadline}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.feature_list && (
              <Grid item md={12}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Feature List :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {selectedDeal?.feature_list}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.supply_team_notes && (
              <Grid item md={12}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Notes For Supply Team :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {selectedDeal?.supply_team_notes}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.app_link && (
              <Grid item md={12}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Client Website / App Link :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {selectedDeal?.app_link}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.client_references && (
              <Grid item md={12}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Client References :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {selectedDeal?.client_references}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {selectedDeal?.requested_by && (
              <Grid item md={6}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Requested By :{" "}
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      {selectedDeal?.requested_by}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Card>

        <Grid container spacing={2} mt={5}>
          <Grid item md={12}>
            <Box sx={{ padding: "2rem 0rem 0rem 2rem" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                spacing={2}
                mb={2}
              >
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <img
                    src="/static/suitible_vendors.svg"
                    style={{ borderRadius: 0 }}
                  />
                  <Typography variant="h5">Suitable Vendors</Typography>
                </div>
              </Stack>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                marginBottom: "1rem",
                marginTop: "2rem",
              }}
            ></Typography>

            {shortListedVenodrsData?.length === 0 ? (
              <SearchNotFound
                height="300px"
                src="/static/support.svg"
                subtitle="There is no selected vendors."
                title=""
              />
            ) : (
              shortListedVenodrsData?.map((data) => (
                <VendorProfileCard
                  data={data}
                  actionFn={fncUnSelect}
                  selected
                />
              ))
            )}

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ marginBottom: "2rem" }}
            >
              <Typography
                variant="h5"
                sx={{
                  textAlign: "left",
                  marginBottom: "1rem",
                  marginTop: "5rem",
                }}
              >
                Recommended Vendors
              </Typography>
              {userPerm.hasAccess(
                systemPermissions["VendorShorlistItem.filter"],
                actions.read
              ) && (
                <ListSearchbar
                  placeholder="Search vendor profile"
                  handleSearchChange={handleSearchChange}
                  styles={{
                    width: "280px",
                  }}
                  rootStyle={{
                    alignItems: "end",
                  }}
                />
              )}
              {userPerm.hasAccess(
                systemPermissions["VendorShorlistItem.filter"],
                actions.read
              ) && (
                <Button
                  variant="outlined"
                  sx={{
                    padding: "10px 20px",
                    marginRight: "20px",
                    marginTop: "5rem",
                  }}
                  onClick={handleFilter}
                >
                  <Icon icon="bytesize:filter" />{" "}
                  <span style={{ marginLeft: "10px" }}>Filter</span>
                </Button>
              )}
            </Stack>
            {recommendedVendors?.length === 0 ? (
              <SearchNotFound
                height="300px"
                src="/static/support.svg"
                subtitle="There is no recommended vendors."
                title=""
              />
            ) : (
              <div key={new Date().toISOString()}>
                {recommendedVendors?.map((data) => (
                  <VendorProfileCard data={data} actionFn={fncSelect} />
                ))}
              </div>
            )}
            {recommendedVendors?.length != 0 && (
              <Grid
                item
                xs={12}
                md={12}
                sx={{ display: "flex", justifyContent: "right" }}
              >
                <Paper>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        count={totalRecommendedVendors}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={false}
                      />
                    </TableRow>
                  </TableFooter>
                </Paper>
              </Grid>
            )}

            {/* //----------------------------------------  Recommended Vendors ends here --------------------------------------------- */}

            <Modal
              isOpen={showModal}
              closeModal={handleClose}
              title="Notes"
              content={<ShortlistNotesModal />}
              titleStyle={{ textAlign: "center", marginTop: 2 }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ShorlistedVendor;
