import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Button } from "@mui/material";
import ConfirmationsModal from "../Modal/ConfirmationsModal";
import usePermissions from "../../hooks/usePermission";
import systemPermissions, { actions } from "../../utils/global/permissions.global";

function DeleteAction({ handleDelete, fromScreen, disabled }) {
  const [isActionAllowed, setIsActionAllowed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const userPerm = usePermissions();

  function _handleDelete(e) {
    e.stopPropagation();
    // if (
      // !userPerm.hasAccess(
      //   systemPermissions["vendorProfileDetailPage.address"],
      //   actions.delete
      // )
    // )
    //   return;

    
    setShowModal(true);
  }

  function userAction(feedback) {
    if (feedback) setIsActionAllowed(true);
    setShowModal(false);
  }

  useEffect(() => {
    if (isActionAllowed) {
      handleDelete();
      setIsActionAllowed(false);
    }
  }, [isActionAllowed]);

  return (
    <>
      {showModal && (
        <ConfirmationsModal
          successCallback={() => userAction(true)}
          closeModal={() => {
            setShowModal(false);
            userAction(false);
          }}
          isOpen={showModal}
          fromScreen={fromScreen}
        />
      )}

      {fromScreen ? (
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          className="markasresolved"
          onClick={_handleDelete}
        >
          Mark as resolved
        </Button>
      ) : (
        <IconButton color="error" component="span" onClick={_handleDelete} disabled={disabled}>
          <DeleteIcon
            color="error"
            sx={{
              cursor: "pointer",
              zIndex: "999",
            }}
          />
        </IconButton>
      )}
    </>
  );
}

export default DeleteAction;
