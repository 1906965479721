import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllTeamInfoHandler,
  deleteTeamInfoHandler,
  addTeamInfoHandler,
  updateTeamInfoHandler,
} from "./teamInfo.thunk";

const INIT_STATE = {
  teaminfodata: [],
  isLoading: false,
};

export const fetchAllTeamInoThunk = createAsyncThunk(
  "fetchAllTeamInfo",
  fetchAllTeamInfoHandler
);

export const deleteTeamInfoThunk = createAsyncThunk(
  "deleteTeamInfo",
  deleteTeamInfoHandler
);

export const AddTeamInfoThunk = createAsyncThunk(
  "addTeamInfo",
  addTeamInfoHandler
);

export const updateTeamInofThunk = createAsyncThunk(
  "updateTeamInfo",
  updateTeamInfoHandler
);

const teaminfoSlice = createSlice({
  name: "teaminfo",
  initialState: INIT_STATE,
  reducers: {
    startTeamInfoLoader: (state) => {
      state.isLoading = true;
    },
    stopTeamInfoLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTeamInoThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllTeamInoThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.teaminfodata = action.payload;
      })
      .addCase(fetchAllTeamInoThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(AddTeamInfoThunk.fulfilled, (state, action) => {
        state.teaminfodata.push(action.payload);
      })
      .addCase(deleteTeamInfoThunk.fulfilled, (state, action) => {
        var id = action.payload;
        var data = state.teaminfodata.filter((ele) => ele.id !== id);
        state.teaminfodata = data;
        return state;
      })
      .addCase(updateTeamInofThunk.fulfilled, (state, action) => {
        const idx = state.teaminfodata.findIndex(
          (ele) => ele.id === action.payload.id
        );
        state.teaminfodata[idx] = action.payload;
        return state;
      });
  },
});

export const { startTeamInfoLoader, stopTeamInfoLoader } =
  teaminfoSlice.actions;

export default teaminfoSlice.reducer;
