import React from "react";
import {
  Typography,
  Container,
  Card,
  Box,
  Grid,
  Stack,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
// components
import { green } from "@mui/material/colors";
import Page from "./../../components/Page";
import Element from "./../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { Formik } from "formik";
import Address from "../../components/Form/Address";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch } from "react-redux";
import { updateUsersThunk } from "../../store/slices/edituser/edituser.slice";

export default function EditPersonalDetails({ handleprofileEditMode, data }) {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    var data = {
      data: {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        address_line1: values.address.address_line1,
        address_line2: values.address.address_line2,
        city: values.address.city,
        state: values.address.state,
        country: values.address.country,
        zip_code: values.address.zip_code,
        country_code: values.mobile?.countryCode,
        designation: values.designation,
      },
    };
    dispatch(updateUsersThunk(data))
      .unwrap()
      .then(() => {
        handleprofileEditMode(false);
      });
  };

  return (
    <Page title="Edit MyProfile">
      <Container>
        <Box>
          <Grid container spacing={4} mt={5}>
            <Grid item md={4}>
              <Card sx={{ minHeight: "420px" }}>
                <Box sx={{ padding: "30px 0px" }}>
                  <Box
                    sx={{
                      marginTop: "60px",
                      width: 144,
                      height: 144,
                      mx: "auto",
                      borderRadius: "50%",
                      padding: "8px",
                      border: "1px dashed rgba(145, 158, 171, 0.32)",
                      backgroundColor: "rgb(244, 246, 248)",
                    }}
                  >
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{ color: "rgb(99, 115, 129)" }}
                      my={3}
                    >
                      <label htmlFor="icon-button-file">
                        {/* <Input
                          accept="image/*"
                          id="icon-button-file"
                          type="file"
                          onChange={fncUploadOnChange}
                        /> */}
                        <IconButton
                          aria-label="upload picture"
                          component="span"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            my: "1rem",
                          }}
                        >
                          <PhotoCamera />
                          <Typography variant="caption">
                            Upload Photo
                          </Typography>
                        </IconButton>
                      </label>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      width: "60%",
                      textAlign: "center",
                      mx: "auto",
                      pt: "1rem",
                      color: "rgb(99, 115, 129)",
                    }}
                  >
                    <Typography variant="caption">
                      Allowed *.jpeg, *.jpg, *.png, *.gif max size of 3.1 MB
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>

            <Grid item md={8}>
              <Card>
                <Container>
                  <Box sx={{ margin: "20px 0 10px 0" }}>
                    <Formik
                      initialValues={{
                        first_name: data.first_name,
                        last_name: data.last_name,
                        email: data.email,
                        designation: data?.designation ? data?.designation : "",
                        address: {
                          address_line1: data.address_line1,
                          address_line2: data.address_line2,
                          zip_code: data.zip_code,
                          state: data.state,
                          country: data.country,
                          city: data.city,
                        },
                      }}
                      onSubmit={handleSubmit}
                    >
                      {({
                        values,
                        handleChange,
                        handleBlur,
                        touched,
                        errors,
                        handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <Box sx={{ margin: "20px 0" }}>
                            <Stack
                              direction="row"
                              justifyContent="space-evenly"
                              spacing={2}
                            >
                              <Element
                                value={values.first_name}
                                eletype={inputType.input}
                                label="First Name"
                                inputProps={{
                                  name: "first_name",
                                  onChange: handleChange,
                                  onBlur: handleBlur,
                                }}
                                error={Boolean(
                                  touched.first_name && errors.first_name
                                )}
                                errorText={
                                  touched.first_name && errors.first_name
                                }
                              />
                              <Element
                                value={values.last_name}
                                eletype={inputType.input}
                                label="Last Name"
                                inputProps={{
                                  name: "last_name",
                                  onChange: handleChange,
                                  onBlur: handleBlur,
                                }}
                              />
                            </Stack>
                          </Box>
                          <Box sx={{ margin: "20px 0 10px 0" }}>
                            <Stack
                              direction="row"
                              justifyContent="space-evenly"
                              spacing={2}
                            >
                              <Element
                                value={values.email}
                                eletype={inputType.input}
                                label="Email Address"
                                inputProps={{
                                  name: "email",
                                  onChange: handleChange,
                                  onBlur: handleBlur,
                                }}
                                readOnly={true}
                              />
                            </Stack>
                          </Box>

                          <Box sx={{ margin: "20px 0 10px 0" }}>
                            <Stack
                              direction="row"
                              justifyContent="space-evenly"
                              spacing={2}
                            >
                              <Element
                                value={values.designation}
                                eletype={inputType.input}
                                label="Designation"
                                inputProps={{
                                  name: "designation",
                                  onChange: handleChange,
                                  onBlur: handleBlur,
                                }}
                              />
                            </Stack>
                          </Box>

                          <Address
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            values={values.address}
                          />

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "right",
                              margin: "20px 0",
                            }}
                          >
                            <Stack direction="row" spacing={1}>
                              <Button
                                onClick={() => handleprofileEditMode(false)}
                              >
                                Back
                              </Button>
                              <Button
                                type="submit"
                                color="secondary"
                                startIcon={<SendIcon />}
                                variant="contained"
                                sx={{
                                  background: green[500],
                                  ":hover": { background: green[300] },
                                }}
                              >
                                Save
                              </Button>
                            </Stack>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Box>
                </Container>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}
