import React from "react";
import {
  Box,
  Card,
  Stack,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { Save } from "@mui/icons-material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ConfirmationsModal({
  successCallback,
  closeModal,
  isOpen,
  title = "Are you sure you want to delete this ?",
  subtitle = "This item will removed permanently and cannot be recovered?",
  type = "delete",
  btnText = "Delete",
}) {
  return (
    <>
      <BootstrapDialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={closeModal}
          sx={{ zIndex: "10" }}
        />

        <DialogContent sx={{ p: "0.5rem!important" }}>
          <Stack>
            <Card
              className="dialog-content-confirmation"
              sx={{ padding: "10px", boxShadow: "none" }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <div>
                  <IconButton
                    color="error"
                    component="span"
                    sx={{ backgroundColor: "rgba(255, 72, 66, 0.08)" }}
                  >
                    {type === "success" ? (
                      <Save
                        sx={{ fontSize: "2.5rem", color: "#00AB55!important" }}
                      />
                    ) : (
                      <DeleteIcon
                        sx={{ fontSize: "2.5rem", color: "#E05353!important" }}
                      />
                    )}
                  </IconButton>
                </div>
                <div>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      padding: "0px 0px 10px 0",
                      fontWeight: "bold",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography sx={{ color: "#9c9fa1" }}>{subtitle}</Typography>
                </div>
              </Stack>
            </Card>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ marginRight: "1rem !important" }}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            pb={3}
          >
            <Button
              variant="text"
              autoFocus
              onClick={closeModal}
              sx={{
                color: "#9c9fa1",
                backgroundColor: "#fff",

                "&:hover": {
                  backgroundColor: "#fff",
                  opacity: "0.9",
                  color: "#222",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: type === "success" ? "#00AB55" : "#E05353",
                boxShadow:
                  "0px 5px 5px -3px rgb(255 241 240), 0px 8px 10px 1px rgb(255 241 240), 0px 3px 14px 2px rgb(255 241 240)!important",

                "&:hover": {
                  backgroundColor: type === "success" ? "#00AB55" : "#E05353",
                  opacity: "0.9",
                },
              }}
              onClick={successCallback}
            >
              {btnText}
            </Button>
          </Stack>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
