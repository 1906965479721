import {
  getAllLanguage,
  deleteLanguage,
  addLanguage,
  updateLanguage,
} from "../../../utils/apis.utils";
import {
  startLanguageLoader,
  stopLanguageLoader,
  fetchAllLanguageWithPaginationThunk,
} from "../language/language.slice";
import { createQueryString } from "../../../utils/global/global";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";

export const fetchAllLanguageHandler = async (page = 1, thunkApi) => {
  thunkApi.dispatch(startLanguageLoader());
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(getAllLanguage);
  thunkApi.dispatch(stopLanguageLoader());

  return {
    data: response.data,
    total: response.total,
  };
  // return response.data;
};

export const fetchAllLanguageWithPaginationHandler = async (
  page = 1,
  thunkApi
) => {
  thunkApi.dispatch(startLanguageLoader());
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(
    getAllLanguage + "?is_pagination=1&page=" + page
  );
  thunkApi.dispatch(stopLanguageLoader());

  return {
    data: response.data,
    total: response.total,
  };
  // return response.data;
};

export const deleteLanguageHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startLanguageLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.delete(deleteLanguage + "/" + data.id);
    thunkApi.dispatch(stopLanguageLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllLanguageWithPaginationThunk(data.page));
    // return id;
  } catch (err) {
    thunkApi.dispatch(stopLanguageLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopLanguageLoader());
  }
};

export const addLanguageHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startLanguageLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(addLanguage, { name: data.name });
    thunkApi.dispatch(stopLanguageLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    // return response.data;
    thunkApi.dispatch(fetchAllLanguageWithPaginationThunk(data.page));
  } catch (err) {
    thunkApi.dispatch(stopLanguageLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopLanguageLoader());
  }
};

export const updateLanguageHandler = async (data, thunkApi) => {
  var id = data.id;
  var apiData = {
    name: data.name,
  };

  try {
    thunkApi.dispatch(startLanguageLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(updateLanguage + "/" + id, apiData);
    thunkApi.dispatch(stopLanguageLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopLanguageLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopLanguageLoader());
  }
};
