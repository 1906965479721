import { Card } from "@mui/material";
import React, { useState, useMemo } from "react";
import Input from "../Form/Input";
import _ from "lodash";
import { searchServiceHandler } from "../../utils/global/vendor.global";
import TreeList from "./TreeList.js";
import { Box } from "@mui/system";
import AutocompleteChip from "../Form/AutocompleteChip.js";

function SingleSelectSearchIndustry({
  onChange,
  onBlur,
  label,
  placeholder,
  formatter,
  errorText,
  handler,
  name,
  value,
  extras,
}) {
  const [isOpen, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState(value);

  const handleTextChange = useMemo(() => {
    const _fn = _.debounce((value) => {
      if (!value) return;
      handler(value, extras).then((response) => {
        if (typeof formatter === "function") {
          if (response.data?.length === 0) {
            return setOpen(false);
          }
          setOptions(formatter(response.data));
          setOpen(true);
        }
      });
    }, 500);

    return function handleChange(e) {
      const value = e.target.value;
      setSearch(value);
      _fn(value);
    };
  }, []);

  function handleItemClick(e) {
    onChange({
      target: {
        name: name,
        value: { ...e.target.value },
      },
    });
    setSearch(e.target.value);
  }

  return (
    <>
      <Input
        label={label}
        inputProps={{
          placeholder: placeholder,
          onChange: handleTextChange,
          name: "treeTextChange",
        }}
        value={search?.name}
        errorText={errorText}
      />
      {isOpen && (
        <Card>
          <Box p={2}>
            {options.map((d) => (
              <TreeList
                {...d}
                onChange={handleItemClick}
                onBlur={onBlur}
                setOpen={setOpen}
                name={name}
                idName="id"
                valueName="name"
              />
            ))}
          </Box>
        </Card>
      )}
    </>
  );
}

export default SingleSelectSearchIndustry;
