import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function AddressLoader() {
  return (
    <SkeletonTheme baseColor="#edf2f9" highlightColor="#dde6f0" duration={0.7}>
      <div
        style={{
          justifyContent: "space-between",
          borderRadius: "10px",
          padding: "30px 30px",
          background: "#f3f6f8",
          flexDirection: "column",
          minHeight: "420px",
        }}
      >
        <Skeleton
          style={{
            width: "130px",
            height: "130px",
            borderRadius: "50%",
            marginTop: "65px",
            marginLeft: "50px",
          }}
        />

        <Skeleton
          style={{
            width: "65%",
            borderRadius: "5px",
            marginTop: "15px",
            marginLeft: "40px",
          }}
        />
      </div>
    </SkeletonTheme>
  );
}

export default AddressLoader;
