import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllCategoryHandler,
  addCategoryHandler,
  deleteCategoryHandler,
  updateCategoryHandler,
  fetchAllCategoryWithPaginationHandler,
} from "./category.thunk";

const INIT_STATE = {
  categoryData: [],
  isLoading: false,
  totalCategory: 0,
};

export const fetchAllCategoryThunk = createAsyncThunk(
  "fetchAllCategory",
  fetchAllCategoryHandler
);
export const fetchAllCategoryWithPaginationThunk = createAsyncThunk(
  "fetchAllCategoryWithPagination",
  fetchAllCategoryWithPaginationHandler
);

export const addCategoryThunk = createAsyncThunk(
  "addCategory",
  addCategoryHandler
);

export const deleteCategoryThunk = createAsyncThunk(
  "deleteCategory",
  deleteCategoryHandler
);

export const updateCategoryThunk = createAsyncThunk(
  "updateCategory",
  updateCategoryHandler
);

const categorySlice = createSlice({
  name: "category",
  initialState: INIT_STATE,
  reducers: {
    startCategoryLoader: (state) => {
      state.isLoading = true;
    },
    stopCategoryLoader: (state) => {
      state.isLoading = false;
    },
    setCategory: (state, payload) => {
      state.categoryData = payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchAllCategoryThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllCategoryThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categoryData = action.payload.data;
        state.totalCategory = action.payload.total;
      })
      .addCase(fetchAllCategoryThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAllCategoryWithPaginationThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchAllCategoryWithPaginationThunk.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.categoryData = action.payload.data;
          state.totalCategory = action.payload.total;
        }
      )
      .addCase(fetchAllCategoryWithPaginationThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateCategoryThunk.fulfilled, (state, action) => {
        var ndx = state.categoryData.findIndex(
          (e) => e._id === action.payload._id
        );
        state.categoryData[ndx] = action.payload;
        return state;
      })
      .addCase(addCategoryThunk.fulfilled, (state, action) => {
        // state.categoryData.push(action.payload);
        // state.totalCategory = state.totalCategory + 1;
      })
      .addCase(deleteCategoryThunk.fulfilled, (state, action) => {
        // var id = action.payload;
        // var newData = state.categoryData.filter((ele) => ele._id !== id);
        // state.categoryData = newData;
        // state.totalCategory = state.totalCategory - 1;
        // return state;
      });
  },
});

export const { startCategoryLoader, stopCategoryLoader, setCategory } =
  categorySlice.actions;

export default categorySlice.reducer;
