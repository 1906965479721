import React, { useRef } from "react";
import {
  Typography,
  Container,
  IconButton,
  Card,
  Box,
  Grid,
  Stack,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import FlagIcon from "@mui/icons-material/Flag";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
// components

import Page from "../../components/Page.js";
import { green } from "@mui/material/colors";
import useUser from "../../hooks/useUser.js";
import { useDispatch } from "react-redux";
import { uploadProfileThunk } from "../../store/slices/user/user.slice.js";
import AddressLoader from "../../components/Skeletons/AddressLoader";
import Address2Loader from "../../components/Skeletons/Address2Loader";

export default function PersonalDetails({ handleprofileEditMode }) {
  const user = useUser();
  const dispatch = useDispatch();

  function handleFileChange(e) {
    var formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_type", "profile");
    dispatch(uploadProfileThunk(formData));
  }

  const inputRef = useRef();

  return (
    <Page title="Dashboard: Products | EMB">
      <input
        type="file"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={handleFileChange}
      />
      <Container>
        <Box>
          <Grid container spacing={4} mt={5}>
            <Grid item md={4}>
              <Card sx={{ minHeight: "420px" }}>
                <Stack direction="column" spacing={2} sx={{ p: 3, pr: 0 }}>
                  <Box sx={{ textAlign: "right", mr: 1 }}>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      onClick={() => handleprofileEditMode(true)}
                    >
                      <ModeEditOutlinedIcon
                        fontSize="small"
                        sx={{ color: green[400] }}
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <Avatar
                      alt="Profile-image"
                      src={
                        user.profileUrl
                          ? user.profileUrl
                          : "https://cdn.worldvectorlogo.com/logos/israel-aircraft-unit-2.svg"
                      }
                      sx={{
                        marginTop: "10px",
                        width: 144,
                        height: 144,
                        mx: "auto",
                        borderRadius: "50%",
                        border: "1px dashed rgba(145, 158, 171, 0.32)",
                        backgroundColor: "rgb(244, 246, 248)",
                        cursor: "pointer",
                      }}
                      onClick={() => inputRef.current.click()}
                    />
                  </Box>
                  {/* <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                    Dummy Text
                  </Typography>
                  <Typography px={2} sx={{ color: "#a2a5b9" }}>
                    Vestibulum suscipit nulla quis orci. Fusce a quam. Morbi
                    mollis tellus ac sapien.
                  </Typography> */}
                </Stack>
              </Card>
              {/* <AddressLoader /> */}
            </Grid>

            <Grid item md={8}>
              <Card sx={{ minHeight: "420px", py: 2, px: 1 }}>
                <Grid container>
                  <Grid item sm={6} md={6}>
                    <Stack direction="row" spacing={2} sx={{ p: 3, pr: 0 }}>
                      <Box
                        sx={{
                          backgroundColor: "rgb(244, 246, 248)",
                          borderRadius: "5px",
                          maxWidth: "50px",
                          width: "100%",
                          height: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <PersonPinIcon sx={{ color: "#637281" }} />
                      </Box>
                      <Box>
                        <Typography variant="subtitle2">Full Name</Typography>
                        <Typography variant="body2">{user.fullName}</Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item sm={6} md={6}>
                    <Stack direction="row" spacing={2} sx={{ p: 3, pr: 0 }}>
                      <Box
                        sx={{
                          backgroundColor: "rgb(244, 246, 248)",
                          borderRadius: "5px",
                          maxWidth: "50px",
                          width: "100%",
                          height: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <MarkEmailReadIcon sx={{ color: "#637281" }} />
                      </Box>
                      <Box>
                        <Typography variant="subtitle2">Email</Typography>
                        <Typography variant="body2">{user.email}</Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item sm={6} md={6}>
                    <Stack direction="row" spacing={2} sx={{ p: 3, pr: 0 }}>
                      <Box
                        sx={{
                          backgroundColor: "rgb(244, 246, 248)",
                          borderRadius: "5px",
                          maxWidth: "50px",
                          width: "100%",
                          height: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <PhoneInTalkIcon sx={{ color: "#637281" }} />
                      </Box>
                      <Box>
                        <Typography variant="subtitle2">Phone</Typography>
                        <Typography variant="body2">
                          {user.mobile ? user.mobile : "-"}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item sm={6} md={6}>
                    <Stack direction="row" spacing={2} sx={{ p: 3, pr: 0 }}>
                      <Box
                        sx={{
                          backgroundColor: "rgb(244, 246, 248)",
                          borderRadius: "5px",
                          maxWidth: "50px",
                          width: "100%",
                          height: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FlagIcon sx={{ color: "#637281" }} />
                      </Box>
                      <Box>
                        <Typography variant="subtitle2">Country</Typography>
                        <Typography variant="body2">USA</Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item sm={6} md={8}>
                    <Stack direction="row" spacing={2} sx={{ p: 3, pr: 0 }}>
                      <Box
                        sx={{
                          backgroundColor: "rgb(244, 246, 248)",
                          borderRadius: "5px",
                          maxWidth: "50px",
                          width: "100%",
                          height: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FmdGoodIcon sx={{ color: "#637281" }} />
                      </Box>
                      <Box>
                        <Typography variant="subtitle2">Address</Typography>
                        <Typography variant="body2">
                          {user.address ? user.address : "-"}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
              {/* <Address2Loader /> */}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}
