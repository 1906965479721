let yKey = 100;
let parameter = 200;

const modelData = {
  offset: {
    x: 0,
    y: 0,
  },
  nodes: {
    node1: {
      id: "node1",
      type: "read-only",
      position: {
        x: 300,
        y: 300,
      },
      ports: {
        port1: {
          id: "port1",
          type: "output",
        },
      },
      properties: {
        title: "EMB25",
        description: "Deal EMB25 is been created on 4 July 2024 at 12:35 pm",
      },
    },
    node2: {
      id: "node2",
      type: "read-only",
      position: {
        x: 300,
        y: (yKey = yKey + parameter),
      },
      ports: {
        port1: {
          id: "port1",
          type: "input",
        },
      },
      properties: {
        title: "User assignment",
        description:
          "Rohan Raj assigned the deal to Harsh on 4 July 2024 at 01:00 pm",
      },
    },
    node3: {
      id: "node3",
      type: "read-only",
      position: {
        x: 300,
        y: (yKey = yKey + parameter),
      },
      ports: {
        port1: {
          id: "port1",
          type: "input",
        },
      },
      properties: {
        title: "Upload JD",
        description: "Harsh has JD for this deal on on 5 July 2024 at 10:00 am",
        link: {
          name: "Click to view",
          link: "www.google.com",
        },
      },
    },
    node4: {
      id: "node4",
      type: "read-only",
      position: {
        x: 300,
        y: (yKey = yKey + parameter),
      },
      ports: {
        port1: {
          id: "port1",
          type: "input",
        },
      },
      properties: {
        title: "User assignment",
        description:
          "Harsh has assigned the deal to Rishi on 5 July 2024 at 1:00 pm",
      },
    },
    node5: {
      id: "node5",
      type: "read-only",
      position: {
        x: 300,
        y: (yKey = yKey + parameter),
      },
      ports: {
        port1: {
          id: "port1",
          type: "output",
        },
        port2: {
          id: "port2",
          type: "output",
        },
        port3: {
          id: "port3",
          type: "output",
        },
        port4: {
          id: "port4",
          type: "output",
        },
      },
      properties: {
        title: "Shorlist Vendors",
        description:
          "Rishi has shorlisted Alphonics, Ebslon, HSK and Vord Digital on 6 July at 2:00 pm",
      },
    },
    node6: {
      id: "node6",
      type: "read-only",
      position: {
        x: 100,
        y: (yKey = yKey + parameter),
      },
      ports: {
        port1: {
          id: "port1",
          type: "input",
        },
      },
      properties: {
        title: "Vendor Name",
        description: "Alphonics",
      },
    },
    node7: {
      id: "node7",
      type: "read-only",
      position: {
        x: 450,
        y: yKey,
      },
      ports: {
        port1: {
          id: "port1",
          type: "input",
        },
      },
      properties: {
        title: "Vendor Name",
        description: "Ebslon",
      },
    },
    node8: {
      id: "node8",
      type: "read-only",
      position: {
        x: 800,
        y: yKey,
      },
      ports: {
        port1: {
          id: "port1",
          type: "input",
        },
      },
      properties: {
        title: "Vendor Name",
        description: "HSK",
      },
    },
    node9: {
      id: "node9",
      type: "read-only",
      position: {
        x: 1150,
        y: yKey,
      },
      ports: {
        port1: {
          id: "port1",
          type: "input",
        },
      },
      properties: {
        title: "Vendor Name",
        description: "Vord Digital",
      },
    },
    node10: {
      id: "node10",
      type: "read-only",
      position: {
        x: 100,
        y: (yKey = yKey + parameter),
      },
      ports: {
        port1: {
          id: "port1",
          type: "input",
        },
      },
      properties: {
        title: "Not Intrested",
        description:
          "Aplhonics marked this deal as not interested on 7 July 2024 at 01:00pm.",
      },
    },
    node11: {
      id: "node11",
      type: "read-only",
      position: {
        x: 450,
        y: yKey,
      },
      ports: {
        port1: {
          id: "port1",
          type: "input",
        },
      },
      properties: {
        title: "Uploaded CV",
        description:
          "1 CV's have been uploaded by Ebslon on 8th July 2024 at 10:00 am",
        link: {
          name: "Click Here",
          link: "www.google.com",
        },
      },
    },
    node12: {
      id: "node12",
      type: "read-only",
      position: {
        x: 800,
        y: yKey,
      },
      ports: {
        port1: {
          id: "port1",
          type: "input",
        },
      },
      properties: {
        title: "Uploaded CV",
        description:
          "1 CV's have been uploaded by HKS on 8th July 2024 at 02:00pm",
        link: {
          name: "Click Here",
          link: "www.google.com",
        },
      },
    },
    node13: {
      id: "node13",
      type: "read-only",
      position: {
        x: 1150,
        y: yKey,
      },
      ports: {
        port1: {
          id: "port1",
          type: "input",
        },
      },
      properties: {
        title: "Pending",
      },
    },
    node14: {
      id: "node14",
      type: "read-only",
      position: {
        x: 450,
        y: (yKey = yKey + parameter),
      },
      ports: {
        port1: {
          id: "port1",
          type: "input",
        },
      },
      properties: {
        title: "Move To HR",
        description:
          "Harsh has Moved the CV to HR Round on 8 July 2024 at 10:00am",
      },
    },
    node15: {
      id: "node15",
      type: "read-only",
      position: {
        x: 800,
        y: yKey,
      },
      ports: {
        port1: {
          id: "port1",
          type: "input",
        },
      },
      properties: {
        title: "Rejected",
        description: "Harsh has rejected the CV on 10 July 2024 at 08:00pm",
      },
    },
    node16: {
      id: "node16",
      type: "read-only",
      position: {
        x: 450,
        y: (yKey = yKey + parameter),
      },
      ports: {
        port1: {
          id: "port1",
          type: "input",
        },
      },
      properties: {
        title: "Move to Client",
        description:
          "Harsh has moved this CV to Client on 12 July 2024 at 03:00pm",
      },
    },
    node17: {
      id: "node17",
      type: "read-only",
      position: {
        x: 450,
        y: (yKey = yKey + parameter),
      },
      ports: {
        port1: {
          id: "port1",
          type: "input",
        },
      },
      properties: {
        title: "Client Selected",
        description: "Client has selected the candidate",
      },
    },
  },
  links: {
    link1: {
      id: "link1",
      from: {
        nodeId: "node1",
        portId: "port1",
      },
      to: {
        nodeId: "node2",
        portId: "port1",
      },
    },
    link2: {
      id: "link2",
      from: {
        nodeId: "node2",
        portId: "port1",
      },
      to: {
        nodeId: "node3",
        portId: "port1",
      },
    },
    link3: {
      id: "link3",
      from: {
        nodeId: "node3",
        portId: "port1",
      },
      to: {
        nodeId: "node4",
        portId: "port1",
      },
    },
    link4: {
      id: "link4",
      from: {
        nodeId: "node4",
        portId: "port1",
      },
      to: {
        nodeId: "node5",
        portId: "port1",
      },
    },
    link5: {
      id: "link5",
      from: {
        nodeId: "node5",
        portId: "port1",
      },
      to: {
        nodeId: "node6",
        portId: "port1",
      },
    },
    link5: {
      id: "link5",
      from: {
        nodeId: "node5",
        portId: "port1",
      },
      to: {
        nodeId: "node6",
        portId: "port1",
      },
    },
    link6: {
      id: "link6",
      from: {
        nodeId: "node5",
        portId: "port2",
      },
      to: {
        nodeId: "node7",
        portId: "port1",
      },
    },
    link7: {
      id: "link7",
      from: {
        nodeId: "node5",
        portId: "port3",
      },
      to: {
        nodeId: "node8",
        portId: "port1",
      },
    },
    link8: {
      id: "link8",
      from: {
        nodeId: "node5",
        portId: "port4",
      },
      to: {
        nodeId: "node9",
        portId: "port1",
      },
    },
    link9: {
      id: "link9",
      from: {
        nodeId: "node6",
        portId: "port1",
      },
      to: {
        nodeId: "node10",
        portId: "port1",
      },
    },
    link10: {
      id: "link10",
      from: {
        nodeId: "node7",
        portId: "port1",
      },
      to: {
        nodeId: "node11",
        portId: "port1",
      },
    },
    link11: {
      id: "link11",
      from: {
        nodeId: "node8",
        portId: "port1",
      },
      to: {
        nodeId: "node12",
        portId: "port1",
      },
    },
    link12: {
      id: "link12",
      from: {
        nodeId: "node9",
        portId: "port1",
      },
      to: {
        nodeId: "node13",
        portId: "port1",
      },
    },
    link13: {
      id: "link13",
      from: {
        nodeId: "node11",
        portId: "port1",
      },
      to: {
        nodeId: "node14",
        portId: "port1",
      },
    },
    link14: {
      id: "link14",
      from: {
        nodeId: "node12",
        portId: "port1",
      },
      to: {
        nodeId: "node15",
        portId: "port1",
      },
    },
    link15: {
      id: "link15",
      from: {
        nodeId: "node14",
        portId: "port1",
      },
      to: {
        nodeId: "node16",
        portId: "port1",
      },
    },
    link16: {
      id: "link16",
      from: {
        nodeId: "node16",
        portId: "port1",
      },
      to: {
        nodeId: "node17",
        portId: "port1",
      },
    },
  },
  selected: {},
  hovered: {},
};

export default modelData;

const emb20_ete = [
  {
    id: "32jgjhg234",
    title: "Deal Created",
    description: "Deal Created on 9th july 2024",
  },
];
