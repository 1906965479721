import React, { useEffect } from "react";
import {
  Box,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import useForm from "../hooks/useForm";
import Element from "../components/Form/Element";
import { inputType } from "../utils/enum";
import { platformReviewOptions } from "../utils/options.util";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch } from "react-redux";
import { fetchPlatformReviewThunk } from "../store/slices/vendor/vendor.slice";

const PlatformReview = () => {
  // submit form input
  const dispatch=useDispatch();
  const onSubmit = (data) => {
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useForm({
      initialValues: {},
      onSubmit: onSubmit,
    });

    useEffect(()=>{
      dispatch(fetchPlatformReviewThunk());
    },[dispatch])

  return (
    <Container>
      <Box>
        <Grid container spacing={2} mt={5}>
          <Grid item md={12}>
            <Card sx={{ minHeight: "420px", py: 2, px: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                m={2}
              >
                <Typography variant="h4" gutterBottom>
                  Platform Review
                </Typography>
              </Stack>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} p={3} alignItems={"end"}>
                  <Grid item md={6} p={3}>
                    <Element
                      label="Platform"
                      inputProps={{
                        name: "founder_selectOption",
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      value={values.founder_selectOption}
                      options={platformReviewOptions}
                      errorText={
                        touched.founder_selectOption &&
                        errors.founder_selectOption
                      }
                      eletype={inputType.select}
                      icons={
                        <ArrowDropDownIcon
                          sx={{
                            position: "absolute",
                            right: 20,
                            bottom: 24,
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item md={6} p={3}>
                    <Element
                      label="Count"
                      inputProps={{
                        placeholder: "Number of Employees (8Yrs+ Exp)",
                        type: "text",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "NOE_8_plus",
                      }}
                      eletype={inputType.number}
                      value={values.NOE_8_plus}
                      errorText={touched.NOE_8_plus && errors.NOE_8_plus}
                    />
                  </Grid>
                  <Grid item md={6} pb={2}>
                    <Element
                      label="Website Link"
                      inputProps={{
                        type: "text",
                        placeholder: "Enter Website url",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "website_link",
                      }}
                      eletype={inputType.url}
                      value={values.website_link}
                      errorText={touched.website_link && errors.website_link}
                    />
                  </Grid>

                  <Grid item md={12} p={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ float: "right" }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PlatformReview;
