import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import {
  Typography,
  Container,
  Card,
  Box,
  Grid,
  Stack,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  IconButton,
  TableFooter,
  TablePagination,
} from "@mui/material";
import Scrollbar from "../../components/Common/Scrollbar";
import { Icon } from "@iconify/react";
import Modal from "../../components/Modal/Modal";
import DeleteAction from "../../components/Common/DeleteAction";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import {
  fetchAllCategoryWithPaginationThunk,
  updateTagThunk,
  deleteCategoryThunk,
} from "../../store/slices/category/category.slice";

import plusFill from "@iconify/icons-eva/plus-fill";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { sk } from "date-fns/locale";
import CategoryModal from "../../components/Modal/CategoryModal";

//declaring column heading
const columns = [
  {
    id: "category",
    label: "Catogory",
    align: "left",
    minWidth: 250,
  },
  { id: "created_at", label: "Added On", align: "left", minWidth: 250 },
  { id: "Action", label: "Action", align: "left", minWidth: 100 },
];

export default function Category() {
  const { categoryData, totalCategory } = useSelector(
    ({ category }) => category
  );

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [currentdata, setCurrentData] = useState("");

  // --new for pagination starts here---
  useEffect(() => {
    if (page != 0) {
      dispatch(fetchAllCategoryWithPaginationThunk(page));
      setPage(parseInt(page - 1));
    }
  }, [categoryData.length == 0]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);

  const handleChangePage = (_, newPage) => {
    dispatch(fetchAllCategoryWithPaginationThunk(newPage + 1));
    setPage(newPage);
  };

  // --new for pagination ends here---
  const handleOpen = () => {
    setCurrentData({
      name: "",
      _id: "",
      page: page == 0 ? 1 : parseInt(page + 1),
    });
    setShowModal(true);
  };
  const handleClose = () => {
    setCurrentData("");
    setShowModal(false);
  };

  useEffect(() => {
    dispatch(fetchAllCategoryWithPaginationThunk());
  }, []);

  function handleEdit(id) {
    var data = categoryData.find((d) => d._id === id);
    setCurrentData(data);
    setTimeout(() => {
      setShowModal(true);
    }, 0);
  }
  function handleDelete(id) {
    dispatch(deleteCategoryThunk({ id: id, page: page == 0 ? 1 : page + 1 }))
      .unwrap()
      .then((d) => {
        handleClose();
      });
  }

  return (
    <Container>
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
        >
          <Typography variant="h5" gutterBottom>
            Category
          </Typography>
          <Button
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={handleOpen}
            sx={{ float: "right", mb: "1rem" }}
          >
            Add
          </Button>
        </Stack>
        {categoryData.length > 0 ? (
          <Grid container spacing={2} mt={1}>
            <Grid item md={12}>
              <Card>
                <Scrollbar>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                top: 57,
                                minWidth: column.minWidth,
                                backgroundColor: "#f3f6f8",
                                paddingLeft: "40px",
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {categoryData?.map((data) => (
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ paddingLeft: "40px" }}
                            >
                              {data.name}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ paddingLeft: "40px" }}
                            >
                              {DateTime.fromISO(data.created_at).toLocaleString(
                                DateTime.DATE_MED
                              )}
                            </TableCell>
                            <TableCell style={{ paddingLeft: "20px" }}>
                              <IconButton color="success" component="span">
                                <EditIcon
                                  color="success"
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => handleEdit(data._id)}
                                />
                              </IconButton>
                              <DeleteAction
                                handleDelete={() => handleDelete(data._id)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            count={totalCategory}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={false}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <SearchNotFound
            src="/static/support.svg"
            subtitle="There is currently no languages to show."
          />
        )}
      </Box>

      <Modal
        maxWidth="md"
        isOpen={showModal}
        closeModal={handleClose}
        title="Add Category"
        content={<CategoryModal closeModal={handleClose} props={currentdata} />}
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
    </Container>
  );
}
