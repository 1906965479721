import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDashboardMatrixHandler } from "./dashboard.thunk";

const INIT_STATE = {
  main: {},
  isLoading: false,
  matrix: {}
};


export const fetchDashboardMatrixThunk = createAsyncThunk(
  "fetchDashboardMatrix",
  fetchDashboardMatrixHandler
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: INIT_STATE,
  reducers: {
    startDashboardLoader: (state) => {
      state.isLoading = true;
    },
    stopDashboardLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardMatrixThunk.fulfilled, (state, action) => {
      state.matrix = action.payload;
      return state;
    })
  }
});

export const { startDashboardLoader, stopDashboardLoader } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
