import React, { useMemo } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  Button,
  Divider,
} from "@mui/material";
import { Icon } from "@iconify/react";
import "simplebar/dist/simplebar.min.css";
import { useSelector } from "react-redux";
import Text from "./Text";
import Dropdown from "./Dropdown";
import IndustrySearch from "./IndustrySearch";
import {
  newSearchDeliveryclassHandler,
  newSearchIndustryHandler,
  newSearchServiceHandler,
  newSearchSubIndustryHandler,
  searchIndustryHandler,
  searchServiceHandler,
  serviceFormatter,
} from "../../utils/global/vendor.global";
import ServiceSearch from "./ServiceSearch";

function FilterShortlist({ handleFilterClose, filterState, selectedService }) {
  const { industrydata } = useSelector(({ industry }) => industry);
  const { techStackByService } = useSelector(({ shortlist }) => shortlist);
  const { tagsByService } = useSelector(({ shortlist }) => shortlist);

  const options_mapper = useMemo(() => {
    return {
      industry: industrydata.map((ele) => ({
        label: ele.title,
        value: ele.title,
      })),
      tech_stack: techStackByService.map((ele) => ({
        label: ele.name,
        value: ele.name,
      })),
      tag: tagsByService.map((ele) => ({
        label: ele.tag,
        value: ele.tag,
      })),
    };
  }, [techStackByService, tagsByService, industrydata]);

  const { state, applyFilter, resetFilter, ...rest } = filterState;

  return (
    <Container>
      <Box>
        <Grid container>
          <Grid item md={12}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              justifyContent="space-between"
            >
              <Typography
                variant="h5"
                component="div"
                pt={2}
                mb={1}
                pl={"40px"}
              >
                Filter By
              </Typography>
              <Icon
                icon="eva:close-outline"
                style={{ fontSize: "27px", cursor: "pointer" }}
                onClick={handleFilterClose}
              />
            </Stack>
            <Divider />
          </Grid>
          <Grid item md={12} pt={2} pl={3}>
            <ServiceSearch
              name="service"
              label="Select Service"
              placeholder="Select Service"
              formatter={serviceFormatter}
              value={state.service?.values}
              handler={newSearchServiceHandler}
              selectedService={selectedService}
              {...rest}
            />
          </Grid>
          <Grid item md={10} pl={3}>
            <IndustrySearch
              name="industry"
              label="Select Industry"
              placeholder="Select Industry"
              formatter={serviceFormatter}
              value={state.industry?.values}
              handler={newSearchIndustryHandler}
              {...rest}
            />
          </Grid>
          <Grid item md={10} pl={3}>
            <IndustrySearch
              name="industry"
              label="Select Sub Industry"
              placeholder="Select Industry"
              formatter={serviceFormatter}
              value={state.sub_industry?.values}
              handler={newSearchSubIndustryHandler}
              {...rest}
            />
          </Grid>
          <Grid item md={10} pl={3}>
            <IndustrySearch
              name="industry"
              label="Select Delivery Class"
              placeholder="Select Industry"
              formatter={serviceFormatter}
              value={state.delivery_class?.values}
              handler={newSearchDeliveryclassHandler}
              {...rest}
            />
          </Grid>
          <Grid item md={12} pl={3}>
            <Dropdown
              width="500px"
              state={state.tech_stack}
              options={options_mapper.tech_stack}
              label="Tech Stack"
              value="tech_stack"
              {...rest}
            />
          </Grid>
          <Grid item md={12} pl={3}>
            <Dropdown
              width="500px"
              state={state.tag}
              options={options_mapper.tag}
              label="Tags"
              value="tag"
              {...rest}
            />
          </Grid>
          {/* <Grid item md={10} pl={3}>
            <IndustrySearch
              name="industry"
              label="Select Industry"
              placeholder="Select Industry"
              formatter={serviceFormatter}
              value={state.industry?.values}
              handler={searchIndustryHandler}
              {...rest}
            />
          </Grid> */}
          <Grid item md={12} pl={3}>
            <Text
              state={state.city}
              label="Type City"
              value="city"
              {...rest}
              height="56px"
              width="500px"
              placeholder=" "
            />
          </Grid>
          <Grid item md={12} pl={3}>
            <Text
              state={state.state}
              label="Type State"
              value="state"
              {...rest}
              height="56px"
              width="500px"
              placeholder=" "
            />
          </Grid>
          <Grid item md={12}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ textAlign: "center", width: "100%" }} mt={3} mb={3}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{ padding: "10px 30px", marginRight: "10px" }}
                  onClick={() => {
                    applyFilter();
                    handleFilterClose();
                  }}
                >
                  Apply Filter
                </Button>

                <Button
                  color="inherit"
                  size="large"
                  sx={{ padding: "10px 20px", border: "2px solid black" }}
                  onClick={resetFilter}
                >
                  Reset Filter
                </Button>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default FilterShortlist;
