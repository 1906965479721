import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useRoutes } from "react-router-dom";
import {
  removeAccessToken,
  storeAccessToken,
} from "../../../utils/global/auth.global";
import { signInWithEmail, forgotPassword } from "./auth.thunk";

const INIT_STATE = {
  isLoggedIn: false,
  isLoading: false,
};

export const loginByEmailAsyncThunk = createAsyncThunk(
  "emailLogin",
  signInWithEmail
);
export const forgotPasswordAsycnThunk = createAsyncThunk(
  "forgotPassword",
  forgotPassword
);

const initSlice = createSlice({
  name: "auth",
  initialState: INIT_STATE,
  reducers: {
    logout: (state) => {
      removeAccessToken();
      state.isLoggedIn = false;
      return state;
    },
    setUserAsLogin: (state) => {
      state.isLoggedIn = true;
      return state;
    },
    startLoader: (state) => {
      state.isLoading = true;
      return state;
    },
    stopLoader: (state) => {
      state.isLoading = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginByEmailAsyncThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(loginByEmailAsyncThunk.fulfilled, (state, action) => {
        const token = action.payload?.access_token;
        storeAccessToken(token);
        state.isLoading = false;
        state.isLoggedIn = true;
        return state;
      })
      .addCase(loginByEmailAsyncThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(forgotPasswordAsycnThunk.fulfilled, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(forgotPasswordAsycnThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(forgotPasswordAsycnThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      });
  },
});

export const { logout, setUserAsLogin, startLoader, stopLoader } =
  initSlice.actions;

export default initSlice.reducer;
