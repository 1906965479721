import {
  getAllServices,
  deleteServices,
  addServices,
} from "../../../utils/apis.utils";
import {
  startServiceLoader,
  stopServiceLoader,
  fetchAllSericesThunk,
} from "../services/service.slice";

import { showFailureToast, showSuccessToast } from "../toast/toast.slice";

export const fetchAllServicesHandler = async (__, thunkApi) => {
  thunkApi.dispatch(startServiceLoader());
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(getAllServices);
  thunkApi.dispatch(stopServiceLoader());
  return response.data;
};

export const deleteServicesHandler = async (id, thunkApi) => {
  try {
    thunkApi.dispatch(startServiceLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.delete(deleteServices + "/" + id);
    thunkApi.dispatch(stopServiceLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllSericesThunk());
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopServiceLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopServiceLoader());
  }
};

export const addServicesHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startServiceLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(addServices, data);
    thunkApi.dispatch(stopServiceLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllSericesThunk());
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopServiceLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopServiceLoader());
  }
};

export const updateServicesHandler = async (data, thunkApi) => {
  var id = data.id;
  var apiData = {
    data: data.data,
  };

  try {
    thunkApi.dispatch(startServiceLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(addServices + "/" + id, apiData);
    thunkApi.dispatch(stopServiceLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllSericesThunk());
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopServiceLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopServiceLoader());
  }
};
