import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllSupportTicketsHandler,
  createGlobalSupportTicketCommentHandler,
  fetchAllGlobalTicketsDataByTicketIdHanldler,
  getAllSupportCommentHanldler,
  updateTicketStatusHandler,
} from "./support.thunk";

const INIT_STATE = {
  tickets: [],
  selectedTicket: null,
  comments: {
    comments_data: [],
  },
};

export const fetchAllSupportTicketsThunk = createAsyncThunk(
  "fetchAllSupportTickets",
  fetchAllSupportTicketsHandler
);

export const getAllSupportCommentThunk = createAsyncThunk(
  "getAllSupportComment",
  getAllSupportCommentHanldler
);

export const createGlobalSupportTicketCommentThunk = createAsyncThunk(
  "createSupportTicketComment",
  createGlobalSupportTicketCommentHandler
);

export const fetchAllGlobalTicketsDataByTicketIdThunk = createAsyncThunk(
  "fetchAllGlobalTicketsDataByTicketId",
  fetchAllGlobalTicketsDataByTicketIdHanldler
);

export const updateTicketStatusThunkNew = createAsyncThunk(
  "updateTicketStatus",
  updateTicketStatusHandler
);

const supportSlice = createSlice({
  name: "support",
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSupportTicketsThunk.fulfilled, (state, action) => {
        state.tickets = action.payload;
      })
      .addCase(getAllSupportCommentThunk.fulfilled, (state, action) => {
        state.comments = action.payload;
        return state;
      })

      .addCase(
        createGlobalSupportTicketCommentThunk.fulfilled,
        (state, action) => {
          state.comments.comments_data.push(action.payload);
          return state;
        }
      );
  },
});

export default supportSlice.reducer;
