import { Button, Card, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import Modal from "../../components/Modal/Modal";
import { ORG_ID } from "../../utils/constant";
import { fetchAllServicesOfVendor } from "../../utils/global/shortlisting.global";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ShortListServiceModel from "../../components/Modal/ShortListServiceModel";
import ShortListPortfolioModal from "../../components/Modal/ShortListPortfolioModal";
import SelectReasonModel from "../../components/Modal/SelectReasonModel";

function VendorProfileCard({ data, actionFn, selected }) {
  const [showModalService, setShowModalService] = useState(false);
  const [showModalPortfolio, setShowModalPortfolio] = useState(false);
  const [showModalReason, setShowModalReason] = useState(false);
  const [servicesData, setServicesData] = useState(false);
  const [portfolioIDs, setPortfolioIDs] = useState(false);
  const [reasonData, setReasonData] = useState(false);

  function handleOpenService(vendorOrgId) {
    localStorage.setItem(ORG_ID, vendorOrgId);
    fetchAllServicesOfVendor().then((services) => {
      setServicesData(services);
      setShowModalService(true);
    });
  }
  function handleOpenPortfolio(data) {
    localStorage.setItem(ORG_ID, data.org_id);
    setPortfolioIDs(data);
    setShowModalPortfolio(true);
  }

  function handleCloseService() {
    setServicesData([]);
    setShowModalService(false);
    localStorage.removeItem(ORG_ID);
  }

  function handleClosePortfolio() {
    setShowModalPortfolio(false);
  }

  function handleCloseReason() {
    setShowModalReason(false);
  }

  function handleOpenReason(data) {
    setReasonData(data);
    setShowModalReason(true);
  }

  function dataFromChild(data) {
    setServicesData([]);
    actionFn(data);
  }

  return (
    <>
      <Card
        p={2}
        sx={{
          background: selected ? "#005DC6" : "#fff",
          color: selected ? "#fff" : "#222",
          marginBottom: "15px",
        }}
      >
        <Box sx={{ paddingRight: "2rem", paddingLeft: "2rem" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="center"
            spacing={2}
            sx={{ height: "100px" }}
          >
            <Stack
              direction="column"
              justifyContent="flex-start"
              spacing={1}
              mb={2}
            >
              <Typography variant="h5" pt={2}>
                {data?.brand_name}
              </Typography>
              <Typography
                variant="body"
                sx={{
                  color: selected ? "#fff" : "#343434",
                  opacity: 0.75,
                  fontSize: "0.9rem",
                }}
              >
                {data?.registered_business_name}
              </Typography>
            </Stack>

            <Stack
              direction="column"
              justifyContent="flex-end"
              spacing={2}
              sx={{ height: "100%", paddingBottom: "1.25rem" }}
            >
              <Typography variant="body">
                <a
                  href={"/dashboard/profiles/" + data?.id + "/basic"}
                  target="_blank"
                  style={{
                    color: selected ? "#fff" : "#4389CE",
                    fontSize: "0.9rem",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                  rel="noreferrer"
                >
                  View Profile
                </a>
              </Typography>
              <Typography variant="body" sx={{ marginTop: "5px !important" }}>
                <a
                  href={data?.website}
                  target="_blank"
                  style={{
                    color: selected ? "#fff" : "black",
                    fontSize: "0.9rem",
                  }}
                  rel="noreferrer"
                >
                  {data?.website}
                </a>
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="center"
            spacing={2}
            mb={2}
            sx={{
              height: "40px",
              background: selected ? "#004DA3" : "#F8F8F8",
            }}
          >
            <div className="poc">
              <ul className="poc_list">
                {data?.poc?.map((d, indx) => (
                  <li>
                    <Typography variant="subtitle2">
                      POC {indx + 1} :{d.first_name + " " + d.last_name}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          </Stack>
        </Box>
        <Box sx={{ paddingRight: "2rem", paddingLeft: "2rem" }}>
          <Grid container>
            <Grid item md={6}>
              <Typography
                variant="subtitle2"
                sx={{
                  marginBottom: "1rem",
                  display: "flex",
                  gap: "10px",
                }}
              >
                Total projects :{" "}
                <span style={{ fontWeight: "500" }}>
                  {data?.total_projects}
                </span>
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="subtitle2"
                sx={{
                  marginBottom: "1rem",
                  textAlign: "left",
                  display: "flex",
                  gap: "10px",
                }}
              >
                Active projects :{" "}
                <span style={{ fontWeight: "500" }}>
                  {data?.active_projects}
                </span>
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="subtitle2"
                sx={{
                  marginBottom: "1rem",
                  display: "flex",
                  gap: "10px",
                }}
              >
                Portfolio :{" "}
                <span style={{ fontWeight: "500" }}>
                  {data?.portfolios_count}
                </span>
                {data?.portfolio_ids.length > 0 && (
                  <span onClick={() => handleOpenPortfolio(data)}>
                    <a
                      className="view_btn"
                      style={{
                        color: selected ? "#ddd" : "#4389ce",
                      }}
                    >
                      <RemoveRedEyeIcon
                        sx={{ mr: "0.5rem", fontSize: "1rem" }}
                      />{" "}
                      view
                    </a>
                  </span>
                )}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="subtitle2"
                sx={{
                  marginBottom: "1rem",
                  textAlign: "left",
                  display: "flex",
                  gap: "10px",
                }}
              >
                Services Provided :
                <span style={{ fontWeight: "500" }}>
                  {data?.vendor_services_count}
                </span>
                {data?.vendor_services_count != 0 && (
                  <span onClick={() => handleOpenService(data?.org_id)}>
                    <a
                      className="view_btn"
                      style={{
                        color: selected ? "#ddd" : "#4389ce",
                      }}
                    >
                      <RemoveRedEyeIcon
                        sx={{ mr: "0.5rem", fontSize: "1rem" }}
                      />{" "}
                      view
                    </a>
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            paddingRight: "2rem",
            paddingLeft: "2rem",
            paddingBottom: "2rem",
          }}
        >
          <Grid container>
            <Grid item md={6}></Grid>
            <Grid item md={6}>
              <Button
                type="submit"
                variant="contained"
                onClick={
                  selected ? () => actionFn(data) : () => handleOpenReason(data)
                }
                sx={{
                  float: "right",
                  paddingRight: "25px",
                  paddingLeft: "25px",
                  borderRadius: "20px",
                  backgroundColor: selected ? "#74A3D7" : "#12A54B",
                }}
              >
                {selected ? "Unselect" : "Select"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Modal
        maxWidth="md"
        isOpen={showModalService}
        closeModal={handleCloseService}
        title=""
        content={
          <ShortListServiceModel
            closeModal={handleCloseService}
            data={servicesData}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{
          justifyContent: "center",
          marginBottom: 2,
        }}
        contentStyle={{ padding: "0" }}
      />

      <Modal
        maxWidth="lg"
        isOpen={showModalPortfolio}
        closeModal={handleClosePortfolio}
        title=""
        content={
          <ShortListPortfolioModal
            closeModal={handleClosePortfolio}
            data={portfolioIDs}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{
          justifyContent: "center",
          marginBottom: 2,
        }}
        contentStyle={{ padding: "0" }}
      />

      <Modal
        maxWidth="300px"
        isOpen={showModalReason}
        closeModal={handleCloseReason}
        title=""
        content={
          <SelectReasonModel
            closeModal={handleCloseReason}
            data={reasonData}
            dataFromChild={dataFromChild}
          />
        }
        dialogProps={{ fullWidth: false }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{
          justifyContent: "center",
          marginBottom: 2,
        }}
        contentStyle={{ padding: "0" }}
      />
    </>
  );
}

export default VendorProfileCard;
