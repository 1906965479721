import { userApi } from "../../../utils/apis.utils";
import {
  startEditUserLoader,
  stopEditUserLoader,
  fetchAllUsersThunk,
} from "../edituser/edituser.slice";

import { showFailureToast, showSuccessToast } from "../toast/toast.slice";

export const fetchAllUsersHandler = async (__, thunkApi) => {
  thunkApi.dispatch(startEditUserLoader());
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(userApi);
  thunkApi.dispatch(stopEditUserLoader());
  return response.data;
};

export const deleteUsersHandler = async (id, thunkApi) => {
  try {
    thunkApi.dispatch(startEditUserLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.delete(userApi + "/" + id);
    thunkApi.dispatch(stopEditUserLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllUsersThunk());
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopEditUserLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopEditUserLoader());
  }
};

export const addUserHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startEditUserLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(userApi, data);
    thunkApi.dispatch(stopEditUserLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllUsersThunk());
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopEditUserLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopEditUserLoader());
  }
};

export const updateUserHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startEditUserLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(userApi, data);
    thunkApi.dispatch(stopEditUserLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopEditUserLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopEditUserLoader());
  }
};
