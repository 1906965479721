// material
import {
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Box,
  Stack,
} from "@mui/material";
// components
import Page from "../components/Page.js";
import { Outlet } from "react-router-dom";
import { AppWeeklySales } from "../components/_dashboard/app";
import SimpleSlider from "../components/_dashboard/app/SlickSlider";
import { green } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardMatrixThunk } from "../store/slices/dashboard/dashboard.slice";
import Clocks from "../components/_dashboard/app/Clock.js";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import usePermissions from "../hooks/usePermission.js";
import { actions } from "../utils/global/permissions.global.js";
import systemPermissions from "../utils/global/permissions.global.js";

export default function DashboardApp() {
  const dispatch = useDispatch();
  const [value, setValue] = useState(new Date());

  const userPerm = usePermissions();

  // useEffect(() => {
  //   const deg = 6; //360deg / 60(min||sec) => 6
  //   const hr = document.querySelector("#hr");
  //   const mn = document.querySelector("#mn");
  //   const sc = document.querySelector("#sc");

  //   setInterval(() => {
  //     let day = new Date();
  //     let hh = day.getHours() * 30; //360deg / 12 hour => 30
  //     let mm = day.getMinutes() * deg;
  //     let ss = day.getSeconds() * deg;

  //     hr.style.transform = `rotateZ(${hh + mm / 12}deg)`;
  //     mn.style.transform = `rotateZ(${mm}deg)`;
  //     sc.style.transform = `rotateZ(${ss}deg)`;
  //   });
  // });

  useEffect(() => {
    dispatch(fetchDashboardMatrixThunk());
  }, [dispatch]);

  const { matrix } = useSelector(({ dashboard }) => dashboard);

  return (
    <>
      <Outlet />
      <Page title="OPS Dashboard | EMB">
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={8}>
              <AppWeeklySales />
              {/* <AppWeeklyLoader /> */}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {/* <SimpleSlider /> */}
              {/* <Card sx={{ height: "250px", background: "#4A4B54" }}>
                <div class="clock">
                  <div class="hour">
                    <div class="hr" id="hr"></div>
                  </div>
                  <div class="min">
                    <div class="mn" id="mn"></div>
                  </div>
                  <div class="sec">
                    <div class="sc" id="sc"></div>
                  </div>
                </div>
              </Card> */}
              <Clocks />
            </Grid>

            {userPerm.hasAccess(
              systemPermissions["dashboard.analytics"],
              actions.read
            ) && (
              <>
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      border: "2px solid #E4E8EB",
                      borderRadius: "26px",
                      boxShadow: "none",
                    }}
                  >
                    <Grid container>
                      <Grid md={3}>
                        <img
                          src="/static/icons/verified.png"
                          width="60"
                          height="64"
                          style={{
                            margin: "20px 0 20px 20px",
                          }}
                          alt=""
                        />
                      </Grid>
                      <Grid item md={9}>
                        <Stack
                          direction="row"
                          justifyContent="space-evenly"
                          spacing={2}
                        >
                          <Box>
                            <Box>
                              <CardHeader
                                title="Verified Vendors"
                                pt={2}
                                sx={{ marginLeft: "-20px" }}
                              />
                            </Box>
                            <Typography
                              variant="h5"
                              sx={{
                                pl: 3,
                                color: "#02AB54",
                                marginLeft: "-20px",
                              }}
                            >
                              {matrix.verified_vendors}
                            </Typography>
                          </Box>
                          <Box sx={{ paddingTop: "40px" }}>
                            <KeyboardArrowRightIcon
                              sx={{ fontSize: "2rem!important" }}
                            />
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      border: "2px solid #E4E8EB",
                      borderRadius: "26px",
                      boxShadow: "none",
                    }}
                  >
                    <Grid container>
                      <Grid md={3}>
                        <Box>
                          <img
                            src="/static/icons/approved.png"
                            width="60"
                            height="64"
                            style={{
                              margin: "20px 0 20px 20px",
                            }}
                            alt=""
                          />
                        </Box>
                      </Grid>
                      <Grid item md={9}>
                        <Stack
                          direction="row"
                          justifyContent="space-evenly"
                          spacing={2}
                        >
                          <Box>
                            <Box sx={{ marginLeft: "-20px" }}>
                              <CardHeader title="Approved Vendors" pt={2} />
                            </Box>
                            <Typography
                              variant="h5"
                              sx={{
                                pl: 3,
                                color: "#02AB54",
                                marginLeft: "-20px",
                              }}
                            >
                              {matrix.approved_vendors}
                            </Typography>
                          </Box>
                          <Box sx={{ paddingTop: "40px" }}>
                            <KeyboardArrowRightIcon
                              sx={{ fontSize: "2rem!important" }}
                            />
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Card>
                  {/* <TotalProjectLoader /> */}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      border: "2px solid #E4E8EB",
                      borderRadius: "26px",
                      boxShadow: "none",
                    }}
                  >
                    <Grid container>
                      <Grid md={3}>
                        <img
                          src="/static/icons/pending.png"
                          width="60"
                          height="64"
                          style={{
                            margin: "20px 0 20px 20px",
                          }}
                          alt=""
                        />
                      </Grid>
                      <Grid item md={9}>
                        <Stack
                          direction="row"
                          justifyContent="space-evenly"
                          spacing={2}
                        >
                          <Box>
                            <Box>
                              <CardHeader
                                title="Pending Vendors"
                                pt={2}
                                sx={{ marginLeft: "-20px" }}
                              />
                            </Box>
                            <Typography
                              variant="h5"
                              sx={{
                                pl: 3,
                                color: "#02AB54",
                                marginLeft: "-20px",
                              }}
                            >
                              {matrix.pending_vendors}
                            </Typography>
                          </Box>
                          <Box sx={{ paddingTop: "40px" }}>
                            <KeyboardArrowRightIcon
                              sx={{ fontSize: "2rem!important" }}
                            />
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      border: "2px solid #E4E8EB",
                      borderRadius: "26px",
                      boxShadow: "none",
                    }}
                  >
                    <Grid container>
                      <Grid md={3}>
                        <Box>
                          <img
                            src="/static/icons/rejected.png"
                            width="60"
                            height="64"
                            style={{
                              margin: "20px 0 20px 20px",
                            }}
                            alt=""
                          />
                        </Box>
                      </Grid>
                      <Grid item md={9}>
                        <Stack
                          direction="row"
                          justifyContent="space-evenly"
                          spacing={2}
                        >
                          <Box>
                            <Box sx={{ marginLeft: "-20px" }}>
                              <CardHeader title="Rejected Vendors" pt={2} />
                            </Box>
                            <Typography
                              variant="h5"
                              sx={{
                                pl: 3,
                                color: "#02AB54",
                                marginLeft: "-20px",
                              }}
                            >
                              {matrix.unverified_vendors}
                            </Typography>
                          </Box>
                          <Box sx={{ paddingTop: "40px" }}>
                            <KeyboardArrowRightIcon
                              sx={{ fontSize: "2rem!important" }}
                            />
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Card>
                  {/* <TotalProjectLoader /> */}
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </Page>
    </>
  );
}
