const timeLocalStorage = {
  setItemWithExpiry(key, value, expirySeconds) {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + expirySeconds * 1000, // Calculate expiry timestamp in milliseconds
    };
    localStorage.setItem(key, JSON.stringify(item));
  },

  getItemWithExpiry(key) {
    const itemString = localStorage.getItem(key);
    if (!itemString) {
      return null;
    }
    try {
      const item = JSON.parse(itemString);
      const now = new Date().getTime();
      if (now >= item.expiry) {
        localStorage.removeItem(key); // Remove expired item
        return null;
      }
      return item.value;
    } catch (error) {
      console.error("Error parsing localStorage item:", error);
      return null;
    }
  },

  removeItem(key) {
    localStorage.removeItem(key);
  },
};

export default timeLocalStorage;
