import { FormControl, FormGroup, Input, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { advanceFilterFieldType, inputType } from "../../utils/enum";
import Element from "../Form/Element";
import StyledTreeItem from "./StylesTreeItem";

function FilterDate({ label, value, state, handleFilterValueChange, ...rest }) {
  const [isBetween, setIsBetween] = useState(state.search_type === "between");

  function handleChoiceChange(e) {
    const eleValue = e.target.value;
    if (eleValue === "between") {
      setIsBetween(true);
    } else setIsBetween(false);

    handleFilterValueChange(advanceFilterFieldType.date, value, eleValue);
  }

  function handleDateChange(e) {
    const name = e.target.name;
    if (name === "value") {
      return handleFilterValueChange(
        advanceFilterFieldType.date,
        value,
        null,
        e.target.value
      );
    }

    handleFilterValueChange(
      advanceFilterFieldType.date,
      value,
      null,
      null,
      e.target.value
    );
  }

  return (
    <StyledTreeItem nodeId={value} labelText={label}>
      <FormGroup>
        <FormControl sx={{ mt: 2, ml: 2, maxWidth: 150 }}>
          <Select
            sx={{ height: "40px" }}
            placeholder="Select Choice"
            onChange={handleChoiceChange}
            value={state.search_type}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="gt">greater</MenuItem>
            <MenuItem value="gte">greater or equal</MenuItem>
            <MenuItem value="lt">less</MenuItem>
            <MenuItem value="lte">less or equal</MenuItem>
            <MenuItem value="between">between</MenuItem>
          </Select>
        </FormControl>
      </FormGroup>
      <FormControl sx={{ m: 2, width: 300 }}>
        <Element
          inputProps={{
            name: "value",
            onChange: handleDateChange,
            placeholder: "Select Year",
          }}
          value={state.values && state.values[0]}
          eletype={inputType.date}
          showYearPicker
          dateFormat="yyyy"
          yearItemNumber={9}
          label="Year of Establishment"
          autoComplete="off"
        />
      </FormControl>
      {isBetween && (
        <FormControl sx={{ m: 2, width: 300 }}>
          <Element
            inputProps={{
              name: "max",
              onChange: handleDateChange,
              placeholder: "Select Year",
            }}
            value={state.values && state.values[1]}
            eletype={inputType.date}
            showYearPicker
            dateFormat="yyyy"
            yearItemNumber={9}
            label="To"
            autoComplete="off"
          />
        </FormControl>
      )}
    </StyledTreeItem>
  );
}

export default FilterDate;
