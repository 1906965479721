import axios from "axios";
import {
  assignUserSALogAPI,
  changeStatusOfLead,
  fetchAllCvsByPartner,
  fetchAllRA,
  fetchAllSALeadsApi,
  fetchAllSAUsersApis,
  fetchAllTechStackList,
  fetchRAById,
  fetchRAByLineItems,
  postAssignUser,
  postShortlistApi,
  putEmbJdUpload,
  putShortlistApi,
  raEnableVendors,
  searchClientRequirment,
  uploadCVByEMB,
} from "../../../utils/apis.utils";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import { assignUserTemplate } from "../../../utils/template.util";

export const fetchAllRALeadsHandler = async (__, thunkApi) => {
  const { sa } = thunkApi.extra.apiService;
  const response = await sa.get(fetchAllSALeadsApi);
  return response.data;
};

export const fetchAllRALeadsPaginationHandler = async (
  { search, page = 1 },
  thunkApi
) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(
    fetchAllRA + "?page=" + page + "&query=" + search
  );
  return response;
};

export const fetchRAAssignUserHandler = async (_, thunkApi) => {
  const { sa } = thunkApi.extra.apiService;
  const response = await sa.get(fetchAllSAUsersApis);
  return response.data;
};

export const postRAAssignUserHandler = async (data, thunkApi) => {
  const { name, email, lead_line_item_id, assigned_user_id, lead_internal_id } =
    data.data;
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.post(postAssignUser, {
    data: {
      lead_line_item_id,
      assigned_user_id,
    },
  });
  // const assignUserData = assignUserTemplate(
  //   lead_internal_id,
  //   new Date().toLocaleString(),
  //   assigned_user_id,
  //   name,
  //   email
  // );

  // await logs.post(assignUserSALogAPI, {
  //   leadId: lead_line_item_id,
  //   meta: {
  //     ...assignUserData,
  //   },
  // });

  thunkApi.dispatch(
    showSuccessToast({
      message: response.message,
      vertical: "top",
      horizontal: "right",
    })
  );
  return response.data;
};

export const fetchAllRaEnableVendorHandler = async (
  { search = "", page = 1 },
  thunkApi
) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(
    raEnableVendors + "?page=" + page + "&query=" + search
  );
  // thunkApi.dispatch(
  //   showSuccessToast({
  //     message: response.message,
  //     vertical: "top",
  //     horizontal: "right",
  //   })
  // );
  return response;
};

export const searchClientRequirmentHandler = async (data, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(
    `${searchClientRequirment}?query=${data?.search}`
  );
  thunkApi.dispatch(
    showSuccessToast({
      message: response.message,
      vertical: "top",
      horizontal: "right",
    })
  );
  return response;
};

export const fetchRaLeadByIdHandler = async (id, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(fetchRAById + "/" + id);
  return response.data;
};

export const fetchRaLeadLineItemsHandler = async (id, thunkApi) => {
  const { sa } = thunkApi.extra.apiService;
  const response = await sa.get(fetchRAByLineItems + "/" + id);
  return response.data;
};

export const fetchAllCvsByPartnerHandler = async (id, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(fetchAllCvsByPartner + "/" + id);
  return response.data;
};

export const changeStatusOfLeadHandler = async (
  { lead_id, applicant_id, status, remarks },
  thunkApi
) => {
  try {
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.put(
      changeStatusOfLead + "/" + lead_id + "/" + applicant_id,
      {
        emb_applicant_status: status,
        reason: remarks,
      }
    );
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
  }
};

export const uploadCVByEMBHandler = async (
  { id, formData, leadId },
  thunkApi
) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.put(
    uploadCVByEMB + "/" + leadId + "/" + id,
    formData
  );
  thunkApi.dispatch(
    showSuccessToast({
      message: response.message,
      vertical: "top",
      horizontal: "right",
    })
  );
  return response.data;
};

export const fetchAllTechStackListHandler = async ({ search }, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(fetchAllTechStackList + "?query=" + search);
  return response.data;
};

export const postShorlistedVendorsHandler = async ({ data, id }, thunkApi) => {
  try {
    const { vendor } = thunkApi.extra.apiService;

    const response = await vendor.put(putShortlistApi + "/" + id, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
  }
};

export const putEmbJdUploadThunkHandler = async ({ data, id }, thunkApi) => {
  try {
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.put(putEmbJdUpload + "/" + id, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
  }
};
