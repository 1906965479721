import React, { useState } from "react";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Stack,
  Card,
  Typography,
  Table,
  Button,
  ButtonGroup,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  IconButton,
  Paper,
  TableHead,
} from "@mui/material";

//Component
import Page from "../../../components/Page";
import useModal from "../../../hooks/useModal";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import { projectApprovalStatus } from "../../../utils/enum";
/* import AlertModal from '../components/modals' */

//Declared dummy data
const rows = [
  {
    vendor_name: "Sandy",
    project_amount: 50000,
    project_duration: 26,
    propsal_recieved: "17-12-2021",
    proposal_download: "",
    action: "",
    id: 1,
    status: projectApprovalStatus.pending,
  },
  {
    vendor_name: "Sourabh Chaprana",
    project_amount: 150000,
    project_duration: 21,
    propsal_recieved: "12-12-2021",
    proposal_download: "",
    action: "",
    id: 2,
    status: projectApprovalStatus.pending,
  },
  {
    vendor_name: "Keshav",
    project_amount: 60000,
    project_duration: 21,
    propsal_recieved: "09-12-2021",
    proposal_download: "",
    action: "",
    id: 3,
    status: projectApprovalStatus.pending,
  },
  {
    vendor_name: "Mayank Aggarwal",
    project_amount: 21000,
    project_duration: 30,
    propsal_recieved: "21-12-2021",
    proposal_download: "",
    action: "",
    id: 4,
    status: projectApprovalStatus.pending,
  },
];

//Currency Convertor
const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  }).format(value);

//Accept & Reject Button
/* const [accept,setAccept] = useState(true); */

export default function Proposal() {
  const [proposals, setProposals] = useState(rows);
  const { isModalOpen, showModal, hideModal, args } = useModal();
  const [isAlreadyAccepted, setIsAlreadyAccepted] = useState(() => {
    let flag = false;
    proposals.forEach((proposal) => {
      if (proposal.status === projectApprovalStatus.accepted) flag = true;
    });
    return flag;
  });

  function setAccepted(id) {
    const copyPropsals = proposals.map((proposal) => {
      if (proposal.id === id) {
        return {
          ...proposal,
          status: projectApprovalStatus.accepted,
        };
      }
      return proposal;
    });
    setProposals(copyPropsals);
    setIsAlreadyAccepted(true);
  }

  function setRejected(id) {
    const copyPropsals = proposals.map((proposal) => {
      if (proposal.id === id) {
        return {
          ...proposal,
          status: projectApprovalStatus.rejected,
        };
      }
      return proposal;
    });
    setProposals(copyPropsals);
  }

  function handleSubmit() {
    const [id, status] = args;
    if (status === projectApprovalStatus.accepted) setAccepted(id);
    if (status === projectApprovalStatus.rejected) setRejected(id);
    hideModal();
  }

  function handleShowModal(id, status) {
    if (isAlreadyAccepted) return;
    showModal(id, status);
  }

  function renderConditionButtons(state) {
    if (state === projectApprovalStatus.accepted) {
      /* return 'accepted' */
      return (
        <Button color="primary">
          <CheckCircleOutlineIcon sx={{ fontSize: 16, marginRight: "5px" }} />
          Accepted
        </Button>
      );
    } else {
      return (
        <Button color="error">
          <CancelOutlinedIcon sx={{ fontSize: 16, marginRight: "5px" }} />
          Rejected
        </Button>
      );
    }
  }

  return (
    <>
      <ConfirmationModal
        isOpen={isModalOpen}
        successCallback={handleSubmit}
        closeModal={hideModal}
        title="Are you sure?"
        content={
          args[1] === projectApprovalStatus.accepted ? (
            <div>
              <p>Do you really want to Accept the proposal?</p>
              <p>This process cannot be undone.</p>
            </div>
          ) : (
            <div>
              <p>Do you really want to Reject the proposal?</p>{" "}
              <p>This process cannot be undone.</p>
            </div>
          )
        }
        buttonSaveText={
          args[1] === projectApprovalStatus.accepted ? "Accepted" : "Rejected"
        }
      />
      <Page title="Proposal | EMB">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Proposal
            </Typography>
          </Stack>
          <Card>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Vendor Name</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Duration</TableCell>
                    <TableCell align="center">Propsal Recieved</TableCell>
                    <TableCell align="center">Proposal Download</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {proposals.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {/* <Avatar sx={{ bgcolor: deepOrange[500] }} size="small">SC</Avatar> */}
                        {row.vendor_name}
                      </TableCell>
                      <TableCell align="center">
                        {numberFormat(row.project_amount)}
                      </TableCell>
                      <TableCell align="center">
                        {row.project_duration}
                      </TableCell>
                      <TableCell align="center">
                        {row.propsal_recieved}
                      </TableCell>
                      <TableCell align="center">
                        {/* {row.proposal_download} */}
                        <IconButton
                          color="primary"
                          size="small"
                          aria-label="add an alarm"
                        >
                          <DownloadForOfflineIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        {/* {row.action} */}
                        {row.status === projectApprovalStatus.pending ? (
                          <ButtonGroup
                            variant="outlined"
                            color="inherit"
                            size="small"
                            aria-label="outlined button group"
                          >
                            <Button
                              color="primary"
                              onClick={() => {
                                handleShowModal(
                                  row.id,
                                  projectApprovalStatus.accepted
                                );
                              }}
                              sx={{
                                opacity: isAlreadyAccepted ? "0.5" : "1",
                                cursor: isAlreadyAccepted
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                            >
                              Accept
                            </Button>
                            <Button
                              color="error"
                              onClick={() => {
                                handleShowModal(
                                  row.id,
                                  projectApprovalStatus.rejected
                                );
                              }}
                              sx={{
                                opacity: isAlreadyAccepted ? "0.5" : "1",
                                cursor: isAlreadyAccepted
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                            >
                              Reject
                            </Button>
                          </ButtonGroup>
                        ) : (
                          renderConditionButtons(row.status)
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Container>
      </Page>
    </>
  );
}
