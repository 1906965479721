import { Card } from "@mui/material";
import React, { useEffect } from "react";
import "./clock.css";
import Clock from "react-live-clock";
import ClockLoader from "../../Skeletons/ClockLoader";

export default function Clocks() {
  useEffect(() => {
    const deg = 6;
    const hr = document.querySelector("#hr");

    const id = setInterval(() => {
      let day = new Date();
      let hh = day.getHours() * 30;
      let mm = day.getMinutes() * deg;
      let ss = day.getSeconds() * deg;
      hr.style.transform = `rotateZ(${hh + mm / 12}deg)`;
    });

    return () => {
      clearInterval(id);
    };
  });
  return (
    <>
      <Card sx={{ borderRadius: "15px", height: "258px" }}>
        <div class="clock_wrapper">
          <div class="clock">
            <div class="circle">
              <div class="time">
                <Clock
                  format={"h:mm A"}
                  timezone={"Asia/Kolkata"}
                  ticking={true}
                />
              </div>
            </div>
            <div class="digit">
              <div class="twelve">12</div>
              <span class="three">3</span>
              <span class="six">6</span>
              <span class="nine">9</span>
            </div>
            <div id="hr" class="triangle-rotate  hr">
              <div class="triangle "></div>
            </div>
          </div>
        </div>
      </Card>
      {/* <ClockLoader /> */}
    </>
  );
}
