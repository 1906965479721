import {
  getAllUploadCOQ,
  deleteUploadCOQ,
  addUploadCOQ,
} from "../../../utils/apis.utils";
import {
  startUploadCOQLoader,
  stopUploadCOQLoader,
} from "../uploadcoq/uploadcoq.slice";

import { showFailureToast, showSuccessToast } from "../toast/toast.slice";

export const fetchAllPortfolioHandler = async (__, thunkApi) => {
  thunkApi.dispatch(startUploadCOQLoader());
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(getAllUploadCOQ);
  thunkApi.dispatch(stopUploadCOQLoader());
  return response.data;
};

export const deletePortfolioHandler = async (id, thunkApi) => {
  try {
    thunkApi.dispatch(startUploadCOQLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.delete(deleteUploadCOQ + "/" + id);
    thunkApi.dispatch(stopUploadCOQLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopUploadCOQLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopUploadCOQLoader());
  }
};

export const addPortfolioHandler = async (data, thunkApi) => {
  try {
    // const formData = new FormData();
    // formData.append("files", data.file);
    // thunkApi.dispatch(startUploadCOQLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.post(addUploadCOQ, data);
    thunkApi.dispatch(stopUploadCOQLoader());

    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopUploadCOQLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopUploadCOQLoader());
  }
};

export const updatePortfolioHandler = async (data, thunkApi) => {
  var id = data.id;
  var apiData = {
    data: data.data,
  };

  try {
    thunkApi.dispatch(startUploadCOQLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(addUploadCOQ + "/" + id, apiData);
    thunkApi.dispatch(stopUploadCOQLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopUploadCOQLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopUploadCOQLoader());
  }
};
