import React from "react";
import { FlowChartWithState } from "@mrblenny/react-flow-chart";
import { Link } from "@mrblenny/react-flow-chart";

import modelData from "./data";

function DealTimeline() {
  return (
    <div>
      <FlowChartWithState
        initialValue={modelData}
        config={{ readonly: true, zoom: false }}
        Components={{
          NodeInner: CustomNodeInner,
        }}
      />
    </div>
  );
}

export default DealTimeline;

const CustomNodeInner = ({ node }) => {
  return (
    <div style={{ padding: 10, width: "300px" }}>
      <h3>{node.properties?.title}</h3>
      <p>{node.properties?.description}</p>
      {node.properties?.link && (
        <a href={node.properties?.link.link} target="_blank">
          {node.properties?.link.name}
        </a>
      )}
    </div>
  );
};
