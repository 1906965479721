import React, { useEffect } from "react";
import { Box, Card, Typography, Stack, Avatar, Grid } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DropZone from "./DropZone";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllUploadPortfolioThunk,
  addUploadPortfolioThunk,
} from "../store/slices/uploadportfolio/uploadportfolio.slice";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { DateTime } from "luxon";
import useBusinessId from "../hooks/useBusinessId";
import withBusinessId from "../hoc/withBusinessId";
import usePermissions from "../hooks/usePermission";
import systemPermissions, { actions } from "../utils/global/permissions.global";
import { fetchVendorProfileDetailsThunk } from "../store/slices/vendor/vendor.slice";

function UploadPortfolio() {
  const dispatch = useDispatch();
  const businessId = useBusinessId();

  const userPerm = usePermissions();

  const { uploadportfoliodata } = useSelector(
    (uploadPortfolio) => uploadPortfolio.uploadportfolio
  );

  useEffect(() => {
    dispatch(fetchAllUploadPortfolioThunk(businessId));
  }, [dispatch, businessId]);

  async function addAttachment({ file }) {
    if (!file || file.length === 0) return;
    const formData = new FormData();
    file.forEach((ele) => {
      formData.append("files", ele);
    });
    dispatch(addUploadPortfolioThunk(formData))
      .unwrap()
      .then(dispatch(fetchVendorProfileDetailsThunk()));
  }
  return (
    <div>
      <Card>
        <Box p={5}>
          <Typography variant="h5"> Upload Portfolio</Typography>
          <Box py={3}>
            {userPerm.hasAccess(
              systemPermissions["vendorProfileDetailPage.corporate_deck"],
              actions.create
            ) && (
              <DropZone
                addAttachment={addAttachment}
                accept=".pdf,.doc,.png,.jpg,.jpeg"
              />
            )}
          </Box>
          <Stack direction="row" spacing={5}>
            <Grid container spacing={2}>
              {uploadportfoliodata &&
                uploadportfoliodata.map((data) => (
                  <Grid item xs={6} md={4}>
                    <a href={data.url} target="_blank">
                      <Card sx={{ width: "300px", borderRadius: "10px" }}>
                        <Box p={3}>
                          <Stack direction="row" spacing={2}>
                            <Avatar
                              sx={{ bgcolor: "#eff8f1" }}
                              variant="rounded"
                            >
                              {data.media_type === "pdf" ? (
                                <PictureAsPdfIcon sx={{ color: "#de4435" }} />
                              ) : (
                                <PhotoLibraryIcon sx={{ color: "#00c9a7" }} />
                              )}
                            </Avatar>
                            <Box sx={{ width: "160px" }}>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: "500" }}
                              >
                                {data.filename?.length > 15
                                  ? data.filename.substring(0, 15) + "..."
                                  : data.filename}
                              </Typography>
                              <Typography sx={{ fontSize: "12px" }}>
                                {DateTime.fromISO(data.created_by).toFormat(
                                  "DD"
                                )}
                              </Typography>
                            </Box>
                            <Avatar
                              sx={{ bgcolor: "#f3f6f8", color: "#637281" }}
                            >
                              S
                            </Avatar>
                          </Stack>
                        </Box>
                      </Card>
                    </a>
                  </Grid>
                ))}
            </Grid>
          </Stack>
        </Box>
      </Card>
      {/* <UploadLoader /> */}
    </div>
  );
}

export default withBusinessId(UploadPortfolio);
