import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchVenderExistHandler,
  deleteNotesHandler,
  addNotesByIdHandler,
  updateNotesHandler,
} from "./notes.thunk";

const INIT_STATE = {
  venderResponseData: {},
  isLoading: false,
  message: "",
  isNotExist: false,
};

export const fetchVenderExistThunk = createAsyncThunk(
  "fetchVenderExist",
  fetchVenderExistHandler
);

const venderExistSlice = createSlice({
  name: "venderExist",
  initialState: INIT_STATE,
  reducers: {
    startNotesLoader: (state) => {
      state.isLoading = true;
    },
    stopNotesLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVenderExistThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVenderExistThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.venderResponseData = action.payload.data;
        state.message = action.payload.message;
        if (action?.payload?.data?.id) {
          state.isNotExist = false;
        } else {
          state.isNotExist = true;
        }
      })
      .addCase(fetchVenderExistThunk.rejected, (state) => {
        state.venderResponseData = {};
        state.isLoading = false;
        state.message = "";
        state.isNotExist = "";
      });
  },
});

export const { startNotesLoader, stopNotesLoader } = venderExistSlice.actions;

export default venderExistSlice.reducer;
