import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import usePermissions from "../../hooks/usePermission";
import systemPermissions, {
  actions,
  getMappedPermission,
  isWhitelisted,
  whilteListUrls,
} from "../../utils/global/permissions.global";

function AuthProtector({ children }) {
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const userPerm = usePermissions();

  const location = useLocation();

  if (!isLoggedIn) return <Navigate to="/login" />;

  // check for Support list Page
  const permissions = getMappedPermission(location.pathname);
  // console.log("data", location.pathname, permissions);

  let isHaveAccess = false;
  if (Array.isArray(permissions)) {
    permissions.forEach((permission) => {
      const access = userPerm.hasAccess(
        systemPermissions[permission],
        actions.read
      );
      if (access) {
        isHaveAccess = true;
      }
    });
  } else {
    isHaveAccess = userPerm.hasAccess(
      systemPermissions[permissions],
      actions.read
    );
  }

  if (location.pathname === "/dashboard/profiles/new/basic") {
    isHaveAccess = userPerm.hasAccess(
      systemPermissions["vendorProfileMainPage.allVendors"],
      actions.create
    );
  }

  if (!isHaveAccess && !isWhitelisted(location.pathname))
    return <Navigate to="/dashboard/app" />;

  return children;
}

export default AuthProtector;
