import React, { useEffect, useState } from "react";

import { Box, Grid, Button, Typography, Rating } from "@mui/material";
import Element from "../../components/Form/Element";

import { inputType } from "../../utils/enum";

function ViewRatingSls({ handleChange, handleBlur, style }) {
  const [ratingVal, setRating] = useState(5);
  const [ratingVal2, setRating2] = useState(4);
  const [ratingVal3, setRating3] = useState(3);
  const [ratingVal4, setRating4] = useState(2);
  const [ratingVal5, setRating5] = useState(4);
  const [ratingVal6, setRating6] = useState(1);
  const [ratingVal7, setRating7] = useState(3);
  return (
    <>
      <Box>
        <form>
          <Grid container spacing={2} p={3}>
            <Grid item md={12} pb={2} justify="center">
              <Typography variant="h5" align="center">
                Add Review By SLS
              </Typography>
              <Box mt={2}>
                <Element
                  eletype={inputType.input}
                  label="Title"
                  inputProps={{
                    name: "title",
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                  value="sourabh"
                />

                <Grid container spacing={4} mb={1}>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "18px",

                        color: "#a3a2a2f7",
                        marginBottom: "8px",
                      }}
                    >
                      Communication Skills
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "18px",

                        color: "#a3a2a2f7",
                        marginBottom: "8px",
                      }}
                    >
                      Quotation Quality
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "18px",

                        color: "#a3a2a2f7",
                        marginBottom: "8px",
                      }}
                    >
                      Turnaround Time
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "18px",

                        color: "#a3a2a2f7",
                        marginBottom: "8px",
                      }}
                    >
                      Budget
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "18px",

                        color: "#a3a2a2f7",
                        marginBottom: "8px",
                      }}
                    >
                      Logical Understanding
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "18px",

                        color: "#a3a2a2f7",
                        marginBottom: "8px",
                      }}
                    >
                      Quality of past-work
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "18px",

                        color: "#a3a2a2f7",
                        marginBottom: "8px",
                      }}
                    >
                      Budget Accuracy
                    </Typography>
                  </Grid>

                  <Grid item xs={5} textAlign="end">
                    <Rating
                      name="simple-controlled"
                      value={ratingVal}
                      sx={{ fontSize: "30px" }}
                      onChange={(event, newValue) => {
                        setRating(newValue);
                      }}
                    />

                    <Rating
                      name="simple-controlled"
                      value={ratingVal2}
                      sx={{ fontSize: "30px" }}
                      onChange={(event, newValue) => {
                        setRating2(newValue);
                      }}
                    />
                    <Rating
                      name="simple-controlled"
                      value={ratingVal3}
                      sx={{ fontSize: "30px" }}
                      onChange={(event, newValue) => {
                        setRating3(newValue);
                      }}
                    />
                    <Rating
                      name="simple-controlled"
                      value={ratingVal4}
                      sx={{ fontSize: "30px" }}
                      onChange={(event, newValue) => {
                        setRating4(newValue);
                      }}
                    />
                    <Rating
                      name="simple-controlled"
                      value={ratingVal5}
                      sx={{ fontSize: "30px" }}
                      onChange={(event, newValue) => {
                        setRating5(newValue);
                      }}
                    />
                    <Rating
                      name="simple-controlled"
                      value={ratingVal6}
                      sx={{ fontSize: "30px" }}
                      onChange={(event, newValue) => {
                        setRating6(newValue);
                      }}
                    />
                    <Rating
                      name="simple-controlled"
                      value={ratingVal7}
                      sx={{ fontSize: "30px" }}
                      onChange={(event, newValue) => {
                        setRating7(newValue);
                      }}
                    />
                  </Grid>
                </Grid>

                <Element
                  eletype={inputType.textarea}
                  label="Description"
                  inputProps={{
                    name: "title",
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                  value="sourabh"
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default ViewRatingSls;
