/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, Box, Tab, Tabs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const CustomTabs = ({ labels }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [childSelectTab, setChildSelectTab] = useState(0);

  const handleTabChange = (e, val) => {
    setSelectedTab(val);
  };

  const handleChildTabChange = (e, val) => {
    setChildSelectTab(val);
  };
  const location = useLocation();

  useEffect(() => {
    const idx = labels.findIndex((label) =>
      location.pathname.includes(label.value)
    );
    if (idx >= 0) {
      setSelectedTab(idx);
    }
  }, [labels, location.pathname]);

  useEffect(() => {
    const idx = labels?.[selectedTab]?.childTabs?.findIndex((label) =>
      location.pathname.includes(label.value)
    );
    if (idx >= 0) {
      setChildSelectTab(idx);
    }
  }, [labels, location.pathname]);

  function getIcon(label, i) {
    if (label) {
      if (selectedTab === i  ) {
        return (
          <img
            src={label.activeIcon}
            alt="Commercials"
            width="17"
            style={{ borderRadius: "0" }}
          />
        );
      }
      return (
        <img
          src={label.icon}
          alt="Commercials"
          width="17"
          style={{ borderRadius: "0" }}
        />
      );
    }

    return null;
  }

  function getChildIcon(label, i) {
    
    if (label) {
      if (childSelectTab === i  ) {
        return (
          <img
            src={label.activeIcon}
            alt="Commercials"
            width="17"
            style={{ borderRadius: "0" }}
          />
        );
      }
      return (
        <img
          src={label.icon}
          alt="Commercials"
          width="17"
          style={{ borderRadius: "0" }}
        />
      );
    }

    return null;
  }

  // console.log(labels);

  return (
    <Grid>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          {labels &&
            labels.map((label, i) => {
              return (
                <Tab
                  key={i}
                  label={label.label}
                  component={Link}
                  to={label.value}
                  icon={label.icon && getIcon(label, i)}
                  iconPosition="start"
                  disabled={label.isDisabled}
                />
              );
            })}
        </Tabs>

        <Tabs
          value={childSelectTab}
          onChange={handleChildTabChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          {labels &&
            labels?.[selectedTab]?.childTabs?.map((label, i) => (
              <>
                <Tab
                  key={i}
                  label={label.label}
                  component={Link}
                  to={label.value}
                  icon={label.icon && getChildIcon(label, i)}
                  iconPosition="start"
                  disabled={label.isDisabled}
                />
              </>
            ))}
        </Tabs>
      </Box>
    </Grid>
  );
};

export default CustomTabs;
