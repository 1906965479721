import React from "react";
// material
import { styled } from "@mui/material/styles";
import { Grid, Card, Typography, Box, Button } from "@mui/material";

import useUser from "../../../hooks/useUser";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: "#f3f6f8",
}));

export default function AppWeeklySales() {
  const user = useUser();

  return (
    <RootStyle align="left">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={7}>
          <Typography
            align="left"
            ml={5}
            sx={{ color: "#212A35", fontSize: "32px", fontWeight: "bold" }}
          >
            Welcome back! <br />
            {user.fullName}
          </Typography>
          <Typography
            align="left"
            ml={5}
            sx={{
              marginTop: "10px",
              marginBottom: "10px",
              fontWeight: 500,
              paddingBottom: "2.25rem",
              fontSize: "20px",
              color: "#212A35",
            }}
          >
            Manage onboarding, background checks and perform other
            functionalities to onboard agencies conveniently!
          </Typography>
          {/* <Box ml={5} textAlign="left">
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={{
                background: "#02AB54",
                width: "150px",
                height: "50px",
                fontSize: "20px",
                borderRadius: "12px",
                boxShadow: "none",
                ":hover": { background: "#81c784" },
                textDecoration: "none",
              }}
            >
              CTA
            </Button>
          </Box> */}
        </Grid>

        <Grid item xs={12} sm={6} md={5} pt={3}>
          <Box sx={{ marginLeft: "20px" }}>
            <img
              src="/static/welcome_client.svg"
              width="240"
              height="240"
              alt=""
            />
          </Box>
        </Grid>
      </Grid>
    </RootStyle>
  );
}
