import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchAllShortListedVendorsHandler,
  fetchShortlistedVendorDetailsByIdHandler,
  searchAllShortlistedVendorsHandler,
  getShortlistedVendorsHandler,
  getRecommendedVendorsHandler,
  addVendorAsShortlistedHandler,
  deleteVendorAsShortlistedHandler,
  fetchAllTechStackByServiceHandler,
  fetchAllTagsByServiceHandler,
  fetchVendorShortlistingProfileByFilterHandler,
  fetchAllTechStackByIDsHandler,
} from "./shortlisting.thunk";

const INIT_STATE = {
  dealsList: {
    allDeals: [],
    page: 1,
    totalShortlistedVendors: 0,
  },
  selectedDeal: {},
  shortlistedVendors: {
    recommendedVendors: [],
    shortListedVenodrsData: [],
    totalRecommendedVendors: 0,
  },
  isDealClosed: false,
  isLoading: false,
  techStackByService: [],
  tagsByService: [],
};

export const fetchAllShortlisetedVendorsThunk = createAsyncThunk(
  "fetchAllShortlisetedVendors",
  fetchAllShortListedVendorsHandler
);

export const fetchAllTechStackByIDsThunk = createAsyncThunk(
  "fetchAllTechStackByIDs",
  fetchAllTechStackByIDsHandler
);

export const fetchVendorShortlistingProfileByFilterThunk = createAsyncThunk(
  "fetchVendorShortlistingProfileByFilter",
  fetchVendorShortlistingProfileByFilterHandler
);

export const fetchDealInfoById = createAsyncThunk(
  "fetchShortlistedVendorDetailsBy",
  fetchShortlistedVendorDetailsByIdHandler
);

export const fetchAllTechStackByServiceThunk = createAsyncThunk(
  "fetchAllTechStackByService",
  fetchAllTechStackByServiceHandler
);

export const fetchAllTagsByServiceThunk = createAsyncThunk(
  "fetchAllTagsByService",
  fetchAllTagsByServiceHandler
);

export const searchAllShortlistedVendorsThunk = createAsyncThunk(
  " searchAllShortlistedVendors",
  searchAllShortlistedVendorsHandler
);

export const getRecommendedVendorsThunk = createAsyncThunk(
  " getRecommendedVendors",
  getRecommendedVendorsHandler
);

export const getShortlistedVendorsThunk = createAsyncThunk(
  " getShortlistedVendors",
  getShortlistedVendorsHandler
);

export const addVendorAsShortlistedHThunk = createAsyncThunk(
  "addVendorAsShortlistedH",
  addVendorAsShortlistedHandler
);

export const deleteVendorAsShortlistedHThunk = createAsyncThunk(
  "deleteVendorAsShortlistedH",
  deleteVendorAsShortlistedHandler
);

const shortlistingSlice = createSlice({
  name: "shortlisting",
  initialState: INIT_STATE,
  reducers: {
    startShortlistLoader: (state) => {
      state.isLoading = true;
      return state;
    },
    stopShortlistLoader: (state) => {
      state.isLoading = false;
      return state;
    },
    setEmptyTechStackAndTags: (state, payload) => {
      state.techStackByService = [];
      state.tagsByService = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllShortlisetedVendorsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllShortlisetedVendorsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dealsList.allDeals = action.payload.data;
        state.dealsList.totalShortlistedVendors = action.payload.total;
      })
      .addCase(fetchAllShortlisetedVendorsThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(
        fetchVendorShortlistingProfileByFilterThunk.fulfilled,
        (state, action) => {
          state.shortlistedVendors.recommendedVendors = action.payload.data;
          state.shortlistedVendors.totalRecommendedVendors =
            action.payload.total;
        }
      )
      .addCase(fetchAllTechStackByServiceThunk.fulfilled, (state, action) => {
        state.techStackByService = action.payload;
      })
      .addCase(fetchAllTagsByServiceThunk.fulfilled, (state, action) => {
        state.tagsByService = action.payload;
      })
      .addCase(searchAllShortlistedVendorsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchAllShortlistedVendorsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dealsList.allDeals = action.payload.data;
        state.dealsList.totalShortlistedVendors = action.payload.total;
      })
      .addCase(searchAllShortlistedVendorsThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchDealInfoById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDealInfoById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedDeal = action.payload;
      })
      .addCase(fetchDealInfoById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getRecommendedVendorsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRecommendedVendorsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.shortlistedVendors.totalRecommendedVendors = action.payload.total;
        state.shortlistedVendors.recommendedVendors = action.payload.data;
      })
      .addCase(getRecommendedVendorsThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getShortlistedVendorsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getShortlistedVendorsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.shortlistedVendors.shortListedVenodrsData = action.payload;
      })
      .addCase(getShortlistedVendorsThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addVendorAsShortlistedHThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(addVendorAsShortlistedHThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        var recState = [...state.shortlistedVendors.recommendedVendors];
        var data = recState.filter((d) => d.id === action.payload.vendor_id);
        var datarec = recState.filter((d) => d.id !== action.payload.vendor_id);
        state.shortlistedVendors.totalRecommendedVendors =
          state.shortlistedVendors.recommendedVendors.length - 1;
        state.shortlistedVendors.recommendedVendors = datarec;
        state.shortlistedVendors.shortListedVenodrsData.unshift(data[0]);
      })
      .addCase(addVendorAsShortlistedHThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(deleteVendorAsShortlistedHThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(deleteVendorAsShortlistedHThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        var shortState = [...state.shortlistedVendors.shortListedVenodrsData];
        var data = shortState.filter((d) => d.id === action.payload.vendors[0]);
        var datashort = shortState.filter(
          (d) => d.id !== action.payload.vendors[0]
        );
        state.shortlistedVendors.totalRecommendedVendors =
          state.shortlistedVendors.recommendedVendors.length + 1;
        state.shortlistedVendors.shortListedVenodrsData = datashort;
        state.shortlistedVendors.recommendedVendors.unshift(data[0]);
      })
      .addCase(deleteVendorAsShortlistedHThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(fetchAllTechStackByIDsThunk.fulfilled, (state, action) => {
        state.techStackByService = action.payload.tech_stacks;
        state.tagsByService = action.payload.tags;
      });
  },
});

export const {
  stopShortlistLoader,
  startShortlistLoader,
  setEmptyTechStackAndTags,
} = shortlistingSlice.actions;

export default shortlistingSlice.reducer;
