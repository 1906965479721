import React, { useEffect, useState } from "react";
import { addNotesByIdThunk } from "../../store/slices/viewProfile/viewProfile.slice";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Button,
  Typography,
  Rating,
  TextareaAutosize,
} from "@mui/material";
import Element from "../../components/Form/Element";

import { inputType } from "../../utils/enum";

function NotesModal({ handleChange, handleBlur, style }) {
  const [note, setNote] = useState(null);
  const dispatch = useDispatch();

  function handleNotesChange(e) {
    setNote(e.target.value);
  }

  return (
    <>
      {/* <Element
        eletype={inputType.textarea}
        label="Description"
        inputProps={{
          name: "title",
          onChange: handleChange,
          onBlur: handleBlur,
        }}
        style={{
          fontSize: "18px",
          fontWeight: "500",
        }}
        value="sourabh"
      /> */}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(
            addNotesByIdThunk({
              // id: params.id,
              // name: note,
            })
          )
            .unwrap()
            .then(() => {
              setNote("");
            });
        }}
      >
        <Grid container spacing={2} p={3}>
          <Grid item xs={12}>
            <TextareaAutosize
              aria-label="empty textarea"
              minRows={3}
              placeholder="Add Notes"
              // width="100%"
              style={{
                width: "100%",
                padding: "20px",
                borderRadius: "10px",
                fontSize: "16px",
              }}
              onChange={handleNotesChange}
            />
          </Grid>
          <Button size="large" type="submit" variant="contained">
            Add Notes
          </Button>
        </Grid>
      </form>
    </>
  );
}

export default NotesModal;
