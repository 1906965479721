import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  Button,
  Divider,
} from "@mui/material";
import { Icon } from "@iconify/react";
import "simplebar/dist/simplebar.min.css";
import { useDispatch, useSelector } from "react-redux";
import Text from "./Text";
import Dropdown from "./Dropdown";
import IndustrySearch from "./IndustrySearch";
import {
  newSearchDeliveryclassHandler,
  newSearchIndustryHandler,
  newSearchServiceHandler,
  newSearchSubIndustryHandler,
  searchIndustryHandler,
  searchServiceHandler,
  serviceFormatter,
} from "../../utils/global/vendor.global";
import ServiceSearch from "./ServiceSearch";
import Element from "../Form/Element";
import { inputType } from "../../utils/enum";
import { fetchAllTechStackListThunk } from "../../store/slices/ra/ra.slice";
import { useFormik } from "formik";

function RAFilterShortlist({
  handleFilterClose,
  filterState,
  selectedService,
}) {
  // const { techStackByService } = useSelector(({ shortlist }) => shortlist);
  const { techStacklist } = useSelector(({ ra }) => ra);
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [techStacks, setTechStacks] = useState([])


  useEffect(()=>{
    dispatch(fetchAllTechStackListThunk({search}))
  },[dispatch])

  useEffect(()=>{
    setTechStacks(techStacklist)
  },[techStacklist])


  // const options_mapper = useMemo(() => {
  //   return (
  //     techStacklist?.map((ele) => ({
  //       label: ele.name,
  //       value: ele.name,
  //     })),
  // )
  // }, [techStacklist]);

  const { state, applyFilter, resetFilter, ...rest } = filterState;

  const formik = useFormik({
    initialValues:{
      techstack: '',
    },
    onSubmit: (value) =>{
      console.log("formik value :", value);
    }
  })

  return (
    <Container>
      <Box>
        <Grid container>
          <Grid item md={12}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              justifyContent="space-between"
            >
              <Typography
                variant="h5"
                component="div"
                pt={2}
                mb={1}
                pl={"40px"}
              >
                Filter By
              </Typography>
              <Icon
                icon="eva:close-outline"
                style={{ fontSize: "27px", cursor: "pointer" }}
                onClick={handleFilterClose}
              />
            </Stack>
            <Divider />
          </Grid>
          <Grid item md={12} pl={3} mt={4}>
            {/* <Dropdown
              width="500px"
              state={state.tech_stack}
              options={options_mapper.tech_stack}
              label="Tech Stack"
              value="tech_stack"
              {...rest}
            /> */}
            <Element
              label="Tech Stack"
              inputProps={{
                placeholder: "Tech Stack",
                type: "text",
                  onChange: formik.handleChange,
                // onChange: () => {},
                  onBlur: formik.handleBlur,
                // onBlur: () => {},
                name: "techstack",
              }}
              options={techStacks}
              eletype={inputType.select}
              value={formik.values.techstack}
              // value=""
            />
          </Grid>
          <Grid item md={12}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{ textAlign: "right", width: "100%" }}
                mt={3}
                mb={3}
                mr={3}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{ padding: "10px 30px", marginRight: "10px" }}
                  onClick={() => {
                    applyFilter();
                    handleFilterClose();
                  }}
                >
                  Apply Filter
                </Button>

                {/* <Button
                  color="inherit"
                  size="large"
                  sx={{ padding: "10px 20px", border: "2px solid black" }}
                  onClick={resetFilter}
                >
                  Reset Filter
                </Button> */}
              </Box>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default RAFilterShortlist;
