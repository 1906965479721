import {
  fetchAllSupportApi,
  globalReportedIssueApi,
  getAllSupportComment,
  createGlobalSupportCommentApi,
  ticketstatusApi,
} from "../../../utils/apis.utils";
import {
  startDashboardLoader,
  stopDashboardLoader,
} from "../dashboard/dashboard.slice";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";

export const fetchAllSupportTicketsHandler = async (__, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(fetchAllSupportApi);
  return response.data;
};
export const fetchAllGlobalTicketsDataByTicketIdHanldler = async (
  { ticketId },
  thunkApi
) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(`${globalReportedIssueApi}/${ticketId}`);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const getAllSupportCommentHanldler = async ({ ticketId }, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(`${getAllSupportComment}/${ticketId}`);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const createGlobalSupportTicketCommentHandler = async (
  data,
  thunkApi
) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(createGlobalSupportCommentApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const updateTicketStatusHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(ticketstatusApi, data);
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};
