import { Button } from "@mui/material";
import { capitalCase } from "change-case";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import IosShareIcon from "@mui/icons-material/IosShare";

const CSVDownload = ({ headers, data }) => {
  const [updatedHeader, setUpdatedHeader] = useState([]);
  useEffect(() => {
    const newHeader = headers.map((item) => ({
      key: item.id,
      label: item.label,
    }));
    setUpdatedHeader(newHeader);
  }, [headers]);

  const [updatedData, setUpdatedData] = useState([]);
  useEffect(() => {
    const newData = data.map((item) => ({
      ...item,
      address:
        item.city && item.state
          ? capitalCase(item.city) + ", " + capitalCase(item.state)
          : item.city && !item.state
          ? capitalCase(item.city)
          : !item.city && item.state
          ? capitalCase(item.state)
          : "-",
      status: capitalCase(item.profile_stage),
    }));
    setUpdatedData(newData);
  }, [data]);

  return (
    <>
      <Button variant="contained">
        <CSVLink
          data={updatedData}
          headers={updatedHeader}
          style={{
            color: "white",
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
          filename={`vendor_filter_export_${new Date().toLocaleString()}.csv`}
        >
          <IosShareIcon fontSize="small" />
          <span>Export</span>
        </CSVLink>
      </Button>
    </>
  );
};
export default CSVDownload;
