import {
  getNotesByID,
  deleteServices,
  addNotesByID,
  addServices,
  deleteNoteApi,
  updateNotesByID,
} from "../../../utils/apis.utils";
import { startNotesLoader, stopNotesLoader } from "../notes/notes.slice";
import { createQueryString } from "../../../utils/global/global";
import { fetchNotesByIdThunk } from "./notes.slice";

import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import { useDebugValue } from "react";

export const fetchNotesByIdHandler = async (page = 1, thunkApi) => {
  const query = createQueryString({
    page,
  });
  thunkApi.dispatch(startNotesLoader());
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(getNotesByID + query);

  thunkApi.dispatch(stopNotesLoader());
  return {
    data: response.data,
    total: response.total,
  };
};

export const deleteNotesHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startNotesLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.delete(deleteNoteApi + "/" + data.id);
    thunkApi.dispatch(stopNotesLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );

    thunkApi.dispatch(fetchNotesByIdThunk(data.page));

    // return id;
  } catch (err) {
    thunkApi.dispatch(stopNotesLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopNotesLoader());
  }
};

export const addNotesByIdHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startNotesLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.post(addNotesByID, {
      notes: data.name,
    });
    thunkApi.dispatch(stopNotesLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchNotesByIdThunk(data.page));

    // return response.data;
  } catch (err) {
    thunkApi.dispatch(stopNotesLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopNotesLoader());
  }
};

export const updateNotesHandler = async (data, thunkApi) => {
  var id = data.id;
  var apiData = {
    notes: data.notes,
  };

  try {
    thunkApi.dispatch(startNotesLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.put(updateNotesByID + "/" + id, apiData);
    thunkApi.dispatch(stopNotesLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopNotesLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopNotesLoader());
  }
};
