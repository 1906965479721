import React, { useEffect, useState, useMemo } from "react";
import Drawer from "@mui/material/Drawer";
import Filter from "../../components/Filter/Filter";
import ListSearchbar from "../../components/Common/ListSearchbar";
import ListHead from "../../components/Common/ListHead";
import Scrollbar from "../../components/Common/Scrollbar";
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllShortlisetedVendorsThunk,
  searchAllShortlistedVendorsThunk,
} from "../../store/slices/shortlisting/shortlisting.slice";
import { capitalCase } from "../../components/Common/change-case";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { DateTime } from "luxon";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import usePermissions from "../../hooks/usePermission";
import systemPermissions, {
  actions,
} from "../../utils/global/permissions.global";

const TABLE_HEAD = [
  { id: "s_no", label: "S.No.", alignRight: false },
  { id: "deal_name", label: "Deal Name", alignRight: false },
  {
    id: "requested_by",
    label: "Requested By",
    alignRight: false,
  },
  {
    id: "total_selected_vendors",
    label: "Shortlisted Vendors",
    alignRight: false,
  },
  { id: "time_stamp", label: "Time Stamp", alignRight: false },
  { id: "shortlist", label: "Shortlist", alignRight: false },
];

function VendorShortlist() {
  const [showDrawer, setShowDrawer] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    dealsList: { allDeals, totalShortlistedVendors },
  } = useSelector(({ shortlist }) => shortlist);

  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(fetchAllShortlisetedVendorsThunk());
  }, [dispatch]);

  function handleRowClick(s_no) {
    navigate("/dashboard/shortlist/" + s_no);
  }

  const userPerm = usePermissions();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);

  const handleChangePage = (_, newPage) => {
    if (search) {
      setPage(newPage);
      return dispatch(
        searchAllShortlistedVendorsThunk({
          search,
          page: newPage + 1,
        })
      );
    }
    dispatch(fetchAllShortlisetedVendorsThunk(newPage + 1));
    setPage(newPage);
  };

  const _searchChange = useMemo(() => {
    return _.debounce((search) => {
      if (!search) {
        return dispatch(fetchAllShortlisetedVendorsThunk());
      }
      setPage(0);
      dispatch(
        searchAllShortlistedVendorsThunk({
          search,
        })
      );
    }, 500);
  }, []);

  function handleSearchChange(e) {
    const value = e.target.value;
    _searchChange(value);
    setSearch(value);
  }

  return (
    <>
      <Container>
        <Drawer
          anchor="right"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          PaperProps={{
            style: { width: "400px" },
          }}
        >
          <Filter />
        </Drawer>

        <Stack
          mb={2}
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Typography variant="h4" gutterBottom>
            Select Deal to Shortlist
          </Typography>
        </Stack>

        <Card>
          <Breadcrumb />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              display: { lg: "flex", md: "flex", sm: "block", xs: "block" },
            }}
          >
            <ListSearchbar
              placeholder="Search Deal"
              handleSearchChange={handleSearchChange}
              styles={{
                width: "280px",
              }}
            />
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  headLabel={
                    userPerm.hasAccess(
                      systemPermissions["VendorShorlistItem.shortlist"],
                      actions.read
                    )
                      ? TABLE_HEAD
                      : [...TABLE_HEAD].slice(0, TABLE_HEAD.length - 1)
                  }
                  rowCount={allDeals?.length}
                  styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                />
                <TableBody>
                  {allDeals.map((row, indx) => {
                    const {
                      _id,
                      deal_name,
                      requested_by,
                      time_stamp,
                      total_selected_vendors,
                    } = row;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        sx={{ cursor: "pointer", zIndex: "" }}
                      >
                        <TableCell>
                          <Typography noWrap sx={{ fontSize: "15px" }}>
                            {page + (indx + 1)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography noWrap sx={{ fontSize: "15px" }}>
                            {capitalCase(deal_name)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography noWrap sx={{ fontSize: "15px" }}>
                            {capitalCase(requested_by ? requested_by : "-")}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography noWrap sx={{ fontSize: "15px" }}>
                            {total_selected_vendors}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {DateTime.fromISO(time_stamp).toLocaleString(
                            DateTime.DATE_MED
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {total_selected_vendors == "true" ? (
                            <Typography
                              onClick={(e) => handleRowClick(_id)}
                              noWrap
                              sx={{ fontSize: "15px" }}
                            >
                              <Button sx={{ color: "#005bbe" }}>View</Button>
                            </Typography>
                          ) : (
                            userPerm.hasAccess(
                              systemPermissions["VendorShorlistItem.shortlist"],
                              actions.read
                            ) && (
                              <Typography
                                onClick={(e) => handleRowClick(_id)}
                                noWrap
                                sx={{
                                  fontSize: "15px",
                                  borderRadius: "10px",
                                }}
                              >
                                <Button sx={{ paddingLeft: "0px!important" }}>
                                  Shortlist
                                </Button>
                              </Typography>
                            )
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={totalShortlistedVendors}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPageOptions={false}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}

export default VendorShortlist;
