import React, { useMemo } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  Stack,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Chip,
  IconButton,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

// components
import Page from "../../components/Page";
import { useEffect, useState } from "react";
import Scrollbar from "../../components/Scrollbar";
import { UserMoreMenu } from "../../components/_dashboard/user";
//
import { useDispatch, useSelector } from "react-redux";
import ListSearchbar from "../../components/Common/ListSearchbar";
import ListHead from "../../components/Common/ListHead";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import { fetchAllSupportTicketsThunk } from "../../store/slices/support/support.slice";
import { DateTime } from "luxon";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useSearch from "../../hooks/useSearch";
import { capitalCase } from "change-case";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },

  { id: "status", label: "Status", alignRight: false },
  { id: "createdOn", label: "Created On", alignRight: false },
  // { id: "action", label: "Action ", alignRight: false },
];

export default function Contact() {
  const dispatch = useDispatch();
  const tickets = useSelector(({ support }) => support?.tickets);
  const [search, setSearch] = useState("");
  const { handleTextChange } = useSearch(tickets, "title");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAllSupportTicketsThunk());
  }, [dispatch]);

  const serachedTickets = useMemo(() => {
    if (!search) return tickets;
    return handleTextChange(search);
  }, [search, tickets]);

  return (
    <Page title="Support | EMB">
      <Container>
        <Typography variant="h4">Support</Typography>

        <Box mt={5}>
          <Card>
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignContent="center"
                alignItems="center"
                p={1}
              >
                <Paper>
                  <ListSearchbar
                    placeholder="Search tickets"
                    handleSearchChange={(e) => setSearch(e.target.value)}
                  />
                </Paper>
              </Stack>
            </Box>

            {serachedTickets.length > 0 ? (
              <Card sx={{ boxShadow: "none", borderRadius: "0px" }}>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <ListHead
                        headLabel={TABLE_HEAD}
                        rowCount={serachedTickets.length}
                        styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                      />
                      <TableBody>
                        {serachedTickets.map((row) => {
                          const { id, title, state, created_at } = row;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              sx={{ cursor: "pointer" }}
                              onClick={()=>navigate('tickets/'+id)}
                            >
                              <TableCell align="left">
                                {/* <Link
                                  to={`tickets/${id}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                > */}
                                  {capitalCase(title)}
                                {/* </Link> */}
                              </TableCell>

                              <TableCell
                                align="left"
                                sx={{ paddingLeft: "8px!important" }}
                              >
                                <Chip
                                  size="small"
                                  variant="outlined"
                                  label={capitalCase(state)}
                                  sx={{
                                    fontSize: "12px",
                                    border: "none",
                                    textTransform: "capitalize",

                                    color:
                                      state === "open" ? "#11A54A" : "#909090",
                                    // backgroundColor:
                                    //   state === "open" ? "#eff8f1" : "#fdd8de",
                                  }}
                                />
                              </TableCell>

                              <TableCell align="left">
                                {DateTime.fromISO(created_at).toFormat("DD")}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </Card>
            ) : (
              <SearchNotFound
                src="/static/support.svg"
                subtitle="There is currently zero support ticket to show."
              />
            )}
          </Card>
        </Box>
      </Container>
      {/* Modal Used */}
    </Page>
  );
}
