/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import Input from "../Form/Input";
import _, { values } from "lodash";
import {
  fetchAllSubIndustry,
  searchIndustryHandler,
} from "../../utils/global/vendor.global";
import TreeList from "./TreeList.js";
import { Box } from "@mui/system";
import AutocompleteWithoutChip from "../Form/AutoCompleteWithoutChip.js";

function SearchIndustryWithChild({
  onChange,
  onBlur,
  label,
  placeholder,
  formatter,
  value = {},
  errorText,
  handler = searchIndustryHandler,
  name,
  initTechStackData = {},
}) {
  const [isOpen, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState(value?.name ?? "");
  const [teckStacks, setTeckStacks] = useState(initTechStackData);

  function handleTechStackDelete(val) {
    setTeckStacks(val);
  }

  async function getOptions(_, value) {
    const data = await fetchAllSubIndustry(value?.id);
    return data.data?.[0]?.children?.map((lan) => ({
      ...lan,
      label: lan.title,
      value: lan.title,
    }));
  }

  function handleTeckStackChange(val) {
    if (!val) return;
    setTeckStacks(val);
  }

  useEffect(() => {
    onChange({
      target: {
        name: "sub_industry",
        value: {
          id: teckStacks?._id ?? teckStacks?.id,
          name: teckStacks?.value ?? teckStacks?.name,
        },
      },
    });
  }, [teckStacks]);

  useEffect(() => {
    setSearch(value?.name);
  }, [value]);

  const handleTextChange = useMemo(() => {
    const _fn = _.debounce((value) => {
      if (!value) return;
      handler(value).then((response) => {
        if (typeof formatter === "function") {
          if (response.data?.length === 0) {
            return setOpen(false);
          }
          setOptions(formatter(response.data));
          setOpen(true);
        }
      });
    }, 500);

    return function handleChange(e) {
      const value = e.target.value;
      setSearch(value);
      _fn(value);
    };
  }, []);

  return (
    <>
      <Input
        label={label}
        inputProps={{
          placeholder: placeholder,
          onChange: handleTextChange,
          name: "treeTextChange",
        }}
        value={search}
        errorText={errorText}
      />
      {isOpen && (
        <Card>
          <Box p={2}>
            {options.map((d) => (
              <TreeList
                {...d}
                onChange={onChange}
                onBlur={onBlur}
                setOpen={setOpen}
                name={name}
                idName="id"
                valueName="name"
              />
            ))}
          </Box>
        </Card>
      )}
      {value?.id && (
        <AutocompleteWithoutChip
          label="Search Sub Industry"
          deleteChip={handleTechStackDelete}
          getOptions={getOptions}
          onChange={handleTeckStackChange}
          extra={value}
          placeholder="Search Tech Stack"
          value={teckStacks?.name}
        />
      )}
    </>
  );
}

export default SearchIndustryWithChild;
