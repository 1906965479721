import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";

import styled from "styled-components";

const SpacedAccordion = styled(Accordion)`
  margin-bottom: 16px;
`;

const vendor1 = [
  {
    meta: "Basic Info",
    type: "new",
    data: {
      name: "Some Vendor",
      email: "Vendor@email.com",
      phone: "995533778800",
      address: "cyber city, gurugram, haryana, india",
    },
  },
  {
    meta: "Past Work",
    type: "new",
    data: {
      name: "Some Vendor",
      email: "Vendor@email.com",
      phone: "995533778800",
    },
  },
  {
    meta: "Product Code",
    type: "edit",
    data: {
      name: "Updated Vendor",
      email: "update.vendor@email.com",
      phone: "995599778877",
    },
    past_data: {
      name: "Some Vendor",
      email: "vendor@email.com",
      phone: "995533778800",
    },
  },
];
const vendor2 = [
  {
    meta: "Basic Info",
    type: "new",
    data: {
      name: "Some Vendor",
      email: "Vendor@email.com",
      phone: "995533778800",
      address: "cyber city, gurugram, haryana, india",
    },
  },
  {
    meta: "Past Work",
    type: "new",
    data: {
      name: "Some Vendor",
      email: "Vendor@email.com",
      phone: "995533778800",
    },
  },
  {
    meta: "Bank Details",
    type: "new",
    data: {
      name: "Some Vendor",
      email: "Vendor@email.com",
      phone: "995533778800",
      address: "cyber city, gurugram, haryana, india",
    },
  },
  {
    meta: "Product Code",
    type: "edit",
    data: {
      name: "Updated Vendor",
      email: "update.vendor@email.com",
      phone: "995599778877",
    },
    past_data: {
      name: "Some Vendor",
      email: "vendor@email.com",
      phone: "995533778800",
    },
  },
  {
    meta: "Testimonials",
    type: "new",
    data: {
      "SPOC Name": "qwerty",
      Email: "qwerty@email.com",
      "Contact No": "9867542310",
      "Company Name": "Qwerty pvt. ltd.",
      "Service Delivered": "43",
      "Product Code": "qwe-123-rty-456",
    },
  },
];

export default function ProfileApproval() {
  return (
    <>
      <Container>
        <Stack
          mb={2}
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Typography variant="h4" gutterBottom>
            Profile Approval
          </Typography>
        </Stack>

        {/* <Card> */}
        {/* <div> */}
        <Card style={{ marginBottom: "30px" }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h4">Vendor 1</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "14px",
                  marginBottom: "20px",
                }}
              >
                {/* mapping over the data to show in the UI  */}
                {vendor1.map((item, ind) => (
                  <Card
                    style={{
                      boxShadow: "0px 0px 8px  #cfcece",
                      minWidth: "32%",
                      padding: "20px 30px",
                    }}
                    key={ind}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "space-between",
                        marginBottom: "12px",
                      }}
                    >
                      <div
                        style={{
                          padding: "8px",
                          backgroundColor: "rgb(5 202 103)",
                          borderRadius: "5px",
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "10px",
                        }}
                      >
                        <FeedOutlinedIcon color="white" />
                      </div>
                      <div>
                        <h2>{item.meta || "Title:"}</h2>
                        <p style={{ color: "gray" }}>
                          Last updated at May 2024
                        </p>
                      </div>
                    </div>

                    <hr />

                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {Object.entries(item.data).map(([key, value]) => (
                        <div key={key}>
                          <strong>{key}: </strong>
                          {value}
                        </div>
                        // <p key={key}>
                        //   <span>{key}</span> :{" "}
                        //   <span style={{ textDecoration: "underline" }}>
                        //     {value}
                        //   </span>
                        // </p>
                      ))}
                    </div> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "30px",
                      }}
                    >
                      <p>
                        <span>Key</span> : <span>Value</span>
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>
                        <span>Key</span> : <span>Value</span>
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                        justifyContent: "center",
                        margin: "30px 0px 0px 0px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => console.log("Reject Button Clicked")}
                      >
                        Reject
                      </Button>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => console.log("Approve Button Clicked")}
                        style={{ color: "white" }}
                      >
                        Approve
                      </Button>
                    </div>
                  </Card>
                ))}

                {/* <Card
                  style={{
                    boxShadow: "0px 0px 8px  #cfcece",
                    width: "49%",
                    padding: "10px 20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "12px",
                    }}
                  >
                    <h2>Title:</h2>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => console.log("Approve Button Clicked")}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => console.log("Reject Button Clicked")}
                      >
                        Reject
                      </Button>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>
                      <span>Key</span> :{" "}
                      <span style={{ textDecoration: "underline" }}>Value</span>
                    </p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>
                      <span>Key</span> : <span>Value</span>
                    </p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>
                      <span>Key</span> : <span>Value</span>
                    </p>
                  </div>
                </Card> */}
              </div>
            </AccordionDetails>
          </Accordion>
        </Card>

        <Card style={{ marginBottom: "30px" }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography variant="h4">Vendor 2</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "stretch",
                  flexWrap: "wrap",
                  gap: "14px",
                  marginBottom: "20px",
                }}
              >
                {/* mapping over the data to show in the UI  */}
                {vendor2.map((item, ind) => (
                  <Card
                    style={{
                      boxShadow: "0px 0px 8px  #cfcece",
                      minWidth: item.past_data ? "48%" : "32%",
                      padding: "20px 30px",
                      maxWidth: item.past_data ? "48%" : "32%",
                    }}
                    key={ind}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "space-between",
                        marginBottom: "12px",
                      }}
                    >
                      <div
                        style={{
                          padding: "8px",
                          backgroundColor: "rgb(5 202 103)",
                          borderRadius: "5px",
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "10px",
                        }}
                      >
                        <FeedOutlinedIcon color="white" />
                      </div>
                      <div>
                        <h2>{item.meta || "Title:"}</h2>
                        <p style={{ color: "gray" }}>
                          Last updated at May 2024
                        </p>
                      </div>
                    </div>

                    <hr />

                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {Object.entries(item.data).map(([key, value]) => (
                        <div key={key}>
                          <strong>{key}: </strong>
                          {value}
                        </div>
                        // <p key={key}>
                        //   <span>{key}</span> :{" "}
                        //   <span style={{ textDecoration: "underline" }}>
                        //     {value}
                        //   </span>
                        // </p>
                      ))}
                    </div> */}
                    {Object.entries(item.data).map(([key, value]) => {
                      // console.log(`${key}: ${value}`);
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "30px",
                            gap: "8px",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <Typography variant="h6">{key}: </Typography> &nbsp;
                            <p>
                              {item.past_data && (
                                <>
                                  <strike>{item?.past_data[key]}</strike>
                                </>
                              )}
                              <p>{value}</p>
                            </p>
                          </div>
                          {item.type === "edit" && (
                            <>
                              <div>
                                <Button
                                  variant="outlined"
                                  color="success"
                                  style={{
                                    padding: "5px 15px",
                                    minWidth: "auto",
                                  }}
                                >
                                  &#10007;
                                </Button>
                                <Button
                                  variant="contained"
                                  color="success"
                                  style={{
                                    color: "white ",
                                    padding: "5px 15px",
                                    minWidth: "auto",
                                    marginLeft: "6px",
                                  }}
                                >
                                  &#10003;
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}

                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>
                        <span>Key</span> : <span>Value</span>
                      </p>
                    </div> */}

                    {item.type !== "edit" ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                            justifyContent: "center",
                            margin: "30px 0px 0px 0px",
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="success"
                            onClick={() => console.log("Reject Button Clicked")}
                          >
                            Reject
                          </Button>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() =>
                              console.log("Approve Button Clicked")
                            }
                            style={{ color: "white" }}
                          >
                            Approve
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ textAlign: "right", marginTop: "30px" }}>
                          <Button
                            variant="contained"
                            color="success"
                            style={{ color: "white" }}
                          >
                            Done
                          </Button>
                        </div>
                      </>
                    )}
                  </Card>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Container>
    </>
  );
}
