import { Rating } from "@mui/material";
import React from "react";
import StarIcon from "@mui/icons-material/Star";
import { Box } from "@mui/system";
import { ratingLabels } from "../../utils/constant";

import { useStyles } from "./Form.theme";

function Ratings({ value = 0, onChange, onBlur, label, name, errorText }) {
  const [hover, setHover] = React.useState(-1);
  const classes = useStyles();
  return (
    <>
      <label className={classes.formLabel}>{label}</label>
      <Box
        sx={{
          width: 200,
          display: "flex",
          alignItems: "center",
          height: "50px",
          lineHeight: "50px",
        }}
      >
        <Rating
          name="hover-feedback"
          size="large"
          value={value}
          precision={0.5}
          onChange={(event, newValue) => {
            onChange({
              target: {
                name,
                value: newValue,
              },
            });
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          onBlur={onBlur}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
        {value !== null && (
          <Box sx={{ ml: 2 }}>{ratingLabels[hover !== -1 ? hover : value]}</Box>
        )}
      </Box>
      {errorText && <span className={classes.formErrorStyle}>{errorText}</span>}
    </>
  );
}

export default Ratings;
