import { Container, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from "../../components/Page";
import { tabChange } from "../../store/slices/project/project.slice";
import OverView from "./components/Overview/OverView";
import ProjectFeedback from "./components/ProjectFeedback";
import Proposal from "./components/Proposal";
import Ticket from "./components/Ticket";
import Calender from "../../components/_dashboard/app/Calender/Calender";
import Phases from "./components/Phases";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Container sx={{ my: 4, mx: "auto" }}>
      {value === index ? children : null}
    </Container>
  );
}

function ProjectDetails() {
  const selectedTab = useSelector(({ project }) => project.selectedTab);
  const dispatch = useDispatch();

  function handleTabChange(e, value) {
    dispatch(tabChange(value));
  }

  return (
    <Page title="Dashboard: Blog | EMB">
      <Container sx={{ margin: 0 }}>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Expand My Business
          </Typography>
          <Breadcrumb />
        </Stack>
        <Grid>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Overview" />
              <Tab label="Proposals" />
              <Tab label="Phases" />
              <Tab label="Calender" />
              <Tab label="Ratings" />
              <Tab label="Support" />
            </Tabs>
          </Box>
          <TabPanel value={selectedTab} index={0}>
            <OverView />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <Proposal />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <Phases />
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <Calender />
          </TabPanel>
          <TabPanel value={selectedTab} index={4}>
            <ProjectFeedback />
          </TabPanel>
          <TabPanel value={selectedTab} index={5}>
            <Ticket />
          </TabPanel>
        </Grid>
      </Container>
    </Page>
  );
}

export default ProjectDetails;
