import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  button: {
    marginBottom: "2rem",
    marginRight: "2rem",
  },
  table: {
    width: "100%",
    textAlign: "left",
    border: "none!important",
  },
  thead: {
    color: "rgb(99, 115, 129)",
    backgroundColor: " rgb(244, 246, 248)",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  },
  th: {
    border: "none!important",
    borderBottom: "1px solid rgba(241, 243, 244, 1)!important",
    color: "#212B36",
    lineHeight: "40px",
    "&:nth-child(1)": {
      width: "10%",
      paddingLeft: "1rem",
    },
    "&:nth-child(3)": {
      width: "20%",
      textAlign: "center",
    },
  },
  td: {
    border: "none!important",
    borderBottom: "1px solid rgba(241, 243, 244, 1)!important",
    color: "#212B36",
    lineHeight: "40px",
    "&:nth-child(1)": {
      width: "10%",
      paddingLeft: "1rem",
    },
    "&:nth-child(3)": {
      width: "20%",
    },
  },
  input: {
    // background: "red",
  },
  dropdownTable: {
    padding: "1rem",
    "& $table": {
      borderSpacing: " 0",
      border: "1px solid black",
      "& $tr:last-child": {
        "& $td": {
          borderBottom: "0",
        },
      },

      "& th": {
        margin: "0",
        padding: "0.5rem",
        // borderBottom: "1px solid black",
        // borderRight: "1px solid black",
        "& $input": {
          fontSize: "1rem",
          padding: "0",
          margin: "0",
          border: "0",
        },
      },
      "& $th:last-child": {
        borderRight: "0",
      },

      "& td": {
        margin: "0",
        padding: "0.5rem",
        // borderBottom: "1px solid black",
        // borderRight: "1px solid black",

        "& $input": {
          fontSize: "1rem",
          padding: "0",
          margin: "0",
          border: "0",
        },
      },
      "& $td:last-child": {
        borderRight: "0",
      },
    },
    pagination: {
      padding: "0.5rem",
    },
  },
});
