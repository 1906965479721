import FilterBoolean from "./FilterBoolean";
import FilterDate from "./FilterDate";
import FilterDropdown from "./FilterDropdown";
import FilterInput from "./FilterInput";

function FilterElement({ type, ...rest }) {
  return (
    <>
      {type === "text" && <FilterInput {...rest} />}
      {type === "dropdown" && <FilterDropdown {...rest} />}
      {type === "date" && <FilterDate {...rest} />}
      {type === "boolean" && <FilterBoolean {...rest} />}
    </>
  );
}

export default FilterElement;
