import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllTechstackHandler,
  addTechstackHandler,
  deleteTechstackHandler,
  updateTechstackHandler,
  fetchAllTechstackWithPaginationHandler,
  fetchAllTechStackByIDsHandler,
} from "./techstack.thunk";

const INIT_STATE = {
  techstack: [],
  isLoading: false,
  totaltech: 0,
};

export const fetchAllTechstackThunk = createAsyncThunk(
  "fetchAllTechstack",
  fetchAllTechstackHandler
);
export const fetchAllTechstackWithPaginationThunk = createAsyncThunk(
  "fetchAllTechstackWithoutPagination",
  fetchAllTechstackWithPaginationHandler
);

export const AddTechstackThunk = createAsyncThunk(
  "addTechStack",
  addTechstackHandler
);

export const deleteTechstackThunk = createAsyncThunk(
  "deleteTechstack",
  deleteTechstackHandler
);

export const updateTechstackThunk = createAsyncThunk(
  "updateTechstack",
  updateTechstackHandler
);

const techstackSlice = createSlice({
  name: "techstack",
  initialState: INIT_STATE,
  reducers: {
    startTechstackLoader: (state) => {
      state.isLoading = true;
    },
    stopTechstackLoader: (state) => {
      state.isLoading = false;
    },
    setTechStacks: (state, payload) => {
      state.techstack = payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchAllTechstackThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllTechstackThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.techstack = action.payload.data;
        state.totaltech = action.payload.total;
      })
      .addCase(fetchAllTechstackThunk.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(fetchAllTechstackWithPaginationThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchAllTechstackWithPaginationThunk.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.techstack = action.payload.data;
          state.totaltech = action.payload.total;
        }
      )
      .addCase(fetchAllTechstackWithPaginationThunk.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(updateTechstackThunk.fulfilled, (state, action) => {
        var ndx = state.techstack.findIndex(
          (e) => e._id === action.payload._id
        );
        state.techstack[ndx] = action.payload;
        return state;
      })
      .addCase(AddTechstackThunk.fulfilled, (state, action) => {
        // state.techstack.push(action.payload);
        // state.totaltech = state.totaltech + 1;
      })
      .addCase(deleteTechstackThunk.fulfilled, (state, action) => {
        // var id = action.payload;
        // var newData = state.techstack.filter((ele) => ele._id !== id);
        // state.techstack = newData;
        // state.totaltech = state.totaltech - 1;
        // return state;
      });
  },
});

export const { startTechstackLoader, stopTechstackLoader, setTechStacks } =
  techstackSlice.actions;

export default techstackSlice.reducer;
