// import { putPersonalInfoApi } from "@/Utils/apis_utils";

import { getSelectedServicesList, getServiceListApi, postServicesApi } from "../../../utils/apis.utils";
import { showSuccessToast } from "../toast/toast.slice";

// export const uploadPersonalInfoHandler = async (data, thunkApi) => {
//   const { vendor } = thunkApi.extra.apiService;
//   const response = await vendor.post(updatePasswordsApi, data);
//   return response;
// };

// export const updatePersonalInfoHandler = async (
//   { id, data },
//   thunkApi
// ) => {
//   const { vendor } = thunkApi.extra.apiService;
//   // const response = await vendor.put(putPersonalInfoApi + "/new", { data });
//   const response = await vendor.put(updatePasswordsApi + "/new", { data });
//   thunkApi.dispatch(
//     showSuccessToast({
//       message: response.message,
//       vertical: "top",
//       horizontal: "right",
//     })
//   );
//   return response.data;
// };

export const getServicesListHandler = async (_, thunkApi) => {
  try {
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.get(getServiceListApi);
    
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postServicesHandler = async (data, thunkApi) => {
  try{
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.post(postServicesApi, {
      data:{
        service_ids:data
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const updateServicesHandler = async (data, thunkApi) => {
  try{
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.put(postServicesApi, {
      data:{
        service_ids:data.selectedServices,
        custom_services: data.customServices,
      }
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const getSelectedServicesListHandler = async (_, thunkApi) => {
  try {
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.get(getSelectedServicesList);
    
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// export const getPersonalInfoHandler = async (_: any, thunkApi: any) => {};
