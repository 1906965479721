import {
  getAllVenderForFinance,
  approveFinance,
  rejectFinance,
  getAllVenderForFinanceNew,
} from "../../../utils/apis.utils";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import {
  fetchFinanceApprovalThunk,
  startFinanceLoader,
  stopFinanceLoader,
} from "./financeApproval.slice";

export const fetchFinanceApprovalHandler = async (page = 1, thunkApi) => {
  thunkApi.dispatch(startFinanceLoader());
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(
    getAllVenderForFinanceNew + "?limit=10&page=" + page
  );
  thunkApi.dispatch(stopFinanceLoader());
  return {
    data: response.data,
  };
};

export const approveFinanceHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startFinanceLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.put(approveFinance, data);
    thunkApi.dispatch(stopFinanceLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchFinanceApprovalThunk());
    return response.data;
  } catch (error) {
    thunkApi.dispatch(showFailureToast({ message: error }));
    return Promise.reject(error);
  } finally {
    thunkApi.dispatch(stopFinanceLoader());
  }
};

export const rejectFinanceHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startFinanceLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.put(rejectFinance, data);
    thunkApi.dispatch(stopFinanceLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchFinanceApprovalThunk());
    return response.data;
  } catch (error) {
    thunkApi.dispatch(showFailureToast({ message: error }));
    return Promise.reject(error);
  } finally {
    thunkApi.dispatch(stopFinanceLoader());
  }
};
