import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCvsByPartnerThunk,
  fetchRaLeadByIdThunk,
} from "../../store/slices/ra/ra.slice";
import { useParams } from "react-router-dom";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RaCvTable from "./RaCvTable";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import Modal from "../../components/Modal/Modal";

const rows = [
  {
    name: "Ebslon Private Limited",
    total_cvs: 2,
    applicants: [
      {
        id: 1,
        applicant_name: "Snow",
        skill: "react",
        vendor_budget: 35000,
        year_of_exp: 3,
        cv_filename: "link",
        emb_cv_filename: "link",
      },
      {
        id: 2,
        applicant_name: "Snow",
        skill: "react",
        vendor_budget: 35000,
        year_of_exp: 3,
        cv_filename: "link",
        emb_cv_filename: "link",
      },
    ],
  },
  {
    name: "Alphonics",
    total_cvs: 1,
    applicants: [
      {
        id: 1,
        applicant_name: "Snow",
        skill: "react",
        vendor_budget: 35000,
        year_of_exp: 3,
        cv_filename: "link",
        emb_applicant_status: "pending",
        emb_cv_filename: "link",
        client_applicant_status: "pending",
        client_applicant_reason: "",
      },
    ],
  },
];

const accordionSummaryStyle = {
  backgroundColor: "#f0f0f0", // Example background color
  color: "black", // Example text color
  fontWeight: "bold", // Example font weight
  "& .MuiIconButton-root": {
    // Style the expand icon
    color: "black", // Example icon color
  },
};
const accordionStyle = {
  marginBottom: "10px",
};

const RaCvs = () => {
  const { partner_cvs, lead } = useSelector(({ ra }) => ra);
  const [formatted_CVS, set_formatted_CVS] = useState([]);

  // console.log('formatted_CVS',formatted_CVS, partner_cvs);

  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRaLeadByIdThunk(params.leadId));
    dispatch(fetchAllCvsByPartnerThunk(params.leadId));
  }, [params.leadId]);

  useEffect(() => {
    if (Object.keys(partner_cvs).length > 0) {
      var formatted_CVS = Object.keys(partner_cvs).map((key) => {
        return {
          name: key,
          ...partner_cvs[key],
        };
      });
      set_formatted_CVS(formatted_CVS);
    }
  }, [partner_cvs]);

  return (
    <>
      <Box>
        {formatted_CVS?.length > 0 ? (
          // {rows?.length > 0 ? (
          <div>
            {formatted_CVS.map((cvs) => (
              // {rows.map((cvs) => (
              <Accordion sx={accordionStyle}>
                <AccordionSummary
                  sx={accordionSummaryStyle}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "98%",
                    }}
                  >
                    <span>{cvs.name}</span>
                    <span>{cvs.applicants.length} Cvs</span>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <RaCvTable cvs={cvs.applicants} />
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        ) : (
          <>
            <SearchNotFound />
          </>
        )}
      </Box>
    </>
  );
};

export default RaCvs;
