import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Grid,
  Button,
  Stack,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Paper,
} from "@mui/material";

const columns = [
  {
    id: "service_name",
    label: "Service Name",
    align: "left",
    minWidth: 200,
  },
  {
    id: "service_type",
    label: "Service Type",
    align: "left",
    minWidth: 150,
  },
  {
    id: "max_price",
    label: "Max Price",
    align: "left",
    minWidth: 100,
  },
  {
    id: "min_price",
    label: "Min Price",
    align: "left",
    minWidth: 100,
  },
  {
    id: "notes",
    label: "Notes",
    align: "left",
    minWidth: 150,
  },
];

export default function ShortListServiceModel({ closeModal, data }) {
  return (
    <div style={{ padding: "0" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="customized sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      // top: 57,
                      minWidth: column.minWidth,
                      fontWeight: 900,
                      fontSize: "18px",
                      backgroundColor: "#f3f6f8",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row) => (
                <TableRow>
                  <TableCell align="left">{row.service_name}</TableCell>
                  <TableCell align="left">{row.service_type}</TableCell>
                  <TableCell align="left">{row.max_price}</TableCell>
                  <TableCell align="left">{row.min_price}</TableCell>
                  <TableCell align="left">{row.notes}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
