import React from "react";

import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Chip,
  Avatar,
} from "@mui/material";
import { css } from "@emotion/css";
import { mockImgCover } from "../../../utils/mockImages";
import { styled } from "@mui/material/styles";
import UserMoreMenu from "./../user/UserMoreMenu";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const ProjectCard = ({ project }) => {
  const { name, cover, price, colors, status, priceSale } = project;

  return (
    <Card
      className={css`
        padding: 24px;
        border-radius: 4px;
      `}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex" }}>
          <Box
            component="img"
            alt="demo"
            src={mockImgCover(4)}
            sx={{ width: 42, height: 42, borderRadius: "4px" }}
          />
          <Box
            className={css`
              margin-left: 12px;
            `}
          >
            <Typography
              className={css`
                font-weight: 600;
                font-size: 16px;
              `}
              variant="body2"
              sx={{ maxWidth: 160 }}
              noWrap
            >
              Project 1
            </Typography>
            <Typography
              className={css`
                font-size: 14px;
              `}
              sx={{ color: "text.secondary", maxWidth: 160 }}
              noWrap
            >
              5 days
            </Typography>
          </Box>
        </Box>
        <UserMoreMenu row={project} />
      </Box>

      <Typography
        className={css`
          margin-top: 24px;
        `}
        variant="body1"
        sx={{ color: "text.secondary", maxWidth: 550 }}
      >
        Lorem Ipsum is simply dummy text of the printing and printing and
      </Typography>
      <Box>
        <Stack
          className={css`
            margin-top: 28px;
            margin-bottom: 12px;
          `}
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Typography
            className={css`
              font-size: 14px;
            `}
          >
            Progress
          </Typography>
          <Typography
            className={css`
              font-size: 14px;
            `}
          >
            60%
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }}>
          <BorderLinearProgress variant="determinate" value={50} />
        </Box>
      </Box>
      <Stack
        className={css`
          margin-top: 14px;
        `}
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Chip
          className={css`
            font-size: 12px;
            border: none;
            background-color: lightcyan;
          `}
          size="small"
          variant="outlined"
          label="Ready"
          color="primary"
        />
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Avatar
            sx={{ width: 22, height: 22 }}
            alt="Remy Sharp"
            src={mockImgCover(4)}
          />
          <Avatar
            sx={{ width: 22, height: 22 }}
            alt="Travis Howard"
            src={mockImgCover(4)}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

export default ProjectCard;
