import {
  getAllTechstack,
  addTechstack,
  deleteTechstack,
  updateTechstack,
  getAllTechstackAndTagsById,
} from "../../../utils/apis.utils";
import {
  startSkillsLoader,
  stopSkillsLoader,
  // fetchAllSkillsThunk,
} from "../skills/skills.slice";
import { fetchAllTechstackWithPaginationThunk } from "./techstack.slice";

import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import { setTags } from "../tag/tag.slice";

export const fetchAllTechstackHandler = async (page = 1, thunkApi) => {
  thunkApi.dispatch(startSkillsLoader());
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(getAllTechstack);
  thunkApi.dispatch(stopSkillsLoader());
  return {
    data: response.data,
    total: response.total,
  };
};
export const fetchAllTechstackWithPaginationHandler = async (
  page = 1,
  thunkApi
) => {
  thunkApi.dispatch(startSkillsLoader());
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(
    getAllTechstack + "?is_pagination=1&page=" + page
  );
  thunkApi.dispatch(stopSkillsLoader());
  return {
    data: response.data,
    total: response.total,
  };
};

export const deleteTechstackHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startSkillsLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.delete(deleteTechstack + "/" + data.id);
    thunkApi.dispatch(stopSkillsLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllTechstackWithPaginationThunk(data.page));

    // return id;
  } catch (err) {
    thunkApi.dispatch(stopSkillsLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopSkillsLoader());
  }
};

export const addTechstackHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startSkillsLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(addTechstack, { name: data.name });
    thunkApi.dispatch(stopSkillsLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllTechstackWithPaginationThunk(data.page));
    // return response.data;
  } catch (err) {
    thunkApi.dispatch(stopSkillsLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopSkillsLoader());
  }
};

export const updateTechstackHandler = async (data, thunkApi) => {
  var id = data.id;
  var apiData = {
    name: data.name,
  };

  try {
    thunkApi.dispatch(startSkillsLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(updateTechstack + "/" + id, apiData);
    thunkApi.dispatch(stopSkillsLoader());

    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopSkillsLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopSkillsLoader());
  }
};
