import {
  getAllShortlistedVendors,
  getShortlistedVendorById,
  searchShortlistedVendors,
  getRecommendedVendors,
  getShortlistedVendors,
  addVendorAsSelected,
  deleteVendorAsSelected,
  getServiceTeckStacksApi,
  getServiceTagsApi,
  fetchVendorShortlistedProfilesByFilterApi,
  getAllTechstackAndTagsById,
} from "../../../utils/apis.utils";
import {
  startShortlistLoader,
  stopShortlistLoader,
} from "./shortlisting.slice";
import { createQueryString } from "../../../utils/global/global";
import { showSuccessToast, showFailureToast } from "../toast/toast.slice";

export const fetchAllShortListedVendorsHandler = async (page = 1, thunkApi) => {
  try {
    thunkApi.dispatch(startShortlistLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(getAllShortlistedVendors + "?page=" + page);
    return {
      data: response.data,
      total: response.total,
    };
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopShortlistLoader());
  }
};

export const fetchShortlistedVendorDetailsByIdHandler = async (
  id,
  thunkApi
) => {
  try {
    thunkApi.dispatch(startShortlistLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(
      getShortlistedVendorById + "?deal_id=" + id
    );
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopShortlistLoader());
  }
};

export const searchAllShortlistedVendorsHandler = async (
  { page = 1, search },
  thunkApi
) => {
  try {
    thunkApi.dispatch(startShortlistLoader());
    const { user } = thunkApi.extra.apiService;
    const query = createQueryString({
      search_term: search,
      page,
    });
    const response = await user.get(searchShortlistedVendors + query);
    return {
      data: response.data,
      total: response.total,
    };
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopShortlistLoader());
  }
};

export const getRecommendedVendorsHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startShortlistLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(
      getRecommendedVendors +
        "?deal_id=" +
        data.id +
        "&page=" +
        data.page +
        "&limit=" +
        data.rowperpage
    );
    return {
      data: response.data,
      total: response.total,
    };
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopShortlistLoader());
  }
};

export const getShortlistedVendorsHandler = async (id, thunkApi) => {
  try {
    thunkApi.dispatch(startShortlistLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(getShortlistedVendors + "?deal_id=" + id);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopShortlistLoader());
  }
};

export const addVendorAsShortlistedHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startShortlistLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(addVendorAsSelected, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );

    return data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopShortlistLoader());
  }
};

export const deleteVendorAsShortlistedHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startShortlistLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.delete(deleteVendorAsSelected, { data });
    thunkApi.dispatch(stopShortlistLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return data;
  } catch (err) {
    thunkApi.dispatch(stopShortlistLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopShortlistLoader());
  }
};

export const fetchAllTechStackByServiceHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const query = createQueryString({
    service_id: id,
  });
  const response = await user.get(getServiceTeckStacksApi + query);
  return response.data;
};

export const fetchAllTagsByServiceHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const query = createQueryString({
    service_id: id,
  });
  const response = await user.get(getServiceTagsApi + query);
  return response.data;
};

export const fetchVendorShortlistingProfileByFilterHandler = async (
  data,
  thunkApi
) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.post(
    fetchVendorShortlistedProfilesByFilterApi,
    data
  );

  // return response.data;
  return {
    data: response.data,
    total: response.total,
  };
};

export const fetchAllTechStackByIDsHandler = async (ids, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(
    `${getAllTechstackAndTagsById}?service_ids=[${ids.join(",")}]`
  );
  const responseData = response.data;
  return responseData;
};
