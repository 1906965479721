import { Autocomplete, Card, Chip, ListItem } from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import Input from "../Form/Input";
import _, { values } from "lodash";
import { searchServiceHandler } from "../../utils/global/vendor.global";
import TreeList from "./TreeList.js";
import { Box } from "@mui/system";

function SearchCountry({
  onChange,
  onBlur,
  label,
  placeholder,
  formatter,
  value = [],
  errorText,
  handler = searchServiceHandler,
  name,
}) {
  const [isOpen, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);

  const handleTextChange = useMemo(() => {
    const _fn = _.debounce((value) => {
      if (!value) return;
      handler(value).then((response) => {
        if (typeof formatter === "function") {
          if (response.data?.length === 0) {
            return setOpen(false);
          }
          setOptions(formatter(response.data));
          setOpen(true);
        }
      });
    }, 500);

    return function handleChange(e) {
      const value = e.target.value;
      setSearch(value);
      _fn(value);
    };
  }, []);

  function handleDelete(idx) {
    const copiedData = value.filter((ele) => (ele.location || ele) !== idx);
    onChange({
      target: {
        name: name,
        value: copiedData,
      },
    });
  }

  function handleItemClick(e) {
    onChange({
      target: {
        name: name,
        value: [...value, { ...e.target.value }],
      },
    });
    setSearch("");
  }

  // console.log("value split:", value.split(","));

  return (
    <>
      <Input
        label={label}
        inputProps={{
          placeholder: placeholder,
          onChange: handleTextChange,
          name: "treeTextChange",
        }}
        value={search}
        errorText={errorText}
      />
      {isOpen && (
        <Card>
          <Box p={2}>
            {options.map((d) => (
              <TreeList
                {...d}
                onChange={handleItemClick}
                onBlur={onBlur}
                setOpen={setOpen}
                name={name}
                valueName="location"
              />
            ))}
          </Box>
        </Card>
      )}
      {value.map((val) => (
        <ListItem sx={{ display: "contents" }}>
          <Chip
            label={val?.location || val}
            onDelete={() => handleDelete(val?.location || val)}
            sx={{ marginRight: "1rem" }}
          />
        </ListItem>
      ))}
    </>
  );
}

export default SearchCountry;
