import React, { useState } from "react";
import ListHead from "../../Common/ListHead";
import Scrollbar from "./../../Scrollbar";
import { filter } from "lodash";
import USERLIST from "../../../mock-data/user";

import Label from "./../../Label";
import UserMoreMenu from "./../user/UserMoreMenu";
import { mockImgCover } from "./../../../utils/mockImages";
import { styled } from "@mui/material/styles";
import {
  Card,
  Box,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { css } from "@emotion/css";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteProject } from "../../../store/slices/project/project.slice";

const TABLE_HEAD = [
  { id: "project", label: "Project", alignRight: false },
  { id: "tasks", label: "Tasks", alignRight: false },
  { id: "members", label: "Members", alignRight: false },
  { id: "progress", label: "Progress", alignRight: false },
  { id: "" },
];

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const ProjectTable = ({ data }) => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const dispatch = useDispatch();

  return (
    <div>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, padding: "20px" }}>
            <Table>
              <ListHead
                // order={order}
                // orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={USERLIST.length}
                // numSelected={selected.length}
                // onRequestSort={handleRequestSort}
                // onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const { id, name } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            // onChange={(event) => handleClick(event, name)}
                          />
                        </TableCell> */}
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Box
                              component="img"
                              alt="demo"
                              src={mockImgCover(4)}
                              sx={{
                                width: 42,
                                height: 42,
                                borderRadius: "4px",
                              }}
                            />
                            <Link
                              to="/dashboard/app/projects/1"
                              className={css`
                                text-decoration: none;
                                color: black;
                              `}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Link>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">5 days</TableCell>
                        <TableCell align="left">
                          <Stack direction="row" spacing={1}>
                            <Avatar
                              sx={{ width: 22, height: 22 }}
                              alt="Remy Sharp"
                              src={mockImgCover(4)}
                            />
                            <Avatar
                              sx={{ width: 22, height: 22 }}
                              alt="Travis Howard"
                              src={mockImgCover(4)}
                            />
                            <div
                              className={css`
                                border: none;
                                border-radius: 50%;
                                height: 22px;
                                width: 22px;
                                display: inline-block;
                                background-color: #f1f2f3;
                                text-align: center;
                                font-weight: 600;
                              `}
                            >
                              +2
                            </div>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={2}
                          >
                            <Box sx={{ flexGrow: 1, marginTop: "8px" }}>
                              <BorderLinearProgress
                                variant="determinate"
                                value={50}
                              />
                            </Box>
                            <Typography
                              className={css`
                                font-size: 14px;
                              `}
                            >
                              60%
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="right">
                          <UserMoreMenu
                            handleDelete={() => dispatch(deleteProject(row.id))}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        {/* <TablePagination
    rowsPerPageOptions={[5, 10, 25]}
    component="div"
    count={USERLIST.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  /> */}
      </Card>
    </div>
  );
};

export default ProjectTable;
