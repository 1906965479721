import React, { useState } from "react";
import { Stack, Box } from "@mui/material";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import GridViewIcon from "@mui/icons-material/GridView";
import UserListToolbar from "../../Common/ListSearchbar";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const ProjecMenu = ({ type, setType, handleSearchChange }) => {
  const [alignment, setAlignment] = useState("all");

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment) {
      setAlignment(newAlignment);
    }
  };

  const handleType = (event, newType) => {
    setType(newType);
  };

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      margin: theme.spacing(0.5),
      border: 0,
      "&.Mui-disabled": {
        border: 0,
      },
      "&:not(:first-of-type)": {
        borderRadius: theme.shape.borderRadius,
      },
      "&:first-of-type": {
        borderRadius: theme.shape.borderRadius,
      },
    },
  }));

  const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    "&.Mui-selected": {
      color: "white",
      backgroundColor: "#00ab55",
      // width: "80px",
      "&:hover": {
        background: "#00ab55",
      },
    },
    "&.MuiToggleButton-root": {
      width: "60px",
    },
    // "&:hover": {
    //   background: "#00ab55",
    // },
  }));
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <div>
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            border: (theme) => `1px solid ${theme.palette.divider}`,
            flexWrap: "wrap",
          }}
        >
          <StyledToggleButtonGroup
            size="small"
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="projects"
          >
            <StyledToggleButton
              // className={css`
              //   &:active {
              //     color: "#00ab55";
              //   }
              // `}
              // sx={{ color: "#00ab55" }}
              value="all"
              aria-label="all project"
            >
              All
            </StyledToggleButton>
            <StyledToggleButton
              color="success"
              value="active"
              aria-label="active project"
            >
              Active
            </StyledToggleButton>
            <StyledToggleButton
              color="success"
              value="closed"
              aria-label="closed project"
            >
              Closed
            </StyledToggleButton>
            <StyledToggleButton
              color="success"
              value="pending"
              aria-label="pending project"
            >
              Pending
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Paper>
      </div>
      <Stack direction="row" spacing={1} alignItems="center">
        <Box>
          <UserListToolbar
            placeholder="Search projects"
            handleSearchChange={handleSearchChange}
          />
        </Box>
        <Box>
          <ToggleButtonGroup
            value={type}
            size="small"
            exclusive
            onChange={handleType}
            aria-label="view projects"
          >
            <ToggleButton value="table" aria-label="table">
              <FormatListNumberedIcon />
            </ToggleButton>
            <ToggleButton value="grid" aria-label="grid">
              <GridViewIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ProjecMenu;
