import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllTagHandler,
  addTagHandler,
  deleteTagHandler,
  updateTagHandler,
  fetchAllTagWithPaginationHandler,
} from "./tag.thunk";

const INIT_STATE = {
  tagData: [],
  isLoading: false,
  totaltag: 0,
};

export const fetchAllTagThunk = createAsyncThunk(
  "fetchAllTag",
  fetchAllTagHandler
);
export const fetchAllTagWithPaginationThunk = createAsyncThunk(
  "fetchAllTagWithPagination",
  fetchAllTagWithPaginationHandler
);

export const addTagThunk = createAsyncThunk("addTag", addTagHandler);

export const deleteTagThunk = createAsyncThunk("deleteTag", deleteTagHandler);

export const updateTagThunk = createAsyncThunk("updateTag", updateTagHandler);

const tagSlice = createSlice({
  name: "tag",
  initialState: INIT_STATE,
  reducers: {
    startTagLoader: (state) => {
      state.isLoading = true;
    },
    stopTagLoader: (state) => {
      state.isLoading = false;
    },
    setTags: (state, payload) => {
      state.tagData = payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchAllTagThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllTagThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tagData = action.payload.data;
        state.totaltag = action.payload.total;
      })
      .addCase(fetchAllTagThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAllTagWithPaginationThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllTagWithPaginationThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tagData = action.payload.data;
        state.totaltag = action.payload.total;
      })
      .addCase(fetchAllTagWithPaginationThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateTagThunk.fulfilled, (state, action) => {
        var ndx = state.tagData.findIndex((e) => e._id === action.payload._id);
        state.tagData[ndx] = action.payload;
        return state;
      })
      .addCase(addTagThunk.fulfilled, (state, action) => {
        // state.tagData.push(action.payload);
        // state.totaltag = state.totaltag + 1;
      })
      .addCase(deleteTagThunk.fulfilled, (state, action) => {
        // var id = action.payload;
        // var newData = state.tagData.filter((ele) => ele._id !== id);
        // state.tagData = newData;
        // state.totaltag = state.totaltag - 1;
        // return state;
      });
  },
});

export const { startTagLoader, stopTagLoader, setTags } = tagSlice.actions;

export default tagSlice.reducer;
