import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Drawer,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik, FormikProvider, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Divider, Pagination, Table } from "antd";
import {
  fetchAllRaEnableVendorThunk,
  fetchRaLeadByIdThunk,
  postShorlistedVendorsThunk,
} from "../../store/slices/ra/ra.slice";
import ListSearchbar from "../../components/Common/ListSearchbar";
import useAdvanceFilter from "../../hooks/useAdvanceFilter";
import _, { set } from "lodash";
import { checkForFilterValidity } from "../../utils/global/global";
import { Icon } from "@iconify/react";
import {
  fetchAllVendorProfilesThunk,
  searchVendorProfilesThunk,
} from "../../store/slices/vendor/vendor.slice";
import Modal from "../../components/Modal/Modal";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import RAFilterShortlist from "../../components/FilterShortList/RAFilterShortlist";
import * as Yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 4,
};

const budgetRange = [
  { value: "1-1.5 Lakhs/month", label: "1-1.5 Lakhs/month" },
  { value: "1.5-2 Lakhs/month", label: "1.5-2 Lakhs/month" },
  { value: "2-2.5 Lakhs/month", label: "2-2.5 Lakhs/month" },
  { value: "2.5-3 Lakhs/month", label: "2.5-3 Lakhs/month" },
  { value: "3-5 Lakhs/month", label: "3-5 Lakhs/month" },
  { value: "5+ Lakhs/month", label: "5+ Lakhs/month" },
];

const raDocumentUpdateValidation = Yup.object().shape({
  skills: Yup.array().of(
    Yup.object().shape({
      // file: Yup.object({
      //   url: Yup.string().required("EMB CV is required").nullable(),
      // }),
      budget: Yup.number()
        .required("Budget is required")
        .min(0, "Budget must be a positive number"),
      description: Yup.string().trim().required("Description is required"),
    })
  ),
});

export default function RaShortlist() {
  const dispatch = useDispatch();
  const params = useParams();

  const { raEnableVendors, lead } = useSelector(({ ra }) => ra);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isVendorSelected, setIsVendorSelected] = useState(false);

  const [open, setOpen] = useState(false);
  const [totalVendor, setTotalVendor] = useState(0);
  const [selectedVendors, setSelectedVendors] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTotalVendor(raEnableVendors.total || 0);
  }, [raEnableVendors.total]);

  const formik = useFormik({
    initialValues: {
      description: "",
      budget: "",
    },
    // validationSchema: raDocumentUpdateValidation,
    onSubmit: (values) => {
      // to send jd datas in payload

      const payload = {
        emb_budget: values.budget,
        shortlist_emb_job_description: values.description,
        selected_vendor_ids: selectedVendors,
      };

      dispatch(
        postShorlistedVendorsThunk({ data: payload, id: params.leadId })
      );

      console.log("oayload", payload);
    },
  });

  useEffect(() => {
    dispatch(fetchRaLeadByIdThunk(params.leadId)).then((res) => {
      //     if (res.type === "fetchRaLeadById/fulfilled") {
      //       formik.setValues({
      //         // ra_jd: res.payload?.ra_jd || "",
      //         skills:
      //           res.payload?.skills.map((elem) => {
      //             return {
      //               file: {},
      //               budget: "",
      //               description: "",
      //               skill: elem.skill || "N/A",
      //               skill_name: elem.skill_name || "",
      //               id: elem.id,
      //             };
      //           }) || [],
      //       });
      //     }
    });
    fetchVendors();
  }, [dispatch, params.leadId, page]);

  const fetchVendors = (searchValue = "") => {
    dispatch(fetchAllRaEnableVendorThunk({ search: searchValue, page }));
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
      setSelectedVendors(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const [search, setSearch] = useState("");
  const filterState = useAdvanceFilter({ search });

  const [showDrawer, setShowDrawer] = useState(false);

  function handleFilter() {
    setShowDrawer(true);
  }
  function handleFilterClose() {
    setShowDrawer(false);
  }

  const _searchChange = useMemo(() => {
    return _.debounce((value) => {
      if (!value) {
        setPage(1);
        fetchVendors();
      } else {
        setPage(1);
        dispatch(
          fetchAllRaEnableVendorThunk({
            search: value,
            page: 1,
          })
        );
      }
    }, 500);
  }, [dispatch]);

  function handleSearchChange(e) {
    const value = e.target.value;
    setSearch(value);
    _searchChange(value);
  }

  const handlePageChange = (page) => {
    setPage(page);
    fetchVendors(search);
  };

  // for JD Checkbox
  // const [sameJD, setSameJD] = useState(false);
  // const handleJDCheckboxChange = (event) => {
  //   setSameJD(event.target.checked);
  // };
  // useEffect(() => {
  //   if (sameJD && formik.values.skills.length > 0) {
  //     const firstJD = formik.values.skills[0]?.file;
  //     const firstBudget = formik.values.skills[0]?.budget;
  //     const firstDec = formik.values.skills[0]?.description;
  //     formik.setValues({
  //       ...formik.values,
  //       skills: formik.values.skills.map((skill) => ({
  //         ...skill,
  //         file: firstJD,
  //         budget: firstBudget,
  //         description: firstDec,
  //       })),
  //     });
  //   }
  // }, [sameJD, formik.values.skills.length]);

  const columns = [
    {
      title: "Vendor Name",
      dataIndex: "registered_business_name",
    },
    {
      title: "Skill Operators",
      dataIndex: "skills_operates",
      render: (text) => <span>{text ?? " - "}</span>,
    },
    {
      title: "Leads Given",
      dataIndex: "requirement_given",
      render: (text) => <span>{text ?? " - "}</span>,
    },
    {
      title: "Resource Working",
      dataIndex: "resource_working",
      render: (text) => <span>{text ?? " - "}</span>,
    },
    {
      title: "Bench Strength",
      dataIndex: "bench_strength",
      render: (text) => <span>{text ?? " - "}</span>,
    },
    {
      title: "Analytics",
      dataIndex: "analytics",
      render: (text) => <a onClick={handleOpen}>{text ?? "view"}</a>,
    },
  ];

  return (
    <>
      <Drawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        PaperProps={{
          style: { width: "600px" },
        }}
      >
        <RAFilterShortlist
          filterState={filterState}
          handleFilterClose={handleFilterClose}
        />
      </Drawer>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          display: { lg: "flex", md: "flex", sm: "block", xs: "block" },
        }}
      >
        <ListSearchbar
          placeholder="Search vendor profile"
          handleSearchChange={handleSearchChange}
          styles={{
            width: "280px",
          }}
        />
        <Button
          variant="outlined"
          sx={{ padding: "10px 20px", marginRight: "20px" }}
          onClick={handleFilter}
        >
          <Icon icon="bytesize:filter" />{" "}
          <span style={{ marginLeft: "10px" }}>Filter</span>
        </Button>
      </Stack>

      <Card
        style={{
          padding: "1rem",
          marginTop: "1rem",
          marginBottom: "1rem",
          width: "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          <Table
            rowSelection={
              isVendorSelected
                ? false
                : {
                    type: "checkbox",
                    ...rowSelection,
                  }
            }
            columns={columns}
            dataSource={raEnableVendors.data
              ?.filter(
                (item, ind) =>
                  ind >= (page - 1) * rowsPerPage && ind < page * rowsPerPage
              )
              .map((ele) => ({
                ...ele,
                key: ele.id,
              }))}
            pagination={false}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <Pagination
              current={page}
              total={totalVendor}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              defaultPageSize={rowsPerPage}
              showSizeChanger={false}
              onChange={handlePageChange}
            />
          </div>
        </div>

        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Grid md={12} px={3} mt={8}>
              {/* <Typography variant="h4">EMB J.D Upload:</Typography> */}
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={sameJD}
                    onChange={handleJDCheckboxChange}
                    name="SameJD"
                  />
                }
                label="Use Same JD for all skills? (Please enter the first JD and then click this checkox)"
              /> */}
            </Grid>
            <FieldArray
              name="skills"
              render={() => (
                <>
                  {/* {formik.values.skills.map((items, index) => ( */}
                  <Grid
                    container
                    alignItems="flex-end"
                    // spacing={2}
                    p={3}
                    mt={4}
                  >
                    <Grid item md={7}>
                      <Element
                        eletype={inputType.select}
                        label="Vendor Cost"
                        inputProps={{
                          name: `budget`,
                          onChange: formik.handleChange,
                          onBlur: formik.handleBlur,
                        }}
                        value={formik.values.budget}
                        errorText={
                          formik.touched.budget && formik.errors.budget
                        }
                        options={budgetRange}
                      />
                    </Grid>
                    <Grid item md={7}>
                      <Element
                        eletype={inputType.textarea}
                        label="Description"
                        inputProps={{
                          name: `description`,
                          onChange: formik.handleChange,
                          onBlur: formik.handleBlur,
                        }}
                        value={formik.values.description}
                        errorText={
                          formik.touched.description &&
                          formik.errors.description
                        }
                        cols="4"
                        rows="6"
                      />
                    </Grid>
                  </Grid>
                  {/* ))} */}
                </>
              )}
            />
            <Grid container justifyContent="flex-end" spacing={2} p={3}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={false}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
      </Card>
    </>
  );
}
