import React, { useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";

import Scrollbar from "../../components/Common/Scrollbar";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import IconButton from "@mui/material/IconButton";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";

import {
  Card,
  Typography,
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableHead,
} from "@mui/material";
import ListHead from "../../components/Common/ListHead";
import { Link, useNavigate } from "react-router-dom";
import { capitalCase } from "../../components/Common/change-case";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllRALeadsThunk,
  fetchAllRALeadsWithPaginationThunk,
  fetchRAAssignUserThunk,
  postRAAssignUserThunk,
  searchClientRequirmentThunk,
} from "../../store/slices/ra/ra.slice";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { Button } from "antd";
import ListSearchbar from "../../components/Common/ListSearchbar";
import useAdvanceFilter from "../../hooks/useAdvanceFilter";
import _ from "lodash";

const TABLE_HEAD = [
  { id: "id", label: "Requested Id", alignRight: false },
  { id: "name", label: "Client Name", alignRight: false },
  { id: "company_name", label: "Company Name", alignRight: false },
  { id: "mobile", label: "Mobile", alignRight: false },
  { id: "skills", label: "Skills", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "created_at", label: "Requested Date", alignRight: false },
  {
    id: "assigned_user_id",
    label: "Assign User",
    alignRight: false,
    minWidth: "500px",
  },
];

function RAList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { leads, assign_user_list } = useSelector(({ ra }) => ra);

  const [page, setPage] = useState(0);
  const [dropdownSelections, setDropdownSelections] = useState({});
  const [search, setSearch] = useState("");
  const filterState = useAdvanceFilter({ search });

  useEffect(() => {
    dispatch(fetchAllRALeadsThunk());
    dispatch(fetchRAAssignUserThunk());
  }, [dispatch]);

  const handleRowClick = (id) => {
    navigate("/dashboard/ra/" + id + "/basic");
  };

  const handleChangePage = (_, newPage) => {
    dispatch(
      fetchAllRALeadsWithPaginationThunk({ search: search, page: newPage + 1 })
    );
    setPage(newPage);
  };

  const handleDropdownChange = (id, event) => {
    setDropdownSelections((prev) => ({
      ...prev,
      [id]: event.target.value,
    }));
  };

  const handleSubmit = (id) => {
    const userId = dropdownSelections[id];
    const userDetails = assign_user_list.find((ele) => ele.id === userId);
    const dealInfo = leads.data.find((ele) => {
      let value = null;
      ele.lead_line_items.forEach((ele) => {
        if (ele._id === id) value = ele;
      });
      return value;
    });
    const payload = {
      assigned_user_id: dropdownSelections[id],
      lead_line_item_id: id,
      name: userDetails.name,
      email: userDetails.email,
      lead_internal_id: dealInfo.emb_lead_id,
    };

    dispatch(postRAAssignUserThunk({ data: payload })).then((res) => {
      if (res.type === "postRAAssignUser/fulfilled") {
        setDropdownSelections({});
        dispatch(fetchAllRALeadsThunk());
      }
    });
  };

  const handleClear = (id) => {
    setDropdownSelections((prev) => ({
      ...prev,
      [id]: "",
    }));
  };

  const _searchChange = useMemo(
    () =>
      _.debounce((value) => {
        if (!value) {
          dispatch(fetchAllRALeadsThunk());
        } else {
          setPage(0);
          dispatch(searchClientRequirmentThunk({ search: value }));
        }
      }, 500),
    [dispatch]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    _searchChange(value);
  };

  return (
    <>
      <Stack
        mb={2}
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        sx={{ display: { lg: "flex", md: "flex", sm: "block", xs: "block" } }}
      >
        <Typography variant="h4" gutterBottom>
          Staff Augmentation Leads
        </Typography>
        <ListSearchbar
          placeholder="Search Clients"
          handleSearchChange={handleSearchChange}
          styles={{ width: "280px" }}
        />
      </Stack>

      <Card>
        <Scrollbar>
          {leads?.data?.length > 0 ? (
            <TableContainer sx={{ minWidth: 800 }}>
              {/* <Table>
                <ListHead
                  rowCount={leads.data?.length}
                  styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                  headLabel={TABLE_HEAD}
                />
                <TableBody>
                  {leads.data?.map((row) => {
                    const {
                      _id,
                      lead_status,
                      emb_lead_id,
                      user,
                      skills,
                      created_at,
                      assigned_user_id,
                    } = row;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell
                          align="left"
                          onClick={() => handleRowClick(_id)}
                        >
                          {emb_lead_id}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(_id)}>
                          <Typography noWrap sx={{ fontSize: "15px" }}>
                            <Link
                              to={"/"}
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              {capitalCase(user.name)}
                            </Link>
                          </Typography>
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(_id)}>
                          <Typography noWrap sx={{ fontSize: "15px" }}>
                            <Link
                              to={"/"}
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              {capitalCase(
                                user.company_name ? user.company_name : "-"
                              )}
                            </Link>
                          </Typography>
                        </TableCell>

                        <TableCell
                          align="left"
                          onClick={() => handleRowClick(_id)}
                        >
                          {!user.mobile_number
                            ? "-"
                            : user.country_code + "-" + user.mobile_number}
                        </TableCell>

                        <TableCell
                          sx={{ textTransform: "capitalize" }}
                          align="left"
                          onClick={() => handleRowClick(_id)}
                        >
                          {skills.map((skill) => skill.skill_name).join(", ")}
                        </TableCell>

                        <TableCell
                          align="left"
                          onClick={() => handleRowClick(_id)}
                        >
                          {capitalCase(lead_status)}
                        </TableCell>

                        <TableCell
                          align="left"
                          onClick={() => handleRowClick(_id)}
                        >
                          {DateTime.fromISO(created_at).toLocaleString(
                            DateTime.DATE_MED
                          )}
                        </TableCell>

                        <TableCell align="left">
                          <div
                            style={{
                              display: "flex",
                              gap: "1rem",
                              alignItems: "center",
                            }}
                          >
                            <Element
                              label=""
                              inputProps={{
                                placeholder: "Assign User",
                                type: "text",
                                onChange: (event) =>
                                  handleDropdownChange(_id, event),
                                name: "assigned_user_id",
                              }}
                              options={assign_user_list}
                              eletype={inputType.select}
                              value={
                                dropdownSelections[_id] ||
                                assigned_user_id ||
                                ""
                              }
                              dropdownIcon={false}
                            />
                            {dropdownSelections[_id] && (
                              <>
                                <div>
                                  <Button
                                    type="primary"
                                    shape="circle"
                                    disabled={
                                      !dropdownSelections[_id] ||
                                      dropdownSelections[_id] ===
                                        assigned_user_id
                                    }
                                    onClick={() => handleSubmit(_id)}
                                    style={{ marginRight: "1rem" }}
                                    size="large"
                                    color="#00AB55"
                                  >
                                    &#10003;
                                  </Button>
                                  <Button
                                    type="dashed"
                                    shape="circle"
                                    onClick={() => handleClear(_id)}
                                    size="large"
                                    color="#00AB55"
                                  >
                                    &#10007;
                                  </Button>
                                </div>
                              </>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={leads.total}
                      rowsPerPage={leads.limit}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPageOptions={false}
                    />
                  </TableRow>
                </TableFooter>
              </Table> */}
              <Table aria-label="collapsible table">
                <TableHead sx={{ bgcolor: "lightgrey" }}>
                  <TableRow>
                    <TableCell />
                    <TableCell>Deal Id</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Company Name</TableCell>
                    <TableCell align="center">Mobile Number</TableCell>
                    <TableCell align="center">Created At</TableCell>
                    <TableCell align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leads?.data?.map((row) => (
                    <Row
                      key={row.name}
                      row={row}
                      leads={row}
                      handleDropdownChange={handleDropdownChange}
                      assign_user_list={assign_user_list}
                      dropdownSelections={dropdownSelections}
                      handleSubmit={handleSubmit}
                      handleClear={handleClear}
                      handleRowClick={handleRowClick}
                      // assigned_user_id={assigned_user_id}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <SearchNotFound
              src="/static/support.svg"
              subtitle="There is currently no vendors data to show."
            />
          )}
        </Scrollbar>
      </Card>
    </>
  );
}

export default RAList;

function Row(props) {
  const {
    row,
    // setDropdownSelections,
    handleDropdownChange,
    assign_user_list = [],
    dropdownSelections,
    handleRowClick,
    handleSubmit,
    handleClear,
    assigned_user_id,
  } = props;
  const [open, setOpen] = React.useState(false);

  function convertDate() {
    const date = new Date(row.created_at);
    return date.toLocaleString();
  }

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset", cursor: "pointer" } }}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="body2" fontWeight="bold">
            {row.emb_lead_id}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2" fontWeight="bold">
            {capitalCase(row.user?.name)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2" fontWeight="bold">
            {capitalCase(row.user?.company_name)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2" fontWeight="bold">
            {row.user?.mobile_number}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2" fontWeight="bold">
            {convertDate().split(",").join(" ")}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <span
            style={{
              textTransform: "uppercase",
              padding: "6px 12px",
              color: "green",
              backgroundColor: "lightgreen",
              borderRadius: "12px",
            }}
          >
            {row.lead_status}
          </span>
        </TableCell>
      </TableRow>
      <TableRow style={{ width: "80%", margin: "auto" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell width={120}></TableCell>
                    <TableCell>Skill Name</TableCell>
                    <TableCell align="left">Budget</TableCell>
                    <TableCell align="left">Year Of Experience</TableCell>
                    <TableCell align="left">Stage</TableCell>
                    <TableCell width={350} align="left">
                      Assign User
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.lead_line_items?.map((lineItem) => (
                    <TableRow
                      key={lineItem.date}
                      hover={true}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell width={120}></TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        // colSpan={2}
                        onClick={() => handleRowClick(lineItem?._id)}
                      >
                        {lineItem.skill_name}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => handleRowClick(lineItem?._id)}
                      >
                        {lineItem.budget}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => handleRowClick(lineItem?._id)}
                      >
                        {lineItem.year_of_exp}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => handleRowClick(lineItem?._id)}
                      >
                        <span
                          style={{
                            textTransform: "uppercase",
                            padding: "6px 12px",
                            color: "green",
                            backgroundColor: "lightgrey",
                            borderRadius: "12px",
                          }}
                        >
                          {capitalCase(lineItem.lead_line_item_status)}
                        </span>
                      </TableCell>
                      <TableCell align="left">
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            gap: "1rem",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Element
                            label=""
                            inputProps={{
                              placeholder: "Assign User",
                              type: "text",
                              onChange: (event) =>
                                handleDropdownChange(lineItem._id, event),
                              name: "assigned_user_id",
                            }}
                            options={assign_user_list}
                            eletype={inputType.select}
                            value={
                              dropdownSelections[lineItem._id] ||
                              lineItem.assigned_user_id
                            }
                            dropdownIcon={false}
                          />
                          {dropdownSelections[lineItem._id] && (
                            <>
                              <div>
                                <Button
                                  type="primary"
                                  shape="circle"
                                  disabled={
                                    !dropdownSelections[lineItem._id] ||
                                    dropdownSelections[lineItem._id] ===
                                      assigned_user_id
                                  }
                                  onClick={() => handleSubmit(lineItem._id)}
                                  style={{ marginRight: "1rem" }}
                                  size="large"
                                  color="#00AB55"
                                >
                                  &#10003;
                                </Button>
                                <Button
                                  type="dashed"
                                  shape="circle"
                                  onClick={() => handleClear(lineItem._id)}
                                  size="large"
                                  color="#00AB55"
                                >
                                  &#10007;
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
