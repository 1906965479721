import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllPortfolioHandler,
  deletePortfolioHandler,
  addPortfolioHandler,
  updatePortfolioHandler,
} from "./uploadcoq.thunk";

const INIT_STATE = {
  uploadcoqdata: [],
  isLoading: false,
};

export const fetchAllUploadCOQThunk = createAsyncThunk(
  "fetchAllUploadCOQ",
  fetchAllPortfolioHandler
);

export const deleteUploadCOQThunk = createAsyncThunk(
  "deleteUploadCOQ",
  deletePortfolioHandler
);

export const addUploadCOQThunk = createAsyncThunk(
  "addUploadCOQ",
  addPortfolioHandler
);

export const updateUploadCOQThunk = createAsyncThunk(
  "updateUploadCOQ",
  updatePortfolioHandler
);

const uploadcoqSlice = createSlice({
  name: "uploadcoq",
  initialState: INIT_STATE,
  reducers: {
    startUploadCOQLoader: (state) => {
      state.isLoading = true;
    },
    stopUploadCOQLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUploadCOQThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllUploadCOQThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.uploadcoqdata = action.payload;
      })
      .addCase(fetchAllUploadCOQThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addUploadCOQThunk.fulfilled, (state, action) => {
        state.uploadcoqdata = [...state.uploadcoqdata, ...action.payload];
        return state;
      });
  },
});

export const { startUploadCOQLoader, stopUploadCOQLoader } =
  uploadcoqSlice.actions;

export default uploadcoqSlice.reducer;
