import React, { useMemo, useState } from "react";
import { Container, Typography } from "@mui/material";
import ProjectTable from "./ProjectTable";
import ProjectGrid from "./ProjectGrid";
import ProjectMenu from "./ProjectMenu";
import { makeStyles } from "@mui/styles";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";
import SearchNotFound from "../../NotFound/SearchNotFound";

const useStyles = makeStyles({
  menuMarginTop: {
    marginTop: "24px",
  },
});

const typeEnum = {
  table: "table",
  grid: "grid",
};

const AllProjects = () => {
  const projects = useSelector(({ project }) => []);
  const [type, setType] = useState(typeEnum.table);
  const [search, setSearch] = useState("");
  const classes = useStyles();

  const data = useMemo(() => {
    if (!search) return projects;
    return projects.filter((product) =>
      product.name.toLowerCase().includes(search)
    );
  }, [search, projects]);

  const handleSearchChange = (e) => {
    const lowercasedValue = e.target.value.toLowerCase();
    setSearch(lowercasedValue);
  };
  return (
    <Container>
      <Typography variant="h4" mb={2}>
        All Projects
      </Typography>
      <Breadcrumb />
      <ProjectMenu
        type={type}
        setType={setType}
        handleSearchChange={handleSearchChange}
      />
      <div className={classes.menuMarginTop}>
        {type === typeEnum.table ? (
          data.length === 0 ? (
            <SearchNotFound />
          ) : (
            <ProjectTable data={data} />
          )
        ) : (
          <ProjectGrid projects={data} />
        )}
      </div>
    </Container>
  );
};

export default AllProjects;
