import React, { useState, useEffect, useMemo, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllSericesThunk } from "../../store/slices/services/service.slice";
import { useTable, useExpanded } from "react-table";
import { transformServices } from "../../utils/global/global";
import Modal from "../../components/Modal/Modal";
import {
  Button,
  Box,
  Stack,
  Container,
  Grid,
  Typography,
  Card,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useStyles } from "./AddServices.theme";
import DeleteAction from "../../components/Common/DeleteAction";
import ServiceMoreMenu from "./ServiceMoreMenu";
import { styled } from "@mui/material/styles";
import {
  addService,
  deleteServiceHandler,
  updateServiceHandler,
  getAllCategory,
} from "../../utils/global/vendor.global";
import ServiceInput from "./ServiceInput";
import ListSearchbar from "../../components/Common/ListSearchbar";
import AddServiceModal from "../../components/Modal/AddServiceModal";
import { serviceAddType } from "../../utils/enum";
import CategoryDropdown from "../../components/Form/CategoryDropdown";
import usePermissions from "../../hooks/usePermission";
import systemPermissions, {
  actions,
} from "../../utils/global/permissions.global";

// const DDL_Category_Options = [
//   {
//     id: 1,
//     value: "E-Commerce",
//     label: "E-Commerce",
//   },
//   {
//     id: 2,
//     value: "ERP",
//     label: "ERP",
//   },
//   {
//     id: 3,
//     value: "BC",
//     label: "BC",
//   },
//   {
//     id: 4,
//     value: "Marketing",
//     label: "Marketing",
//   },
//   {
//     id: 5,
//     value: "EDTech",
//     label: "EDTech",
//   },
// ];

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
var ddlCat = "";
const AddServices = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const services = useSelector(({ services }) => services);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [currentExpanded, setCurrentExpanded] = useState({});
  const [search, setSearch] = useState("");
  const [type, setType] = useState(null);
  const [DDL_Category_Options, setDDL_Category_Options] = useState([]);
  // const [ddlCat, setDDLCategory] = useState("");

  const userPerm = usePermissions();

  const inputRef = useRef();

  const { isLoading, servicedata } = services;

  const fncGetSelectedCategory = (option) => {
    ddlCat = option;
  };

  const DDLCategory = (
    <CategoryDropdown
      fncGetSelectedCategory={fncGetSelectedCategory}
      options={DDL_Category_Options}
    />
  );

  const handleClose = () => {
    setShowModal(false);
  };
  const handleOpen = () => {
    setShowModal(true);
  };

  useEffect(() => {
    dispatch(fetchAllSericesThunk());
    getAllCategory().then((response) => {
      // setPortfoliosData(response.data);
      var ddlData = response.data.map((d) => {
        return {
          id: d._id,
          label: d.name,
          value: d.name,
        };
      });
      setDDL_Category_Options(ddlData);
    });
  }, [dispatch]);

  useMemo(() => {
    if (services.servicedata?.length !== 0) {
      var data = transformServices(services.servicedata);
      setData(data);
    }
  }, [services.servicedata]);

  const _searchChangeDebounce = useMemo(() => {
    return _.debounce((search) => {}, 500);
  }, []);

  function searchServiceHandler(e) {
    setSearch(e.target.value);
    _searchChangeDebounce(e.target.value);
  }

  function addServiceHandler(props) {
    const updatedExpandedRows = props.expandedRows.reduce((acc, val) => {
      return {
        ...acc,
        [val.id]: val.isExpanded,
      };
    }, {});

    var newData = addNewRow(data, props.row.original._id);
    function addNewRow(data, selID) {
      if (!data) return [];
      return data.map((service) => {
        var { subRows, _id, ...rest } = service;
        if (_id === selID)
          return {
            _id,
            ...rest,
            subRows: [
              ...subRows,
              {
                parent_id: _id,
                isNew: true,
              },
            ],
          };
        else {
          return {
            _id,
            subRows: subRows.length === 0 ? [] : addNewRow(subRows, selID),
            ...rest,
          };
        }
      });
    }
    setCurrentExpanded(updatedExpandedRows);
    setData(newData);
  }

  function delServiceHandler(props) {
    const updatedExpandedRows = getUpdatedExpandState(props);
    deleteServiceHandler(props.row.original?._id);
    const clonedState = _.cloneDeep(data);
    (function changeEditStatus(data) {
      for (let i = 0; i < data.length; i++) {
        var ele = data[i];
        if (ele._id === props.row.original?._id) {
          data.splice(i, 1);
        }
        if (ele.subRows?.length > 0) {
          changeEditStatus(ele.subRows);
        }
      }
    })(clonedState);
    setCurrentExpanded(updatedExpandedRows);
    setData(clonedState);
  }
  const addNewParent = () => {
    var dataCopy = [
      ...data,
      {
        parent_id: "",
        isEdit: false,
        isNew: true,
      },
    ];
    setData(dataCopy);
  };

  function handleActiveService(row) {
    setSelectedService(row);
  }

  function handleEditMode(props) {
    const updatedExpandedRows = getUpdatedExpandState(props);
    const clonedState = _.cloneDeep(data);
    (function changeEditStatus(data) {
      for (let i = 0; i < data.length; i++) {
        var ele = data[i];
        if (ele.subRows?.length > 0) {
          changeEditStatus(ele.subRows);
        }
        if (ele._id === props.row.original?._id) {
          ele.isEdit = true;
        } else {
          ele.isEdit = false;
        }
      }
    })(clonedState);
    setCurrentExpanded(updatedExpandedRows);
    setData(clonedState);
  }

  function getUpdatedExpandState(props) {
    return props.expandedRows.reduce((acc, val) => {
      return {
        ...acc,
        [val.id]: val.isExpanded,
      };
    }, {});
  }

  function handleCancel(props) {
    const updatedExpandedRows = getUpdatedExpandState(props);

    const clonedState = _.cloneDeep(data);
    (function changeEditStatus(data) {
      for (let i = 0; i < data.length; i++) {
        var ele = data[i];
        if (ele.subRows?.length > 0) {
          changeEditStatus(ele.subRows);
        }
        if (ele._id === props.row.original?._id) {
          if (ele.isNew) data.splice(i, 1);
          else ele.isEdit = false;
        }
      }
    })(clonedState);
    setCurrentExpanded(updatedExpandedRows);
    setData(clonedState);
  }

  function handleSave(props) {
    const updatedExpandedRows = getUpdatedExpandState(props);

    var promiseFunction = props.row.original.isNew
      ? addService
      : updateServiceHandler;

    // const serverData = {
    //   title: inputRef.current.value,
    //   parent_id: props.row.original.parent_id,
    // };

    var cat_id = {
      name: ddlCat.name,
      _id: ddlCat.id,
    };

    const serverData = {
      title: inputRef.current.value,
      parent_id: props.row.original.parent_id,
    };

    if (!props.row.original.isNew) {
      serverData._id = props.row.original._id;
    } else {
      serverData.slug = inputRef.current.value
        ?.toLowerCase()
        ?.split(" ")
        ?.join("-");
      serverData.tech_stacks = [];
      serverData.category = cat_id;
    }

    promiseFunction(serverData).then(() => {
      dispatch(fetchAllSericesThunk());
      setCurrentExpanded(updatedExpandedRows);
    });
  }

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
          return (
            <span {...getToggleAllRowsExpandedProps()}>
              {isAllRowsExpanded ? (
                <ExpandLessIcon />
              ) : (
                <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
              )}
            </span>
          );
        },
        Cell: (props) => {
          const { row } = props;
          return (
            row.canExpand && (
              <span
                {...row.getToggleRowExpandedProps({
                  style: {
                    paddingLeft: `${row.depth * 2}rem`,
                  },
                })}
              >
                {row.isExpanded ? (
                  <ExpandMoreIcon />
                ) : (
                  <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
                )}
              </span>
            )
          );
        },
      },

      {
        Header: "Title",
        accessor: "title",
        Cell: function (props) {
          if (props.row.original.isEdit || props.row.original.isNew) {
            return (
              <ServiceInput
                initValue={props.row.values.title}
                inputRef={inputRef}
              />
            );
          }
          return (
            <>
              <p
                onDoubleClick={() => handleEditMode(props)}
                style={{
                  paddingLeft: `${props.row.depth * 2}rem`,
                }}
              >
                {props.row.values.title}
              </p>
            </>
          );
        },
      },
      {
        Header: "Action",
        accessor: "",
        Cell: function (props) {
          const rowData = props.row.original;

          if (rowData.isNew || rowData.isEdit) {
            return (
              <Stack
                direction="row"
                sx={{ paddingRight: `${props.row.depth * 7}rem` }}
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <div>
                  {rowData.isEdit ? rowData?.category?.name : DDLCategory}
                </div>
                <Button size="small" onClick={() => handleSave(props)}>
                  Save
                </Button>
                <Button size="small" onClick={() => handleCancel(props)}>
                  Cancel
                </Button>
              </Stack>
            );
          }

          return (
            <>
              <Stack
                direction="row"
                sx={{ paddingRight: `${props.row.depth * 7}rem` }}
                spacing={2}
                justifyContent="center"
              >
                <div style={{ width: "250px" }}>{rowData?.category?.name} </div>
                <Box pt={1.5} sx={{ cursor: "pointer", marginRight: "8px" }}>
                  <img
                    src="/static/price-tag.svg"
                    width="20"
                    height="20"
                    onClick={() => {
                      setSelectedService(rowData);
                      setType(serviceAddType.tags);
                      handleOpen();
                    }}
                  />
                </Box>
                <Box pt={1.5} sx={{ cursor: "pointer" }}>
                  <img
                    src="/static/stacked-files.svg"
                    width="20"
                    height="20"
                    onClick={() => {
                      setSelectedService(rowData);
                      setType(serviceAddType.tech);
                      handleOpen();
                    }}
                  />
                </Box>
                <ServiceMoreMenu
                  handleAddRow={() => addServiceHandler(props)}
                />
                <DeleteAction handleDelete={() => delServiceHandler(props)} />
              </Stack>
            </>
          );
        },
      },
    ],
    [data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns: columns,
      data,
      initialState: {
        expanded: currentExpanded,
      },
    },
    useExpanded
  );

  return (
    <>
      <Container>
        <Grid container spacing={2} mt={5}>
          <Grid item md={12}>
            <Card
              sx={{ minHeight: "420px", py: 2, px: 1 }}
              style={{ overflow: "inherit" }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                m={2}
              >
                <Typography variant="h6" gutterBottom>
                  Add Services
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <ListSearchbar
                  placeholder="Search Services"
                  handleSearchChange={searchServiceHandler}
                  styles={{
                    width: "280px",
                  }}
                />
                {userPerm.hasAccess(
                  systemPermissions["contentRepo.services"],
                  actions.create
                ) && (
                  <Box>
                    <Button
                      variant="contained"
                      onClick={addNewParent}
                      sx={{ marginRight: "1rem" }}
                    >
                      Add Service
                    </Button>
                  </Box>
                )}
              </Stack>
              <Grid item md={12}>
                <div className={classes.dropdownTable}>
                  <table {...getTableProps()} className={classes.table}>
                    <thead className={classes.thead}>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              className={classes.th}
                              {...column.getHeaderProps()}
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  className={classes.td}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <Modal
          maxWidth="md"
          isOpen={showModal}
          closeModal={handleClose}
          title={`Service: ${selectedService?.title}`}
          content={
            <AddServiceModal type={type} selectedService={selectedService} />
          }
          dialogProps={{ fullWidth: true }}
          titleStyle={{ textAlign: "center", marginTop: 2 }}
          actionStyle={{ justifyContent: "center", marginBottom: 2 }}
        />
      </Container>
    </>
  );
};

export default AddServices;
