import React, { useEffect, useState, useMemo } from "react";
import { Box, Grid, Button, Stack } from "@mui/material";
import { inputType } from "../../utils/enum";
import { personalityPOC, ageRangeOfPOC } from "../../utils/options.util";
import Ratings from "../../components/Form/Ratings";
import Element from "../../components/Form/Element";
import { skillsValidations } from "../../utils/validations/vendor.validation";
import MobileInput from "../../components/Form/MobileInput";
import useForm from "../../hooks/useForm";

//

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  fetchAllTechstackThunk,
  updateTechstackThunk,
  deleteTechstackThunk,
  AddTechstackThunk,
} from "../../store/slices/teckstack/techstack.slice";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import { techStackValidations } from "../../utils/validations/vendor.validation";

export default function TechStackModel({ props, closeModal }) {
  const dispatch = useDispatch();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useForm({
      initialValues: {
        tech: props.name == "" ? "" : props.name,
      },
      onSubmit: onSubmit,
      validationSchema: techStackValidations,
    });

  function onSubmit(data) {
    if (props.name == "") {
      var datas = {
        name: data.tech,
        page: props.page,
      };

      dispatch(AddTechstackThunk(datas))
        .unwrap()
        .then((d) => {
          closeModal();
        });
    } else {
      var data = {
        id: props._id,
        name: data.tech,
      };

      dispatch(updateTechstackThunk(data))
        .unwrap()
        .then((d) => {
          closeModal();
        });
    }
  }

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} p={3}>
            <Grid item md={12} pb={3}>
              <Element
                label="Please enter the Tech"
                inputProps={{
                  placeholder: "Please enter the Tech",
                  type: "text",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "tech",
                }}
                eletype={inputType.input}
                value={values.tech}
                errorText={touched.tech && errors.tech}
              />
            </Grid>

            <Grid item md={12} p={3} sx={{ textAlign: "center" }}>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
