import React from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  Chip,
  Button,
  Card,
  Link,
} from "@mui/material";
import Scrollbar from "../Scrollbar";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";

function ShortlistNotesModal() {
  const { selectedDeal } = useSelector(({ shortlist }) => shortlist);

  return (
    <Container>
      {selectedDeal?.notes.map((data) => (
        <Card
          sx={{
            marginBottom: 3,
            background: "#f3f6f8",
            padding: "15px",
            width: "31.5rem",
            borderRadius: "16px",
            boxShadow:
              "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6" sx={{ color: "rgb(33, 43, 54)" }}>
              {data.noted_by}
            </Typography>
            <Typography variant="body2" sx={{ color: "rgb(145, 158, 171)" }}>
              {DateTime.fromISO(data.created_at).toLocaleString(
                DateTime.DATE_MED
              )}
            </Typography>
          </Stack>
          <Typography
            variant="body2"
            sx={{ color: "rgb(99, 115, 129)", fontSize: "0.9rem" }}
          >
            {data.content}
          </Typography>
          <Box mt={2}>
            {data.attachments.map((item) => (
              <a
                href={item.url}
                target="_blank"
                style={{
                  marginRight: "1rem",
                }}
              >
                <Chip
                  label={item.filename}
                  variant="outlined"
                  sx={{ cursor: "pointer!important", marginBottom: "0.75rem" }}
                />
              </a>
            ))}
          </Box>
        </Card>
      ))}
    </Container>
  );
}

export default ShortlistNotesModal;
