import React, { useState } from "react";
import { useStyles } from "../../components/Form/Form.theme";

const ServiceInput = ({
  label,
  errorText,
  inputRef,
  initValue = "",
  readOnly,
  style,
  icon,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(initValue);

  function handleChange(e) {
    const value = e.target.value;
    setValue(value);
  }

  return (
    <div className={classes.formGroup}>
      {label ? (
        <label className={classes.formLabel} style={style}>
          {label}
        </label>
      ) : null}
      <input
        className={classes.formControl}
        onChange={handleChange}
        value={value}
        readOnly={readOnly}
        icon={icon}
        autoComplete="off"
        ref={inputRef}
      />
      {errorText && <span className={classes.formErrorStyle}>{errorText}</span>}
    </div>
  );
};

export default ServiceInput;
