import { Card, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { serviceAddType } from "../../utils/enum";
import {
  fetchAllTageByService,
  fetchAllTagsBySearch,
  fetchAllTechStackBySearch,
  fetchAllTechStackByService,
  updateServiceHandler,
} from "../../utils/global/vendor.global";
import AutocompleteChip from "../Form/AutocompleteChip";

function AddServiceModal({ type, selectedService }) {
  const [teckStacks, setSelectedTeckStacks] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (type === serviceAddType.tech) {
      fetchAllTechStackByService(selectedService?._id).then((response) => {
        setSelectedTeckStacks(response.data);
      });
    }

    if (type === serviceAddType.tags) {
      fetchAllTageByService(selectedService?._id).then((response) => {
        setTags(response.data);
      });
    }
  }, [selectedService, type]);

  function handleTechStackDelete(value) {
    updateServiceHandler({
      ...selectedService,
      tech_stacks: value,
    }).then((response) => {
      setSelectedTeckStacks(response.data?.tech_stacks);
    });
  }

  function handleTagsDelete(value) {
    updateServiceHandler({
      ...selectedService,
      tags: value,
    }).then((response) => {
      setTags(response.data?.tags);
    });
  }

  async function getOptions(value) {
    const data = await fetchAllTechStackBySearch(value);
    return data.data.map((lan) => ({
      ...lan,
      label: lan.name,
      value: lan.name,
    }));
  }

  async function getTagsOptions(value) {
    const data = await fetchAllTagsBySearch(value);
    return data.data.map((lan) => ({
      ...lan,
      label: lan.tag,
      value: lan.tag,
    }));
  }

  function handleTeckStackChange(value) {
    if (value) {
      updateServiceHandler({
        ...selectedService,
        tech_stacks: [
          ...teckStacks,
          {
            id: value?._id,
            name: value.name,
          },
        ],
      }).then((response) => {
        setSelectedTeckStacks(response.data?.tech_stacks);
      });
    }
  }

  function handleTagsChange(value) {
    if (value) {
      updateServiceHandler({
        ...selectedService,
        tags: [
          ...tags,
          {
            id: value?._id,
            tag: value.tag,
          },
        ],
      }).then((response) => {
        setTags(response.data?.tags);
      });
    }
  }

  return type === serviceAddType.tags ? (
    <Grid
      container
      spacing={2}
      mt={5}
      sx={{ position: "relative", top: "-50px" }}
    >
      <Grid item md={12}>
        <Grid item md={12} p={2}>
          <Stack direction="row" spacing={3}>
            <AutocompleteChip
              label="Add Tags"
              chipsData={tags.map((ele) => ({ ...ele, name: ele.tag }))}
              deleteChip={handleTagsDelete}
              getOptions={getTagsOptions}
              isChipsVisible
              onChange={handleTagsChange}
              placeholder="Search Tags"
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      spacing={2}
      mt={5}
      sx={{ position: "relative", top: "-50px" }}
    >
      <Grid item md={12}>
        <Grid item md={12} p={2}>
          <Stack direction="row" spacing={3}>
            <AutocompleteChip
              label="Add Tech Stack"
              chipsData={teckStacks}
              deleteChip={handleTechStackDelete}
              getOptions={getOptions}
              isChipsVisible
              onChange={handleTeckStackChange}
              placeholder="Search Tech Stack"
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AddServiceModal;
