import Input from "./Input";
import { useStyles } from "./Form.theme";
import { useMemo, useRef, useState } from "react";
import { getVirtualElement } from "../../utils/global/global";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const CategoryDropdown = ({
  label,
  options = [],
  value,
  errorText,
  icons,
fncGetSelectedCategory,

}) => {

  const classes = useStyles();
  const [showMenuItem, setShowMenuItem] = useState(false);
  const [ddlValue, setDDLValue] = useState(value);


  function handleItemClick(option) {
    fncGetSelectedCategory(option)
    setShowMenuItem(false);
    setDDLValue(option.label)
    getLabelFromValue()
  }

  var dropdownRef = useRef();

  function renderMenu() {
    return (
      <div className={classes.menuWrapper} ref={dropdownRef} style={{top: 50, zIndex:"999"}}>
        <ul className={classes.dropDown} >
          {options.map((option) => {
            return (
              <li
                className={classes.dropdownItem}
                onClick={() => handleItemClick(option)}
              >
                {option.label}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  useOnClickOutside(dropdownRef, () => setShowMenuItem(false));

  function getLabelFromValue() {

    const finsalOptions =  options;
    if (!ddlValue) return "--Select category--";
    // return ddlValue.label
    const selectedOption = finsalOptions.find(
      (option) => option.value === ddlValue
    );
    return selectedOption?.label;
  }
  return (
    <div className={classes.dropdownWrapper} onClick={() => setShowMenuItem(true)}>
      <ArrowDropDownIcon
        sx={{
          position: "absolute",
          right: "6px",
          top: "15px",
          cursor: "pointer",
        }}
        
      />
      <Input
        style={{ cursor: "pointer" }}
        label={label}
          onClick= {() => setShowMenuItem(true)}
          readOnly= {true}
        value={getLabelFromValue()}
        errorText={errorText}
      />
      {showMenuItem && renderMenu()}
    </div>
  );
};

export default CategoryDropdown;
