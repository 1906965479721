import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllUsersHandler,
  deleteUsersHandler,
  addUserHandler,
  updateUserHandler,
} from "./edituser.thunk";

const INIT_STATE = {
  userData: [],
  isLoading: false,
};

export const fetchAllUsersThunk = createAsyncThunk(
  "fetchAllUser",
  fetchAllUsersHandler
);

export const deleteUsersThunk = createAsyncThunk(
  "deleteUser",
  deleteUsersHandler
);

export const AddUsersThunk = createAsyncThunk("addUser", addUserHandler);

export const updateUsersThunk = createAsyncThunk(
  "updateUser",
  updateUserHandler
);

const edituserslice = createSlice({
  name: "edituser",
  initialState: INIT_STATE,
  reducers: {
    startEditUserLoader: (state) => {
      state.isLoading = true;
    },
    stopEditUserLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsersThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllUsersThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userData = action.payload;
      })
      .addCase(fetchAllUsersThunk.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { startEditUserLoader, stopEditUserLoader } =
  edituserslice.actions;

export default edituserslice.reducer;
