import { useSelector } from "react-redux";
import _, { first } from "lodash";

import sidebarConfig, {
  contentRepoSidebarConfig,
  financeApprovalSidebarConfig,
  raSidebarConfig,
  supportSidebarConfig,
  vendorProfileSidebarConfig,
  vendorShortlistSidebarConfig,
} from "../layouts/dashboard/SidebarConfig";
import systemPermissions, { actions } from "../utils/global/permissions.global";
import {
  getAffiliationsTabs,
  getBankInfoTabs,
  getBasicInfoTabs,
  getExpertiseBreakupTabs,
  getPlatformReviewTabs,
  getPortfolioTabs,
  getResourcesTabs,
} from "../pages/Clients";
import { useState } from "react";

function usePermissions() {
  const permissions = useSelector(({ init }) => init.userPermissions);
  const [firstAvailableLink, setFirstAvailableLink] = useState(null);

  function hasAccess(permission, action) {
    const isHaveAccess = permissions[permission];
    if (isHaveAccess) {
      if (isHaveAccess.type === "deny") return false;
      if (Array.isArray(isHaveAccess.actions)) {
        return isHaveAccess.actions.includes(action);
      } else {
        return false;
      }
    }
    return false;
  }

  function hasAccessCheckMany(allPermissions, actions) {
    const isHaveAccess = true;
    allPermissions.forEach((permission, idx) => {
      if (!hasAccess(permission, actions[idx])) {
        isHaveAccess = false;
      }
    });
    return isHaveAccess;
  }

  function getUserSidebarConfig() {
    let finalConfig = [...sidebarConfig];

    function checkForContentConfig() {
      const ids = [];

      if (hasAccess(systemPermissions["contentRepo.services"], actions.read))
        ids.push("0");
      if (hasAccess(systemPermissions["contentRepo.industry"], actions.read))
        ids.push("1");
      if (hasAccess(systemPermissions["contentRepo.language"], actions.read))
        ids.push("2");
      if (hasAccess(systemPermissions["contentRepo.techStack"], actions.read))
        ids.push("3");
      if (hasAccess(systemPermissions["contentRepo.tags"], actions.read))
        ids.push("4");
      if (hasAccess(systemPermissions["contentRepo.category"], actions.read))
        ids.push("5");

      return ids;
    }

    function checkForVendorProfileConfig() {
      return (
        hasAccess(
          systemPermissions["vendorProfileMainPage.allVendors"],
          actions.read
        ) ||
        hasAccess(
          systemPermissions["vendorProfileMainPage.approvedVendors"],
          actions.read
        )
      );
    }

    function checkForVendorShortlistConfig() {
      return hasAccess(systemPermissions.vendorShorlistListPage, actions.read);
    }

    function checkForFinanceApprovalConfig() {
      return hasAccess(systemPermissions.financeApprovalListPage, actions.read);
    }

    function checkForSupportConfig() {
      return hasAccess(systemPermissions.supportListPage, actions.read);
    }

    function checkForRAConfig() {
      return hasAccess(systemPermissions.raListPage, actions.read);
    }

    if (checkForContentConfig())
      finalConfig = [
        ...finalConfig,
        contentRepoSidebarConfig(checkForContentConfig()),
      ];
    if (checkForVendorProfileConfig())
      finalConfig = [...finalConfig, vendorProfileSidebarConfig];
    if (checkForVendorShortlistConfig())
      finalConfig = [...finalConfig, vendorShortlistSidebarConfig];
    if (checkForFinanceApprovalConfig())
      finalConfig = [...finalConfig, financeApprovalSidebarConfig];
    if (checkForSupportConfig())
      finalConfig = [...finalConfig, supportSidebarConfig];
    if (checkForRAConfig()) {
      finalConfig = [...finalConfig, raSidebarConfig];
    }

    return finalConfig.filter((val) => val !== null);
  }

  function getProfileTabs() {
    function checkForBasicInfoTabs() {
      const ids = [];
      if (
        hasAccess(
          systemPermissions["vendorProfileDetailPage.info"],
          actions.read
        )
      )
        ids.push("0");
      if (
        hasAccess(
          systemPermissions["vendorProfileDetailPage.address"],
          actions.read
        )
      )
        ids.push("1");
      if (
        hasAccess(
          systemPermissions["vendorProfileDetailPage.poc"],
          actions.read
        )
      )
        ids.push("2");
      if (
        hasAccess(
          systemPermissions["vendorProfileDetailPage.foundersInfo"],
          actions.read
        )
      )
        ids.push("3");
      return ids;
    }

    function checkForBankTabs() {
      const ids = [];
      if (
        hasAccess(
          systemPermissions["vendorProfileDetailPage.bankDetails"],
          actions.read
        )
      )
        ids.push("0");
      if (
        hasAccess(
          systemPermissions["vendorProfileDetailPage.verification"],
          actions.read
        )
      )
        ids.push("1");
      return ids;
    }

    function checkForPortfolioTab() {
      const ids = [];
      if (
        hasAccess(
          systemPermissions["vendorProfileDetailPage.services"],
          actions.read
        )
      )
        ids.push("0");
      if (
        hasAccess(
          systemPermissions["vendorProfileDetailPage.product_code"],
          actions.read
        )
      )
        ids.push("1");
      if (
        hasAccess(
          systemPermissions["vendorProfileDetailPage.pastWork"],
          actions.read
        )
      )
        ids.push("2");
      if (
        hasAccess(
          systemPermissions["vendorProfileDetailPage.corporate_deck"],
          actions.read
        )
      )
        ids.push("3");
      if (
        hasAccess(
          systemPermissions["vendorProfileDetailPage.testimonials"],
          actions.read
        )
      )
        ids.push("4");
      return ids;
    }

    function checkForAffilicationTab() {
      const ids = [];
      if (
        hasAccess(
          systemPermissions["vendorProfileDetailPage.saasChannelPartner"],
          actions.read
        )
      )
        ids.push("0");
      if (
        hasAccess(
          systemPermissions["vendorProfileDetailPage.certifications"],
          actions.read
        )
      )
        ids.push("1");
      return ids;
    }

    function checkForExperiseBreakupTab() {
      return hasAccess(
        systemPermissions["vendorProfileDetailPage.expertiseBreakup"],
        actions.read
      );
    }

    function checkoutForPlatformReviewTab() {
      return hasAccess(
        systemPermissions["vendorProfileDetailPage.platformReview"],
        actions.read
      );
    }

    function checkForResourcesTab() {
      const ids = [];

      if (
        hasAccess(
          systemPermissions["vendorProfileDetailPage.techStackForte"],
          actions.read
        )
      )
        ids.push("0");
      if (
        hasAccess(
          systemPermissions["vendorProfileDetailPage.benchStrength"],
          actions.read
        )
      )
        ids.push("1");

      return ids;
    }

    let finalConfig = [];
    let firstAvailableLink = null;
    let ids = checkForBasicInfoTabs();
    let data = [];
    if (ids.length > 0) {
      data = getBasicInfoTabs(ids);
      if (data.childTabs) {
        firstAvailableLink = data.childTabs[0]?.value;
      }
      data.value = data.childTabs[0]?.value;
    }
    finalConfig = [...finalConfig, data];

    ids = checkForBankTabs();
    data = [];
    if (ids.length > 0) {
      data = getBankInfoTabs(ids);
      if (data.childTabs) {
        if (firstAvailableLink === null)
          firstAvailableLink = data.childTabs[0]?.value;
      }
      data.value = data.childTabs[0]?.value;
    }
    finalConfig = [...finalConfig, data];

    ids = checkForPortfolioTab();
    data = [];
    if (ids.length > 0) {
      data = getPortfolioTabs(ids);
      if (data.childTabs) {
        if (firstAvailableLink === null)
          firstAvailableLink = data.childTabs[0]?.value;
      }
      data.value = data.childTabs[0]?.value;
    }
    finalConfig = [...finalConfig, data];

    ids = checkForAffilicationTab();
    data = [];
    if (ids.length > 0) {
      data = getAffiliationsTabs(ids);
      if (data.childTabs) {
        if (firstAvailableLink === null)
          firstAvailableLink = data.childTabs[0]?.value;
      }
      data.value = data.childTabs[0]?.value;
    }
    finalConfig = [...finalConfig, data];

    if (checkForExperiseBreakupTab()) {
      let data = getExpertiseBreakupTabs();
      if (firstAvailableLink === null) {
        firstAvailableLink = data.value;
      }
      finalConfig = [...finalConfig, data];
    }

    if (checkoutForPlatformReviewTab()) {
      let data = getPlatformReviewTabs();
      if (firstAvailableLink === null) {
        firstAvailableLink = data.value;
      }
      finalConfig = [...finalConfig, data];
    }

    ids = checkForResourcesTab();
    data = [];
    if (ids.length > 0) {
      data = getResourcesTabs(ids);
      if (data.childTabs) {
        if (firstAvailableLink === null)
          firstAvailableLink = data.childTabs[0]?.value;
      }
      data.value = data.childTabs[0]?.value;
    }
    finalConfig = [...finalConfig, data];
    setFirstAvailableLink(firstAvailableLink);

    return _.flatMap(finalConfig);
  }

  return {
    hasAccess,
    hasAccessCheckMany,
    getUserSidebarConfig,
    getProfileTabs,
    firstAvailableLink,
    permissions,
  };
}

export default usePermissions;
