import { combineReducers } from "@reduxjs/toolkit";

import userReducer from "./slices/user/user.slice";
import projectReducer from "./slices/project/project.slice";
import notificationReducer from "./slices/notification/notification.slice";
import initReducer from "./slices/init/init.slice";
import authReducer from "./slices/auth/auth.slice";
import toastReducer from "./slices/toast/toast.slice";
import dashboardReducer from "./slices/dashboard/dashboard.slice";
import serviceReducer from "./slices/services/service.slice";
import industryReducer from "./slices/Industry/industry.slice";
import vendorReducer from "./slices/vendor/vendor.slice";
import notesReducer from "./slices/notes/notes.slice";
import venderExistReducer from "./slices/vendorExist/notes.slice";
import listServicesReducer from "./slices/viewProfile/viewProfile.slice";
import portfolioReducer from "./slices/portfolio/portfolio.slice";
import uploadportfolioReducer from "./slices/uploadportfolio/uploadportfolio.slice";
import vendorServicesReducer from "./slices/vendorServices/vendorservice.slice";
import edituserReducer from "./slices/edituser/edituser.slice";
import skillsReducer from "./slices/skills/skills.slice";
import languageReducer from "./slices/language/language.slice";
import supportReducer from "./slices/support/support.slice";
import techstackReducer from "./slices/teckstack/techstack.slice";
import expertiseReducer from "./slices/expertisebreakup/expertisebreakup.slice";
import teaminfoReducer from "./slices/teamInfo/teamInfo.slice";
import uploadCOQReducer from "./slices/uploadcoq/uploadcoq.slice";
import tagReducer from "./slices/tag/tag.slice";
import shortlistingReducer from "./slices/shortlisting/shortlisting.slice";
import categoryReducer from "./slices/category/category.slice";
import raReducer from "./slices/ra/ra.slice";
import financeApprovalReducer from "./slices/financeApproval/financeApproval.slice"
import onboardReducer from "./slices/onboard/onboard.slice"

const rootReducer = combineReducers({
  user: userReducer,
  project: projectReducer,
  notification: notificationReducer,
  init: initReducer,
  toast: toastReducer,
  toastReducer: authReducer,
  auth: authReducer,
  dashboard: dashboardReducer,
  services: serviceReducer,
  vendor: vendorReducer,
  industry: industryReducer,
  notes: notesReducer,
  venderExist:venderExistReducer,
  listServices: listServicesReducer,
  portfolio: portfolioReducer,
  uploadportfolio: uploadportfolioReducer,
  vendorservices: vendorServicesReducer,
  edituser: edituserReducer,
  skills: skillsReducer,
  language: languageReducer,
  support: supportReducer,
  techstack: techstackReducer,
  expertise: expertiseReducer,
  teaminfo: teaminfoReducer,
  uploadCOQ: uploadCOQReducer,
  tag: tagReducer,
  shortlist: shortlistingReducer,
  category: categoryReducer,
  ra: raReducer,
  financeApproval:financeApprovalReducer,
  onBoard: onboardReducer,
});

export default rootReducer;
