import AppRoutes from "./AppRoutes";
import AppTheme from "./theme";
import GlobalStyles from "./theme/globalStyles";
import { BrowserRouter as Router } from "react-router-dom";
import Toast from "./components/Toast/Toast";

// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";


function App() {
  return (
    <AppTheme>
      <Router>
        <ScrollToTop />
        <BaseOptionChartStyle />
        <GlobalStyles />
        <AppRoutes />
        <Toast />
      </Router>
    </AppTheme>
  );
}

export default App;
