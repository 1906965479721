import { createContext, useState } from "react";
import { profileTableType } from "../utils/enum";

export const VendorContext = createContext("");

function ProfileTableTypeProvider({ children }) {
  const [type, setType] = useState(profileTableType.active);

  function changeType() {
    if (type === profileTableType.active) {
      return setType(profileTableType.all);
    }
    setType(profileTableType.active);
  }

  return (
    <VendorContext.Provider value={{ type, changeType }}>
      {children}
    </VendorContext.Provider>
  );
}

export default ProfileTableTypeProvider;
