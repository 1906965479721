import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  formGroup: {
    marginBottom: "15px",
    width: "100%",
  },

  formControl: {
    display: "block",
    height: "50px",
    width: "100%",
    padding: "12px",
    fontSize: "1rem",
    fontWeight: "400",
    color: "#637381",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid #ced4da",
    webkitAppearance: "none",
    mozAppearance: "none",
    appearance: "none",
    borderRadius: "0.5rem",
    outline: " none",
  },
  textareaFormControl: {
    display: "block",
    outline: " none",
    width: "100%",
    padding: "12px",
    fontSize: "1rem",
    fontWeight: "400",
    resize: "none",
    color: "#637381",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid #ced4da",
    webkitAppearance: "none",
    mozAppearance: "none",
    appearance: "none",
    borderRadius: "0.25rem",
    fontFamily: "Public Sans,sans-serif",
  },
  formLabel: {
    marginBottom: "0.5rem",
    display: "inline-block",
    color: "#212b36",
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  formLabelCheckbox: {
    marginBottom: ".5rem",
    display: "inline-block",
    color: "#212b36",
    fontWeight: "bold",
    fontSize: "1.3rem",
  },
  formText: {
    marginTop: "0.25rem",
    fontSize: ".875em",
    color: "#6c757d",
  },

  formErrorStyle: {
    marginTop: "0.25rem",
    fontSize: ".875em",
    color: "red",
    display: "block",
    marginLeft: "0.5rem",
  },
  InnerText: {
    float: "right",
    marginTop: "0.25rem",
    fontSize: ".875em",
    color: "#6c757d",
  },
  formGroupSelect: {
    marginBottom: "15px",
    position: "relative",
  },
  DownArrow: {
    transform: "rotate(45deg)",
    "-webkit-transform": "rotate(45deg)",
    position: "absolute",
    top: "45px",
    right: "12px",
  },
  Arrow: {
    border: "solid #ced4d9",
    borderWidth: " 0 3px 3px 0",
    display: "inline-block",
    padding: " 3px",
  },
  dropdownWrapper: {
    position: "relative",
  },
  dropDown: {
    borderRadius: "4px",
    boxShadow: "0 0 2px rgb(204, 204, 204)",
    backgroundColor: "#fff",
    maxHeight: "170px",
    overflow: "auto",
    listStyle: "none",
    padding: "5px 0px",
  },
  dropdownItem: {
    padding: "8px 12px",
    "&:hover": {
      backgroundColor: "rgb(0,171,85,.05)",
      color: "rgb(0,171,85)",
      cursor: "pointer",
      fontWeight: 600,
      borderRadius: "8px",
    },
  },
  menuWrapper: {
    position: "absolute",
    top: 84,
    width: "100%",
    zIndex: "50",
  },
  filename: {
    marginTop: "10px",
    marginLeft: "0px",
    color: "#aa9",
    fontSize: "14px",
    "&:hover": {
      color: "#4287f5 !important",
      cursor: "pointer",
      fontWeight: 600,
    },
  },
  urlFormGroup: {
    position: "relative",
    display: "flex",
    marginBottom: "15px",
    width: "100%",
    background: "transparent",
    borderRadius: "0.5rem",
    border: "1px solid #ced4da",
  },

  urlFormControl: {
    display: "block",
    height: "50px",
    maxWidth: "100px",
    width: "100%",
    padding: "12px",
    fontSize: "1rem",
    fontWeight: "400",
    color: "#637381",
    backgroundColor: "#fff",
    backgroundClip: "content-box",
    webkitAppearance: "none",
    mozAppearance: "none",
    appearance: "none",
    borderRadius: "0.5rem",
    outline: " none",
    border: 0,
    background: "transparent",
    borderRight: "1px solid #ced4da",
    borderRadius: 0,
  },
  customFormControl: {
    display: "block",
    height: "50px",
    width: "100%",
    padding: "12px",
    fontSize: "1rem",
    fontWeight: "400",
    color: "#637381",
    backgroundColor: "#fff",
    backgroundClip: "content-box",
    border: 0,
    webkitAppearance: "none",
    mozAppearance: "none",
    appearance: "none",
    borderRadius: "0.5rem",
    outline: " none",
  },
});
