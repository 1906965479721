import React, { useState } from "react";
import {
  Container,
  Stack,
  Typography,
  Grid,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { tabChange } from "../../store/slices/project/project.slice";
import PersonalDetails from "./PersonalDetails";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import EditPersonalDetails from "./EditPersonalDetails";
import Page from "../../components/Page";
import EditPassword from "./EditPassword";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Container sx={{ my: 4, mx: "auto" }}>
      {value === index ? children : null}
    </Container>
  );
}

const MyProfile = () => {
  const [profileEditMode, setProfileEditMode] = useState(false);

  const selectedTab = useSelector(({ project }) => project.selectedTab);

  const { profile } = useSelector(({ user }) => user.userData);

  const dispatch = useDispatch();

  const handleprofileEditMode = (val) => {
    setProfileEditMode(val);
  };

  const resetEditMode = () => {
    setProfileEditMode(false);
  };

  function handleTabChange(e, value) {
    dispatch(tabChange(value));
    resetEditMode();
  }

  return (
    <Page title="Dashboard: Blog | EMB">
      <Container sx={{ margin: 0 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            My Profile
          </Typography>
          <Breadcrumb />
        </Stack>
        <Grid>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab
                icon={<AccountBoxIcon />}
                iconPosition="start"
                label="Profile"
              />
              <Tab
                icon={<VpnKeyIcon />}
                iconPosition="start"
                label="Change Password"
              />
            </Tabs>
          </Box>
          <TabPanel value={selectedTab} index={0}>
            {profileEditMode ? (
              <EditPersonalDetails
                handleprofileEditMode={handleprofileEditMode}
                data={profile}
              />
            ) : (
              <PersonalDetails handleprofileEditMode={handleprofileEditMode} />
            )}
          </TabPanel>

          <TabPanel value={selectedTab} index={1}>
            <EditPassword />
          </TabPanel>
        </Grid>
      </Container>
    </Page>
  );
};

export default MyProfile;
