import {
  getAllTags,
  addTag,
  deleteTag,
  updateTag,
} from "../../../utils/apis.utils";
import { fetchAllTagWithPaginationThunk } from "../tag/tag.slice";

import { startTagLoader, stopTagLoader } from "../tag/tag.slice";

import { showFailureToast, showSuccessToast } from "../toast/toast.slice";

export const fetchAllTagHandler = async (page = 1, thunkApi) => {
  thunkApi.dispatch(startTagLoader());
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(getAllTags);
  thunkApi.dispatch(stopTagLoader());
  return {
    data: response.data,
    total: response.total,
  };
};

export const fetchAllTagWithPaginationHandler = async (page = 1, thunkApi) => {
  thunkApi.dispatch(startTagLoader());
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(getAllTags + "?is_pagination=1&page=" + page);
  thunkApi.dispatch(stopTagLoader());
  return {
    data: response.data,
    total: response.total,
  };
  // return response.data;
};

export const deleteTagHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startTagLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.delete(deleteTag + "/" + data.id);
    thunkApi.dispatch(stopTagLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllTagWithPaginationThunk(data.page));
    // return id;
  } catch (err) {
    thunkApi.dispatch(stopTagLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopTagLoader());
  }
};

export const addTagHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startTagLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(addTag, { tag: data.tag });
    thunkApi.dispatch(stopTagLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllTagWithPaginationThunk(data.page));

    // return response.data;
  } catch (err) {
    thunkApi.dispatch(stopTagLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopTagLoader());
  }
};

export const updateTagHandler = async (data, thunkApi) => {
  var id = data.id;
  var apiData = {
    tag: data.tag,
  };

  try {
    thunkApi.dispatch(startTagLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(updateTag + "/" + id, apiData);
    thunkApi.dispatch(stopTagLoader());

    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopTagLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopTagLoader());
  }
};
