import {
  FormControl,
  FormGroup,
  Input,
  MenuItem,
  Select,
  OutlinedInput,
} from "@mui/material";
import { advanceFilterFieldType } from "../../utils/enum";
import StyledTreeItem from "./StylesTreeItem";

function FilterInput({ label, value, handleFilterValueChange, state }) {
  function handleSearchValueChange(e) {
    const eleValue = e.target.value;
    handleFilterValueChange(advanceFilterFieldType.text, value, eleValue);
  }

  function searchTextChange(e) {
    const eleValue = e.target.value;
    handleFilterValueChange(advanceFilterFieldType.text, value, null, eleValue);
  }

  return (
    <StyledTreeItem nodeId={value} labelText={label}>
      <FormGroup>
        <FormControl sx={{ mt: 2, ml: 2, maxWidth: 150 }}>
          <Select
            sx={{ height: "40px" }}
            placeholder="Select Choice"
            value={state.search_type}
            onChange={handleSearchValueChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="is">is</MenuItem>
            <MenuItem value="is_not">is not</MenuItem>
            <MenuItem value="contains">contains</MenuItem>
            <MenuItem value="not_contains">not contains</MenuItem>
            <MenuItem value="start_with">starts with</MenuItem>
            <MenuItem value="ends_with">ends with</MenuItem>
            <MenuItem value="is_empty">is empty</MenuItem>
            <MenuItem value="is_not_empty">is not empty</MenuItem>
            <MenuItem value="equals_to">equals to</MenuItem>
          </Select>
        </FormControl>
      </FormGroup>
      <FormControl sx={{ m: 2, width: 300 }}>
        <OutlinedInput
          sx={{ height: "40px" }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          value={state.values && state.values[0]}
          onChange={searchTextChange}
          placeholder="Search"
          variant="outlined"
        />
      </FormControl>
    </StyledTreeItem>
  );
}

export default FilterInput;
