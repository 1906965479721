import React, { useEffect, useState } from "react";
import "./index.css";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import styled from "styled-components";
import {
  Typography,
  Box,
  Card,
  Stack,
  Button,
  Grid,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Rating,
  Avatar,
  IconButton,
  Tooltip,
  CardContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { css } from "@emotion/css";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import doneAllFill from "@iconify/icons-eva/done-all-fill";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StarIcon from "@mui/icons-material/Star";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useParams } from "react-router-dom";
import { fetchVendorProfileDetailsThunk } from "../../store/slices/vendor/vendor.slice";
import { useDispatch, useSelector } from "react-redux";
import CustomSide from "./SideBar";
import {
  allVendorServicesThunk,
  vendorBusinessAddressThunk,
  vendorNotesThunk,
  addNotesByIdThunk,
  fetchVendorViewPortfolioThunk,
} from "../../store/slices/viewProfile/viewProfile.slice";

import { DateTime } from "luxon";
import { storeOrgId } from "../../utils/global/vendor.global";
import { blue } from "@mui/material/colors";
import Modal from "../../components/Modal/Modal";
import ViewRatingKam from "./viewRatingKam";
import ViewRatingSls from "./viewRatingSls";
import NotesModal from "./NotesModal";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function ViewProfile() {
  const useStyles = makeStyles({
    btnHover: {
      "&:hover": {
        background: "#d93460",
      },
    },
    btnHover_Kam: {
      "&:hover": {
        background: "#348dd9",
      },
    },
  });
  const [ratingVal, setRating] = React.useState(5);
  const [ratingVal2, setRating2] = React.useState(4);
  const [ratingVal3, setRating3] = React.useState(3);
  const [ratingVal4, setRating4] = React.useState(2);
  const [ratingVal5, setRating5] = React.useState(4);
  const [ratingVal6, setRating6] = React.useState(1);
  const [ratingVal7, setRating7] = React.useState(3);
  const { profileData } = useSelector(({ vendor }) => vendor);
  // const vendorData = useSelector(({ vendor }) => vendor);
  const [showModal, setShowModal] = useState(false);
  const [showNotesMod, setShowNotesMod] = useState(false);

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const NotesModOpen = () => setShowNotesMod(true);
  const NotesModClose = () => setShowNotesMod(false);

  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const { list, address, notes } = useSelector(
    ({ listServices }) => listServices
  );

  useEffect(() => {
    storeOrgId(params.id);
    dispatch(fetchVendorProfileDetailsThunk());
    dispatch(allVendorServicesThunk());
    dispatch(vendorNotesThunk());
    dispatch(vendorBusinessAddressThunk());
    dispatch(fetchVendorViewPortfolioThunk());
  }, [dispatch, params.id]);

  return (
    <div>
      <div className="left">
        <CustomSide />
      </div>
      <div className="view">
        <Card sx={{ padding: "2rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <img
                src="https://cdn.pixabay.com/photo/2017/10/17/16/10/fantasy-2861107__340.jpg"
                width="320"
                height="200"
                alt=""
              />
              <Typography mt={1} variant="h5" align="center">
                Founded 2020 Private Ltd
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h5">
                {profileData?.brand_name}
                {/* Re-equil Solutions */}
              </Typography>
              <Typography variant="h6">
                <a href="/" style={{ cursor: "pointer" }}>
                  {/* www.exmyb.com */}
                  {profileData?.registered_business_name}
                </a>
              </Typography>
              <Typography mt={2}>{profileData?.description}</Typography>
            </Grid>
          </Grid>
        </Card>

        {/* Services */}

        <Box mt={5}>
          <Card sx={{ padding: "2rem" }}>
            <Typography
              mb={4}
              variant="h4"
              style={{
                borderLeft: " 6px #1f46c9 solid",
                paddingLeft: "5px",
                lineHeight: " 1.1",
              }}
            >
              Services{" "}
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Min Price</TableCell>
                    <TableCell>Max Price</TableCell>
                    {/* <TableCell>Skills</TableCell> */}
                    <TableCell>Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.service_name}
                      </TableCell>
                      <TableCell>{row.min_price}</TableCell>
                      <TableCell>{row.max_price}</TableCell>
                      <TableCell>
                        <Tooltip title={row.notes}>
                          <IconButton color="primary">
                            <Icon icon="bx:comment-detail" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Box>

        {/* Portfolio */}
        <div className="portfolio">
          <Box mt={5} mb={5}>
            <Card sx={{ padding: "2rem" }}>
              <Typography
                mb={4}
                variant="h4"
                style={{
                  borderLeft: " 6px #c91f6d solid",
                  paddingLeft: "5px",
                  lineHeight: " 1.1",
                }}
              >
                Portfolio{" "}
              </Typography>

              <Grid container spacing={5}>
                <Grid item xs={5}>
                  <img
                    src="https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832__480.jpg"
                    alt=""
                    width="400"
                    height="300"
                  />
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="h4">
                    {profileData?.brand_name}
                  </Typography>
                  <Typography variant="h6">
                    {profileData?.registered_business_name}
                    <br /> 2018-2019
                  </Typography>

                  <Typography>{profileData?.description}</Typography>

                  <Box mt={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                        Website:
                      </Typography>
                      <Box sx={{ fontSize: "17px" }}>
                        <a href="https://www.exmyb.com/"> www.exmyb.com</a>
                      </Box>
                    </Stack>
                  </Box>

                  <Box>
                    <Stack direction="row" spacing={1}>
                      <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                        App Link:
                      </Typography>
                      <Box sx={{ fontSize: "17px" }}>
                        <a href="https://www.exmyb.com/"> www.exmyb.com</a>
                      </Box>
                    </Stack>
                  </Box>
                  <Box>
                    <Stack direction="row" spacing={1}>
                      <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                        Industry:
                      </Typography>
                      <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                        Fashion Industry
                      </Typography>
                    </Stack>
                  </Box>
                  <Box>
                    <Stack direction="row" spacing={1}>
                      <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                        Service:
                      </Typography>
                      <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                        Website Development
                      </Typography>
                    </Stack>
                  </Box>
                  <Box>
                    <Stack direction="row" spacing={1}>
                      <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                        Added on:
                      </Typography>
                      <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                        Oct 26, 2019
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                {/* <Grid item xs={4}>
  <img src="https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832__480.jpg" height="209" alt="" />
</Grid>
<Grid item xs={4}>
  <img src="https://cdn.pixabay.com/photo/2013/10/02/23/03/dog-190056__480.jpg" alt="" />
</Grid>
<Grid item xs={4}>
  <img src="https://cdn.pixabay.com/photo/2017/12/15/13/51/polynesia-3021072__340.jpg" alt="" />
</Grid> */}
              </Grid>

              <Box mt={5}>
                <Grid container spacing={5}>
                  <Grid item xs={2}>
                    <img
                      src="https://cdn.pixabay.com/photo/2017/10/17/16/10/fantasy-2861107__340.jpg"
                      height="100"
                      alt=""
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      {profileData?.brand_name}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ color: "#525252", fontWeight: "bold" }}
                    >
                      {" "}
                      {profileData?.registered_business_name}
                      <br /> 2018-2019
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{ color: "#999999" }}
                    >
                      Skincare
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <img
                      src="https://cdn.pixabay.com/photo/2017/10/17/16/10/fantasy-2861107__340.jpg"
                      height="100"
                      alt=""
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      {profileData?.brand_name}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ color: "#525252", fontWeight: "bold" }}
                    >
                      {" "}
                      {profileData?.registered_business_name}
                      <br /> 2018-2019
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{ color: "#999999" }}
                    >
                      Skincare
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
        </div>

        {/* Buisness Documents */}

        <Box mt={5}>
          <Card sx={{ padding: "2rem" }}>
            <Typography
              mb={4}
              variant="h4"
              style={{
                borderLeft: " 6px #33c888 solid",
                paddingLeft: "5px",
                lineHeight: " 1.1",
              }}
            >
              Buisness Documents
            </Typography>

            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Box>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                      Gst Number:
                    </Typography>
                    <Typography sx={{ fontSize: "18px" }}>
                      {profileData.gst_number}
                    </Typography>
                  </Stack>
                </Box>

                <Box mt={3}>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                      Pan Number:
                    </Typography>
                    <Typography sx={{ fontSize: "18px" }}>
                      {profileData.pan_number}
                    </Typography>
                  </Stack>
                </Box>

                <Box mt={3}>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                      Corp Number:
                    </Typography>
                    <Typography sx={{ fontSize: "18px" }}>
                      {profileData.incorporation_number}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box>
                  <Stack direction="row" spacing={1}>
                    <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                      Gst Number File:
                    </Typography>
                    <Box sx={{ fontSize: "18px" }}>
                      <a
                        href={
                          profileData.gst_file == undefined
                            ? ""
                            : profileData.gst_file.url
                        }
                      >
                        {profileData.gst_file == undefined
                          ? ""
                          : profileData.gst_file.filename}
                      </a>
                    </Box>
                  </Stack>
                </Box>
                <Box mt={3}>
                  <Stack direction="row" spacing={1}>
                    <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                      Pan Number File:
                    </Typography>
                    <Box sx={{ fontSize: "18px" }}>
                      <a
                        href={
                          profileData.pan_file == undefined
                            ? ""
                            : profileData.pan_file.url
                        }
                      >
                        {profileData.pan_file == undefined
                          ? ""
                          : profileData.pan_file.filename}
                      </a>
                    </Box>
                  </Stack>
                </Box>
                <Box mt={3}>
                  <Stack direction="row" spacing={1}>
                    <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                      Corp Number File:
                    </Typography>
                    <Box sx={{ fontSize: "18px" }}>
                      <a
                        href={
                          profileData.incorporation_file == undefined
                            ? ""
                            : profileData.incorporation_file.url
                        }
                      >
                        {profileData.incorporation_file == undefined
                          ? ""
                          : profileData.incorporation_file.filename}
                      </a>
                    </Box>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>

        {/* Contact */}
        {/* 
        <Box mt={5}>
          <Card sx={{ padding: "2rem" }}>
            <Typography
              mb={4}
              variant="h4"
              style={{
                borderLeft: " 6px #348dd9 solid",
                paddingLeft: "5px",
                lineHeight: " 1.1",
              }}
            >
              Contacts{" "}
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Full Name</TableCell>
                    <TableCell align="center">Designation</TableCell>
                    <TableCell align="center">Phone Number</TableCell>
                    <TableCell align="center">Email Address</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contacts.map((contact) => (
                    <TableRow
                      key={contact.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {contact.fullName}
                      </TableCell>
                      <TableCell align="center">
                        {contact.designation}
                      </TableCell>
                      <TableCell align="center">{contact.phone}</TableCell>
                      <TableCell align="center">{contact.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Box> */}

        {/* Address */}

        <Box mt={5}>
          <Card sx={{ padding: "2rem" }}>
            <Typography
              mb={4}
              variant="h4"
              style={{
                borderLeft: " 6px #fdbfb2 solid",
                paddingLeft: "5px",
                lineHeight: " 1.1",
              }}
            >
              Address{" "}
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Address 1</TableCell>
                    <TableCell align="center">Address 2</TableCell>
                    <TableCell align="center">City</TableCell>
                    <TableCell align="center">State</TableCell>
                    <TableCell align="center">Country</TableCell>
                    <TableCell align="center">Pin Code</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {address.map((address) => (
                    <TableRow
                      key={address.address1}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {address.address_line1}
                      </TableCell>
                      <TableCell align="center">
                        {address.address_line2}
                      </TableCell>
                      <TableCell align="center">{address.city}</TableCell>
                      <TableCell align="center">{address.state}</TableCell>
                      <TableCell align="center">{address.country}</TableCell>
                      <TableCell align="center">{address.zip_code}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Box>

        {/* Notes */}

        <Box mt={5}>
          <Card sx={{ padding: "2rem" }}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography
                mb={4}
                variant="h4"
                style={{
                  borderLeft: " 6px #c91f6d solid",
                  paddingLeft: "5px",
                  lineHeight: " 1.1",
                }}
              >
                Notes{" "}
              </Typography>
              <Box>
                <Button
                  size="large"
                  variant="contained"
                  onClick={NotesModOpen}
                  startIcon={<Icon icon={plusFill} />}
                >
                  Add Note
                </Button>
              </Box>
            </Stack>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Message</TableCell>
                    <TableCell align="center">Added By</TableCell>
                    <TableCell align="center">Timestamp</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notes.map((note) => (
                    <TableRow
                      key={note.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {note.notes_content}
                      </TableCell>
                      <TableCell align="center">{note.created_by}</TableCell>
                      <TableCell align="center">
                        {DateTime.fromISO(note.created_at).toFormat("DD")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box mt={5} ml={2}></Box>
          </Card>
        </Box>

        {/* Review */}

        <Box mt={5}>
          <Card sx={{ padding: "2rem" }}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography
                variant="h4"
                style={{
                  borderLeft: " 6px #c0a2d1 solid",
                  paddingLeft: "5px",
                  lineHeight: " 1.1",
                }}
              >
                Reviews
              </Typography>
              <Box>
                <Button
                  size="large"
                  variant="contained"
                  onClick={handleOpen}
                  startIcon={<Icon icon={plusFill} />}
                >
                  Add Reviews
                </Button>
              </Box>
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card
                  sx={{
                    boxShadow: "0 1px 6px 0 rgb(32 33 36 / 28%)",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={2}
                    sx={{
                      background: "#f4f4f4",
                      padding: "10px",
                      color: "#a4a4a4",
                    }}
                  >
                    <Typography variant="h6">
                      Shopify Website for Skincare
                    </Typography>

                    <Button
                      className={classes.btnHover}
                      sx={{
                        background: "#d93460",
                        color: "#fff",
                        borderRadius: "10px",
                        fontWeight: "bold",
                        padding: "2px 10px",
                      }}
                      variant="contained"
                    >
                      SLS
                    </Button>
                  </Stack>

                  <CardContent sx={{ padding: "2rem" }}>
                    <Typography variant="h4">Website Development</Typography>

                    <Box mt={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            sx={{ marginBottom: "6px" }}
                          >
                            Communication Skills
                          </Typography>

                          <Typography
                            variant="body1"
                            sx={{ marginBottom: "6px" }}
                          >
                            Quotation Quality
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ marginBottom: "6px" }}
                          >
                            Turnaround Time
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ marginBottom: "6px" }}
                          >
                            Budget
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ marginBottom: "6px" }}
                          >
                            Logical Understanding
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ marginBottom: "6px" }}
                          >
                            Quality of past-work
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ marginBottom: "6px" }}
                          >
                            Budget Accuracy
                          </Typography>
                        </Grid>

                        <Grid item xs={5}>
                          <Rating
                            name="simple-controlled"
                            value={ratingVal}
                            onChange={(event, newValue) => {
                              setRating(newValue);
                            }}
                            readOnly
                          />

                          <Rating
                            name="simple-controlled"
                            value={ratingVal2}
                            onChange={(event, newValue) => {
                              setRating2(newValue);
                            }}
                            readOnly
                          />
                          <Rating
                            name="simple-controlled"
                            value={ratingVal3}
                            onChange={(event, newValue) => {
                              setRating3(newValue);
                            }}
                            readOnly
                          />
                          <Rating
                            name="simple-controlled"
                            value={ratingVal4}
                            onChange={(event, newValue) => {
                              setRating4(newValue);
                            }}
                            readOnly
                          />
                          <Rating
                            name="simple-controlled"
                            value={ratingVal5}
                            onChange={(event, newValue) => {
                              setRating5(newValue);
                            }}
                            readOnly
                          />
                          <Rating
                            name="simple-controlled"
                            value={ratingVal6}
                            onChange={(event, newValue) => {
                              setRating6(newValue);
                            }}
                            readOnly
                          />
                          <Rating
                            name="simple-controlled"
                            value={ratingVal7}
                            onChange={(event, newValue) => {
                              setRating7(newValue);
                            }}
                            readOnly
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box
                      mt={3}
                      sx={{
                        background: "#f2f8ff",
                        borderRadius: "8px",
                        padding: "8px",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <Avatar sx={{ bgcolor: "#d93460" }}>S</Avatar>
                        </Grid>
                        <Grid item xs={10} sx={{ marginLeft: "-21px" }}>
                          <Typography variant="h6">Sourabh Chaprana</Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#8d8d8d",
                              marginTop: "-5px",
                              fontSize: "12px",
                            }}
                          >
                            Posted 6 months ago
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography sx={{ marginTop: "5px", padding: "7px" }}>
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Debitis, molestias a earum quisquam fuga
                        dignissimos itaque exercitationem iure incidunt
                        inventore.
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={6}>
                <Card
                  sx={{
                    boxShadow: "0 1px 6px 0 rgb(32 33 36 / 28%)",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={2}
                    sx={{
                      background: "#f4f4f4",
                      padding: "10px",
                      color: "#a4a4a4",
                    }}
                  >
                    <Typography variant="h6">
                      Shopify Website for Skincare
                    </Typography>

                    <Button
                      className={classes.btnHover_Kam}
                      sx={{
                        background: "#348dd9",
                        color: "#fff",
                        borderRadius: "10px",
                        fontWeight: "bold",
                        padding: "2px 10px",
                      }}
                      variant="contained"
                    >
                      KAM
                    </Button>
                  </Stack>

                  <CardContent sx={{ padding: "2rem" }}>
                    <Typography variant="h4">Website Development</Typography>

                    <Box mt={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            sx={{ marginBottom: "6px" }}
                          >
                            Communication Skills
                          </Typography>

                          <Typography
                            variant="body1"
                            sx={{ marginBottom: "6px" }}
                          >
                            Quotation Quality
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ marginBottom: "6px" }}
                          >
                            Turnaround Time
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ marginBottom: "6px" }}
                          >
                            Budget
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ marginBottom: "6px" }}
                          >
                            Logical Understanding
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ marginBottom: "6px" }}
                          >
                            Quality of past-work
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ marginBottom: "6px" }}
                          >
                            Budget Accuracy
                          </Typography>
                        </Grid>

                        <Grid item xs={5}>
                          <Rating
                            name="simple-controlled"
                            value={ratingVal}
                            onChange={(event, newValue) => {
                              setRating(newValue);
                            }}
                            readOnly
                          />

                          <Rating
                            name="simple-controlled"
                            value={ratingVal2}
                            onChange={(event, newValue) => {
                              setRating2(newValue);
                            }}
                            readOnly
                          />
                          <Rating
                            name="simple-controlled"
                            value={ratingVal3}
                            onChange={(event, newValue) => {
                              setRating3(newValue);
                            }}
                            readOnly
                          />
                          <Rating
                            name="simple-controlled"
                            value={ratingVal4}
                            onChange={(event, newValue) => {
                              setRating4(newValue);
                            }}
                            readOnly
                          />
                          <Rating
                            name="simple-controlled"
                            value={ratingVal5}
                            onChange={(event, newValue) => {
                              setRating5(newValue);
                            }}
                            readOnly
                          />
                          <Rating
                            name="simple-controlled"
                            value={ratingVal6}
                            onChange={(event, newValue) => {
                              setRating6(newValue);
                            }}
                            readOnly
                          />
                          <Rating
                            name="simple-controlled"
                            value={ratingVal7}
                            onChange={(event, newValue) => {
                              setRating7(newValue);
                            }}
                            readOnly
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box
                      mt={2}
                      sx={{
                        background: "#f2f8ff",
                        borderRadius: "8px",
                        padding: "15px",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <Avatar sx={{ bgcolor: " #348dd9" }}>S</Avatar>
                        </Grid>
                        <Grid item xs={10} sx={{ marginLeft: "-21px" }}>
                          <Typography variant="h6">Sourabh Chaprana</Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#8d8d8d",
                              marginTop: "-5px",
                              fontSize: "12px",
                            }}
                          >
                            Posted 6 months ago
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography sx={{ marginTop: "5px", padding: "7px" }}>
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Debitis, molestias a earum quisquam fuga
                        dignissimos itaque exercitationem iure incidunt
                        inventore.
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Card>
          <Modal
            isOpen={showModal}
            closeModal={handleClose}
            // title=""
            content={<ViewRatingKam />}
            dialogProps={{ fullWidth: true }}
            titleStyle={{ textAlign: "center", marginTop: 2 }}
            actionStyle={{ justifyContent: "center", marginBottom: 2 }}
            action={
              <Button size="large" type="submit" variant="contained">
                Add Review
              </Button>
            }
          />
          ]
          <Modal
            isOpen={showNotesMod}
            closeModal={NotesModClose}
            title="Add Notes"
            content={<NotesModal />}
            dialogProps={{ fullWidth: true }}
            titleStyle={{ textAlign: "center", marginTop: 2 }}
            actionStyle={{ justifyContent: "center", marginBottom: 2 }}
          />
        </Box>
      </div>
    </div>
  );
}

export default ViewProfile;
