import { createSlice } from "@reduxjs/toolkit";
import {
  notificationData,
  notificationNavData,
} from "../../../utils/fakeData/notification.data";

const INIT_STATE = {
  notification: notificationData,
  notificationNav: notificationNavData,
};

const projectSlice = createSlice({
  name: "notification",
  initialState: INIT_STATE,
  reducers: {},
});

export default projectSlice.reducer;
