import {
  getAllSkills,
  deleteSkills,
  addSkills,
  updateSkills,
} from "../../../utils/apis.utils";
import {
  startSkillsLoader,
  stopSkillsLoader,
  fetchAllSkillsThunk,
} from "../skills/skills.slice";

import { showFailureToast, showSuccessToast } from "../toast/toast.slice";

export const fetchAllSkillsHandler = async (__, thunkApi) => {
  thunkApi.dispatch(startSkillsLoader());
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(getAllSkills);
  thunkApi.dispatch(stopSkillsLoader());

  return response.data;
};

export const deleteSkillsHandler = async (id, thunkApi) => {
  try {
    thunkApi.dispatch(startSkillsLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.delete(deleteSkills + "/" + id);
    thunkApi.dispatch(stopSkillsLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return id;
  } catch (err) {
    thunkApi.dispatch(stopSkillsLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopSkillsLoader());
  }
};

export const addSkillsHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startSkillsLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(addSkills, data);
    thunkApi.dispatch(stopSkillsLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopSkillsLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopSkillsLoader());
  }
};

export const updateSkillsHandler = async (data, thunkApi) => {
  var id = data.id;
  var apiData = {
    name: data.name,
  };

  try {
    thunkApi.dispatch(startSkillsLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(updateSkills + "/" + id, apiData);
    thunkApi.dispatch(stopSkillsLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopSkillsLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopSkillsLoader());
  }
};
