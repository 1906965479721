import React from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

export default function SearchNotFound({
  height = "100%",
  width = "100%",
  src = "/static/result-not-found.svg",
  title = "No data to show!",
  subtitle = "There is currently no data to show.",
}) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Box>
              <img
                src={src}
                style={{ width: width, margin: "0 auto", height: height }}
                alt="Not Found"
              />
            </Box>
            {title && <Typography variant="h6">{title}</Typography>}
            <Typography
              variant="body1"
              sx={{ color: grey[500], paddingBottom: "5rem" }}
            >
              {subtitle}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
