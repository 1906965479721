import React, { useState, useEffect, useMemo, useRef } from "react";
import _, { values } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllIndustryThunk } from "../../store/slices/Industry/industry.slice";
import { useTable, useExpanded } from "react-table";
import { ColumnFilterTable } from "../../components/ColumnFilterTable";
import { transformServices } from "../../utils/global/global";
import {
  Button,
  Box,
  Stack,
  Container,
  Grid,
  Typography,
  Card,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useStyles } from "../Services/AddServices.theme";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { rest } from "lodash";
import DeleteAction from "../../components/Common/DeleteAction";
import ServiceMoreMenu from "../Services/ServiceMoreMenu";
import { useFormik, Form, FormikProvider } from "formik";
import FormControl from "@mui/material/FormControl";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { styled } from "@mui/material/styles";
import {
  addIndustry,
  deleteIndustryHandler,
  fetchAllTechStackBySearch,
  fetchAllTechStackByService,
  updateIndustryHandler,
} from "../../utils/global/vendor.global";
import AutocompleteChip from "../../components/Form/AutocompleteChip";
import ServiceInput from "../Services/ServiceInput";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const Industry = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const services = useSelector(({ industry }) => industry);
  const [data, setData] = useState([]);
  const [currentExpanded, setCurrentExpanded] = useState({});

  const inputRef = useRef();

  const { isLoading, industrydata } = services;

  useEffect(() => {
    dispatch(fetchAllIndustryThunk());
  }, [dispatch]);

  useMemo(() => {
    if (services.industrydata?.length !== 0) {
      var data = transformServices(services.industrydata);
      setData(data);
    }
  }, [services.industrydata]);

  function addServiceHandler(props) {
    const updatedExpandedRows = props.expandedRows.reduce((acc, val) => {
      return {
        ...acc,
        [val.id]: val.isExpanded,
      };
    }, {});

    var newData = addNewRow(data, props.row.original._id);
    function addNewRow(data, selID) {
      if (!data) return [];
      return data.map((service) => {
        var { subRows, _id, ...rest } = service;
        if (_id === selID)
          return {
            _id,
            ...rest,
            subRows: [
              ...subRows,
              {
                parent_id: _id,
                isNew: true,
              },
            ],
          };
        else {
          return {
            _id,
            subRows: subRows.length === 0 ? [] : addNewRow(subRows, selID),
            ...rest,
          };
        }
      });
    }
    setCurrentExpanded(updatedExpandedRows);
    setData(newData);
  }

  function delServiceHandler(props) {
    const updatedExpandedRows = getUpdatedExpandState(props);
    deleteIndustryHandler(props.row.original?._id);
    const clonedState = _.cloneDeep(data);
    (function changeEditStatus(data) {
      for (let i = 0; i < data.length; i++) {
        var ele = data[i];
        if (ele._id === props.row.original?._id) {
          data.splice(i, 1);
        }
        if (ele.subRows?.length > 0) {
          changeEditStatus(ele.subRows);
        }
      }
    })(clonedState);
    setCurrentExpanded(updatedExpandedRows);
    setData(clonedState);
  }
  const addNewParent = () => {
    var dataCopy = [
      ...data,
      {
        parent_id: "",
        isEdit: true,
        isNew: true,
      },
    ];
    setData(dataCopy);
  };

  // function handleActiveService(row) {
  //   setSelectedService(row);
  // }

  function handleEditMode(props) {
    const updatedExpandedRows = getUpdatedExpandState(props);
    const clonedState = _.cloneDeep(data);
    (function changeEditStatus(data) {
      for (let i = 0; i < data.length; i++) {
        var ele = data[i];
        if (ele.subRows?.length > 0) {
          changeEditStatus(ele.subRows);
        }
        if (ele._id === props.row.original?._id) {
          ele.isEdit = true;
        } else {
          ele.isEdit = false;
        }
      }
    })(clonedState);
    setCurrentExpanded(updatedExpandedRows);
    setData(clonedState);
  }

  function getUpdatedExpandState(props) {
    return props.expandedRows.reduce((acc, val) => {
      return {
        ...acc,
        [val.id]: val.isExpanded,
      };
    }, {});
  }

  function handleCancel(props) {
    const updatedExpandedRows = getUpdatedExpandState(props);

    const clonedState = _.cloneDeep(data);
    (function changeEditStatus(data) {
      for (let i = 0; i < data.length; i++) {
        var ele = data[i];
        if (ele.subRows?.length > 0) {
          changeEditStatus(ele.subRows);
        }
        if (ele._id === props.row.original?._id) {
          if (ele.isNew) data.splice(i, 1);
          else ele.isEdit = false;
        }
      }
    })(clonedState);
    setCurrentExpanded(updatedExpandedRows);
    setData(clonedState);
  }

  function handleSave(props) {
    const updatedExpandedRows = getUpdatedExpandState(props);

    var promiseFunction = props.row.original.isNew
      ? addIndustry
      : updateIndustryHandler;

    const serverData = {
      title: inputRef.current.value,
      parent_id: props.row.original.parent_id,
    };

    if (!props.row.original.isNew) {
      serverData._id = props.row.original._id;
    } else {
      serverData.slug = inputRef.current.value
        ?.toLowerCase()
        ?.split(" ")
        ?.join("-");
      // serverData.tech_stacks = [];
    }

    promiseFunction(serverData).then(() => {
      dispatch(fetchAllIndustryThunk());
      setCurrentExpanded(updatedExpandedRows);
    });
  }

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
          return (
            <span {...getToggleAllRowsExpandedProps()}>
              {isAllRowsExpanded ? (
                <ExpandLessIcon />
              ) : (
                <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
              )}
            </span>
          );
        },
        Cell: (props) => {
          const { row } = props;
          return (
            row.canExpand && (
              <span
                {...row.getToggleRowExpandedProps({
                  style: {
                    paddingLeft: `${row.depth * 2}rem`,
                  },
                })}
              >
                {row.isExpanded ? (
                  <ExpandMoreIcon />
                ) : (
                  <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
                )}
              </span>
            )
          );
        },
      },

      {
        Header: "Title",
        accessor: "title",
        Cell: function (props) {
          if (props.row.original.isEdit || props.row.original.isNew) {
            return (
              <ServiceInput
                initValue={props.row.values.title}
                inputRef={inputRef}
              />
            );
          }
          return (
            <p
              onDoubleClick={() => handleEditMode(props)}
              style={{
                paddingLeft: `${props.row.depth * 2}rem`,
              }}
            >
              {props.row.values.title}
            </p>
          );
        },
      },
      {
        Header: "Action",
        accessor: "",
        Cell: function (props) {
          const rowData = props.row.original;

          if (rowData.isNew || rowData.isEdit) {
            return (
              <Stack
                direction="row"
                sx={{ paddingRight: `${props.row.depth * 7}rem` }}
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Button size="small" onClick={() => handleSave(props)}>
                  Save
                </Button>
                <Button size="small" onClick={() => handleCancel(props)}>
                  Cancel
                </Button>
              </Stack>
            );
          }

          return (
            <>
              <Stack
                direction="row"
                sx={{ paddingRight: `${props.row.depth * 7}rem` }}
                spacing={2}
                justifyContent="center"
              >
                <ServiceMoreMenu
                  handleAddRow={() => addServiceHandler(props)}
                  // selectTeckStack={() => handleActiveService(rowData)}
                />
                <DeleteAction handleDelete={() => delServiceHandler(props)} />
              </Stack>
            </>
          );
        },
      },
    ],
    [data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns: columns,
      data,
      initialState: {
        expanded: currentExpanded,
      },
    },
    useExpanded
  );

  // useEffect(() => {
  //   if (selectedService) {
  //     fetchAllTechStackByService(selectedService?._id).then((response) => {
  //       setSelectedTeckStacks(response.data);
  //     });
  //   }
  // }, [selectedService]);

  // function handleTechStackDelete(value) {
  //   updateIndustryHandler({
  //     ...selectedService,
  //     tech_stacks: value,
  //   }).then((response) => {
  //     setSelectedTeckStacks(response.data?.tech_stacks);
  //   });
  // }

  async function getOptions(value) {
    const data = await fetchAllTechStackBySearch(value);
    return data.data.map((lan) => ({
      ...lan,
      label: lan.name,
      value: lan.name,
    }));
  }

  // function handleTeckStackChange(value) {
  //   if (value) {
  //     updateIndustryHandler({
  //       ...selectedService,
  //       tech_stacks: [
  //         ...selectedService.tech_stacks,
  //         {
  //           id: value?._id,
  //           name: value.name,
  //         },
  //       ],
  //     }).then((response) => {
  //       setSelectedTeckStacks(response.data?.tech_stacks);
  //     });
  //   }
  // }

  return (
    <>
      <Container>
        <Grid container spacing={2} mt={5}>
          <Grid item md={12}>
            <Card sx={{ minHeight: "420px", py: 2, px: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                m={2}
              >
                <Typography variant="h6" gutterBottom>
                  Add Industry
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Box>
                  <Button
                    variant="contained"
                    onClick={addNewParent}
                    className={classes.button}
                  >
                    Add Industry
                  </Button>
                </Box>
              </Stack>
              <Grid item md={12}>
                <div className={classes.dropdownTable}>
                  <table {...getTableProps()} className={classes.table}>
                    <thead className={classes.thead}>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              className={classes.th}
                              {...column.getHeaderProps()}
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  className={classes.td}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        {/* 
        {selectedService && (
          <Grid container spacing={2} mt={5}>
            <Grid item md={12}>
              <Card sx={{ minHeight: "250px", py: 2, px: 1 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  m={2}
                >
                  <Typography variant="h6" gutterBottom>
                    {selectedService?.title}
                  </Typography>
                </Stack>

                <Grid item md={12} p={2}>
                  <Stack direction="row" spacing={3}>
                    <AutocompleteChip
                      label="Add Teck Stack"
                      chipsData={teckStacks}
                      deleteChip={handleTechStackDelete}
                      getOptions={getOptions}
                      isChipsVisible
                      onChange={handleTeckStackChange}
                    />
                  </Stack>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        )} */}
      </Container>
    </>
  );
};

export default Industry;
