import {
  getAllCategories,
  addCategory,
  deleteCategory,
  updateCategory,
} from "../../../utils/apis.utils";
import { fetchAllCategoryWithPaginationThunk } from "../category/category.slice";

import {
  startCategoryLoader,
  stopCategoryLoader,
} from "../category/category.slice";

import { showFailureToast, showSuccessToast } from "../toast/toast.slice";

export const fetchAllCategoryHandler = async (page = 1, thunkApi) => {
  thunkApi.dispatch(startCategoryLoader());
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(getAllCategories);
  thunkApi.dispatch(stopCategoryLoader());
  return {
    data: response.data,
    total: response.total,
  };
};

export const fetchAllCategoryWithPaginationHandler = async (
  page = 1,
  thunkApi
) => {
  thunkApi.dispatch(startCategoryLoader());
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(
    getAllCategories + "?is_pagination=1&page=" + page
  );
  thunkApi.dispatch(stopCategoryLoader());
  return {
    data: response.data,
    total: response.total,
  };
  // return response.data;
};

export const deleteCategoryHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startCategoryLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.delete(deleteCategory + "/" + data.id);
    thunkApi.dispatch(stopCategoryLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllCategoryWithPaginationThunk(data.page));
    // return id;
  } catch (err) {
    thunkApi.dispatch(stopCategoryLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopCategoryLoader());
  }
};

export const addCategoryHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startCategoryLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(addCategory, { name: data.name });
    thunkApi.dispatch(stopCategoryLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllCategoryWithPaginationThunk(data.page));

    // return response.data;
  } catch (err) {
    thunkApi.dispatch(stopCategoryLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopCategoryLoader());
  }
};

export const updateCategoryHandler = async (data, thunkApi) => {
  var id = data.id;
  var apiData = {
    name: data.name,
  };

  try {
    thunkApi.dispatch(startCategoryLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(updateCategory + "/" + id, apiData);
    thunkApi.dispatch(stopCategoryLoader());

    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopCategoryLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopCategoryLoader());
  }
};
