/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import useForm from "../hooks/useForm";
import Element from "../components/Form/Element";
import { inputType } from "../utils/enum";
import { techStackOptions } from "../utils/options.util";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Icon } from "@iconify/react/dist/iconify.js";
import plusFill from "@iconify/icons-eva/plus-fill";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  createTechStackForteThunk,
  deleteTechStackForteThunk,
  fetchTechStackForteThunk,
  updateTeckStackForteThunk,
} from "../store/slices/vendor/vendor.slice";
import ResourceBifercationAndTeckStack from "./ResourceBifercationAndTeckStack";
import usePermissions from "../hooks/usePermission";
import systemPermissions, { actions } from "../utils/global/permissions.global";

const TechStackForte = () => {
  const [selectedPlatform, setSelectedPlatform] = useState([]);
  const dispatch = useDispatch();
  const { techStackForteList } = useSelector(({ vendor }) => vendor);
  const userPerm = usePermissions();

  const handleClick = (_, item) => {
    const tech_stack = item.tech_stack;


    if (item.id) {
      dispatch(
        updateTeckStackForteThunk({
          data: {tech_stack: tech_stack},
          id: item.id,
        })
      );
      return;
    }
    dispatch(
      createTechStackForteThunk({
        tech_stack: tech_stack,
      })
    );
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: { techStack: techStackForteList ?? [] },
    onSubmit: handleClick,
  });

  const handleAddRating = () => {
    setFieldValue("techStack", [...values.techStack, { tech_stack: "" }]);
  };

  useEffect(() => {
    dispatch(fetchTechStackForteThunk());
  }, [dispatch]);

  useEffect(() => {
    setFieldValue("techStack", techStackForteList);
  }, [techStackForteList]);

  const deleteInputRow = (index, item) => {
    if (item?.id) {
      dispatch(deleteTechStackForteThunk(item?.id));
    } else {
      const remainValue = values?.techStack?.filter(
        (_, i) => _?.tech_stack !== item?.name
      );
      setFieldValue("techStack", remainValue);
    }
    const remainArr = selectedPlatform?.filter((item) => item !== item?.name);
    setSelectedPlatform(remainArr);
  };

  return (
    <Container>
      <Box>
        <Grid container spacing={2} mt={5}>
          <Grid item md={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              m={2}
            >
              <Typography variant="h4" gutterBottom>
                Tech Stack Forte Info
              </Typography>
            </Stack>
            <Grid container spacing={2} p={3} alignItems={"end"}>
              <Typography variant="h6" style={{ marginRight: "20px" }}>
                Let us know what tech stack you are good at?
              </Typography>
              {/* <Grid item md={6} p={3}>
                <Element
                  label="Tech Stack Options"
                  inputProps={{
                    name: "tech_stack_options",
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                  value={values.tech_stack_options}
                  options={techStackOptions?.filter(
                    (item) => !selectedPlatform.includes(item.value)
                  )}
                  errorText={
                    touched.tech_stack_options && errors.tech_stack_options
                  }
                  eletype={inputType.select}
                  icons={
                    <ArrowDropDownIcon
                      sx={{
                        position: "absolute",
                        right: 20,
                        bottom: 24,
                      }}
                    />
                  }
                />
              </Grid> */}

              <Box
                sx={{
                  display: "flex",
                }}
              >
                {userPerm.hasAccess(systemPermissions['vendorProfileDetailPage.techStackForte'],actions.create)&&
                <Button
                  variant="contained"
                  onClick={handleAddRating}
                  startIcon={<Icon icon={plusFill} />}
                >
                  Add
                </Button>
                }
              </Box>
            </Grid>
            {values.techStack?.map((item, index) => (
              <>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2} p={3} alignItems={"end"}>
                    {/* <Grid item md={6} p={3}>
                      <Element
                        label="Tech Stack Options"
                        inputProps={{
                          name: `techStack.${index}.tech_stack`,
                          onChange: handleChange,
                          onBlur: handleBlur,
                        }}
                        value={item.tech_stack}
                        errorText={
                          touched.techStack &&
                          touched.techStack[index] &&
                          errors.techStack &&
                          errors.techStack[index]?.tech_stack
                        }
                        eletype={inputType.input}
                        disabled
                      />
                    </Grid>
                    <Grid item md={1} p={3}>
                      <Box
                        sx={{
                          display: "flex",
                          marginBottom: "30px",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          variant="contained"
                          type="submit"
                          onClick={() => handleClick(index)}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Grid> */}
                    <Grid item md={7}>
                      <ResourceBifercationAndTeckStack
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        index={index}
                        values={values.techStack[index]}
                        item={item}
                      />
                    </Grid>
                    <Grid item md={1} p={3}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        {userPerm.hasAccess(systemPermissions['vendorProfileDetailPage.techStackForte'],actions.edit)&&
                        <Button
                          variant="contained"
                          onClick={() => handleClick(index, item)}
                        >
                          Save
                        </Button>
                        }
                      </Box>
                    </Grid>
                    <Grid item md={1} p={3}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        {userPerm.hasAccess(systemPermissions['vendorProfileDetailPage.techStackForte'],actions.delete)&&
                        <Button
                          variant="contained"
                          x
                          onClick={() => deleteInputRow(index, item)}
                        >
                          Delete
                        </Button>
                        }
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default TechStackForte;
