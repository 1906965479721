import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Drawer,
  Grid,
  Stack,
  Box,
  Checkbox,
  Button,
  Typography,
  Chip,
} from "@mui/material";
import Editor from "./../../../components/editor/Editor";
import { mockImgCover } from "../../../utils/mockImages";
import FormatBoldOutlinedIcon from "@mui/icons-material/FormatBoldOutlined";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedRoundedIcon from "@mui/icons-material/FormatUnderlinedRounded";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import UserMoreMenu from "./../../../components/_dashboard/user/UserMoreMenu";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: "white",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  marginBottom: theme.spacing(1),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  borderBottom: "1px solid rgba(0, 0, 0, .125)",
  padding: `0px`,
}));

const ProjectItemFirst = styled(Grid)(({ theme }) => ({
  borderRight: "1px solid rgba(0, 0, 0, .125)",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F5F5F5",
  },
  padding: `${theme.spacing(1)} ${theme.spacing(6)}`,
}));

const ProjectItem = styled(Grid)(({ theme }) => ({
  borderRight: "1px solid rgba(0, 0, 0, .125)",
  textAlign: "center",
  padding: theme.spacing(1),
}));

const ProjectItemContainer = styled(({ children }) => (
  <Grid sx={{ borderBottom: "1px solid rgba(0, 0, 0, .125)" }} container>
    {children}
  </Grid>
))(() => ({}));
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Drawerr = styled(Drawer)(() => ({
  "&": {
    width: "800px",
  },
}));

function Phases() {
  const [expanded, setExpanded] = useState("panel1");
  const [showDrawer, setShowDrawer] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>Login SignUp Page</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProjectItemContainer>
              <ProjectItemFirst onClick={() => setShowDrawer(true)} item md={4}>
                Login Custom
              </ProjectItemFirst>
              <ProjectItem item md={2}>
                Keshav Singh
              </ProjectItem>
              <ProjectItem item md={2}>
                12.11.2021
              </ProjectItem>
              <ProjectItem item md={2}>
                Do it fast
              </ProjectItem>
              <ProjectItem item md={2}>
                Dev
              </ProjectItem>
            </ProjectItemContainer>
            <ProjectItemContainer>
              <ProjectItemFirst item md={4}>
                Login Custom
              </ProjectItemFirst>
              <ProjectItem item md={2}>
                Keshav Singh
              </ProjectItem>
              <ProjectItem item md={2}>
                12.11.2021
              </ProjectItem>
              <ProjectItem item md={2}>
                Do it fast
              </ProjectItem>
              <ProjectItem item md={2}>
                Dev
              </ProjectItem>
            </ProjectItemContainer>
          </AccordionDetails>
        </Accordion>
      </div>
      <Drawerr
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
      >
        <Box
          sx={{
            display: "flex",
            height: "100vh",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ overflowY: "scroll" }}>
            <Typography variant="h4" gutterBottom mb={4} p={4}>
              Login Custom
            </Typography>
            <Stack direction="row" spacing={4} pl={4} pt={0}>
              <Stack direction="column" spacing={3}>
                <Typography variant="overline" gutterBottom>
                  Assignee
                </Typography>
                <Typography variant="overline" gutterBottom>
                  Due date
                </Typography>
                <Typography variant="overline" gutterBottom>
                  Dependencies
                </Typography>
                <Typography variant="overline" gutterBottom>
                  Status
                </Typography>
                <Typography variant="overline" gutterBottom>
                  Comment
                </Typography>
                <Typography variant="overline" gutterBottom>
                  Priority
                </Typography>
                <Typography variant="overline" gutterBottom>
                  Project
                </Typography>
                <Typography variant="overline" gutterBottom>
                  Description
                </Typography>
              </Stack>
              <Stack direction="column" spacing={3}>
                <Typography variant="overline" gutterBottom>
                  Keshav Singh
                </Typography>
                <Typography variant="overline" gutterBottom>
                  No due date
                </Typography>
                <Typography variant="overline" gutterBottom>
                  Add dependencies
                </Typography>
                <Typography variant="overline" gutterBottom>
                  <Chip
                    sx={{ border: "none", backgroundColor: "lightcyan" }}
                    size="small"
                    variant="outlined"
                    label="To do"
                    color="primary"
                  />
                </Typography>
                <Typography variant="overline" gutterBottom>
                  -
                </Typography>
                <Typography variant="overline" gutterBottom>
                  -
                </Typography>
                <Typography variant="overline" gutterBottom>
                  ABC
                </Typography>
                <Typography variant="overline" gutterBottom>
                  -
                </Typography>
              </Stack>
            </Stack>
            <Box
              sx={{ backgroundColor: "#f0f0f0" }}
              pl={4}
              mt={1}
              pt={0.5}
              pb={2}
            >
              <Stack direction="row" spacing={2} mt={4}>
                <Box
                  component="img"
                  alt="demo"
                  src={mockImgCover(4)}
                  sx={{ width: 32, height: 32, borderRadius: "50%" }}
                />
                <Box>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      sx={{ fontSize: "16px", fontWeight: "600" }}
                      // variant="h6"
                    >
                      Mayank Shakya
                    </Typography>
                    <Box sx={{ display: "flex", textAlign: "right" }}>
                      <UserMoreMenu />
                    </Box>
                  </Stack>
                  <Typography sx={{ whiteSpace: "normal" }}>
                    comment comment comment comment
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" spacing={2} mt={4} pt={2}>
                <Box
                  component="img"
                  alt="demo"
                  src={mockImgCover(4)}
                  sx={{ width: 32, height: 32, borderRadius: "50%" }}
                />
                <Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      sx={{ fontSize: "16px", fontWeight: "600" }}
                      // variant="h6"
                    >
                      Mayank Shakya
                    </Typography>
                    <UserMoreMenu />
                  </Stack>
                  <Typography sx={{ whiteSpace: "normal" }}>
                    comment comment comment comment comment comment
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={2} mt={4} pt={2}>
                <Box
                  component="img"
                  alt="demo"
                  src={mockImgCover(4)}
                  sx={{ width: 32, height: 32, borderRadius: "50%" }}
                />
                <Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      sx={{ fontSize: "16px", fontWeight: "600" }}
                      // variant="h6"
                    >
                      Mayank Shakya
                    </Typography>
                    <UserMoreMenu />
                  </Stack>
                  <Typography sx={{ whiteSpace: "normal" }}>
                    comment comment comment comment comment comment
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Box>
          <Box p={4}>
            <Stack direction="row" justifyContent="space-around" spacing={1}>
              <Box
                component="img"
                alt="demo"
                src={mockImgCover(4)}
                sx={{ width: 30, height: 30, borderRadius: "50%" }}
              />
              <Stack>
                <Editor />
                <Stack direction="row" justifyContent="space-between" mt={1}>
                  <Stack direction="row" spacing={-1}>
                    <Checkbox
                      size="small"
                      {...label}
                      icon={<FormatBoldOutlinedIcon />}
                      checkedIcon={<Favorite />}
                    />
                    <Checkbox
                      size="small"
                      {...label}
                      icon={<FormatItalicIcon />}
                      checkedIcon={<BookmarkIcon />}
                    />
                    <Checkbox
                      size="small"
                      {...label}
                      icon={<FormatUnderlinedRoundedIcon />}
                      checkedIcon={<BookmarkIcon />}
                    />
                    <Checkbox
                      size="small"
                      {...label}
                      icon={<AlternateEmailIcon />}
                      checkedIcon={<BookmarkIcon />}
                    />
                    <Checkbox
                      size="small"
                      {...label}
                      icon={<AttachFileIcon />}
                      checkedIcon={<BookmarkIcon />}
                    />
                  </Stack>
                  <Button variant="contained">Comment</Button>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Drawerr>
    </>
  );
}

export default Phases;
